import { __assign } from "tslib";
import { get, each } from '@antv/util';
import { intersect } from '../../../util/collision-detect';
import { getLabelBackgroundInfo } from '../util';
import { createWorker } from '../util/createWorker';
import { code as layoutCode } from './worker/hide-overlap';
var layout = function (items) {
    var boxes = items.slice();
    for (var i = 0; i < boxes.length; i++) {
        var box1 = boxes[i];
        if (box1.visible) {
            for (var j = i + 1; j < boxes.length; j++) {
                var box2 = boxes[j];
                if (box1 !== box2 && box2.visible) {
                    if (intersect(box1, box2)) {
                        box2.visible = false;
                    }
                }
            }
        }
    }
    return boxes;
};
var cache = new Map();
var worker = createWorker(layoutCode);
/**
 * label 防遮挡布局：在不改变 label 位置的情况下对相互重叠的 label 进行隐藏（非移除）
 * 不同于 'overlap' 类型的布局，该布局不会对 label 的位置进行偏移调整。
 * @param labels 参与布局调整的 label 数组集合
 */
export function hideOverlap(labelItems, labels, shapes, region) {
    // todo 添加 label rank
    return new Promise(function (resolve) {
        var boxes = labels.map(function (d, idx) { return (__assign(__assign({}, getLabelBackgroundInfo(d, labelItems[idx], get(labelItems[idx], 'background.padding'))), { visible: true })); });
        var memoKey = JSON.stringify(boxes);
        var cb = function (items) {
            cache.set(memoKey, items);
            each(items, function (_a, idx) {
                var visible = _a.visible;
                var labelShape = labels[idx];
                if (visible) {
                    labelShape === null || labelShape === void 0 ? void 0 : labelShape.show();
                }
                else {
                    labelShape === null || labelShape === void 0 ? void 0 : labelShape.hide();
                }
            });
            return resolve(items);
        };
        if (cache.get(memoKey)) {
            cb(cache.get(memoKey));
        }
        else if (worker) {
            // Do layout in worker.
            try {
                worker.postMessage(JSON.stringify({ type: 'hide-overlap', items: boxes }));
                worker.onmessage = function (e) { return cb(Array.isArray(e.data) ? e.data : []); };
                worker.onmessageerror = function (e) {
                    console.warn('[AntV G2] Web worker is not available');
                    // Normal layout in main thread.
                    cb(layout(boxes));
                };
            }
            catch (e) {
                console.error(e);
                cb(layout(boxes));
            }
        }
        else {
            // Normal layout in main thread.
            cb(layout(boxes));
        }
    });
}
