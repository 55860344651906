import { Button, DatePicker, Input, Space } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const FilterDateBetween = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, column, visible }) => {

	const [fromDate, setFromDate] = useState("")
	const [toDate, setToDate] = useState("")
	const [searchedColumn, setSearchedColumn] = useState("")

	const searchInput = useRef(null)

	const handleSearch = (selectedKeys, confirm, column) => {
		confirm()
		setFromDate(selectedKeys[0])
		setToDate(selectedKeys[1])
		setSearchedColumn(column)
	}

	const handleReset = (clearFilters) => {
		clearFilters()
		setFromDate("")
		setToDate("")
	}

	useEffect(() => {
		if (visible) {
			setTimeout(() => searchInput.current?.focus(), 100)
		}
	}, [visible])

	return (
		<Wrapper>
			<DatePicker.RangePicker
				ref={searchInput}
				value={selectedKeys}
				format={"DD.MM.YYYY"}
				onChange={value => setSelectedKeys(value)}
				style={{
					marginBottom: 8,
				}}
			/>
			{/*<Input
				ref={searchInput}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys, confirm, column)}
				style={{
					marginBottom: 8,
					display: "block",
				}}
				autoFocus
			/>*/}
			<Space style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					type="link"
					onClick={() => clearFilters && handleReset(clearFilters)}
					size="small"
					disabled={!selectedKeys[0]}
				>
					Сбросить
				</Button>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, column)}
					size="small"
				>
					OK
				</Button>
			</Space>
		</Wrapper>
	)
}

export default observer(FilterDateBetween)

const Wrapper = styled.div`
  padding: 8px;
`