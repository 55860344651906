import {makeAutoObservable} from "mobx"

class AppStore {

	showPoly = true
	showLoading = false
	miniMenu = false

	constructor () {
		makeAutoObservable(this)
	}

	setPolyShow (value) {
		this.showPoly = value
	}

	setShowLoading (value) {
		this.showLoading = value
	}

	toggleMiniMenu () {
		this.miniMenu = !this.miniMenu
	}
}

export default AppStore
