import { Form } from "antd"
import { observer } from "mobx-react-lite"
import { useContext } from "react"
import styled from "styled-components"
import { FormContext } from "./TTForm"

const TTItem = ({ children, name, ...props }) => {
	const submitData = useContext(FormContext)

	return (
		<FormItem
			name={name}
			{...((submitData[name] || submitData["*"]) && { validateStatus: "error" })}
			help={submitData[name] ? submitData[name].join("\n") : null}
			{...props}
		>
			{children}
		</FormItem>
	)
}

export default observer(TTItem)

const FormItem = styled(Form.Item)`
  &:last-child {
    margin-bottom: 0;
  }
`