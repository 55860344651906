import { Modal, Steps, Button } from "antd"
import { observer } from "mobx-react-lite"
import { useMemo, useState } from "react"
import styled from "styled-components"
import { Routing } from "../../../../network"
import { useStore } from "../../../../store/Context"
import StepData from "./StepData"
import StepPublish from "./StepPublish"
import StepUpload from "./StepUpload"

const ModalUploadExcel = ({
	visible = false, onClose = () => {},
}) => {

	const store = useStore()

	const [step, setStep] = useState(0)
	const [data, setData] = useState([])
	const [uploading, setUploading] = useState(false)

	const closeModal = () => {
		setStep(0)
		setUploading(false)
		setData([])
		onClose()
	}

	const onPublish = () => {
		if (data.length) {
			setUploading(true)
			setStep(2)
		}
	}

	const steps = useMemo(() => [
		{
			title: "Загрузка файла",
			component: <StepUpload key={"Загрузка файла"} next={data => {
				setData(data)
				setStep(1)
			}}/>,
		},
		{
			title: "Проверка данных",
			component: <StepData key={"Проверка данных"} data={data}/>,
		},
		{
			title: "Генерация точек",
			component: <StepPublish key={"Генерация точек"} points={data} closeModal={closeModal}/>,
		},
	], [data])

	return (
		<Modal
			visible={visible}
			onCancel={closeModal}
			maskClosable={false}
			centered={true}
			width={"auto"}
			afterClose={() => {
				setStep(0)
			}}
			title={"Импорт данных из Excel"}
			bodyStyle={{
				width: "90vw", height: "80vh", flex: 1, display: "flex", flexDirection: "column",
			}}
			footer={[
				<Button disabled={uploading} key="close" onClick={closeModal}>
					Отмена
				</Button>,
				<Button disabled={!step || uploading} key="back" onClick={() => step > 0 && setStep(step - 1)}>
					Назад
				</Button>,
				<Button type={"primary"} disabled={!step} loading={uploading} key="publish"
						onClick={onPublish}>
					Генерировать
				</Button>,
			]}
		>
			<StepsWrapper>
				<Steps current={step}>
					{steps.map(step => <Steps.Step key={step.title} title={step.title}/>)}
				</Steps>
			</StepsWrapper>
			<PagesWrapper>
				{steps[step].component}
			</PagesWrapper>
		</Modal>
	)
}

export default observer(ModalUploadExcel)

const StepsWrapper = styled.div``
const PagesWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;

  > span {
    width: 100%;
  }
`