import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
	Button,
	Empty,
	Modal,
	message,
	Table,
	Tag,
	Descriptions,
} from "antd"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import CopyString from "../../../component/CopyString"
import RoadNumber from "../../../component/RoadNumber"
import TransportLine from "../../../component/TransportLine"
import { useStore } from "../../../store/Context"
import ModalSetTransportForm from "../Modal/ModalSetTransportForm"
import axios from "../../../inizialize/Axios"

const DriverTabTransport = ({ driver }) => {

	const params = useParams()
	const [history, setHistory] = useState([])
	const [showFormModal, setShowFormModal] = useState(false)
	const [historyLoading, setHistoryLoading] = useState(false)

	const store = useStore()

	useEffect(() => {
		reloadHistory()
	}, [driver, params])

	const reloadHistory = async () => {
		setHistoryLoading(true)
		const result = await axios.get("/v1/driver/transport/" + driver.id + "/history")
		setHistoryLoading(false)
		if (result.success) {
			setHistory(result.data)
		}
	}

	const removeConfirm = () => {
		Modal.confirm({
			title: "Отвязка ТС",
			icon: <ExclamationCircleOutlined/>,
			content: "Вы уверены, что хотите отвязать ТС от водителя?",
			okText: "Да",
			cancelText: "Отмена",
			okButtonProps: {
				danger: true,
			},
			onOk: () => detachTransport(),
		})
	}

	const detachTransport = async () => {
		await new Promise(resolve => setTimeout(resolve, 500))
		let result = await axios.post("/v1/driver/transport/" + driver.id + "/detach")
		if (result.success) {
			message.success("ТС отвязано")
			store.driver.update(result.data)
			reloadHistory()
		}
	}

	const columns = useMemo(() => {
		return [
			{
				key: "transport",
				title: "ТС",
				render: (text, record) => (
					<TransportLine
						transport={record.transport}
						withLink
					/>
				),
			},
			{
				key: "gosnumber",
				title: "Госномер",
				render: (text, record) => (
					<RoadNumber number={record.transport.gosnumber}/>
				),
			},
			{
				key: "attach_at",
				title: "Начало периода",
				render: (text, record) => dayjs(record.attach_at).format("DD.MM.YYYY HH:mm"),
			},
			{
				key: "detach_at",
				title: "Конец периода",
				render: (text, record) => record.detach_at ? dayjs(record.detach_at).format("DD.MM.YYYY HH:mm") :
					<Tag color={"green"}>По текущее время</Tag>,
			},
			{
				key: "period",
				title: "Период",
				render: (text, record) => record.detach_at ? dayjs(record.detach_at).from(record.attach_at, true) : dayjs(record.attach_at).fromNow(true),
			},
		]
	}, [])

	return (
		<Wrapper>
			<CurrentTransport>
				{
					driver.transport ? (
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div style={{ display: "flex" }}>
								<TransportLine withLink transport={driver.transport} textSize={24} avatarSize={48}/>
								<div style={{ display: "flex", flex: 1 }}></div>
							</div>
							<Descriptions style={{ marginTop: 15, padding: 10 }}>
								<Descriptions.Item label="СТС">
									<CopyString value={driver.transport.sts}>
										{driver.transport.sts}
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="ПТС">
									<CopyString value={driver.transport.pts}>
										{driver.transport.pts}
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="ОСАГО">
									<CopyString value={driver.transport.insure}>
										{driver.transport.insure}
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="ExternalID">
									<CopyString value={driver.transport.external_id}>
										{driver.transport.external_id}
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="Госномер">
									<CopyString value={driver.transport.gosnumber}>
										<RoadNumber number={driver.transport.gosnumber}/>
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="VIN">
									<CopyString value={driver.transport.vin}>
										{driver.transport.vin}
									</CopyString>
								</Descriptions.Item>
							</Descriptions>
							<Buttons>
								<Button danger type="primary" onClick={removeConfirm}>
									Отвязать ТС
								</Button>
								<Button type="primary" onClick={() => setShowFormModal(true)}>
									Привязать другое ТС
								</Button>
							</Buttons>
						</div>
					) : (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Транспорт не назначен"}>
							<Button type="primary" onClick={() => setShowFormModal(true)}>Назначить водителю ТС</Button>
						</Empty>
					)
				}
			</CurrentTransport>
			<HistoryWrapper>
				{
					history.length !== 0 && (
						<Table
							loading={historyLoading}
							size={"small"}
							dataSource={history.sort((a, b) => {
								return b.id - a.id
							})}
							columns={columns}
							pagination={false}
							rowKey={record => record.id + ""}
						/>
					)
				}
			</HistoryWrapper>
			<ModalSetTransportForm
				data={driver.transport ? { transport_id: driver.transport.id } : null}
				visible={showFormModal}
				onCancel={() => {
					setShowFormModal(false)
				}}
				api={"/v1/driver/transport/" + driver.id + "/attach"}
				onSuccess={data => {
					setShowFormModal(false)
					store.driver.update(data)
					reloadHistory()
				}}
			/>
		</Wrapper>
	)
}

export default observer(DriverTabTransport)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CurrentTransport = styled.div`
  margin: 20px;
  border-radius: 2px;
  background: #242525;
  padding: 10px;
`
const HistoryWrapper = styled.div`
  margin: 0px 20px 20px;
`
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 1px solid #333;
  padding-top: 10px;
  margin-top: 10px;

  .ant-btn {
    margin-left: 10px;
  }
`