import dayjs from "dayjs"
import React from "react"
import Box from "../../assets/svg/box.svg"
import Icon from "../../component/Icon"
import {faCode} from "@fortawesome/pro-regular-svg-icons/faCode"

export const parseLog = (data, duration, setDuration) => {
	if (data.user_name) {
		let tmpName = data.user_name.split(" ")
		if (tmpName.length == 3) {
			data.user_name = tmpName[0] + ' ' + tmpName[1].substr(0, 1) + '.'
		}
	}

	let tmpData = {
		date: data.date,
		text: '',
		sup: {
			text: null,
			color: null
		},
		reason: null,
		icon: null

	}

	switch (data.item_type) {
		case 'route': {
			switch (data.method) {
				case 'add': {
					tmpData.text = 'Добавил ' + data.user_name
					break
				}

				case 'update': {
					tmpData.text = data.user_name + ' изменил МЛ '
					break
				}

				case 'status': {
					switch (data.data.status) {
						case 'canceled:manager': {
							tmpData.text = data.user_name + ' отменил МЛ'
							break
						}
						case 'created': {
							tmpData.text = data.user_name + ' вернул МЛ'
							break
						}
						case 'accepted': {
							//tmpData.icon = <CheckCircleOutlined/>
							//tmpData.text = data.user_name + ' подтвердил МЛ'
							tmpData.text = 'Подтвердил МЛ'
							break
						}
						case 'performed': {
							//if (!durationStart) durationStart = data.date
							//tmpData.icon = <PlayCircleOutlined/>
							//tmpData.text = data.user_name + ' начал исполнение'
							tmpData.text = 'Начал исполнение'
							break
						}
						case 'done': {
							//tmpData.text = data.user_name + ' завершил исполнение'
							tmpData.text = 'Завершил исполнение'
							break
						}
						case
						'canceled:driver'
						: {
							//tmpData.text = data.user_name + ' прервал исполнение'
							tmpData.text = 'Прервал исполнение'
							break
						}
					}

					break
				}

				case
				'api_callback'
				: {
					tmpData.text = 'Уведомление по API'
					tmpData.sup.text = data.data.code === 200 ? 'OK' : 'Error ' + data.data.code
					tmpData.sup.color = data.data.code === 200 ? '#88db57' : '#E75957'
					tmpData.icon = <Icon name={faCode} style={{color: '#C6C2BB'}}/>
					break
				}
			}
			break
		}
		case
		'point'
		: {
			switch (data.method) {
				case "add": {
					tmpData.text = "Добавил " + data.user_name
					break
				}
				case "update": {
					tmpData.text = "Изменил " + data.user_name
					break
				}

				case "status": {
					switch (data.data.status) {
						case "created": {
							//tmpData.text = data.user_name + " Поставил точку в очередь"
							tmpData.text = "Поставил точку в очередь"
							break
						}
						case "on_way": {
							//tmpData.text = data.user_name + " выехал на точку"
							tmpData.text = "Выехал на точку"
							break
						}
						case "arrived": {
							//tmpData.text = data.user_name + ' прибыл на точку'
							tmpData.text = 'Прибыл на точку'
							break
						}
						case "done": {
							//tmpData.text = data.user_name + " завершил точку"
							tmpData.text = "Завершил точку"
							break
						}
						case "canceled:driver": {
							//tmpData.text = data.user_name + " прервал исполнение"
							tmpData.text = "Прервал исполнение"
							break
						}
						case "canceled:manager": {
							//tmpData.text = data.user_name + " отменил точку"
							tmpData.text = "Отменил точку"
							break
						}
					}

					break
				}

				case "api_callback": {
					tmpData.text = "Уведомление по API"
					tmpData.sup.text = data.data.code === 200 ? "OK" : "Error " + data.data.code
					tmpData.sup.color = data.data.code === 200 ? "#88db57" : "#E75957"
					tmpData.icon = <Icon name={faCode} style={{color: '#C6C2BB'}}/>
					break
				}
			}
			break
		}
	}

	if (data?.reason) {
		tmpData.reason = data.reason
	}

	return tmpData
}
