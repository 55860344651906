/** tofront: 同步所有元素的位置  */
export function placeElementsOrdered(view) {
    if (!view) {
        return;
    }
    var elements = view.geometries[0].elements;
    elements.forEach(function (elem) {
        elem.shape.toFront();
    });
}
