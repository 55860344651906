import { action, makeObservable, observable, runInAction } from "mobx"
import Pusher from "pusher-js"
import { useEffect, useRef } from "react"
/*import * as Updates from "expo-updates"*/
import config from "../config"

//Pusher.logToConsole = __DEV__
const useBroadcastArr = []
const whenBroadcastArr = []

class SocketStore {

	pusher = null
	status = "disconnected"
	error = null

	config = {
		app_key: "socket",
		encrypted: true,
		wsPort: 443,
		logToConsole: false,
		enabledTransports: ["ws"],
		forceTLS: true,
		httpHost: config.axios.baseURL.replace("http://", "").replace("https://", ""),
		wsHost: config.axios.baseURL.replace("http://", "").replace("https://", ""),
		authEndpoint: config.axios.baseURL + "/user/v1/profile/socket",
	}

	id = null
	channels = []
	startChannels = []

	constructor (store) {
		makeObservable(this, {
			status: observable,
			error: observable,
			id: observable,
			channels: observable,
			connect: action,
			disconnect: action,
			setStatus: action,
			subscribe: action,
			unsubscribe: action,
		})
		this.store = store
	}

	events (event, data, channel) {

		switch (event) {
			case "alert": {
				/*Alert.alert(
					data.title,
					data.text,
					[{
						text: "Ok",
					}],
					{cancelable: true},
				)*/
				break
			}
			case "update": {
				//Updates.reloadAsync()
				break
			}
			case "reload": {
				//Updates.reloadAsync()
				break
			}
			default: {

				const whens = whenBroadcastArr.filter(item => item.event === event)
				if (whens.length) {
					for (let i = 0; i < whens.length; i++) {
						const when = whens[i]
						if (when.callback?.(data, channel)) {
							whenBroadcastArr.splice(i, 1)
						}
					}
				}

				useBroadcastArr.map(item => {
					if (item.event === event || item.event === "all") {
						item.callback(data, event, channel)
					}
				})
			}
		}
	}

	setStatus (status) {
		this.status = status

		switch (status) {
			case "connected": {
				this.id = this.pusher.connection.socket_id
				this.startChannels.forEach(item => {
					this.subscribe(item)
				})
				break
			}
			case "failed": {
				setTimeout(() => {
					this.connect()
				}, 3000)
				break
			}
			default: {
				this.id = null
			}
		}
	}

	pusherEvent () {
		this.pusher.connection.bind("state_change", state => {
			this.setStatus(state.current)
		})
	}

	connect (channels, token = null) {

		if (!token) {
			this.config.auth = {}
			return false
		}
		this.startChannels = channels
		this.config.auth = {
			headers: {
				Authorization: "Bearer " + token,
			},
		}
		this.pusher = new Pusher(this.config.app_key, this.config)
		this.pusherEvent()
	}

	disconnect () {
		if (this.pusher !== null) {
			this.channels = []
			this.startChannels = []
			this.config.auth = {}
			this.pusher.disconnect()
		}
	}

	subscribe (name) {
		const channel = this.pusher.subscribe(name)

		channel.bind_global((event, data) => {
			switch (event) {
				case "pusher:subscription_succeeded": {
					runInAction(() => {
						this.channels.push(channel)
					})
					break
				}
				case "pusher:subscription_error ": {
					this.pusher.unsubscribe(name)
					break
				}
				default: {
					this.events(event, data, channel.name)
				}
			}
		})
	}

	unsubscribe (name) {
		const channel = this.channels.find(item => item.name === name)
		if (channel) {
			channel.unbind_global()
			this.pusher.unsubscribe(name)
			this.channels.splice(this.channels.findIndex(item => item.name === name), 1)
		}
	}
}

export const whenBroadcast = (event, callback) => {
	whenBroadcastArr.push({ event, callback })
}

export const useBroadcast = (callback, events) => {
	const saveCallback = useRef()

	useEffect(() => {
		saveCallback.current = callback
	}, [callback])

	useEffect(
		() => {
			const lengths = []
			if (typeof events !== "object") {
				events = [events]
			}

			for (const event in events) {
				lengths.push(useBroadcastArr.push({
					event: events[event],
					callback: saveCallback.current,
				}))
			}

			return () => {
				lengths.map(length => useBroadcastArr.splice(length - 1, useBroadcastArr.length))
			}
		},
		[],
	)
}

export default SocketStore
