import { UploadOutlined } from "@ant-design/icons"
import { Button, Form, Input, Upload } from "antd"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import styled from "styled-components"

const MediaUpload = ({ name }) => {

	const [fileList, setFileList] = useState([])
	const form = Form.useFormInstance()

	const getFile = e => {
		if (Array.isArray(e)) {
			return e
		}
		return e && e.fileList.map(item => item.originFileObj ?? item)
	}

	return (
		<Wrapper
			name={name}
			validateStatus={form.getFieldError(name).length ? "error" : undefined}
			help={form.getFieldError(name).length > 0 && form.getFieldError(name).join("\n")}
			getValueFromEvent={getFile}
		>
			<Upload
				accept={"image/*"}
				multiple
				fileList={fileList}
				defaultFileList={[]}
				beforeUpload={file => {
					setFileList(fileList => [...fileList, file])
					return false
				}}
				onRemove={file => {
					const index = fileList.indexOf(file)
					const newFileList = fileList.slice()
					newFileList.splice(index, 1)
					setFileList(newFileList)
				}}
			>
				<UploadLink size={"small"} type="primary" icon={<UploadOutlined/>}>Загрузить документ</UploadLink>
			</Upload>
		</Wrapper>
	)
}

export default observer(MediaUpload)

const Wrapper = styled(Form.Item)`
	margin: -15px 0 0;
`
const UploadLink = styled(Button)``