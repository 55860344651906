import Avatar from "antd/es/avatar/avatar"
import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import { getPrettyPhone } from "../../../../store/helper/StringMethod"

const DriverListItem = ({ driver }) => {

	return (
		<Wrapper>
			<AvatarWrapper>
				<Avatar
					src={process.env.REACT_APP_API_URL + "/media/driver/" + driver.login + "/avatar"}
					size={22}/>

			</AvatarWrapper>
			<InfoWrapper style={{}}>
				<Name>
					{driver.surname} {driver.name} {driver.middlename}
				</Name>
				<Phone>
					{driver.phone && (getPrettyPhone(driver.phone))}
				</Phone>
			</InfoWrapper>
		</Wrapper>
	)
}

export default observer(DriverListItem)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`
const AvatarWrapper = styled.div``
const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
`
const Name = styled.div`
  color: #E9E5DE;
  font-weight: 500;
`
const Phone = styled.div`
  opacity: .7;
  font-weight: 300;
  font-size: 12px;
`