import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import { Image } from "antd"
import PointPhotosItem from "./PointPhotoItem"

const PointPhotosTab = ({ point }) => {

	return (
		<Wrapper>
			<Image.PreviewGroup>
				{
					point.media?.map(media => (
						<PointPhotosItem
							point={point.id}
							image={media}
						/>
					))
				}
			</Image.PreviewGroup>
		</Wrapper>
	)
}

export default observer(PointPhotosTab)

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  opacity: .80;
  justify-content: center;
`
