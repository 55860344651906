import { observer } from "mobx-react-lite"
import { Marker } from "react-map-gl"

const MapboxMarker = ({
	children,
	...props
}) => {

	return (
		<Marker {...props}>
			{children}
		</Marker>
	)
}

export default observer(MapboxMarker)
