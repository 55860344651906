import { Form, message, Modal } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "../../inizialize/Axios"
import { useBroadcast, whenBroadcast } from "../../store/SocketStore"
import { FieldProvider } from "./Context"
import { FormItem } from "./index"

const ModalForm = ({
	title = "",
	defaultData = {},
	modificator = false,
	loading = false,
	visible = false,
	width = 700,
	maskClosable = false,
	onCancel = () => {},
	onSuccess = null,
	onError = null,
	api = false,
	children,
	labelCol = 8,
	wrapperCol = 16,
	waitSocket = false,
	closeOnSuccess = true,
	modificatorOnSend = false,
	messageFormSuccess = null,
	messageFormFail = null,
	messageFormSuccessDuration = null,
	messageFormFailDuration = null,
}) => {

	const [data, setData] = useState({})
	const [error, setError] = useState({})
	const [formLoading, setFormLoading] = useState(false)
	const [form] = Form.useForm()

	useEffect(() => {
		if (api) {
			loadPatch()
		}
	}, [api])

	useEffect(() => {
		if (visible) {
			if (modificator) {
				defaultData = modificator({ ...defaultData })
			}
			form.setFieldsValue(defaultData)
		} else {
			form.resetFields()
		}
	}, [defaultData, visible])

	useEffect(() => {
		if (Object.entries(error).length) {
			for (const name in form.getFieldsValue()) {
				form.setFields([{ name, errors: null }])
			}
			for (const name in error) {
				form.setFields([{ name, errors: [error[name]] }])
			}
		}
	}, [error])

	const loadPatch = async () => {
		const data = await axios.patch(api)
		if (data.success) {
			setData(data.data)
		}
	}

	const sendForm = async () => {

		console.log("defaultData", defaultData)
		let values = { ...defaultData, ...form.getFieldsValue() }

		if (modificatorOnSend) {
			values = modificatorOnSend(values)
		}

		const formData = new FormData()
		for (const name in values) {
			if (Array.isArray(values[name])) {
				formData.appendArray(name, values[name])
			} else if (typeof values[name] === "object") {
				formData.appendObject(name, values[name])
			} else {
				formData.append(name, values[name] ?? "")
			}
		}
		setError({})
		setFormLoading(true)
		const result = await axios.post(api, formData)
		if (result.success) {
			message.success(messageFormSuccess ?? "Форма отправлена успешно", messageFormSuccessDuration ?? 2)
			if (onSuccess) {
				onSuccess(result.data, form)
			}
			if (closeOnSuccess) {
				cancelHandler()
			}
			if (!waitSocket) {
				setFormLoading(false)
			}
		} else {
			setFormLoading(false)
			message.error(messageFormFail ?? "Ошибка при отправке формы", messageFormFailDuration ?? 2)
			if (result.error?.type === "validation") {
				setError(result.error.data)
			}
			if (onError) {
				onError(result.error)
			}
		}
	}

	const cancelHandler = () => {
		if (!waitSocket) {
			return onCancel(form)
		}
		whenBroadcast(waitSocket[0], (data) => {
			if (data.id === waitSocket[1]) {
				setFormLoading(false)
				onCancel(form)
				return true
			}
		})
	}

	const render = () => {
		if (children) {
			if (typeof children === "function") {
				return children({ form })
			}
			return children
		}

		return data.fields?.map(field => !field.ignore && (
			<FormItem
				key={field.name}
				name={field.name}
			/>
		))
	}

	return (
		<Modal
			title={title ?? data.title}
			visible={visible}
			width={width}
			maskClosable={maskClosable}
			onCancel={() => {
				form.resetFields()
				onCancel(form)
			}}
			onOk={sendForm}
			confirmLoading={loading || formLoading}
		>
			<FieldProvider value={data}>
				<Form
					labelCol={{ span: labelCol }}
					wrapperCol={{ span: wrapperCol }}
					disabled={formLoading}
					form={form}
					name={data.uri}
				>
					{render()}
				</Form>
			</FieldProvider>
		</Modal>
	)
}

export default observer(ModalForm)

const Wrapper = styled.div``