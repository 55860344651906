import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	Tabs,
	message,
	Button,
	Dropdown,
	Menu,
	Modal, Breadcrumb,
} from "antd"
import {
	EllipsisOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons"
import DriverLine from "../../component/DriverLine"
import PageHeader from "../../component/PageHeader"
import TransportLine from "../../component/TransportLine"
import { useStore } from "../../store/Context"
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Transport } from "../../network"
import TransportTabDocument from "./component/TransportTabDocument"
import TransportTabDriver from "./component/TransportTabDriver"
import TransportTabGeneral from "./component/TransportTabGeneral"
import ModalTransportForm from "./Modal/ModalTransportForm"

const TransportViewScreen = () => {

	let { id = null, tab = "general" } = useParams()
	const navigate = useNavigate()
	const store = useStore()
	const [transport, setTransport] = useState(null)
	const [showFormModal, setShowFormModal] = useState(false)

	const menu = (
		<Menu
			items={[
				{
					label: "Редактировать",
					key: "update",
				},
				{
					danger: true,
					label: "Удалить",
					key: "remove",
				},
			]}
			onClick={({ key }) => {
				switch (key) {
					case "update": {
						setShowFormModal(true)
						break
					}
					case "remove": {
						removeConfirm()
						break
					}
				}
			}}
		/>
	)

	useEffect(() => {
		const load = async () => {
			id = parseInt(id)
			let data = store.transport.items.find(item => item.id === id)
			if (data) {
				setTransport(data)
			} else {
				let result = await Transport.info({ id: id })
				if (result.success) {
					store.transport.add(result.data)
					load()
				} else {
					navigate("/404")
				}
			}
		}
		load()
	}, [id])

	const removeConfirm = () => {
		Modal.confirm({
			title: "Подтвердите удаление",
			icon: <ExclamationCircleOutlined/>,
			content: "После удаления данные нельзя будет восстановить.",
			okText: "Удалить",
			cancelText: "Отмена",
			okButtonProps: {
				danger: true,
			},
			onOk: () => remove(),
		})
	}

	const remove = async () => {
		await new Promise(resolve => setTimeout(resolve, 500))
		let result = await Transport.remove({ id: transport.id })
		if (result.success) {
			message.success("Транспорт удален")
			return navigate("/transport")
		}
	}

	if (!transport) return null

	return (
		<Wrapper>
			<PageHeader
				title={[
					<Link to={"/transport"}>Транспорт</Link>,
					<TransportLine
						transport={transport}
						avatarSize={36}
						withName
					/>,
				]}
			>
			</PageHeader>
			<TabsWrapper
				tabBarExtraContent={(
					<ActionBlock>
						<Dropdown overlay={menu}>
							<Button>
								Управление <EllipsisOutlined/>
							</Button>
						</Dropdown>
					</ActionBlock>
				)}
				onChange={key => {
					navigate("/transport/" + transport.id + "/" + (key !== "general" ? key : ""))
				}}
				defaultActiveKey={tab}
			>
				<TabPane tab="Общая информация" key="general">
					<TransportTabGeneral transport={transport}/>
				</TabPane>
				<Tabs.TabPane tab="Водители" key="driver">
					<TransportTabDriver transport={transport}/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Документы" key="docs">
					<TransportTabDocument transport={transport}/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Штрафы" key="fines" disabled/>
				{/*<Tabs.TabPane tab="Логи" key="logs">
					<TransportTabLog transport={transport}/>
				</Tabs.TabPane>*/}
			</TabsWrapper>
			<ModalTransportForm
				visible={showFormModal}
				onCancel={() => setShowFormModal(false)}
				api={"/v1/transport/update/" + transport.id}
				type={"update"}
				data={transport}
				waitSocket={["transport.update", transport.id]}
			/>
		</Wrapper>
	)
}

export default observer(TransportViewScreen)

const TabPane = styled(Tabs.TabPane)`
`
const TabsWrapper = styled(Tabs)`
  background: #2a2c2c;

  .ant-tabs-nav-wrap {
    padding-left: 20px;
    background: #242525;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
`
const ActionBlock = styled.div`
  position: absolute;
  bottom: 10px;
  right: 25px;
`
const BreadName = styled.span`
  margin-right: 5px;
`