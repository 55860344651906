import { types } from "mobx-state-tree"
import { Route } from "../../network"
import RouteStatus from "../helper/RouteStatus"
import { RouteItem, TableItem } from "../item"

const RouteArchive = types.model({
	name: "archive",
	loading: false,
	items: types.optional(types.array(RouteItem), []),
	table: types.optional(TableItem, {}),
}).actions(self => ({
	async fetch () {
		self.setLoading(true)
		const list = await Route.list(self.table.toServer)
		if (list.success) {
			self.table.fromServer(list.data)
			self.setItems(list.data.list)
		}
		self.setLoading(false)
	},
	async clean () {
		self.items = []
	},
	setLoading (loading) {
		self.loading = loading
	},
	setItems (items) {
		self.items = items
	},
})).views(self => ({
	get getFilterStatus () {
		const result = []
		self.items.map(item => {
			if (result.findIndex(i => i.value === item.status) === -1) {
				result.push({
					text: RouteStatus[item.status].name,
					value: item.status,
				})
			}
		})
		return result
	},
}))

export default RouteArchive
