import {faPen} from "@fortawesome/pro-regular-svg-icons/faPen"
import {faBan} from "@fortawesome/pro-regular-svg-icons/faBan"
import {Menu, Modal} from "antd"
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync"
import Icon from "../../../../component/Icon"
import React from "react"
import {faRocketLaunch} from "@fortawesome/pro-regular-svg-icons/faRocketLaunch"
import {faLockOpen} from "@fortawesome/pro-regular-svg-icons"
import {faUnlock} from "@fortawesome/pro-regular-svg-icons/faUnlock"
import {faCut} from "@fortawesome/pro-regular-svg-icons/faCut"

export const routeContextMenu = (route, store, publish, syncRoute, setEditRouteItem, setShowRouteFormModal, changeStatus, remove) => {

	const readyToRelease = (route.driver_id && !route.locked && route.draft && store.routing.points.findIndex(_point => _point.route_id === route.id) !== -1)

	let menuOptions = [
		{
			title: 'Отправить в работу',
			onClick: () => {
				Modal.confirm({
					title: 'Внимание!',
					content: 'После отправки МЛ в работу, он станет доступным водителю. Дальшнейшее изменение МЛ будет производиться в особом порядке.',
					visible: true,
					//centered: true,
					cancelText: 'Закрыть',
					okText: 'Отправить в работу',
					onOk: () => publish(route)
				})
			},
			color: readyToRelease ? '#88db57' : '#C9C6BF',
			icon: faRocketLaunch,
			disabled: !readyToRelease,
			show: true
		},
		{
			title: route.locked ? 'Продолжить редактирование' : 'Заблокировать изменения',
			onClick: () => setTimeout(() => {
				store.routing.setRouteLocked(route.id, !route.locked)
				syncRoute(route.id)
			}, 200),
			color: '#C9C6BF',
			icon: route.locked ? faLockOpen : faUnlock,
			disabled: !route.draft,
			show: true
		},
		{
			title: 'Редактировать',
			onClick: () => {
				setEditRouteItem(route)
				setShowRouteFormModal(true)
			},
			icon: faPen,
			disabled: !route.draft || route.locked,
			color: '#C9C6BF',
			show: true
		},
		{
			title: 'Отменить МЛ',
			onClick: () => {
				Modal.confirm({
					title: 'Внимание!',
					content: 'После отмены МЛ водитель не сможет с ним работать.',
					visible: true,
					cancelText: 'Закрыть',
					okText: 'Отменить МЛ',
					onOk: () => changeStatus(route, 'canceled:manager'),
					okButtonProps: {
						danger: true
					}
				})
			},
			color: '#E75957',
			icon: faBan,
			disabled: route.status === 'done',
			show: !route.draft && !route.isCanceled,
		},
		{
			title: 'Восстановить МЛ',
			onClick: () => {
				Modal.confirm({
					title: 'Внимание!',
					content: 'Подтвердите восстановление МЛ.',
					visible: true,
					cancelText: 'Закрыть',
					okText: 'Восстановить',
					onOk: () => changeStatus(route, 'created')
				})
			},
			color: '#88db57',
			icon: faSync,
			disabled: false,
			show: !route.draft && route.isCanceled,
		},
		{
			title: 'Удалить',
			onClick: () => {
				Modal.confirm({
					title: 'Внимание!',
					content: 'Все точки, которые были в этом МЛ, будут отвязаны.',
					visible: true,
					cancelText: 'Закрыть',
					okText: 'Удалить МЛ',
					onOk: () => remove(route),
					okButtonProps: {
						danger: true
					}
				})
			},
			color: '#E75957',
			icon: faCut,
			disabled: route.locked,
			show: route.draft
		}
	]

	return (
		<Menu
			items={
				menuOptions.filter(_menu => _menu.show).map((menu, index) => {
					return {
						key: 'r' + index,
						label: menu.title,
						onClick: menu.onClick,
						disabled: menu.disabled,
						icon: <Icon
							name={menu.icon}
							color={(menu.color && !menu.disabled) ? menu.color : menu.disabled ? '#636363' : null}
							size={14}
							style={{marginRight: 5}}
						/>,
						style: {color: (menu.color && !menu.disabled) ? menu.color : menu.disabled ? '#636363' : null}
					}
				})
			}/>
	)
}
