import { toJS } from "mobx"
import { cast, types, getParent } from "mobx-state-tree"
import moment from "moment"
import qs from "qs"
import store from "../index"

const Column = types.model({
	column: types.string,
	checked: false,
})

const Filter = types.model({
	column: types.string,
	checked: false,
	value: types.optional(types.union(types.null, types.string, types.array(types.string), types.array(types.frozen())), null),
})

const PossibleColumn = types.model({
	column: types.string,
	title: types.maybe(types.string),
})

const PossibleFilter = types.model({
	column: types.string,
	title: types.maybeNull(types.string),
	type: types.maybeNull(types.string),
	subtype: types.optional(types.union(types.null, types.string), null),
	header: false,
	thead: false,
	possible: types.optional(types.union(types.null, types.string, types.array(types.string), types.array(types.frozen())), null),
})

export const TableItem = types.model({
	count: types.optional(types.integer, 0),
	currentPage: types.optional(types.integer, 1),
	pageSize: types.optional(types.integer, 15),
	order: types.optional(types.string, ""),
	orderBy: types.optional(types.enumeration(["asc", "desc"]), "desc"),
	filters: types.optional(types.array(Filter), []),
	columns: types.optional(types.array(Column), []),
	possibleFilters: types.optional(types.array(PossibleFilter), []),
	possibleColumns: types.optional(types.array(PossibleColumn), []),
	possibleOrders: types.optional(types.array(types.string), []),
})
	.actions(self => ({

		setColumns (columns, save = true) {
			if (JSON.stringify(toJS(self.columns)) !== JSON.stringify(columns)) {
				self.columns = columns
				if (save) {
					self.saveSettings()
				}
			}
		},

		setFilters (filters, save = true) {
			if (JSON.stringify(toJS(self.filters)) !== JSON.stringify(filters)) {
				self.filters = filters
				if (save) {
					self.saveSettings()
				}
			}
		},

		setColumnChecked (column, checked) {
			self.setColumns(self.getColumns.map(item => ({
				column: item.column,
				checked: item.column === column ? checked : item.checked,
			})))
		},

		setFilterChecked (column, checked) {
			self.setFilters(self.getFilters.map(item => ({
				column: item.column,
				checked: item.column === column ? checked : item.checked,
				value: item.value,
			})))
		},

		removeFilter (filter, refresh = true) {
			self.setFilters(self.getFilters.map(item => ({
				column: item.column,
				checked: item.column === filter ? false : item.checked,
				value: item.column === filter ? null : item.value,
			})))
			if (refresh) {
				getParent(self).fetch()
			}
		},

		saveSettings () {
			store.user.setSettings(self.getName, self.getSettings)
		},

		fromServer (data) {
			self.count = data.count ?? 0
			self.possibleFilters = cast(Object.entries(data.filters).map(([column, item]) => ({
				column,
				...item,
			})))
			self.possibleColumns = cast(Object.entries(data.columns).map(([column, title]) => ({
				column,
				title,
			})))
			self.possibleOrders = cast(data.orders)
		},

		fromClient ({ pagination, filters, sorter }, refresh = true) {
			self.currentPage = pagination.current
			self.pageSize = pagination.pageSize
			self.order = sorter.column?.dataIndex
			self.orderBy = sorter.order === "ascend" ? "asc" : "desc"

			Object.entries(filters).map(([column, value]) => {
				self.setFilters(self.getFilters.map(item => ({
					column: item.column,
					checked: item.column === column ? value !== null : item.checked,
					value: item.column === column ? value : item.value,
				})))
			})

			if (refresh) {
				getParent(self).fetch()
			}
			//self.setFilters(filters, refresh)
		},

		fromUri (uri) {
			if (!uri) {
				self.currentPage = 1
				self.pageSize = 15
				self.order = "id"
				self.orderBy = "desc"

				self.filters = []
				return
			}
			const query = qs.parse(uri)

			self.currentPage = parseInt(query._page ?? 1)
			self.pageSize = parseInt(query._size ?? 15)
			self.order = query._order?.[0] ?? "id"
			self.orderBy = query._order?.[1] ?? "desc"

			Object.entries(query._filters ?? {}).map(([column, value]) => {
				if (column === "date") {
					value = value.map(item => moment(item))
				}
				const find = self.filters.find(item => item.column === column)
				if (find) {
					find.checked = true
					find.value = value
				} else {
					self.filters.push({
						column,
						checked: true,
						value,
					})
					if (!self.possibleFilters.find(item => item.column === column)) {
						self.possibleFilters.push({ column })
					}
				}
			})
		},
	}))
	.views(self => ({
		get getSettings () {
			return {
				columns: toJS(self.columns),
			}
		},
		get getOrders () {
			return toJS(self.possibleOrders)
		},
		get getColumns () {

			let columns = self.columns
			if (self.columns.length !== self.possibleColumns.length) {
				columns = self.possibleColumns
			}

			return toJS(columns.map(column => ({
				...self.possibleColumns.find(item => item.column === column.column),
				...self.columns.find(item => item.column === column.column),
			})))
		},
		get getFilters () {
			return toJS(self.possibleFilters.map(filter => ({
				...toJS(filter),
				...toJS(self.filters.find(item => item.column === filter.column)),
			})))
		},
		get getName () {
			return "table" + getParent(self).name[0].toUpperCase() + getParent(self).name.substring(1)
		},

		get getFilterToServer () {
			const result = {}
			self.getFilters.map(filter => {
				if (filter.checked) {
					const values = Array.isArray(filter.value) ? [...filter.value] : [filter.value]
					values.forEach((item, index) => {
						if (moment.isMoment(item)) {
							values[index] = item.format("YYYY-MM-DD HH:mm:ss")
						}
					})
					result[filter.column] = values
				}
			})

			return result
		},

		get toServer () {
			return {
				_page: self.currentPage,
				_size: self.pageSize,
				_order: [
					self.order,
					self.orderBy,
				],
				_filters: self.getFilterToServer,
			}
		},

		get getConfig () {
			return {
				current: self.currentPage,
				defaultPageSize: self.pageSize,
				total: self.count,
			}
		},
	}))
