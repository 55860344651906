import {observer} from "mobx-react-lite"
import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import RouteStatus from "../../../store/helper/RouteStatus"
import PointStatus from "../../../store/helper/PointStatus"
import ModalRouteView from "../../Route/ModalRouteView"
import {prettyTimePeriod} from "../../../store/helper/StringMethod";

const DashboardRouteList = ({data, activeRoute, setActiveRoute, mapRef}) => {

	const [showInfoModal, setShowInfoModal] = useState(false)

	useEffect(() => {
		if (activeRoute) {
			try {
				setTimeout(() => {
					document.getElementById('route-panel-' + activeRoute.id).scrollIntoView({
						block: "center",
						behavior: "smooth"
					})
				}, 1)
			} catch (e) {
			}
		}
	}, [activeRoute])

	const flyToPoint = (point) => {
		if (point.address_location?.lat && point.address_location?.lng) {
			mapRef.current.flyTo({
				center: [
					point.address_location?.lng,
					point.address_location?.lat,
				],
				zoom: 14,
				speed: 2
			})
		}
	}

	return (
		<ListWrapper>
			{
				data.map(item => (
					<RouteListItem
						key={'route-panel-' + item.id}
						onClick={() => setActiveRoute(activeRoute => (!activeRoute || (activeRoute.id !== item.id)) ? item : null)}
						borderRight={RouteStatus[item.status].color}
						backgroundColor={activeRoute && activeRoute.id === item.id ? "#212121" : "null"}
						className={activeRoute?.id === item.id ? 'activeRoute' : activeRoute?.id && (activeRoute.id !== item.id) ? 'blurred' : ''}
						id={'route-panel-' + item.id}
					>
						<RouteInfo>
							<Left>
								<DriverName>
									{item.driver?.stripName}
								</DriverName>
								<StatusBar>
									<RouteName>
										{
											activeRoute?.id === item.id ? (
													<a onClick={(e) => {
														setShowInfoModal(true)
														e.stopPropagation()
													}}>{item.name}</a>
												)
												: (
													item.name
												)
										}
									</RouteName>
								</StatusBar>
							</Left>
							<Right>
								{item.countReadyPoints.ready} из {item.countReadyPoints.all}
							</Right>
						</RouteInfo>
						{
							activeRoute?.id === item.id && (
								<ActiveRouteList>
									{
										item.points.map(point => (
											<PointItem
												onClick={(e) => {
													e.stopPropagation()
													flyToPoint(point)
												}}
												key={'point-panel-' + point.id}
												className={point.status === 'on_way' && 'point-on-way'}
											>
												<PointNumber>
													{point.position < 10 && '0'}{point.position + '. '}
												</PointNumber>
												<PointName title={point.name}>
													{point.name}
												</PointName>
												<PointTime
													style={point.status !== 'on_way' ? {color: PointStatus[point.status].color} : null}
												>
													{prettyTimePeriod(point.from_at, point.to_at)}
												</PointTime>
											</PointItem>
										))
									}
								</ActiveRouteList>
							)
						}
					</RouteListItem>
				))
			}
			{
				showInfoModal && (
					<ModalRouteView
						route={activeRoute}
						onCancel={() => setShowInfoModal(false)}
					/>
				)
			}
		</ListWrapper>
	)
}

export default observer(DashboardRouteList)

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2;
  overflow-y: scroll;

  .activeRoute {
    width: 350px;
    margin-block: 3px;
  }

  .blurred {
    filter: blur(1px);
    opacity: .95;
  }

  .point-on-way {
    animation: color-change 2s infinite;

    @keyframes color-change {
      0% {
        color: #C8C4BD;
      }
      50% {
        color: #409FE7;
      }
      100% {
        color: #C8C4BD;
      }
    }
  }
`

const RouteListItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  border-left: solid 5px ${props => props.borderRight + "99"};
  border-bottom: solid 1px #3e4141;
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  width: 262px;
  font-size: 14px;
  background-color: #242424;

  &:hover {
    background: #212121;
  }
`

const RouteInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const ActiveRouteList = styled.div`
  font-size: 13px;
`

const PointItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-inline: 5px;
  padding-block: 8px;
`
const PointNumber = styled.div`
  padding-right: 3px;
`
const PointName = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const PointTime = styled.div`
  padding-left: 10px;
  text-align: end;
  align-self: flex-start;
`

const Left = styled.div`
  width: 150px;
  flex: 1;
`
const Right = styled.div`
`
const RouteName = styled.div`

`
const DriverName = styled.div`
  max-width: 200px;
`
const StatusBar = styled.div`

`

