import {observer} from "mobx-react-lite"
import React, {useCallback, useRef, useState} from "react"
import {useStore} from "../../../store/Context"
import {AutoComplete, Button, Form, Input, message, Modal, TimePicker} from "antd"
import styled from "styled-components"
import Mapbox from "../../../component/Mapbox/Mapbox"
import Flag from "../../../assets/svg/flag.svg"
import {CopyOutlined} from '@ant-design/icons'
import {debounce} from "lodash";
import {External, Point} from "../../../network"
import {prettyDadataAddress} from "../../../store/helper/StringMethod"
import {MapboxMarker} from "../../../component/Mapbox"
import dayjs from "dayjs"
import {round} from "lodash/math"

const ModalPointForm = ({
							editPointItem,
							setEditPointItem
						}) => {

	const store = useStore()
	const mapAddressRef = useRef()

	const [loading, setLoading] = useState(false)
	const [addressOptions, setAddressOptions] = useState([])
	const [name, setName] = useState(editPointItem.name)
	const [address, setAddress] = useState(editPointItem.address)
	const [time, setTime] = useState([editPointItem.from_at, editPointItem.to_at])
	const [address_location, setAddress_location] = useState(editPointItem.address_location)
	const [client_name, setClient_name] = useState(editPointItem.client_name)
	const [client_contact, setClient_contact] = useState(editPointItem.client_contact)
	const [comment, setComment] = useState(editPointItem.comment)

	const handleCancel = () => {
		setEditPointItem(false)
	}

	const onSelect = (value, option) => {
		flyToPoint(option.location)
		setAddress_location(option.location)
	}

	const onSearch = query => {
		if (query.trim().length > 2) {
			searchAddress(query)
		} else {
			setAddressOptions([])
		}
	}

	const sendForm = async () => {
		setLoading(true)

		let data = {
			id: editPointItem.id,
			name: name,
			address: address,
			address_location: address_location,
			from_at: dayjs(time[0]).format('HH:mm'),
			to_at: dayjs(time[1]).format('HH:mm'),
			client_name: client_name,
			client_contact: client_contact,
			comment: comment
		}

		let result = await Point.update(data)
		if (result.code === 200) {
			store.routing.updatePoint(result.data)
			await new Promise(r => setTimeout(r, 1000));
			message.success('Точка успешно отредактирована')
			handleCancel()
		}

		setLoading(false)
	}


	const searchAddress = useCallback(debounce(async (query) => {
		if (query.trim().length > 2) {
			let result = await External.addressSuggest({query: query})
			if (result.code === 200) {
				let tmp = []
				let i = 0
				result?.suggestions.map(address => {
					if (address.value.trim().length && address.data?.geo_lat && address.data?.geo_lon) {
						tmp.push({
							key: 'option-' + i++,
							value: prettyDadataAddress(address.data),
							location: {
								lat: round(address.data.geo_lat, 4), lng: round(address.data.geo_lon, 4)
							}
						})
					}
				})
				setAddressOptions(tmp)
			}
		}
	}, 500), [])

	const flyToPoint = point => {
		if (point.lat && point.lng) {
			mapAddressRef.current.flyTo({
				center: [
					point.lng,
					point.lat,
				],
				zoom: 12,
				speed: 2,
			})
		}
	}

	return (
		<ModalWrapper
			title={'Редактировать точку'}
			visible={true}
			width={500}
			maskClosable={false}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Закрыть
				</Button>,
				<Button disabled={!name.trim().length || !address.trim().length} loading={loading} key="submit"
						type="primary" onClick={sendForm}>
					Сохранить
				</Button>
			]}
		>
			<FormWrapper
				labelCol={{span: 6}}
				wrapperCol={{span: 18}}
				colon={false}
				initialValues={{
					name: editPointItem.name,
					address: editPointItem.address,
				}}
			>
				<Form.Item name={"name"} label="Название" rules={[{required: true}]}>
					<Input
						allowClear
						onChange={(e) => setName(e.target.value)}
					/>
				</Form.Item>
				<Form.Item name={"address"} label="Адрес" rules={[{required: true}]}>
					<AutoComplete
						options={addressOptions}
						onSelect={onSelect}
						onSearch={onSearch}
						onChange={setAddress}
						placeholder="поиск адреса"
						style={{color: '#BFB8AD'}}
						allowClear
					/>
				</Form.Item>
			</FormWrapper>
			<div style={{height: 300}}>
				<Mapbox
					ref={mapAddressRef}
					initialViewState={{
						latitude: address_location ? address_location.lat : store.user.office.region_map_settings?.lat ?? 55.7421473,
						longitude: address_location ? address_location.lng : store.user.office.region_map_settings?.lng ?? 37.5877594,
						zoom: 12,
					}}
					maxZoom={18}
					style={{
						width: '100%',
						height: '100%'
					}}
				>
					<MapboxMarker
						latitude={address_location?.lat ? address_location?.lat : store.user.office.region_map_settings?.lat}
						longitude={address_location?.lng ? address_location.lng : store.user.office.region_map_settings?.lng}
						anchor={"center"}
						draggable={true}
						onDrag={(coords) => setAddress_location({
							lat: round(coords.lngLat.lat, 4),
							lng: round(coords.lngLat.lng, 4)
						})}
						style={{paddingBottom: 40, paddingLeft: 30}}
					>
						<Flag
							width={40}
							height={40}
							color={"#FFFFFF"}
						/>
					</MapboxMarker>
					{
						(address_location?.lat && address_location?.lng) && (
							<Coords
								onClick={() => {
									navigator.clipboard.writeText(address_location.lat.toFixed(4) + ', ' + address_location.lng.toFixed(4))
									message.success('Координата скопирована')
								}}>
								<a>
									{address_location.lat.toFixed(4) + ', ' + address_location.lng.toFixed(4)}
									&nbsp;<CopyOutlined/>
								</a>
							</Coords>
						)
					}
				</Mapbox>
			</div>
			<FormWrapper
				labelCol={{span: 6}}
				wrapperCol={{span: 18}}
				colon={false}
				initialValues={{
					comment: editPointItem.comment
				}}
			>
				<Form.Item name={"date"} label="Дата">
					<Input.Group compact>
						<TimePicker.RangePicker
							onChange={setTime}
							defaultValue={[dayjs(editPointItem.from_at), dayjs(editPointItem.to_at)]}
							style={{width: '50%'}} format={'HH:mm'} minuteStep={5}/>
						<Input style={{width: '50%',}} disabled
							   defaultValue={dayjs(editPointItem.from_at).format('D MMMM, YYYY')}/>
					</Input.Group>
				</Form.Item>
				<Form.Item name={"client"} label="Клиент">
					<Input.Group compact>
						<Input
							style={{width: '50%'}}
							defaultValue={editPointItem.client_name}
							placeholder="Имя"
							onChange={(e) => setClient_name(e.target.value)}
						/>
						<Input
							style={{width: '50%',}}
							defaultValue={editPointItem.client_contact}
							placeholder="Телефон"
							onChange={(e) => setClient_contact(e.target.value)}
						/>
					</Input.Group>
				</Form.Item>
				<Form.Item name={"comment"} label="Комментарий">
					<Input onChange={(e) => setComment(e.target.value)}/>
				</Form.Item>
			</FormWrapper>
		</ModalWrapper>
	)
}

export default observer(ModalPointForm)

const ModalWrapper = styled(Modal)`

  .ant-modal-body {
    padding: 0;
  }
`
const FormWrapper = styled(Form)`
  margin: 25px;
`
const Coords = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-inline: 7px;
  padding-block: 2px;
  background-color: rgba(0, 0, 0, 70%);
  border-top-left-radius: 5px;
  cursor: pointer;
`
