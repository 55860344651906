import { CopyOutlined } from "@ant-design/icons"
import { message } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard/src"
import styled from "styled-components"

const CopyString = ({
	value,
	tip = "Скопировано",
	children,
}) => {

	return (
		<CopyToClipboard text={String(value)}>
			<Wrapper>
				{children ?? value ?? <Mdash>&mdash;</Mdash>}
				{value && <Icon onClick={() => message.success(tip)}/>}
			</Wrapper>
		</CopyToClipboard>
	)
}

export default observer(CopyString)

const Icon = styled(CopyOutlined)`
  margin-left: 5px;
`
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
const Mdash = styled.div`
  color: #666;
`