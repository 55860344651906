import { types } from "mobx-state-tree"
import { Office } from "../network"
import { OfficeItem, TableItem } from "./item"

const OfficeStore = types.model({
	loading: false,
	items: types.optional(types.array(OfficeItem), []),
	table: types.optional(TableItem, {}),
}).actions(self => ({
	async fetch () {
		self.setLoading(true)
		const list = await Office.list(self.table.toServer)
		if (list.success) {
			self.table.setServerConfig({
				count: list.data.count,
				orders: list.data.orders,
				filters: list.data.filters,
			})
			self.setItems(list.data.list)
		}
		self.setLoading(false)
	},
	setLoading (loading) {
		self.loading = loading
	},
	setItems (items) {
		self.items = items
	},
	add (item) {
		return self.items.push(item)
	},
	update (data) {
		let result = self.items.findIndex(item => item.id === data.id)
		if (result !== -1) {
			self.items[result] = data
		}
		return result
	},
})).views(self => ({}))

export default OfficeStore
