const PointStatus = {
	undefined: {
		name: "Без статуса",
		color: "#FF9500",
		striped: false,
	},
	created: {
		name: "В очереди",
		color: "#C9C5BE",
	},
	on_way: {
		name: "Выехал",
		color: "#409FE7",
	},
	arrived: {
		name: "Прибыл",
		color: "#409FE7"
	},
	done: {
		name: "Завершен",
		color: "#88db57"
	},
	"canceled:manager": {
		name: "Отменили",
		color: "#E75957"
	},
	"canceled:driver": {
		name: "Отменил",
		color: "#E75957"
	},
	"canceled:api": {
		name: "Отменил",
		color: "#E75957"
	},
}

export default PointStatus
