import { Card, Descriptions } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import CopyString from "../../../component/CopyString"
import RoadNumber from "../../../component/RoadNumber"

const TransportTabGeneral = ({ transport }) => {

	return (
		<ContentWrapper>
			<CardWrapper bordered={false}>
				<Descriptions>
					<Descriptions.Item label="ExternalID">
						<CopyString value={transport.external_id}>
							{transport.external_id}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="Наименование">
						<CopyString value={transport.name}>
							{transport.name}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="СТС">
						<CopyString value={transport.sts}>
							{transport.prettySts}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="ПТС">
						<CopyString value={transport.pts}>
							{transport.prettyPts}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="ОСАГО">
						<CopyString value={transport.insure}>
							{transport.insure}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="VIN">
						<CopyString value={transport.vin}>
							{transport.prettyVin}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="Госномер">
						<CopyString value={transport.gosnumber}>
							<RoadNumber number={transport.gosnumber}/>
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="Собственность">
						{
							transport.owner_type === "App\\Models\\Office" &&
							<span>Офис "{transport.owner_name}"</span>
						}
						{
							transport.owner_type === "App\\Models\\Driver" &&
							<Link to={"/driver/" + transport.owner_id}>{transport.owner_name}</Link>
						}
					</Descriptions.Item>
					{/*
						<Descriptions.Item label="Версия приложения">
							{
								driver.device ? (
									<Tooltip placement="top"
											 title={driver.device.brand + " " + driver.device.model_name}>
										{
											driver.device.os_name === "iOS" ? (
												<AppleFilled style={{ marginRight: 2, color: "#FFFFFF" }}/>
											) : (
												<AndroidFilled style={{ marginRight: 2, color: "#39D27E" }}/>
											)
										}
										{driver.device.app_version}
									</Tooltip>
								) : (
									"—"
								)
							}
						</Descriptions.Item>
						<Descriptions.Item label="Стаж">
							—
						</Descriptions.Item>*/}
				</Descriptions>
			</CardWrapper>

			{/*<ChartsWrapper>
					<Row gutter={16}>
						<Col className="gutter-row" span={6}>
							<ChartCard bordered={false}>
								<ChartTitle>
									<ChartName>Успешные</ChartName>
									<InfoCircleOutlined/>
								</ChartTitle>
								<ChartValue>
									0%
								</ChartValue>
								<ChartGraphic>
									<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
										<div style={{ height: "100%", width: "1%", background: "#14BABA" }}/>
									</div>
								</ChartGraphic>
								<ChartDesc>
									Среднее в компании 0%
								</ChartDesc>
							</ChartCard>
						</Col>
						<Col className="gutter-row" span={6}>
							<ChartCard bordered={false}>
								<ChartTitle>
									<ChartName>Своевременность</ChartName>
									<InfoCircleOutlined/>
								</ChartTitle>
								<ChartValue>
									0%
								</ChartValue>
								<ChartGraphic>
									<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
										<div style={{ height: "100%", width: "1%", background: "#588AEE" }}/>
									</div>
								</ChartGraphic>
								<ChartDesc>
									Среднее в компании 0%
								</ChartDesc>
							</ChartCard>
						</Col>
						<Col className="gutter-row" span={6}>
							<ChartCard bordered={false}>
								<ChartTitle>
									<ChartName>Отказы от МЛ</ChartName>
									<InfoCircleOutlined/>
								</ChartTitle>
								<ChartValue>
									0%
								</ChartValue>
								<ChartGraphic>
									<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
										<div style={{ height: "100%", width: "1%", background: "#975FE4" }}/>
									</div>
								</ChartGraphic>
								<ChartDesc>
									Среднее в компании 0%
								</ChartDesc>
							</ChartCard>
						</Col>
						<Col className="gutter-row" span={6}>
							<ChartCard bordered={false}>
								<ChartTitle>
									<ChartName>Успешные</ChartName>
									<InfoCircleOutlined/>
								</ChartTitle>
								<ChartValue>
									0%
								</ChartValue>
								<ChartGraphic>
									<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
										<div style={{ height: "100%", width: "1%", background: "#14BABA" }}/>
									</div>
								</ChartGraphic>
								<ChartDesc>
									Среднее в компании 0%
								</ChartDesc>
							</ChartCard>
						</Col>
					</Row>
				</ChartsWrapper>*/}
		</ContentWrapper>
	)
}

export default observer(TransportTabGeneral)

const CardWrapper = styled(Card)`
  padding: 10px 20px 0;
`
const ContentWrapper = styled.div`
  margin: 20px;
`