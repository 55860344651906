import React from "react";
import {observer} from "mobx-react-lite"

const Chevron = ({width = 40, height = 45, color = '#5eac24', lineColor = '#000000'}) => {

	return (
		<svg
			height={height}
			viewBox="0 0 469.8 318.6"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.7 304.1c-3.3 0-4.9-2-5.4-2.9-.7-1.1-1.2-2.8-.2-4.9L82.5 166c2.2-4.2 2.2-9.1 0-13.3L15.1 22.3c-1.1-2.1-.5-3.8.2-4.9.5-.9 2.1-2.9 5.4-2.9h429.1c3 0 5.5 2.5 5.5 5.5v278.6c0 3-2.5 5.5-5.5 5.5H20.7z"
				fill={color}
			/>
			<path
				d="M440.8 29v260.6H34.9l60.5-117c4.3-8.4 4.3-18.3 0-26.6L34.9 29h405.9m9-29H20.7C5.3 0-4.7 15.6 2.2 28.9l67.4 130.4L2.2 289.7c-6.9 13.3 3.1 28.9 18.5 28.9h429.1c11 0 20-9 20-20V20c0-11-9-20-20-20z"/>
		</svg>
	)
}

export default observer(Chevron)
