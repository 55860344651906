import {observer} from "mobx-react-lite"
import React, {useCallback, useMemo} from "react"
import styled from "styled-components"
import {useStore} from "../../../../store/Context"
import {Divider, Dropdown, Modal, Table, Tooltip} from "antd"
import {VList} from "virtuallist-antd"
import {Route, Routing} from "../../../../network"
import {debounce} from "lodash"
import dayjs from "dayjs"
import RouteStatus from "../../../../store/helper/RouteStatus"
import {faLock} from "@fortawesome/pro-regular-svg-icons/faLock"
import Icon from "../../../../component/Icon"
import Chevron from "../../../../assets/svg/chevron.svg"
import {routeContextMenu} from "./routeContextMenu"
import {LoadingOutlined} from "@ant-design/icons"
import {prettyDigit} from "../../../../store/helper/StringMethod"
import EmptyData from "./EmptyData"

const TableRoutes = ({
						 syncRoute, setEditRouteItem, setShowRouteFormModal
					 }) => {

	const store = useStore()

	const data = useMemo(() => {
		return [...store.routing.routes]
	}, [store.routing.refreshedAt])

	const toolBarData = useMemo(() => {
		return {
			total: [...store.routing.routes].length,
			done: data.filter(_point => _point.route_id).length
		}
	}, [store.routing.refreshedAt])


	const vComponents = useMemo(() => {
		return VList({
			height: store.user.settings.routing.layout.bottomHeight - 80,
			resetTopWhenDataChange: false,
			vid: 'RoutingTableRoutes'
		})
	}, [store.user.settings.routing.layout.bottomHeight])

	const hover = useCallback(debounce(async (routeId) => {
		if (store.routing.activeRoute) return
		store.routing.setRouteHover(routeId, 'routes')
	}, 0), [])

	const publish = (route) => {
		return new Promise(async () => {
			let result = await Routing.publish({id: route.id})
			if (result.code === 200) {
				const data = result.data
				store.routing.setRouteDraft(route.id, false)
				if (dayjs(data.date).isBefore(dayjs().add(2, 'day'))) {
					let _routeIndex = store.route.actual.items.findIndex(_route => _route.id === data.id)
					if (_routeIndex === -1) {
						store.route.actual.setItem({...data})
					}
				}
				setTimeout(() => Modal.destroyAll(), 500)
			}
		}).catch();
	}

	const remove = async (route) => {
		return new Promise(async () => {
			setTimeout(async () => {
				let result = await Routing.remove({id: route.id})
				if (result.code === 200) {
					store.routing.removeRoute(route.id)
					store.routing.setActiveRoute(null)
					Modal.destroyAll()
				}
			})
		}).catch();
	}

	const changeStatus = async (route, status) => {
		return new Promise(async () => {
			let result = await Route.editStatus({id: route.id, status: status})
			if (result.code === 200) {
				store.routing.setRouteStatus(route.id, status)
				setTimeout(() => Modal.destroyAll(), 500)
			}
		}).catch();
	}

	const DropMenu = ({route, children}) => {
		return (
			<Dropdown
				overlay={routeContextMenu(route, store, publish, syncRoute, setEditRouteItem, setShowRouteFormModal, changeStatus, remove)}
				trigger={["contextMenu"]}
			>
				<div className={'droppable'}>
					{children}
				</div>
			</Dropdown>
		)
	}

	const columns = [
		{
			title: 'Маршрутный лист',
			dataIndex: 'name',
			fixed: 'left',
			width: 200,
			render: (text, record) => {
				return (
					<DropMenu route={record}>
						{record.locked &&
						<Icon name={faLock} size={12} color="#FF9500"
							  style={{marginRight: 5}}/>}
						<Chevron width={25} height={25} color={record.color}
								 lineColor={'#000'}/>
						<Ellipsis width={200}>
							<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
								<span style={{marginLeft: 5}}>{record.name}</span>
							</Tooltip>
						</Ellipsis>
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + a.name).localeCompare(b.name),
				multiple: 3,
			}
		},
		{
			title: 'Исполнитель',
			width: 180,
			render: (text, record) => {
				return (
					<DropMenu route={record}>
						<Ellipsis width={180}>
							{record.driver_id ? store.routing.getDriver(record.driver_id) : '—'}
						</Ellipsis>
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + store.routing.getDriver(a.driver_id)).localeCompare(store.routing.getDriver(b.driver_id)),
				multiple: 3,
			}
		},
		{
			title: 'Точек',
			dataIndex: 'id',
			width: 90,
			render: (text, record) => <DropMenu route={record} style={{backgroundColor: 'red'}}>
				{store.routing.routeStatistic(record.id).points ? store.routing.routeStatistic(record.id).points : '—'}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).points - store.routing.routeStatistic(b.id).points,
				multiple: 3,
			}
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: 120,
			render: (text, record) => <DropMenu route={record}>
				{
					record.draft ? (
						'Черновик'
					) : (
						<span style={{color: RouteStatus[record.status].color}}>
							{RouteStatus[record.status].name}
						</span>
					)
				}
			</DropMenu>,
			sorter: {
				compare: (a, b) => ('' + a.status).localeCompare(b.status),
				multiple: 3,
			}
		},
		{
			title: 'Пробег',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{prettyDigit(record.geo?.distance, 'distance')}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).distance - store.routing.routeStatistic(b.id).distance,
				multiple: 3,
			}
		},
		{
			title: 'Время',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{record.geo?.duration ? dayjs.duration(record.geo.duration * 1000).format("HH:mm") : '—'}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).duration - store.routing.routeStatistic(b.id).duration,
				multiple: 3,
			}
		},
		{
			title: 'Кол-во',
			dataIndex: 'count',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{prettyDigit(store.routing.routeStatistic(record.id).count, 'count')}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).count - store.routing.routeStatistic(b.id).count,
				multiple: 3,
			}
		},
		{
			title: 'Вес',
			dataIndex: 'weight',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{prettyDigit(store.routing.routeStatistic(record.id).weight, 'weight')}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).weight - store.routing.routeStatistic(b.id).weight,
				multiple: 3,
			}
		},
		{
			title: 'Объем',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{prettyDigit(store.routing.routeStatistic(record.id).cubic, 'cubic')}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).cubic - store.routing.routeStatistic(b.id).cubic,
				multiple: 3,
			}
		},
		{
			title: 'Площадь',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{prettyDigit(store.routing.routeStatistic(record.id).square, 'square')}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).square - store.routing.routeStatistic(b.id).square,
				multiple: 3,
			}
		},
		{
			title: 'Сумма',
			width: 100,
			render: (text, record) => <DropMenu route={record}>
				{prettyDigit(store.routing.routeStatistic(record.id).price, 'price')}
			</DropMenu>,
			sorter: {
				compare: (a, b) => store.routing.routeStatistic(a.id).price - store.routing.routeStatistic(b.id).price,
				multiple: 3,
			}
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			width: 300,
			render: (text, record) => <DropMenu route={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>
		}
	]

	return (
		<Wrapper>
			<TableWrapper onMouseLeave={() => hover(null)}
						  tableHeight={store.user.settings.routing.layout.bottomHeight}>
				<Table
					dataSource={data}
					columns={columns}
					pagination={false}
					size="small"
					scroll={{
						y: store.user.settings.routing.layout.bottomHeight - 80,
						x: 1600
					}}
					rowKey='id'
					showSorterTooltip={false}
					components={vComponents}
					onRow={(record) => {
						return {
							onClick: () => {
								store.routing.setActiveRoute(record.id)
							},
							onMouseEnter: () => {
								hover(record.id)
							}
						}
					}}
					/*rowClassName={(record => (
						store.routing.activeRoute === record.id && 'tr-active-route'
					))}*/
				/>
			</TableWrapper>

			<EmptyData visible={!data.length} dataLength={data.length}
					   type={'routes'}/>

			<ToolBox>
				<div style={{flex: 1}}>
					Всего: {toolBarData.total}
				</div>


				{
					store.routing.syncLoading && (
						<div style={{color: '#88db57'}}>
							<LoadingOutlined style={{marginRight: 4}} size={12}
											 spin/> Сохранение
						</div>
					)
				}
			</ToolBox>
		</Wrapper>
	)
}

export default observer(TableRoutes)

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 10px;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;


  .ant-table-body {
    min-height: ${props => props.tableHeight - 80}px;
  }
`
const Ellipsis = styled.span`
  width: ${props => props.width}px;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`
const ToolBox = styled.div`
  display: flex;
  min-height: 30px;
  padding: 5px 10px;
  background-color: #1F1F1F;
  color: #7B7975;
  font-size: 13px;
  align-items: center;
`
