import { AndroidFilled, AppleFilled, ExclamationCircleOutlined } from "@ant-design/icons"
import {
	Button,
	Empty,
	Modal,
	message,
	Table,
	Tag,
	Descriptions, Tooltip,
} from "antd"
import dayjs from "dayjs"
import { PhoneNumber } from "libphonenumber-js"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import CopyString from "../../../component/CopyString"
import DriverLine from "../../../component/DriverLine"
import RoadNumber from "../../../component/RoadNumber"
import { useStore } from "../../../store/Context"
import { driverOnlineStatus, getPrettyPhone, prettyDriverId } from "../../../store/helper/StringMethod"
import ModalSetDriverForm from "../Modal/ModalSetDriverForm"
import axios from "../../../inizialize/Axios"

const TransportTabDriver = ({ transport }) => {

	const params = useParams()
	const [history, setHistory] = useState([])
	const [showFormModal, setShowFormModal] = useState(false)
	const [historyLoading, setHistoryLoading] = useState(false)

	const store = useStore()

	useEffect(() => {
		reloadHistory()
	}, [transport, params])

	const reloadHistory = async () => {
		setHistoryLoading(true)
		const result = await axios.get("/v1/transport/driver/" + transport.id + "/history")
		setHistoryLoading(false)
		if (result.success) {
			setHistory(result.data)
		}
	}

	const removeConfirm = () => {
		Modal.confirm({
			title: "Отвязка водителя",
			icon: <ExclamationCircleOutlined/>,
			content: "Вы уверены, что хотите отвязать водителя от ТС?",
			okText: "Да",
			cancelText: "Отмена",
			okButtonProps: {
				danger: true,
			},
			onOk: () => detachTransport(),
		})
	}

	const detachTransport = async () => {
		await new Promise(resolve => setTimeout(resolve, 500))
		let result = await axios.post("/v1/transport/driver/" + transport.id + "/detach")
		if (result.success) {
			message.success("Водитель отвязан")
			store.transport.update(result.data)
			reloadHistory()
		}
	}

	const columns = useMemo(() => {
		return [
			{
				key: "driver",
				title: "Водитель",
				render: (text, record) => (
					<DriverLine
						driver={record.driver}
						withLink
					/>
				),
			},
			{
				key: "phone",
				title: "Телефон",
				render: (text, record) => getPrettyPhone(record.driver.phone),
			},
			{
				key: "attach_at",
				title: "Начало периода",
				render: (text, record) => dayjs(record.attach_at).format("DD.MM.YYYY HH:mm"),
			},
			{
				key: "detach_at",
				title: "Конец периода",
				render: (text, record) => record.detach_at ? dayjs(record.detach_at).format("DD.MM.YYYY HH:mm") :
					<Tag color={"green"}>По текущее время</Tag>,
			},
			{
				key: "period",
				title: "Период",
				render: (text, record) => record.detach_at ? dayjs(record.detach_at).from(record.attach_at, true) : dayjs(record.attach_at).fromNow(true),
			},
		]
	}, [])

	return (
		<Wrapper>
			{/*<CurrentTransport>
				{
					transport.driver ? (
						<div style={{ padding: "10px 10px 0 10px" }}>
							<DriverLine withLink driver={transport.driver} textSize={24} avatarSize={48}/>
							<Descriptions style={{ paddingTop: 20 }}>
								<Descriptions.Item label="Номер аккаунта">
									<CopyString value={transport.driver.login}>
										{prettyDriverId(transport.driver.login)}
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="Электронная почта">
									—
								</Descriptions.Item>
								<Descriptions.Item label="Последняя активность">
							<span
								style={{ color: driverOnlineStatus(transport.driver.last_active_at).online && "#88db57" }}
							>
								{driverOnlineStatus(transport.driver.last_active_at).text}
							</span>
								</Descriptions.Item>
								<Descriptions.Item label="Телефон">
									<CopyString value={transport.driver.phone}>
										{transport.driver.prettyPhone}
									</CopyString>
								</Descriptions.Item>
								<Descriptions.Item label="Версия приложения">
									{
										transport.driver.device ? (
											<Tooltip placement="top"
													 title={transport.driver.device.brand + " " + transport.driver.device.model_name}>
												{
													transport.driver.device.os_name === "iOS" ? (
														<AppleFilled style={{ marginRight: 2, color: "#FFFFFF" }}/>
													) : (
														<AndroidFilled style={{ marginRight: 2, color: "#39D27E" }}/>
													)
												}
												{transport.driver.device.app_version}
											</Tooltip>
										) : (
											"—"
										)
									}
								</Descriptions.Item>
								<Descriptions.Item label="Стаж">
									—
								</Descriptions.Item>
							</Descriptions>
							<Buttons>
								<Button danger type="primary" onClick={removeConfirm}>
									Отвязать водителя
								</Button>
								<Button type="primary" onClick={() => setShowFormModal(true)}>
									Привязать другого водителя
								</Button>
							</Buttons>
						</div>
					) : (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Водитель не назначен"}>
							<Button type="primary" onClick={() => setShowFormModal(true)}>Назначить водителя ТС</Button>
						</Empty>
					)
				}
			</CurrentTransport>*/}
			<HistoryWrapper>
				{
					history.length !== 0 && (
						<Table
							loading={historyLoading}
							size={"small"}
							dataSource={history.sort((a, b) => {
								return b.id - a.id
							})}
							columns={columns}
							pagination={false}
							rowKey={record => record.id + ""}
						/>
					)
				}
			</HistoryWrapper>
			<ModalSetDriverForm
				data={transport.driver ? { driver_id: transport.driver.id } : null}
				visible={showFormModal}
				onCancel={() => {
					setShowFormModal(false)
				}}
				api={"/v1/transport/driver/" + transport.id + "/attach"}
				onSuccess={data => {
					setShowFormModal(false)
					store.transport.update(data)
					reloadHistory()
				}}
			/>
		</Wrapper>
	)
}

export default observer(TransportTabDriver)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CurrentTransport = styled.div`
  margin: 20px;
  border-radius: 2px;
  background: #242525;
  padding: 10px;
`
const HistoryWrapper = styled.div`
  margin: 20px;
`
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 1px solid #333;
  padding-top: 10px;
  margin-top: 10px;

  .ant-btn {
    margin-left: 10px;
  }
`