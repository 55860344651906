import { AndroidFilled, AppleFilled, InfoCircleOutlined } from "@ant-design/icons"
import { Card, Col, Descriptions, Row, Tooltip } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import CopyString from "../../../component/CopyString"
import { driverOnlineStatus, prettyDriverId } from "../../../store/helper/StringMethod"

const DriverTabGeneral = ({ driver }) => {

	return (
		<ContentWrapper>
			<Card bordered={false} style={{ padding: 25 }}>
				<Descriptions>
					<Descriptions.Item label="Номер аккаунта">
						<CopyString value={driver.login}>
							{prettyDriverId(driver.login)}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="Электронная почта">
						—
					</Descriptions.Item>
					<Descriptions.Item label="Последняя активность">
							<span
								style={{ color: driverOnlineStatus(driver.last_active_at).online && "#88db57" }}
							>
								{driverOnlineStatus(driver.last_active_at).text}
							</span>
					</Descriptions.Item>
					<Descriptions.Item label="Телефон">
						<CopyString value={driver.phone}>
							{driver.prettyPhone}
						</CopyString>
					</Descriptions.Item>
					<Descriptions.Item label="Версия приложения">
						{
							driver.device ? (
								<Tooltip placement="top"
										 title={driver.device.brand + " " + driver.device.model_name}>
									{
										driver.device.os_name === "iOS" ? (
											<AppleFilled style={{ marginRight: 2, color: "#FFFFFF" }}/>
										) : (
											<AndroidFilled style={{ marginRight: 2, color: "#39D27E" }}/>
										)
									}
									{driver.device.app_version}
								</Tooltip>
							) : (
								"—"
							)
						}
					</Descriptions.Item>
					<Descriptions.Item label="Стаж">
						—
					</Descriptions.Item>
				</Descriptions>
			</Card>

			<ChartsWrapper>
				<Row gutter={16}>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Успешные</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								0%
							</ChartValue>
							<ChartGraphic>
								<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
									<div style={{ height: "100%", width: "1%", background: "#14BABA" }}/>
								</div>
							</ChartGraphic>
							<ChartDesc>
								Среднее в компании 0%
							</ChartDesc>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Своевременность</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								0%
							</ChartValue>
							<ChartGraphic>
								<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
									<div style={{ height: "100%", width: "1%", background: "#588AEE" }}/>
								</div>
							</ChartGraphic>
							<ChartDesc>
								Среднее в компании 0%
							</ChartDesc>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Отказы от МЛ</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								0%
							</ChartValue>
							<ChartGraphic>
								<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
									<div style={{ height: "100%", width: "1%", background: "#975FE4" }}/>
								</div>
							</ChartGraphic>
							<ChartDesc>
								Среднее в компании 0%
							</ChartDesc>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Успешные</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								0%
							</ChartValue>
							<ChartGraphic>
								<div style={{ backgroundColor: "#DEE3E9", height: 10 }}>
									<div style={{ height: "100%", width: "1%", background: "#14BABA" }}/>
								</div>
							</ChartGraphic>
							<ChartDesc>
								Среднее в компании 0%
							</ChartDesc>
						</ChartCard>
					</Col>
				</Row>
			</ChartsWrapper>
		</ContentWrapper>
	)
}

export default observer(DriverTabGeneral)

const Wrapper = styled.div``
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
const ChartsWrapper = styled.div`
  margin-top: 20px;
`
const ChartCard = styled(Card)`
  padding: 20px;
  color: rgba(229, 224, 216, 0.85);
`
const ChartTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: rgba(229, 224, 216, 0.45);
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
`
const ChartName = styled.div`
  flex: 1;
`
const ChartValue = styled.div`
  font-size: 30px;
  font-weight: 300;
`
const ChartGraphic = styled.div`
  margin-top: 15px;
  height: 45px;
  border-bottom: solid 1px #3E4141;
`
const ChartDesc = styled.div`
  margin-top: 10px;
  font-weight: 300;
`