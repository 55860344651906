import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	Tabs,
	message,
	Statistic,
	Button,
	Dropdown,
	Menu,
	Modal,
} from "antd"
import {
	EllipsisOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons"
import DriverLine from "../../component/DriverLine"
import PageHeader from "../../component/PageHeader"
import { useStore } from "../../store/Context"
import { useNavigate, useParams, Link } from "react-router-dom"
import styled from "styled-components"
import { Driver } from "../../network"
import DriverTabGeneral from "./component/DriverTabGeneral"
import DriverTabRoute from "./component/DriverTabRoute"
import DriverTabTransport from "./component/DriverTabTransport"
import ModalDriverForm from "./Modal/ModalDriverForm"

const DriverViewScreen = () => {

	let { id = null, tab = "general" } = useParams()
	const navigate = useNavigate()
	const store = useStore()
	const [driver, setDriver] = useState(null)
	const [showFormModal, setShowFormModal] = useState(false)

	const menu = (
		<Menu
			items={[
				{
					label: "Редактировать",
					key: "update",
				},
				{
					danger: true,
					label: "Удалить",
					key: "remove",
				},
			]}
			onClick={({ key }) => {
				switch (key) {
					case "update": {
						setShowFormModal(true)
						break
					}
					case "remove": {
						removeConfirm()
						break
					}
				}
			}}
		/>
	)

	useEffect(() => {
		const load = async () => {
			id = parseInt(id)
			let driverData = store.driver.items.find(item => item.id === id)
			if (driverData) {
				setDriver(driverData)
			} else {
				let result = await Driver.info({ id: id })
				if (result.success) {
					store.driver.add(result.data)
					load()
				} else {
					navigate("/404")
				}
			}
		}
		load()
	}, [id])

	const removeConfirm = () => {
		Modal.confirm({
			title: "Подтвердите удаление",
			icon: <ExclamationCircleOutlined/>,
			content: "После удаления данные нельзя будет восстановить.",
			okText: "Удалить",
			cancelText: "Отмена",
			okButtonProps: {
				danger: true,
			},
			onOk: () => removeDriver(),
		})
	}

	const removeDriver = async () => {
		await new Promise(resolve => setTimeout(resolve, 500))
		let result = await Driver.remove({ id: driver.id })
		if (result.success) {
			message.success("Водитель удален")
			navigate("/driver")
		}
	}

	if (!driver) return null

	return (
		<Wrapper>
			<PageHeader
				title={[
					<Link to={"/driver"}>Водители</Link>,
					<DriverLine
						driver={driver}
						avatarSize={32}
					/>,
				]}
				height={80}
			>
				<ScoreInfo>
					<Statistic title="Опытность" valueStyle={{ top: 0, textAlign: "right", color: "#C8C4BE" }}
							   value={"0.0"}
					/>
					<Statistic title="Категория"
							   style={{ marginLeft: 25 }}
							   valueStyle={{ textAlign: "right", color: "#FF9500" }}
							   value={"А+"}
					/>
				</ScoreInfo>
			</PageHeader>
			<TabsWrapper
				tabBarExtraContent={(
					<ActionBlock>
						<Dropdown overlay={menu}>
							<Button>
								Управление <EllipsisOutlined/>
							</Button>
						</Dropdown>
					</ActionBlock>
				)}
				onChange={key => {
					navigate("/driver/" + driver.id + "/" + (key !== "general" ? key : ""))
				}}
				defaultActiveKey={tab}
			>
				<TabPane tab="Общая информация" key="general">
					<DriverTabGeneral driver={driver}/>
				</TabPane>
				<Tabs.TabPane tab="Транспорт" key="transport">
					<DriverTabTransport driver={driver}/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Маршрутные листы" key="route" disabled>
					<DriverTabRoute driver={driver}/>
				</Tabs.TabPane>
			</TabsWrapper>
			<ModalDriverForm
				visible={showFormModal}
				data={driver}
				onCancel={() => {
					setShowFormModal(false)
				}}
				api={"/v1/driver/update/" + driver.id}
				onSuccess={data => {
					store.driver.update(data)
					setShowFormModal(false)
				}}
				update
			/>
		</Wrapper>
	)
}

export default observer(DriverViewScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
`

const ScoreInfo = styled.div`
  position: absolute;
  top: 5px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`
const ActionBlock = styled.div`
  position: absolute;
  bottom: 10px;
  right: 25px;
`
const TabsWrapper = styled(Tabs)`
  background: #2a2c2c;

  .ant-tabs-nav-wrap {
    padding-left: 20px;
    background: #242525;
  }
`
const TabPane = styled(Tabs.TabPane)``