export var ZH_CN_LOCALE = {
    locale: 'zh-CN',
    // 通用
    general: {
        increase: '增加',
        decrease: '减少',
        root: '初始',
    },
    // 按照图表组件
    /** 中心文本 */
    statistic: {
        total: '总计',
    },
    /** 转化率组件 */
    conversionTag: {
        label: '转化率',
    },
    legend: {},
    tooltip: {},
    slider: {},
    scrollbar: {},
    // 按照图表类型
    waterfall: {
        total: '总计',
    },
};
