import { __assign } from "tslib";
import { regressionLinear, regressionExp, regressionLoess, regressionLog, regressionPoly, regressionPow, regressionQuad, } from 'd3-regression';
import { isArray, get, isNumber } from '@antv/util';
import { getSplinePath } from '../../utils';
var REGRESSION_MAP = {
    exp: regressionExp,
    linear: regressionLinear,
    loess: regressionLoess,
    log: regressionLog,
    poly: regressionPoly,
    pow: regressionPow,
    quad: regressionQuad,
};
/**
 * 获取四象限默认配置
 * @param {number} xBaseline
 * @param {number} yBaseline
 */
export function getQuadrantDefaultConfig(xBaseline, yBaseline) {
    // 文本便宜距离
    var textOffset = 10;
    // 四象限默认样式
    var defaultConfig = {
        regionStyle: [
            {
                position: {
                    start: [xBaseline, 'max'],
                    end: ['max', yBaseline],
                },
                style: {
                    fill: '#d8d0c0',
                    opacity: 0.4,
                },
            },
            {
                position: {
                    start: ['min', 'max'],
                    end: [xBaseline, yBaseline],
                },
                style: {
                    fill: '#a3dda1',
                    opacity: 0.4,
                },
            },
            {
                position: {
                    start: ['min', yBaseline],
                    end: [xBaseline, 'min'],
                },
                style: {
                    fill: '#d8d0c0',
                    opacity: 0.4,
                },
            },
            {
                position: {
                    start: [xBaseline, yBaseline],
                    end: ['max', 'min'],
                },
                style: {
                    fill: '#a3dda1',
                    opacity: 0.4,
                },
            },
        ],
        lineStyle: {
            stroke: '#9ba29a',
            lineWidth: 1,
        },
        labelStyle: [
            {
                position: ['max', yBaseline],
                offsetX: -textOffset,
                offsetY: -textOffset,
                style: {
                    textAlign: 'right',
                    textBaseline: 'bottom',
                    fontSize: 14,
                    fill: '#ccc',
                },
            },
            {
                position: ['min', yBaseline],
                offsetX: textOffset,
                offsetY: -textOffset,
                style: {
                    textAlign: 'left',
                    textBaseline: 'bottom',
                    fontSize: 14,
                    fill: '#ccc',
                },
            },
            {
                position: ['min', yBaseline],
                offsetX: textOffset,
                offsetY: textOffset,
                style: {
                    textAlign: 'left',
                    textBaseline: 'top',
                    fontSize: 14,
                    fill: '#ccc',
                },
            },
            {
                position: ['max', yBaseline],
                offsetX: -textOffset,
                offsetY: textOffset,
                style: {
                    textAlign: 'right',
                    textBaseline: 'top',
                    fontSize: 14,
                    fill: '#ccc',
                },
            },
        ],
    };
    return defaultConfig;
}
var splinePath = function (data, config) {
    var view = config.view, _a = config.options, xField = _a.xField, yField = _a.yField;
    var xScaleView = view.getScaleByField(xField);
    var yScaleView = view.getScaleByField(yField);
    var pathData = data.map(function (d) {
        return view.getCoordinate().convert({ x: xScaleView.scale(d[0]), y: yScaleView.scale(d[1]) });
    });
    return getSplinePath(pathData, false);
};
export var getPath = function (config) {
    var options = config.options;
    var xField = options.xField, yField = options.yField, data = options.data, regressionLine = options.regressionLine;
    var _a = regressionLine.type, type = _a === void 0 ? 'linear' : _a, algorithm = regressionLine.algorithm;
    var pathData;
    if (algorithm) {
        pathData = isArray(algorithm) ? algorithm : algorithm(data);
    }
    else {
        var reg = REGRESSION_MAP[type]()
            .x(function (d) { return d[xField]; })
            .y(function (d) { return d[yField]; });
        pathData = reg(data);
    }
    return splinePath(pathData, config);
};
/**
 * 调整散点图 meta: { min, max } ① data.length === 1 ② 所有数据 y 值相等 ③ 所有数据 x 值相等
 * @param options
 * @returns
 */
export var getMeta = function (options) {
    var _a;
    var _b = options.meta, meta = _b === void 0 ? {} : _b, xField = options.xField, yField = options.yField, data = options.data;
    var xFieldValue = data[0][xField];
    var yFieldValue = data[0][yField];
    var xIsPositiveNumber = xFieldValue > 0;
    var yIsPositiveNumber = yFieldValue > 0;
    /**
     * 获得对应字段的 min max scale 配置
     */
    function getMetaMinMax(field, axis) {
        var fieldMeta = get(meta, [field]);
        function getCustomValue(type) {
            return get(fieldMeta, type);
        }
        var range = {};
        if (axis === 'x') {
            if (isNumber(xFieldValue)) {
                if (!isNumber(getCustomValue('min'))) {
                    range['min'] = xIsPositiveNumber ? 0 : xFieldValue * 2;
                }
                if (!isNumber(getCustomValue('max'))) {
                    range['max'] = xIsPositiveNumber ? xFieldValue * 2 : 0;
                }
            }
            return range;
        }
        if (isNumber(yFieldValue)) {
            if (!isNumber(getCustomValue('min'))) {
                range['min'] = yIsPositiveNumber ? 0 : yFieldValue * 2;
            }
            if (!isNumber(getCustomValue('max'))) {
                range['max'] = yIsPositiveNumber ? yFieldValue * 2 : 0;
            }
        }
        return range;
    }
    return __assign(__assign({}, meta), (_a = {}, _a[xField] = __assign(__assign({}, meta[xField]), getMetaMinMax(xField, 'x')), _a[yField] = __assign(__assign({}, meta[yField]), getMetaMinMax(yField, 'y')), _a));
};
