var _a;
import Theme from '../util/theme';
// tooltip 相关 dom 的 css 类名
import * as CssConst from './css-const';
export default (_a = {},
    // css style for tooltip
    _a["" + CssConst.CONTAINER_CLASS] = {
        position: 'relative'
    },
    _a["" + CssConst.CROSSHAIR_LINE] = {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    _a["" + CssConst.CROSSHAIR_TEXT] = {
        position: 'absolute',
        color: Theme.textColor,
        fontFamily: Theme.fontFamily,
    },
    _a);
