export var AxisType;
(function (AxisType) {
    AxisType["Left"] = "Left";
    AxisType["Right"] = "Right";
})(AxisType || (AxisType = {}));
export var DualAxesGeometry;
(function (DualAxesGeometry) {
    DualAxesGeometry["Line"] = "line";
    DualAxesGeometry["Column"] = "column";
})(DualAxesGeometry || (DualAxesGeometry = {}));
