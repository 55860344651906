import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import parsePhoneNumber from "libphonenumber-js"

dayjs.extend(relativeTime)

export const getPrettyPhone = (phone) => {
	if (!phone) return null
	let result = parsePhoneNumber(phone.replace(/[^0-9]/g, ""), "RU")

	if (result?.number) {
		return result.number.substr(0, 2) + " (" + result.number.substr(2, 3) + ") " + result.number.substr(5, 3) + "-" + result.number.substr(8, 2) + "-" + result.number.substr(10, 2)
	}

	return phone
}

export const prettyDriverId = (id) => {
	id = id.toString()
	if (id.length === 6) {
		return id.substr(0, 3) + " " + id.substr(3)
	}

	return id
}

export const prettyMoney = (data) => {
	return new Intl.NumberFormat("ru", {
		style: "currency",
		currency: "RUB",
		minimumFractionDigits: 2,
	}).format(data)
}

export const prettyTimePeriod = (from_at, to_at) => {
	let result = dayjs(from_at).format("HH:mm")
	if (to_at !== from_at) {
		result = result + " - " + dayjs(to_at).format("HH:mm")
	}

	if (result === "00:00") result = "В течении дня"

	return result
}

export const driverOnlineStatus = (lastActive) => {
	let result = {
		online: false,
		text: "—",
	}

	if (lastActive) {
		if (dayjs().diff(lastActive, "minutes") > 15) {
			result.text = dayjs(lastActive).fromNow()
		} else {
			result.text = "онлайн"
			result.online = true
		}
	}

	return result
}

export const declOfNum = (n, titles) => {
	return titles[n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
}

export const prettyDigit = (number, type = 'count') => {
	if (!number) return '—'
	switch (type) {
		case 'count': {
			return number
			break
		}
		case 'distance': {
			return (number / 1000).toFixed(1)
			break
		}
		case 'weight':
		case 'cubic':
		case 'square':
		case 'price': {
			return number.toFixed(2)
			break
		}
	}
}

export const prettyCarNumber = (number) => {
	if (number?.length > 7 && number?.length < 10) {
		return (
			<span style={{ textTransform: "uppercase" }}>
				<span style={{ fontSize: 13 }}>{number.substr(0, 1)}</span>
				<span style={{ marginLeft: 2 }}>{number.substr(1, 3)}</span>
				<span style={{ marginLeft: 2, fontSize: 13 }}>{number.substr(4, 2)}</span>
				<span style={{ marginLeft: 2 }}>{number.substr(6)}</span>
			</span>
		)
	}
}

export const isObject = item => {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target, ...sources) => {
	if (!sources.length) return target
	const source = sources.shift()

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, {[key]: {}})
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, {[key]: source[key]})
			}
		}
	}

	return mergeDeep(target, ...sources);
}


function join (arr /*, separator */) {
	let separator = arguments.length > 1 ? arguments[1] : ", "
	return arr.filter(function (n) {
		return n
	}).join(separator)
}

export const prettyDadataAddress = (address = {}) => {
	if ((address.region || address.area) && !address.city && !address.settlement) {
		return ""
	} else {
		return join([
			address.city_with_type,
			address.settlement_with_type,
			address.street_with_type,
			join([address.house_type, address.house,
				address.block_type, address.block], ". "),
			join([address.flat_type, address.flat], ". "),
		])
	}
}
