import { types } from "mobx-state-tree"
import { RouteItem } from "./RouteItem"
import { getPrettyPhone } from "../helper/StringMethod"
import { TagItem } from "./TagItem"
import { TransportItem } from "./TransportItem"

const DriverRoutes = types.model({
	performed: types.maybeNull(types.late(() => RouteItem)),
	accepted: types.optional(types.array(types.late(() => RouteItem)), []),
})

const DriverCoord = types.model({
	coord_at: types.maybeNull(types.string),
	heading: types.maybeNull(types.number),
	latitude: types.maybeNull(types.number),
	longitude: types.maybeNull(types.number),
})

const DriverDevice = types.model({
	app_version: types.maybeNull(types.string),
	brand: types.maybeNull(types.string),
	model_name: types.maybeNull(types.string),
	os_name: types.maybeNull(types.string),
})


export const DriverItem = types.model({
	external_id: types.maybeNull(types.string),
	id: types.identifierNumber,
	login: types.integer,
	name: types.maybeNull(types.string),
	surname: types.maybeNull(types.string),
	middlename: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	routes: types.optional(types.maybeNull(DriverRoutes), {}),
	coord: types.maybeNull(DriverCoord),
	device: types.maybeNull(DriverDevice),
	last_active_at: types.maybeNull(types.string),
	transport: types.maybeNull(TransportItem),
	tags: types.optional(types.array(TagItem), []),
	created_at: types.maybeNull(types.string),
}).views(self => {
	return {
		get stripName () {
			return ((self.surname ?? "") + " " + (self.name ?? "")).trim()
		},
		get prettyPhone () {
			console.log(self.phone)
			return getPrettyPhone(self.phone)
		},
	}
})
