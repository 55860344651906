import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import styled from "styled-components"
import { Image } from "antd"

const PointPhotoItem = ({ point, image }) => {

	const [visible, setVisible] = useState(false)

	return (
		<Wrapper>
			<Image
				style={{ padding: 10 }}
				width={200}
				height={200}
				src={process.env.REACT_APP_API_URL + "/media/point/" + point + "/photo/" + image + "/thumb"}
				preview={{
					visible,
					src: process.env.REACT_APP_API_URL + "/media/point/" + point + "/photo/" + image,
					onVisibleChange: value => {
						setVisible(value)
					},
				}}
			/>
		</Wrapper>
	)
}

export default observer(PointPhotoItem)

const Wrapper = styled.div``
