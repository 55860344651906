import { __extends } from "tslib";
import ListState from './list-state';
/**
 * 图例项取消勾选的 Action
 * @ignore
 */
var ListUnchecked = /** @class */ (function (_super) {
    __extends(ListUnchecked, _super);
    function ListUnchecked() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'unchecked';
        return _this;
    }
    /**
     * 取消勾选
     */
    ListUnchecked.prototype.unchecked = function () {
        this.setState();
    };
    return ListUnchecked;
}(ListState));
export default ListUnchecked;
