import {observer} from "mobx-react-lite"
import {useMemo} from "react"
import {Layer, Source} from "react-map-gl"
import {useStore} from "../../../store/Context"
import * as geolib from 'geolib'
import dayjs from "dayjs";

const CircleLayer = ({
						 points = [],
						 onFiltered = () => {
						 }
					 }) => {


	const store = useStore()
	const statFilter = ['count', 'weight', 'cubic', 'square', 'price']


	const POINT = useMemo(() => {

			const settings = store.user.settings.routing

			let colors = ['match', ['get', 'routeId'], 'xxx', '#fbb03b']
			let opacity = ['match', ['get', 'pointId'], 'null', 1]
			const excludeTooltipPoints = []

			const pushOpacity = (id, opacityLevel) => {
				id = id?.toString()
				const checkIndex = opacity.findIndex(_item => _item === id)
				if (checkIndex === -1) {
					opacity.push(id)
					opacity.push(opacityLevel)

					if (!opacityLevel) excludeTooltipPoints.push(id)
				}
			}

			if (store.routing.routes.length) {
				colors = [
					'match',
					['get', 'routeId'],
					'xxx',
					'#fbb03b'
				]

				store.routing.routes.map(_route => {
					colors.push(_route?.id?.toString())
					colors.push(_route.color)
				})
			}

			colors.push("#FFFFFF")


			store.routing.points.map(_point => {
				if (store.routing.activeRoute && _point.route_id === store.routing.activeRoute) return
				if (store.routing.routeHover && _point.route_id === store.routing.routeHover) return
				if (store.routing.routeHover && !store.routing.activeRoute && _point.route_id !== store.routing.routeHover) {
					pushOpacity(_point.id, 0)
					return
				}

				statFilter.map(_filter => {
					if (settings.pointFilter[_filter].from && settings.pointFilter[_filter].from > _point.statistic[_filter]) {
						pushOpacity(_point.id, 0)
						return
					}
					if (settings.pointFilter[_filter].to && settings.pointFilter[_filter].to < _point.statistic[_filter]) {
						pushOpacity(_point.id, 0)
						return
					}
				})

				if (dayjs(_point.from_at).format('HH:mm') !== '00:00' || dayjs(_point.to_at).format('HH:mm') !== '00:00') {
					if (settings.pointFilter.time.from) {
						if (dayjs('2000-01-01' + dayjs(_point.from_at).format(' HH:mm')).unix() < dayjs('2000-01-01').add(settings.pointFilter.time.from * 5, 'minutes').unix()) {
							pushOpacity(_point.id, 0)
							return
						}
					}
					if (settings.pointFilter.time.to) {
						if (dayjs('2000-01-01' + dayjs(_point.to_at).format(' HH:mm')).unix() > dayjs('2000-01-01').add(settings.pointFilter.time.to * 5, 'minutes').unix()) {
							pushOpacity(_point.id, 0)
							return
						}
					}
				}

				if (store.routing.activeRoute && _point.route_id && _point.route_id !== store.routing.activeRoute) {
					pushOpacity(_point.id, 0)
					return
				}

				if (settings.pointFilter.hideComplete && _point.route_id) {
					pushOpacity(_point.id, 0)
					return
				}
			})

			opacity.push(1)

			onFiltered(excludeTooltipPoints)

			return {
				id: "point",
				type: "circle",
				source: "points",
				paint: {
					"circle-color": colors,
					"circle-radius": 8,
					"circle-stroke-width": 1,
					"circle-stroke-color": "#111111",
					"circle-opacity": opacity,
					"circle-stroke-opacity": opacity
				}
			}
		}
		, [store.routing.refreshedAt, store.routing.activeRoute, store.routing.routeHover, store.user.updateSettingsEvent])

	const POINT_POSITION = useMemo(() => {
			return {
				id: "point-position",
				type: "symbol",
				source: "points",
				filter: ["==", 'routeId', (store.routing.activeRoute ?? 0)?.toString()],
				layout: {
					'text-field': '{position}',
					'text-font': ['Arial Unicode MS Bold'],
					'text-size': 12
				},
			}
		}
		, [store.routing.refreshedAt, store.routing.activeRoute])


	const POINT_NEAR_TIME = useMemo(() => {
			const bounds = geolib.getBoundsOfDistance(
				{
					latitude: store.routing.lastPointGps(store.routing.activeRoute ?? 0)?.lat,
					longitude: store.routing.lastPointGps(store.routing.activeRoute ?? 0)?.lng,
				}, store.user.settings.routing.showTimeRadius ? store.user.settings.routing.showTimeRadius * 1000 : 5000
			)

			return {
				id: "point-near-time",
				type: "symbol",
				source: "points",
				minzoom: 8.5,
				filter: [
					"all",
					["within", {
						"type": "Polygon",
						"coordinates": [[
							[bounds[1].longitude, bounds[0].latitude],
							[bounds[1].longitude, bounds[1].latitude],
							[bounds[0].longitude, bounds[1].latitude],
							[bounds[0].longitude, bounds[0].latitude],
						]]
					}],
					[
						'==', 'routeId', 0
					]
				],
				layout: {
					'text-allow-overlap': true,
					'text-field': '{time}',
					'text-font': ['Arial Unicode MS Bold'],
					'text-size': 10,
					'text-offset': [0, -1.6]
				},
				paint: {
					'text-color': '#ff9900',
					//'text-opacity': opacity
				}
			}
		}
		, [store.routing.refreshedAt, store.routing.activeRoute, store.user.settings.routing.showTimeRadius, store.user.updateSettingsEvent])

	const POINT_NEAR_TIME_SHADOW = useMemo(() => {
			const bounds = geolib.getBoundsOfDistance(
				{
					latitude: store.routing.lastPointGps(store.routing.activeRoute ?? 0)?.lat,
					longitude: store.routing.lastPointGps(store.routing.activeRoute ?? 0)?.lng,
				}, store.user.settings.routing.showTimeRadius ? store.user.settings.routing.showTimeRadius * 1000 : 5000
			)
			return {
				id: "point-near-time-shadow",
				type: "symbol",
				source: "points",
				minzoom: 8.5,
				filter: [
					"all",
					["within", {
						"type": "Polygon",
						"coordinates": [[
							[bounds[1].longitude, bounds[0].latitude],
							[bounds[1].longitude, bounds[1].latitude],
							[bounds[0].longitude, bounds[1].latitude],
							[bounds[0].longitude, bounds[0].latitude],
						]]
					}],
					[
						'==', 'routeId', 0
					]
				],
				layout: {
					'text-allow-overlap': true,
					'text-field': '{time}',
					'text-font': ['Arial Unicode MS Bold'],
					'text-size': 10,
					'text-offset': [0.1, -1.5]
				},
				paint: {
					//'text-opacity': opacity
				}
			}
		}
		, [store.routing.refreshedAt, store.routing.activeRoute, store.user.settings.routing.showTimeRadius, store.user.updateSettingsEvent])

	const geoJson = useMemo(() => {
		let result = null

		if (points.length > 0) {
			result = {
				"type": "FeatureCollection",
			}
			result.features = points.map(point => {
				return {
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [point.longitude, point.latitude],
					},
					"properties": {
						"pointId": point.id?.toString(),
						"routeId": point.route_id ? point.route_id?.toString() : point.route_id,
						"position": point.position?.toString(),
						"from_at": point.from_at,
						"time": point.time,
						"lat": point.lat,
						"lng": point.lng,
						"name": point.name,
						"address": point.address,
						"count": point.statistic.count,
						"weight": point.statistic.weight,
						"cubic": point.statistic.cubic,
						"square": point.statistic.square,
						"price": point.statistic.price,
					}
				}
			}).filter(a => a)
		}

		return result
	}, [points])

	return (
		<>
			<Source
				type="geojson"
				data={geoJson}
			>
				<Layer id="point-near-time" {...POINT_NEAR_TIME} />
				<Layer id="point-near-time-shadow" beforeId={"point-near-time"} {...POINT_NEAR_TIME_SHADOW} />
				<Layer id="point-position" beforeId={"point-near-time"} {...POINT_POSITION} />
				<Layer id="point" beforeId={"point-position"} {...POINT} />
			</Source>
		</>
	)
}

export default observer(CircleLayer)
