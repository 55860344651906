import {observer} from "mobx-react-lite"
import React, {useMemo} from "react"
import styled from "styled-components"
import {useStore} from "../../../../store/Context"
import {Dropdown, Modal, Table, Tooltip} from "antd"
import Flag from "../../../../assets/svg/flag.svg"
import {getPrettyPhone, prettyDigit, prettyTimePeriod} from "../../../../store/helper/StringMethod"
import PointStatus from "../../../../store/helper/PointStatus"
import {pointContextMenu} from "./pointContextMenu"
import {VList} from "virtuallist-antd"
import {Point} from "../../../../network"
import useForceUpdate from "../../../../component/useForceUpdate"
import {useBroadcast} from "../../../../store/SocketStore"
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc"
import {arrayMoveImmutable} from "array-move"
import Icon from "../../../../component/Icon"
import {faLock} from "@fortawesome/pro-regular-svg-icons/faLock"
import EmptyData from "./EmptyData";

const TablePoints = ({
						 selectPoint,
						 setEditPointItem,
						 setShowMovePointModal,
						 getPolyline,
						 syncRoute
					 }) => {

	const store = useStore()

	const data = useMemo(() => {
		if (!store.routing.activeRoute) return []
		return store.routing.points.filter(_point => _point.route_id === store.routing.activeRoute).sort((a, b) => {
			return a.position - b.position
		})
	}, [store.routing.refreshedAt, store.routing.activeRoute])

	const toolBarData = useMemo(() => {
		return {
			total: data.length
		}
	}, [data])


	const vComponents = useMemo(() => {
		return VList({
			height: store.user.settings.routing.layout.bottomHeight,
			resetTopWhenDataChange: false,
			vid: 'RoutingTableRoutePoints'
		})
	}, [store.user.settings.routing.layout.bottomHeight])

	const forceUpdate = useForceUpdate()

	useBroadcast(() => {
		forceUpdate()
	}, ["point.status"])

	const VRow = vComponents.body.row
	const VWrapper = vComponents.body.wrapper
	const DragHandle = sortableHandle(({title}) => (
		<div className={'drag-handle'}>{title}</div>
	))

	const SortableItem = sortableElement((props) => <VRow {...props} />)
	const SortableContainer = sortableContainer((props) => <VWrapper {...props} />)

	const onSortEnd = ({oldIndex, newIndex}) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex).filter(
				(el) => !!el,
			)
			newData.map((point, index) => {
				store.routing.updatePointPosition(point.id, index)
			})

			store.routing.recountPositions(store.routing.activeRoute)
			getPolyline(store.routing.activeRoute)
			syncRoute(store.routing.activeRoute)
		}
	}

	const DraggableContainer = (props) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({className, style, ...restProps}) => {
		const index = data.findIndex(
			(x) => x.id === restProps["data-row-key"]
		)
		return <SortableItem index={index} {...restProps} />
	}

	const changeStatus = async (point, status) => {
		return new Promise(async () => {
			let result = await Point.editStatus({id: point.id, status: status})
			if (result.code === 200) {
				store.routing.updatePointStatus(point.id, status)
				setTimeout(() => Modal.destroyAll(), 500)
			}
		}).catch()
	}

	const DropMenu = ({point, children}) => {
		let route = point.route_id ? store.routing.getRouteById(point.route_id) : null
		return (
			<Dropdown
				overlay={pointContextMenu(point, route, selectPoint, store, setEditPointItem, setShowMovePointModal, changeStatus)}
				trigger={["contextMenu"]}
				onVisibleChange={(visible) => {
					if (visible) {
						store.routing.setActivePoint(point.id)
					}
				}}
			>
				<div className={'droppable'}>
					{children}
				</div>
			</Dropdown>
		)
	}


	const columns = [
		{
			title: '',
			dataIndex: 'position',
			fixed: 'left',
			width: 30,
			className: 'drag-visible',
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				if (route.draft) {
					return <DragHandle title={text}/>
				} else {
					return (
						<div className='drag-handle disabled-drag'>
							{text}
						</div>
					)
				}
			}
		},
		{
			title: 'Название',
			dataIndex: 'name',
			fixed: 'left',
			width: 200,
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				return (
					<DropMenu point={record}>
						<Flag height={20} width={20}
							  color={record.route_id ? route?.color : "#bec1c1"}
						/>
						<Ellipsis width={200}>
							<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
								{text}
							</Tooltip>
						</Ellipsis>
					</DropMenu>
				)
			},
		},
		{
			title: 'Адрес',
			dataIndex: 'address',
			width: 300,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>
		},
		{
			title: 'Время',
			dataIndex: 'time',
			width: 150,
			render: (text, record) => <DropMenu
				point={record}>{prettyTimePeriod(record.from_at, record.to_at)}</DropMenu>,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: 120,
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				return (
					<DropMenu point={record}>
						{text.isCanceled ? (
							<span style={{color: PointStatus[text].color}}>
								{PointStatus[text].name}
							</span>
						) : (!route || route?.draft) ? (
							"Черновик"
						) : (
							<span style={{color: PointStatus[text].color}}>
								{PointStatus[text].name}
							</span>
						)}
					</DropMenu>
				)
			},
		},
		{
			title: 'Кол-во',
			dataIndex: 'count',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.count)}</DropMenu>
		},
		{
			title: 'Вес',
			dataIndex: 'weight',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.weight, 'weight')}</DropMenu>
		},
		{
			title: 'Объем',
			dataIndex: 'cubic',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.cubic, 'cubic')}</DropMenu>
		},
		{
			title: 'Площадь',
			dataIndex: 'square',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.square, 'square')}</DropMenu>
		},
		{
			title: 'Сумма',
			dataIndex: 'price',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.price, 'price')}
			</DropMenu>
		},
		{
			title: 'Получатель',
			dataIndex: 'client_name',
			width: 250,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={250}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text?.length ? text : '—'}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
		},
		{
			title: 'Телефон',
			dataIndex: 'client_contact',
			width: 150,
			render: (text, record) => <DropMenu point={record}>{getPrettyPhone(text)}</DropMenu>
		},
		{
			title: 'Коммент',
			dataIndex: 'comment',
			width: 300,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
		},
	]

	return (
		<Wrapper>
			<TableWrapper tableHeight={store.user.settings.routing.layout.bottomHeight}>
				<Table
					dataSource={data}
					columns={columns}
					pagination={false}
					size="small"
					scroll={{
						y: store.user.settings.routing.layout.bottomHeight - 80,
						x: 2000
					}}
					rowKey='id'
					showSorterTooltip={false}
					components={{
						...vComponents,
						body: {
							...vComponents?.body,
							wrapper: DraggableContainer,
							row: DraggableBodyRow
						}
					}}
					onRow={(record) => {
						return {
							onClick: e => {
								selectPoint(e, record, true)
							}
						}
					}}
				/>
			</TableWrapper>

			<EmptyData visible={(store.routing.activeRoute && !data.length) || !data.length} dataLength={data.length}
					   type={'routePoints'}/>

			<ToolBox>
				<div style={{flex: 1}}>
					Всего: {toolBarData.total}
				</div>
				{
					(store.routing.activeRoute && store.routing.activeRoute.locked) && (
						<div style={{display: "flex", alignItems: "center", color: "#FF9500"}}>
							<Icon name={faLock} size={11} color="#FF9500"
								  style={{marginRight: 2}}/>
							Установлена блокировка изменений
						</div>
					)
				}
			</ToolBox>
		</Wrapper>
	)
}

export default observer(TablePoints)

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 10px;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;


  .row-dragging {
    border: 10px solid #ccc;
  }

  .row-dragging td {
    margin-right: 200px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }

  .drag-handle {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    min-width: 30px;
    padding: 5px;
    cursor: grab;
  }

  .disabled-drag {
    cursor: default !important;
  }

  .ant-table-body {
    min-height: ${props => props.tableHeight - 80}px;
  }
`

const Ellipsis = styled.span`
  width: ${props => props.width}px;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ToolBox = styled.div`
  display: flex;
  min-height: 30px;
  padding: 5px 10px;
  background-color: #1F1F1F;
  color: #7B7975;
  font-size: 13px;
  align-items: center;
`
