import { SearchOutlined } from "@ant-design/icons"
import { Button, Input, Space } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const FilterSearch = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, column, visible }) => {

	const [searchText, setSearchText] = useState("")
	const [searchedColumn, setSearchedColumn] = useState("")

	const searchInput = useRef(null)

	const handleSearch = (selectedKeys, confirm, column) => {
		confirm()
		setSearchText(selectedKeys[0])
		setSearchedColumn(column)
	}

	const handleReset = (clearFilters) => {
		clearFilters()
		setSearchText("")
	}

	useEffect(() => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100)
		}
	}, [visible])

	return (
		<Wrapper>
			<Input
				ref={searchInput}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys, confirm, column)}
				style={{
					marginBottom: 8,
					display: "block",
				}}
				autoFocus
			/>
			<Space style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					type="link"
					onClick={() => clearFilters && handleReset(clearFilters)}
					size="small"
					disabled={!selectedKeys[0]}
				>
					Сбросить
				</Button>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, column)}
					size="small"
				>
					OK
				</Button>
			</Space>
		</Wrapper>
	)
}

export default observer(FilterSearch)

const Wrapper = styled.div`
  padding: 8px;
`