import {observer} from "mobx-react-lite"
import {MapProvider, Map} from "react-map-gl"
import styled from "styled-components"
import {forwardRef} from "react"

const Mapbox = ({...props}, ref) => {

	return (
		<MapProvider>
			<MapWrapper
				ref={ref}
				mapStyle="mapbox://styles/trin4ik/cl1nvxg4e000514o1guhksh56"
				{...props}
			/>
		</MapProvider>
	)
}

export default observer(forwardRef(Mapbox))

const MapWrapper = styled(Map)`
  flex: 1;
  overflow: hidden;
`
