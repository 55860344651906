import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useMemo } from "react"
import styled from "styled-components"
import Icon from "../Icon"

const TableFilter = ({ filter, table }) => {

	const value = useMemo(() => {
		const values = Array.isArray(filter.value) ? [...filter.value] : filter.value
		if (Array.isArray(values)) {
			values.forEach((value, index) => {
				if (moment.isMoment(value)) {
					values[index] = value.format("DD.MM.YYYY")
				}
			})
			return values.join(", ")
		}
		return values
	}, [filter.value])

	return (
		<Wrapper>
			<ColumnName>
				{filter.title}
			</ColumnName>
			<FilterValue>
				{value}
			</FilterValue>
			<Close onClick={() => table.removeFilter(filter.column)}>
				<Icon color={"#999"} size={14} name={faTimes}/>
			</Close>
		</Wrapper>
	)
}

export default observer(TableFilter)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #3c3c3c;
  background: #1c1c1c;
  overflow: hidden;
`
const ColumnName = styled.div`
  background: #111;
  padding: 3px 5px 3px 10px;
`
const FilterValue = styled.div`
  background: #1c1c1c;
  padding: 3px 5px 3px 5px;
`
const Close = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #444;
  margin-right: 5px;
  cursor: pointer;
  transition: all linear .2s;

  svg {
    transition: all linear .2s;
  }

  &:hover {
    background-color: #666;

    svg {
      color: #fff;
    }
  }
`