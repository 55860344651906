import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import cn from "classnames"
import {
	TeamOutlined,
	WindowsOutlined,
	HistoryOutlined,
	FolderOpenOutlined,
	SettingOutlined,
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	LineChartOutlined, CarOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import Icon from "../../component/Icon"
import { useStore } from "../../store/Context"
import { faDirections } from "@fortawesome/pro-light-svg-icons/faDirections"
import { Button } from "antd"
import MenuItem from "./LeftMenuItem"

const LeftMenu = ({}) => {

	const location = useLocation()
	const store = useStore()

	const [activeMenu, setActiveMenu] = useState("/")

	let menu = [
		{
			key: "/",
			name: "Терминал",
			icon: <WindowsOutlined/>,
		},
		{
			key: "route",
			name: "Маршрутные листы",
			icon: <FolderOpenOutlined/>,
			subMenu: [
				{
					key: "all",
					name: "Все",
					icon: <FolderOpenOutlined/>,
					counter: store.route.actual.routeFilter().length + "",
				},
				{
					key: "performed",
					name: "В работе",
					icon: <FolderOpenOutlined/>,
					counter: store.route.actual.routeFilter("performed").length + "",
				},
				{
					key: "created",
					name: "Ожидают",
					icon: <FolderOpenOutlined/>,
					counter: store.route.actual.routeFilter("created").length + "",
				},
				{
					key: "done",
					name: "Завершенные",
					icon: <FolderOpenOutlined/>,
					counter: store.route.actual.routeFilter("done").length + "",
				},
				{
					key: "canceled",
					name: "Отмененные",
					icon: <FolderOpenOutlined/>,
					counter: store.route.actual.routeFilter("canceled").length + "",
					color: "#E75957",
				},
				{
					key: "archive",
					name: "Архив",
					icon: <HistoryOutlined/>,
				},
			],
		},
		{
			key: "routing",
			name: "Маршрутизация",
			icon: <Icon name={faDirections} color={activeMenu === "routing" ? "#FFFFFF" : "#C8C4BD"} size={15}
						style={{ marginLeft: -2 }}/>,
			badge: 17,
		},
		{
			key: "driver",
			name: "Водители",
			icon: <TeamOutlined/>,
		},
		{
			key: "transport",
			name: "Транспорт",
			icon: <CarOutlined/>,
		},
		/*{
			key: "analytics",
			name: "Аналитика",
			icon: <LineChartOutlined/>,
		},*/
		{
			key: "setting",
			name: "Управление",
			icon: <SettingOutlined/>,
		},
	]

	useEffect(() => {
		const path = location.pathname.split("/")
		if (path[1] && path[1].length) {
			if (path[2] && !["driver", "transport"].includes(path[1])) {
				setActiveMenu(path[2])
			} else {
				setActiveMenu(path[1])
			}
		} else {
			setActiveMenu("/")
		}
	}, [location])

	return (
		<>
			<MenuWrapper className={cn({ mini: store.app.miniMenu })}>
				{
					menu.map(item => (
						<MenuItem mini={store.app.miniMenu} activeMenu={activeMenu} key={item.key}
								  item={item}/>
					))
				}
				<CollapseButton
					type="primary"
					onClick={() => store.app.toggleMiniMenu()}
				>
					{store.app.miniMenu ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
				</CollapseButton>
			</MenuWrapper>
		</>
	)
}

export default observer(LeftMenu)

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  padding-top: 20px;
  z-index: 10;
  box-shadow: rgba(13, 13, 13, 0.65) 0px 2px 8px 0px;
  background: #1F1F1F;
  overflow: hidden;
  transition: .2s ease-in-out all;

  &.mini {
    width: 50px;
  }
`
const CollapseButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  margin-right: 5px;
  align-self: flex-end;
`
