import { each } from '@antv/util';
/**
 * @file 全局的一些变量定义：含国际化、主题...
 */
export var GLOBAL = {
    /** 全局语言 */
    locale: 'en-US',
};
/**
 * 全局变量设置
 * @param key
 * @param value
 */
export function setGlobal(datum) {
    each(datum, function (v, k) { return (GLOBAL[k] = v); });
}
