import React from "react";
import {observer} from "mobx-react-lite"

const Click = ({width = 40, height = 40, color = '#3B3D3D'}) => {

	return (
		<svg
			height={height}
			width={width}
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path
					d="M422.762,507.605l-87.518-87.503l-42.422,42.422c-3.662,3.662-8.979,5.171-14.048,3.999    c-5.068-1.201-9.141-4.922-10.781-9.858l-84.858-254.561c-1.802-5.391-0.396-11.338,3.618-15.352    c3.999-3.999,9.961-5.464,15.352-3.618l254.561,84.858c4.937,1.641,8.657,5.713,9.858,10.781    c1.187,5.054-0.322,10.371-3.999,14.048l-42.422,42.422l87.503,87.517c5.859,5.859,5.859,15.352,0,21.211l-63.633,63.633    C438.113,513.465,428.621,513.465,422.762,507.605z"
					fill={color}
				/>
			</g>
			<g>
				<path
					d="M195,120c-8.291,0-15-6.709-15-15V15c0-8.291,6.709-15,15-15s15,6.709,15,15v90C210,113.291,203.291,120,195,120z"
					fill={color}
				/>
			</g>
			<g>
				<path
					d="M120.762,141.973L57.114,78.325c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0l63.647,63.647    c5.859,5.859,5.859,15.352,0,21.211C136.113,147.832,126.621,147.832,120.762,141.973z"
					fill={color}
				/>
			</g>
			<g>
				<path
					d="M57.114,332.886c-5.859-5.859-5.859-15.352,0-21.211l63.647-63.647c5.859-5.859,15.352-5.859,21.211,0    s5.859,15.352,0,21.211l-63.647,63.647C72.466,338.745,62.974,338.745,57.114,332.886z"
					fill={color}
				/>
			</g>
			<g>
				<path
					d="M248.027,141.973c-5.859-5.859-5.859-15.352,0-21.211l63.647-63.647c5.859-5.859,15.352-5.859,21.211,0    s5.859,15.352,0,21.211l-63.647,63.647C263.379,147.832,253.887,147.832,248.027,141.973z"
					fill={color}
				/>
			</g>
			<g>
				<path
					d="M105,210H15c-8.291,0-15-6.709-15-15s6.709-15,15-15h90c8.291,0,15,6.709,15,15S113.291,210,105,210z"
					fill={color}
				/>
			</g>
		</svg>
	)
}

export default observer(Click)




