import "antd/dist/antd.dark.less"
import "./inizialize/helper"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import "antd/dist/antd.dark.css"
import "./css/index.css"
import AppNavigation from "./navigation"
import reportWebVitals from "./reportWebVitals"
import { StoreProvider } from "./store/Context"
import { ConfigProvider } from "antd"
import ru_RU from "antd/lib/locale/ru_RU"
import Styled from "./inizialize/Styled"
import dayjs from "dayjs"
import ru from "dayjs/locale/ru"
import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

dayjs.locale(ru)

ReactDOM.render(
	<ConfigProvider locale={ru_RU}>
		<Styled>
			<StoreProvider>
				<BrowserRouter>
					<AppNavigation/>
				</BrowserRouter>
			</StoreProvider>
		</Styled>
	</ConfigProvider>,
	document.getElementById("root"),
)

reportWebVitals()
