export var BACKGROUND_STYLE = {
    // fill: 'red',
    opacity: 0,
};
export var LINE_STYLE = {
    stroke: '#C5C5C5',
    strokeOpacity: 0.85,
};
export var AREA_STYLE = {
    fill: '#CACED4',
    opacity: 0.85,
};
