import { Divider, Radio, Switch } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import { FormItem, ModalForm } from "../../../component/ModalForm"

const ModalDocumentForm = ({
	type = "create",
	data = {},
	...props
}) => {

	return (
		<ModalForm defaultData={data} {...props}>
			<FormItem name={"type"}>
				<Radio.Group
					optionType="button"
					buttonStyle="solid"
					options={[
						{ label: "СТС", value: "sts" },
						{ label: "ПТС", value: "pts" },
						{ label: "ОСАГО", value: "insure" },
					]}
				/>
			</FormItem>
			<FormItem name={"name"}/>
			<FormItem name={"description"}/>
			<FormItem name={"number"}/>
			<Divider/>
			<FormItem name={"active"} valuePropName={"checked"}>
				<Switch/>
			</FormItem>
		</ModalForm>
	)
}

export default observer(ModalDocumentForm)

const Wrapper = styled.div``