import { faFileExcel, faSyncAlt } from "@fortawesome/pro-solid-svg-icons"
import { Button, Space, Table, Tooltip } from "antd"
import { toJS } from "mobx"
import qs from "qs"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useStore } from "../../store/Context"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { CalendarFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog"
import Date from "../Date"
import HighLight from "../HighLight"
import Icon from "../Icon"
import { ModalForm } from "../ModalForm"
import FilterDateBetween from "./Filter/DateBetween"
import FilterSearch from "./Filter/Search"
import ModalExportForm from "./Modal/ModalExportForm"
import TableFilter from "./TableFilter"
import TableSettings from "./TableSettings"
import TableTags from "./TableTags"

const TTTable = ({
	data = false,
	fetchOnStart = false,
	columns = {},
	defaultColumns = [],
	defaultFilters = [],
	size = "small",
	addItem = false,
	canExport = false,
	onclick,
	...props
}) => {

	const store = useStore()
	const [settingVisible, setSettingVisible] = useState(false)
	const [exportLoading, setExportLoading] = useState(false)
	const [exportVisible, setExportVisible] = useState(false)
	const navigate = useNavigate()
	const [getParams, setGetParams] = useState(null)
	const [searchParams] = useSearchParams()

	useEffect(() => {
		data.table.fromUri(searchParams.toString())
		setGetParams(searchParams.toString())
	}, [searchParams])

	useEffect(() => {
		if (getParams !== null) {
			if (data) {
				data.fetch()
			}
		}
	}, [getParams])

	useEffect(() => {
		const columnConfig = toJS(store.user.settings[data.table.getName]?.columns) ?? defaultColumns.map(column => ({
			column,
			checked: true,
		}))

		data.table.setColumns(columnConfig, false)
	}, [])

	const exportPossible = useMemo(() => {
		return canExport &&
			data.table.getFilters.find(item =>
				item.column === "date" &&
				item.checked &&
				item.value?.length === 2 &&
				Math.abs(item.value[0].diff(item.value[1], "days")) <= 31,
			) !== undefined
	}, [canExport, data.table.getFilters])

	const tableColumns = useMemo(() => {

		const localColumns = data.table.getColumns.map(({
			column,
			title,
			checked,
		}) => checked && ({
			dataIndex: column,
			title,
			...columns.find(i => i.dataIndex === column),
		})).filter(item => item)

		return localColumns.map(column => {
			if (!column.key && column.dataIndex) {
				if (Array.isArray(column.dataIndex)) {
					column.key = column.dataIndex.join(".")
				} else {
					column.key = column.dataIndex
				}
			}
			if (column.dataIndex === "tags" && !column.render) {
				column.render = (text, record) => <TableTags tags={record.tags}/>
			}
			if (column.dataIndex.substring(column.dataIndex.length - 3) === "_at" && !column.render) {
				column.render = (text, record) => <Date date={record[column.dataIndex]}/>
			}
			if (data.table.getOrders.includes(column.dataIndex)) {
				column.sorter = true
			}
			const filter = data.table.getFilters.find(item => item.column === column.dataIndex)
			if (filter) {
				const filters = []
				switch (filter.type) {
					case "in": {
						filter.possible.map(value => {
							const text = (column.renderFilter && typeof column.renderFilter)
								? column.renderFilter("", { [column.key]: value })
								: (column.render && typeof column.render)
									? column.render("", { [column.key]: value })
									: value
							filters.push({
								text,
								value,
							})
						})
						break
					}
					case "tags": {
						filter.possible.map(item => {
							const text = (column.renderFilter && typeof column.renderFilter)
								? column.renderFilter("", { [column.key]: item.name })
								: item.name
							filters.push({
								text,
								value: item.name,
							})
						})
						if (filter.possible.length > 10) {
							column.filterSearch = true
						}
						break
					}
					case "search": {
						column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) =>
							<FilterSearch
								visible={visible}
								setSelectedKeys={setSelectedKeys}
								selectedKeys={selectedKeys}
								confirm={confirm}
								clearFilters={clearFilters}
								column={filter.column}
							/>

						column.filterIcon = (filtered) => (
							<SearchOutlined
								style={{
									color: filtered ? "#1890ff" : undefined,
								}}
							/>
						)

						break
					}
					case "date": {
						if (filter.subtype === "between") {
							column.filterDropdown = ({
								setSelectedKeys,
								selectedKeys,
								confirm,
								clearFilters,
								visible,
							}) =>
								<FilterDateBetween
									visible={visible}
									setSelectedKeys={setSelectedKeys}
									selectedKeys={selectedKeys}
									confirm={confirm}
									clearFilters={clearFilters}
									column={filter.column}
								/>

							column.filterIcon = (filtered) => (
								<CalendarFilled
									style={{
										color: filtered ? "#1890ff" : undefined,
									}}
								/>
							)
						}
						break
					}
				}
				if (filters.length) {
					column.filters = [...filters]
				}
			}
			column.filteredValue = data.table.getFilters.find(item => item.column === column.key && item.checked)?.value ?? null

			if (!column.render) {
				column.render = text => <HighLight
					highlight={column.filteredValue}
					text={text}
				/>
			}

			return column
		})

	}, [data.table.getColumns])

	if (!data) return null

	return (
		<Wrapper>
			<TableMenu>
				<LeftWrapper size={6} wrap>
					{
						data.table.getFilters.filter(filter => filter.checked).map(filter => <TableFilter
							key={"filter-" + filter.column}
							filter={filter}
							table={data.table}
						/>)
					}
					{
						data.table.getFilters.filter(filter => filter.checked).length === 0 && " "
					}
				</LeftWrapper>
				<RightWrapper>
					{
						canExport && (
							<Tooltip title={!exportPossible && "Выборка по датам должна быть не более одного месяца"}>
								<ReloadButton
									disabled={!exportPossible}
									onClick={() => exportPossible && setExportVisible(true)}
								>
									<Icon name={faFileExcel} color={"#C8C4BD"} size={18} spin={exportLoading}/>
								</ReloadButton>
							</Tooltip>
						)
					}
					<ReloadButton onClick={() => data.fetch()}>
						<Icon name={faSyncAlt} color={"#C8C4BD"} size={18} spin={data.loading}/>
					</ReloadButton>
					<SettingButton onClick={() => setSettingVisible(true)}>
						<Icon name={faCog} color={"#C8C4BD"} size={20}/>
					</SettingButton>
					{
						addItem && (
							<AddButton type="primary" shape="round" onClick={addItem.setShowFormModal}>
								<PlusOutlined/> {addItem.title}
							</AddButton>
						)
					}
				</RightWrapper>
			</TableMenu>
			<Table
				bordered={false}
				size={size}
				loading={data.loading}
				dataSource={[...data.items]}
				rowKey="id"
				columns={tableColumns}
				style={{ cursor: "pointer", flex: 1, marginTop: 15 }}
				pagination={{
					...data.table.getConfig,
					showSizeChanger: false,
				}}
				onChange={(pagination, filters, sorter) => {
					data.table.fromClient({ pagination, filters, sorter })
					navigate("?" + qs.stringify(data.table.toServer, { encodeValuesOnly: true }))
				}}
				onRow={(record, rowIndex) => {
					return {
						onClick: () => {
							onclick(record)
						},
					}
				}}
				{...props}
			/>
			<TableSettings
				settingVisible={settingVisible}
				setSettingVisible={setSettingVisible}
				table={data.table}
			/>
			<ModalExportForm
				visible={exportVisible}
				title={"Экспорт данных"}
				api={"/v1/route/export"}
				onCancel={() => setExportVisible(false)}
				messageFormSuccess={"В ближайшее время отчёт будет сгенерирован и отправлен на указанные почтовые ящики. Обычно это занимает не более 1 минуты."}
				messageFormSuccessDuration={5}
				defaultData={{
					_filters: data.table.getFilterToServer,
				}}
			/>
		</Wrapper>
	)
}

export default observer(TTTable)

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	.ant-table-tbody {
		background-color: transparent;
	}

	.ant-table-tbody > tr > td {
		border-bottom: 1px solid #444;
	}

	.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		width: 1px;
	}
`

const TableMenu = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`
const AddButton = styled(Button)`
`
const SettingButton = styled.div`
	display: flex;
	cursor: pointer;
	background: #1c1c1c;
	height: 34px;
	width: 34px;
	align-items: center;
	justify-content: center;
	border-radius: 20px;

	&:hover {
		background: #111;
	}
`
const ReloadButton = styled.div`
	display: flex;
	cursor: pointer;
	background: #1c1c1c;
	height: 34px;
	width: 34px;
	align-items: center;
	justify-content: center;
	border-radius: 20px;

	${props => props.disabled ?
		"opacity: .5;" :
		"&:hover {    background: #111;  }"
	}
`
const LeftWrapper = styled(Space)`
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
`
const RightWrapper = styled(Space)`
	flex: 0;
	margin-left: 10px;
	flex-direction: row;
`