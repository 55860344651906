/**
 * 一些默认的样式配置
 */
export var BACKGROUND_STYLE = {
    fill: '#416180',
    opacity: 0.05,
};
export var FOREGROUND_STYLE = {
    fill: '#5B8FF9',
    opacity: 0.15,
    cursor: 'move',
};
export var DEFAULT_HANDLER_WIDTH = 10;
export var HANDLER_STYLE = {
    width: DEFAULT_HANDLER_WIDTH,
    height: 24,
};
export var TEXT_STYLE = {
    textBaseline: 'middle',
    fill: '#000',
    opacity: 0.45,
};
export var SLIDER_CHANGE = 'sliderchange';
