import {observer} from "mobx-react-lite"
import React, {useMemo} from "react"
import styled from "styled-components"
import {useStore} from "../../../../store/Context"
import {Dropdown, Modal, Table, Tooltip} from "antd"
import Flag from "../../../../assets/svg/flag.svg"
import {getPrettyPhone, prettyDigit, prettyTimePeriod} from "../../../../store/helper/StringMethod"
import PointStatus from "../../../../store/helper/PointStatus"
import {pointContextMenu} from "./pointContextMenu"
import {VList} from "virtuallist-antd"
import {Point} from "../../../../network"
import {PlusOutlined} from "@ant-design/icons"
import EmptyData from "./EmptyData"


const TablePoints = ({
						 selectPoint,
						 setEditPointItem,
						 setShowMovePointModal,
						 openUploadExcelModal
					 }) => {

	const store = useStore()

	const data = useMemo(() => {
		return [...store.routing.points].sort((a, b) => (a.route_id - b.route_id))
	}, [store.routing.refreshedAt])

	const toolBarData = useMemo(() => {
		return {
			total: data.length,
			done: data.filter(_point => _point.route_id).length
		}
	}, [store.routing.refreshedAt])


	const vComponents = useMemo(() => {
		return VList({
			height: store.user.settings.routing.layout.topHeight - 80,
			resetTopWhenDataChange: false,
			vid: 'RoutingTablePoints'
		})
	}, [store.user.settings.routing.layout.topHeight])

	const changeStatus = async (point, status) => {
		return new Promise(async () => {
			let result = await Point.editStatus({id: point.id, status: status})
			if (result.code === 200) {
				store.routing.updatePointStatus(point.id, status)
				setTimeout(() => Modal.destroyAll(), 500)
			}
		}).catch()
	}

	const DropMenu = ({point, children}) => {
		const route = point.route_id ? store.routing.getRouteById(point.route_id) : null
		return (
			<Dropdown
				overlay={pointContextMenu(point, route, selectPoint, store, setEditPointItem, setShowMovePointModal, changeStatus)}
				onVisibleChange={(visible) => {
					if (visible) {
						store.routing.setActivePoint(point.id)
					}
				}}
				trigger={["contextMenu"]}
			>
				<div className={'droppable'}>
					{children}
				</div>
			</Dropdown>
		)
	}


	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			fixed: 'left',
			width: 200,
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				return (
					<DropMenu point={record}>
						<Flag height={20} width={20}
							  color={record.route_id ? route?.color : "#bec1c1"}
						/>
						<Ellipsis width={200}>
							<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
								{text}
							</Tooltip>
						</Ellipsis>
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + a.name).localeCompare(b.name),
				multiple: 3,
			}
		},
		{
			title: 'Адрес',
			dataIndex: 'address',
			width: 300,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
			sorter: {
				compare: (a, b) => ('' + a.address).localeCompare(b.address),
				multiple: 3,
			},
		},
		{
			title: 'Время',
			dataIndex: 'time',
			width: 150,
			render: (text, record) => <DropMenu
				point={record}>{prettyTimePeriod(record.from_at, record.to_at)}</DropMenu>,
			sorter: {
				compare: (a, b) => ('' + a.from_at).localeCompare(b.from_at),
				multiple: 3,
			}
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: 120,
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				return (
					<DropMenu point={record}>
						{text.isCanceled ? (
							<span style={{color: PointStatus[text].color}}>
								{PointStatus[text].name}
							</span>
						) : (!route || route?.draft) ? (
							"Черновик"
						) : (
							<span style={{color: PointStatus[text].color}}>
								{PointStatus[text].name}
							</span>
						)}
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + a.from_at).localeCompare(b.from_at),
				multiple: 3,
			}
		},
		{
			title: 'Кол-во',
			dataIndex: 'count',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.count)}</DropMenu>,
			sorter: {
				compare: (a, b) => a.statistic.count - b.statistic.count,
				multiple: 3,
			}
		},
		{
			title: 'Вес',
			dataIndex: 'weight',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.weight, 'weight')}</DropMenu>,
			sorter: {
				compare: (a, b) => a.statistic.weight - b.statistic.weight,
				multiple: 3,
			}
		},
		{
			title: 'Объем',
			dataIndex: 'cubic',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.cubic, 'cubic')}</DropMenu>,
			sorter: {
				compare: (a, b) => a.statistic.cubic - b.statistic.cubic,
				multiple: 3,
			}
		},
		{
			title: 'Площадь',
			dataIndex: 'square',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.square, 'square')}</DropMenu>,
			sorter: {
				compare: (a, b) => a.statistic.square - b.statistic.square,
				multiple: 3,
			}
		},
		{
			title: 'Сумма',
			dataIndex: 'price',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.price, 'price')}</DropMenu>,
			sorter: {
				compare: (a, b) => a.statistic.price - b.statistic.price,
				multiple: 3,
			}
		},
		{
			title: 'Получатель',
			dataIndex: 'client_name',
			width: 250,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={250}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text?.length ? text : '—'}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
		},
		{
			title: 'Телефон',
			dataIndex: 'client_contact',
			width: 150,
			render: (text, record) => <DropMenu point={record}>{getPrettyPhone(text)}</DropMenu>
		},
		{
			title: 'Коммент',
			dataIndex: 'comment',
			width: 300,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
		},
	]


	return (
		<Wrapper>
			<TableWrapper tableHeight={store.user.settings.routing.layout.topHeight}>
				<Table
					dataSource={data}
					columns={columns}
					pagination={false}
					size="small"
					scroll={{
						y: store.user.settings.routing.layout.topHeight - 80,
						x: 2000
					}}
					rowKey='id'
					showSorterTooltip={false}
					components={vComponents}
					onRow={(record) => {
						return {
							onClick: e => {
								selectPoint(e, record, true)
							}
						}
					}}
					/*rowClassName={(record => (
						store.routing.activePointId === record.id && 'tr-active-point'
					))}*/
				/>
			</TableWrapper>

			<EmptyData visible={!data.length} dataLength={data.length}
					   type={'points'}/>

			<ToolBox>
				<div style={{flex: 1}}>
					Готово: {toolBarData.done} из {toolBarData.total}
				</div>
				<a onClick={openUploadExcelModal}><PlusOutlined/> Загрузить из файла</a>
			</ToolBox>
		</Wrapper>
	)
}

export default observer(TablePoints)

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 10px;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;

  .ant-table-body {
    height: ${props => props.tableHeight - 80}px;
  }
`
const Ellipsis = styled.span`
  width: ${props => props.width}px;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ToolBox = styled.div`
  display: flex;
  min-height: 30px;
  padding: 5px 10px;
  background-color: #1F1F1F;
  color: #7B7975;
  font-size: 13px;
  align-items: center;
`
