import {List} from "antd"
import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import PointItem from "./PointItem"
import ModalPointView from "../ModalPointView"

const RoutePointsTab = ({route}) => {

	const [activePoint, setActivePoint] = useState(null)
	const [showCargoModal, setShowCargoModal] = useState(false)

	useEffect(() => {
		setShowCargoModal(activePoint ? true : false)
	}, [activePoint])

	return (
		<>
			<List
				itemLayout="horizontal"
				dataSource={route.sortedPoints}
				renderItem={(item, index) =>
					<PointItem point={item}
							   index={index}
							   total={route?.points?.length}
							   activePoint={activePoint}
							   setActivePoint={setActivePoint}
					/>}
			/>
			{
				showCargoModal && (
					<ModalPointView
						point={activePoint}
						onCancel={() => {
							setActivePoint(null)
							setShowCargoModal(false)
						}}
					/>
				)
			}
		</>
	)
}

export default observer(RoutePointsTab)

const Wrapper = styled.div`

`
