import React from "react"
import {Space, Spin} from "antd";

const AppLoader = () => (
	<div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
		<div>
			<Space size="middle">
				<Spin tip='Загрузка...' size="large"/>
			</Space>
			{/*<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData: animationData,
				}}
				loop={true}
				autoplay={true}
				animationData={animationData}
				height={100}
				width={100}
			/>
			<div>
				<span style={{color: '#5666F3', marginLeft: 20}}>Загрузка...</span>
			</div>*/}
		</div>
	</div>
)
export default AppLoader
