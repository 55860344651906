export var EN_US_LOCALE = {
    locale: 'en-US',
    // General
    general: {
        increase: 'Increase',
        decrease: 'Decrease',
        root: 'Root',
    },
    // Plot Components
    /** statistic text component */
    statistic: {
        total: 'Total',
    },
    /** conversionTag component */
    conversionTag: {
        label: 'Rate',
    },
    legend: {},
    tooltip: {},
    slider: {},
    scrollbar: {},
    // Plots
    waterfall: {
        total: 'Total',
    },
};
