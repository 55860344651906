import {observer} from "mobx-react-lite"
import React, {useCallback, useMemo} from "react"
import styled from "styled-components"
import {useStore} from "../../../../store/Context"
import {Divider, Dropdown, Modal, Table, Tooltip} from "antd"
import {VList} from "virtuallist-antd"
import {Route, Routing} from "../../../../network"
import {debounce} from "lodash"
import dayjs from "dayjs"
import RouteStatus from "../../../../store/helper/RouteStatus"
import {faLock} from "@fortawesome/pro-regular-svg-icons/faLock"
import Icon from "../../../../component/Icon"
import Chevron from "../../../../assets/svg/chevron.svg"
import {FileFilled, FileOutlined, FileTwoTone, LinkOutlined, LoadingOutlined} from "@ant-design/icons"
import Avatar from "antd/es/avatar/avatar"

const TableRoutes = ({
						 syncRoute, setEditRouteItem, setShowRouteFormModal
					 }) => {

	const store = useStore()

	const data = useMemo(() => {
		return [...store.route.actual.current]
	}, [store.routing.refreshedAt, store.route.actual.activeRoute])

	const toolBarData = useMemo(() => {
		return {
			total: data.length,
			done: data.filter(_point => _point.route_id).length
		}
	}, [store.routing.refreshedAt])


	const vComponents = useMemo(() => {
		return VList({
			height: store.user.settings.terminal.layout.bottomHeight - 80,
			resetTopWhenDataChange: false,
			vid: 'TerminalTableRoutes'
		})
	}, [store.user.settings.terminal.layout.bottomHeight])

	/*const hover = useCallback(debounce(async (routeId) => {
		if (store.routing.activeRoute) return
		store.routing.setRouteHover(routeId, 'routes')
	}, 0), [])*/

	const DropMenu = ({route, children}) => {
		return (
			/*<Dropdown
				overlay={routeContextMenu(route, store, publish, syncRoute, setEditRouteItem, setShowRouteFormModal, changeStatus, remove)}
				trigger={["contextMenu"]}
			>
				<div className={'droppable'}>
					{children}
				</div>
			</Dropdown>*/
			<div className={'droppable'}>
				{children}
			</div>
		)
	}


	const columns = [
		{
			title: 'Маршрутный лист',
			dataIndex: 'name',
			fixed: 'left',
			width: 200,
			render: (text, record) => {
				return (
					<DropMenu route={record}>
						<Ellipsis width={200}>
							<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
								<span style={{marginLeft: 5}}>{record.name}</span>
								<a style={{marginLeft: 5}}><LinkOutlined/></a>
							</Tooltip>
						</Ellipsis>
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + a.name).localeCompare(b.name),
				multiple: 3,
			}
		},
		{
			title: 'Точки',
			width: 100,
			render: (text, record) => {
				return (
					<DropMenu route={record}>
						{record.countReadyPoints.ready} из {record.countReadyPoints.all}
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + a.name).localeCompare(b.name),
				multiple: 3,
			}
		},
		{
			title: 'Исполнитель',
			width: 220,
			render: (text, record) => {
				return (
					<DropMenu route={record}>
						<Ellipsis width={220}>
							<Avatar
								src={process.env.REACT_APP_API_URL + "/media/driver/" + record.driver.login + "/avatar"}
								size={22}/> <span style={{marginLeft: 5}}>{record.driver?.stripName}</span>
						</Ellipsis>
					</DropMenu>
				)
			},
			sorter: {
				compare: (a, b) => ('' + store.routing.getDriver(a.driver_id)).localeCompare(store.routing.getDriver(b.driver_id)),
				multiple: 3,
			}
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: 120,
			render: (text, record) => <DropMenu route={record}>
						<span style={{color: RouteStatus[record.status].color}}>
							{RouteStatus[record.status].name}
						</span>
			</DropMenu>,
			sorter: {
				compare: (a, b) => ('' + a.status).localeCompare(b.status),
				multiple: 3,
			}
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			width: 200,
			render: (text, record) => <DropMenu route={record}>
				<Ellipsis width={200}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text?.length ? text : '—'}
					</Tooltip>
				</Ellipsis>
			</DropMenu>
		}
	]

	return (
		<Wrapper>
			<TableWrapper tableHeight={store.user.settings.terminal.layout.bottomHeight}>
				<Table
					dataSource={data}
					columns={columns}
					pagination={false}
					size="small"
					scroll={{
						y: store.user.settings.terminal.layout.bottomHeight - 80,
						x: 800
					}}
					rowKey='id'
					showSorterTooltip={false}
					components={vComponents}
					onRow={(record) => {
						return {
							onClick: () => {
								store.route.actual.setActiveRoute(record.id)
							},
						}
					}}
					//rowSelection={[]}
					rowClassName={(record => (
						store.route.actual.activeRoute === record.id && 'tr-active-route'
					))}
				/>
			</TableWrapper>
			<ToolBox>
				<div style={{flex: 1}}>
					Всего: {toolBarData.total}
				</div>
			</ToolBox>
		</Wrapper>
	)
}

export default observer(TableRoutes)

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 10px;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;


  .ant-table-body {
    min-height: ${props => props.tableHeight - 80}px;
  }
`
const Ellipsis = styled.span`
  width: ${props => props.width}px;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`
const ToolBox = styled.div`
  display: flex;
  min-height: 30px;
  padding: 5px 10px;
  background-color: #1F1F1F;
  color: #7B7975;
  font-size: 13px;
  align-items: center;
`
