import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Badge, Button, Dropdown, Input, Menu} from "antd"
import {CloseOutlined, FilterFilled, SearchOutlined, TagFilled} from "@ant-design/icons"
import RouteStatus from "../../../store/helper/RouteStatus"
import Checkbox from "antd/es/checkbox/Checkbox"
import {useStore} from "../../../store/Context"

const FastSearch = ({filters, setFilters, searchText, setSearchText, dataLength = 0, withoutStatus = false}) => {

	const store = useStore()

	const [showFilter, setShowFilter] = useState(false)

	const selectFilter = (event, name) => {
		let sourceArray = [...filters]
		let searchIndex = filters.findIndex(item => item.type === name.type && item.name === name.name)
		if (searchIndex === -1 && event.target.checked) {
			sourceArray.push({type: name.type, name: name.name})
		} else if (!event.target.checked) {
			sourceArray.splice(searchIndex, 1)
		}
		setFilters(sourceArray)
	}

	const menu = (
		<Menu onMouseLeave={() => setShowFilter(false)}>
			{
				!withoutStatus && Object.keys(RouteStatus).map(status => (
					<Menu.Item key={status}>
						<Checkbox
							checked={filters.findIndex(c => c.type === 'status' && c.name === status) !== -1}
							onChange={(e) => selectFilter(e, {type: 'status', name: status})}
							style={{color: RouteStatus[status].color}}>
							{RouteStatus[status].name}
						</Checkbox>
					</Menu.Item>
				))
			}
			{
				store.route.actual.tagList?.length ? (
					<>
						{
							!withoutStatus && (
								<Hr/>
							)
						}
						{
							store.route.actual.tagList.map((tag, index) => (
								<Menu.Item style={{paddingHorizontal: 15}} key={'tag-' + tag.id}>
									<Checkbox
										checked={filters.findIndex(c => c.type === 'tag' && c.name === tag.id) !== -1}
										onChange={(e) => selectFilter(e, {type: 'tag', name: tag.id})}>
										<TagFilled height={4} width={4} fill={"#C9C5BE"}
												   style={{opacity: .5, marginRight: 5}}/>{tag.name}
									</Checkbox>
								</Menu.Item>
							))
						}
					</>
				) : null
			}

			<Menu.Item style={{paddingHorizontal: 15}} key='resetLink'>
				<CloseOutlined style={{color: '#409FE7', marginRight: 2}}/>
				<a key={'resetForm'}
				   style={{color: '#409FE7'}}
				   onClick={() => setFilters([])}>Сброс</a>
			</Menu.Item>
		</Menu>
	)

	return (
		<SearchBar>
			<Input size="small" onChange={(e) => setSearchText(e.target.value)} allowClear value={searchText}
				   placeholder="ФИО или номер МЛ" prefix={<SearchOutlined style={{color: '#727373'}}/>}/>
			<Dropdown overlay={menu} placement="bottomLeft" arrow visible={showFilter}
					  onMouseEnter={() => setShowFilter(!showFilter)}>
				<Badge size="small" count={filters.length}>
					<Button style={{marginLeft: 10}} type={"link"} icon={<FilterFilled/>}/>
				</Badge>
			</Dropdown>
		</SearchBar>
	)
}

export default observer(FastSearch)

const SearchBar = styled.div`
  padding: 10px;
  border-bottom: solid 1px #3e4141;
  display: flex;
  background-color: #242424;
`
const Hr = styled.div`
  border-bottom: solid .5px #3E4141;
  margin-top: 5px;
  margin-bottom: 5px;
`
