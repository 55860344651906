import { applySnapshot, types } from "mobx-state-tree"
import { Link } from "react-router-dom"
import { prettyCarNumber, prettyCarNumber2 } from "../helper/StringMethod"
import { DocumentItem } from "./DocumentItem"
import { DriverItem } from "./DriverItem"
import { TagItem } from "./TagItem"

export const TransportItem = types.model({
	external_id: types.maybeNull(types.string),
	id: types.identifierNumber,
	name: types.maybeNull(types.string),
	brand: types.maybeNull(types.string),
	model: types.maybeNull(types.string),
	owner_type: types.maybeNull(types.string),
	owner_id: types.maybeNull(types.number),
	owner_name: types.maybeNull(types.string),
	sts: types.maybeNull(types.string),
	pts: types.maybeNull(types.string),
	insure: types.maybeNull(types.string),
	gosnumber: types.maybeNull(types.string),
	vin: types.maybeNull(types.string),
	driver: types.maybeNull(types.late(() => DriverItem)),
	documents: types.optional(types.array(DocumentItem), []),
	tags: types.optional(types.array(TagItem), []),
	created_at: types.maybeNull(types.string),
}).actions(self => {
	return {
		setDocuments (data) {
			applySnapshot(self.documents, data)
		},
	}
}).views(self => {
	return {
		get stripName () {
			return self.brand + " " + self.model + (self.name ? " (" + self.name + ")" : "")
		},
		get prettyVin () {
			return ((self.vin ?? "") + "").toUpperCase()
		},
		get prettySts () {
			return self.sts
		},
		get prettyPts () {
			return self.pts
		},
		get owner () {
			switch (self.owner_type) {
				case "App\\Models\\Office": {
					return "Компания"
				}
				case "App\\Models\\Driver": {
					return (<Link to={"/driver/" + self.owner_id}>{self.owner_name}</Link>)
				}
			}
		},
	}
})
