import {observer} from "mobx-react-lite"
import {useMemo} from "react"
import {Layer, Source, useMap} from "react-map-gl"
import styled from "styled-components"

const MapTrackPoint = ({
						   points = []
					   }) => {

	const POINT = useMemo(() => {
			return {
				id: "point",
				type: "circle",
				source: "points",
				filter: ["!", ["has", "point_count"]],
				paint: {
					"circle-color": "#59b125",
					"circle-radius": 4,
					"circle-stroke-width": 1,
					"circle-stroke-color": "#fff"
				},
			}
		}
		, [])

	const geoJson = useMemo(() => {
		let result = null

		if (points.length > 0) {
			result = {
				"type": "FeatureCollection",
			}
			result.features = points.map(point => {
				return {
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [point.longitude, point.latitude],
					},
					"properties": point
				}
			}).filter(a => a)
		}

		return result
	}, [points])

	return (
		<>
			<Source
				type="geojson"
				data={geoJson}
			>
				{
					<Layer id="point" {...POINT} />
				}
			</Source>
		</>
	)
}

export default observer(MapTrackPoint)

const Wrapper = styled.div``
