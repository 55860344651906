import { observer } from "mobx-react-lite"
import styled from "styled-components"

const DriverTabRoute = ({ driver }) => {

	return (
		<Wrapper>
		</Wrapper>
	)
}

export default observer(DriverTabRoute)

const Wrapper = styled.div``