import { Empty, List } from "antd"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import PageHeader from "../../component/PageHeader"
import { useStore } from "../../store/Context"
import ActualListItem from "./component/ActualListItem"
import RouteView from "./component/RouteView"
import RouteFilter from "../../store/helper/RouteFilter"
import FastSearch from "./component/FastSearch"


const RouteActualScreen = ({}) => {

	const store = useStore()
	const navigate = useNavigate()
	let { filter = false, id = null } = useParams()

	const [ready, setReady] = useState(false)

	const [searchText, setSearchText] = useState("")
	const [filters, setFilters] = useState([])

	useEffect(() => {
		const load = () => {
			if (!filter || !RouteFilter[filter]) {
				navigate("/route/all/", { replace: true })
				store.route.actual.setActive(0)
			} else {
				id = parseInt(id)
				if (id && store.route.actual.routeFilter(filter).find(item => item.id === id)) {
					store.route.actual.setActive(id)
					setReady(true)
				} else {
					store.route.actual.setActive(0)
					if (store.route.actual.routeFilter(filter).length) {
						id = store.route.actual.routeFilter(filter)[0].id
						navigate("/route/" + filter + "/" + id, { replace: true })
					} else {
						store.route.actual.setActive(0)
						setReady(true)
					}
				}
			}
		}

		load()
	}, [filter, id])

	if (!ready) return null

	return (
		<Wrapper>
			<PageHeader
				title={<>
					<span>Маршрутные листы</span>
					<span style={{ fontSize: 14, color: "#666", marginLeft: 10 }}>{RouteFilter[filter].name}</span>
				</>}
			/>

			<ContentWrapper>
				{
					store.route.actual.active ? (
						<ContentCard>
							<ListWrapper>
								<FastSearch filters={filters} setFilters={setFilters} searchText={searchText}
											setSearchText={setSearchText}
											withoutStatus/>
								<ListItems>
									<List
										dataSource={store.route.actual.routeFastFilter([...store.route.actual.routeFilter(filter)], filters, searchText)}
										renderItem={item => <ActualListItem item={item}/>}
									/>
								</ListItems>
							</ListWrapper>
							<RouteView route={store.route.actual.active}/>
						</ContentCard>
					) : (
						<Centered>
							<Empty style={{ alignSelf: "center" }}/>
						</Centered>
					)
				}
			</ContentWrapper>
		</Wrapper>
	)
}

export default observer(RouteActualScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding: 20px 20px 0px 20px;
`
const ContentCard = styled.div`
  background-color: #242525;
  display: flex;
  flex: 1;
`
const ListWrapper = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`
const ListItems = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  border-right: solid 1px #303030;
  flex-direction: column;
`
const Centered = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 300px;
`
