FormData.prototype.appendObject = function (namespace, obj) {
	Object.entries(obj).map(([key, value]) => {
		const name = [namespace, "[", key, "]"].join("")
		if (typeof value === "string") {
			this.append(name, value)
		} else if (Array.isArray(value)) {
			this.appendArray(name, value)
		}
	})
}
FormData.prototype.appendArray = function (namespace, obj) {
	obj.map(item => {
		this.append(namespace + "[]", item ?? "")
	})
}