import {observer} from "mobx-react-lite"
import React from "react"
import {Tree} from 'antd'
import styled from "styled-components"
import dayjs from "dayjs"

const LogList = ({
					 logs = [], onClick = () => {
	}
				 }) => {

	const prepareTreeData = (data) => {
		let i = 0
		return data.map((_data) => {
			i++
			return {
				key: i,
				title: _data.item_name,
				children:
					_data.logs.map((_log) => {
						i++
						return {
							key: i,
							title:
								<LogItem onClick={() => {
									if (_log.date) {
										onClick(_log.date)
									}
								}}>
									<Time>{dayjs(_log.date).format('HH:mm')}</Time>
									·
									<Data>
										{_log.text}
										{
											_log?.sup?.text && (
												<Sup color={_log.sup.color}>
													{_log.sup.text}
												</Sup>
											)
										}
										{
											_log?.reason && (
												<div style={{color: '#FF9500'}}>
													{_log.reason}
												</div>
											)
										}
									</Data>
								</LogItem>,

							icon: false
						}
					})
			}
		})

	}

	return (
		<Wrapper>
			{
				logs.map((date, dIndex) => (
					<div key={'d-' + dIndex} style={{marginBottom: 30}}>
						<LogDate>
							{dayjs(date.date).format('D MMMM YYYY')}
						</LogDate>
						<Tree.DirectoryTree
							showIcon={false}
							selectable={false}
							treeData={prepareTreeData(logs[dIndex].items)}
							rootStyle={{color: '#C8C4BD'}}
						/>
					</div>
				))
			}
		</Wrapper>
	)
}

export default observer(LogList)

const Wrapper = styled.div`
  .ant-tree-switcher-noop {
    display: none !important;
  }

  .ant-tree-title {
    text-overflow: ellipsis;
    width: 280px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }
`
const LogItem = styled.span`

`

const Time = styled.span`
  font-size: 12px;
  color: #E5E0D8;
  margin-right: 5px;
`

const Sup = styled.sup`
  font-size: 8px;
  top: -8px;
  left: 4px;
  color: ${props => props.color};
`
const LogDate = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  color: #E5E0D8;
`
const Data = styled.span`
  font-size: 12px;
  color: #E5E0D8;
  margin-left: 5px;
`
