import {faTimes} from "@fortawesome/pro-light-svg-icons/faTimes"
import dayjs from "dayjs"
import {observer} from "mobx-react-lite"
import styled from "styled-components"
import Box from "../../../assets/svg/box.svg"
import Icon from "../../../component/Icon"
import {MapboxMarker} from "../../../component/Mapbox"
import PointStatus from "../../../store/helper/PointStatus"
import {prettyTimePeriod} from "../../../store/helper/StringMethod"

const WIDTH = 40
const HEIGHT = 40

const BoxMarker = ({
					   item,
					   active,
					   width = WIDTH,
					   height = HEIGHT,
					   ...props
				   }) => {

	if (!item.address_location?.lat || !item.address_location?.lng) return null

	return (
		<MapboxMarker
			latitude={item.address_location.lat}
			longitude={item.address_location.lng}
			style={{zIndex: active === item.id ? 5 : 1}}
			anchor={"center"}
		>
			<Wrapper
				zIndex={active === item.id ? 5 : 1}
				opacity={(active === undefined || active === item.id) ? 1 : .5}
				{...props}
			>
				{
					active === item.id && (
						<PointDescription>
							<CloseButton>
								<Icon name={faTimes} size={20} color={"#CCCCCC"}/>
							</CloseButton>
							<TopMenu>
								<Address>
									<Position>
										{item.position}.
									</Position>
									{item.name.charAt(0).toUpperCase() + item.name.slice(1)}
								</Address>
								<Time>
									{prettyTimePeriod(item.from_at, item.to_at)}
								</Time>
							</TopMenu>
							<Description>
								{item.address.charAt(0).toUpperCase() + item.address.slice(1)}
								<Contact>
									{item.client_contact}
								</Contact>
							</Description>
							<Status color={PointStatus[item.status].color}>
								{PointStatus[item.status].name}
								{
									item.status !== "created" && (
										" в " + dayjs(item.updated_at).format("HH:mm")
									)
								}
							</Status>
						</PointDescription>
					)
				}
				<Box width={width} height={height} color={PointStatus[item.status].color}/>
				{
					item.position && (
						<Number>
							{item.position}
						</Number>
					)
				}
			</Wrapper>
		</MapboxMarker>
	)
}

export default observer(BoxMarker)


const Wrapper = styled.div`
  cursor: pointer;
  z-index: ${props => props.zIndex};
  opacity: ${props => props.opacity};
`
const Number = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: -4px;
  bottom: -2px;
  border-radius: 50px;
  background: #282828;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
`


const PointDescription = styled.div`
  position: absolute;
  bottom: 50px;
  left: -140px;
  width: 300px;
  min-height: 110px;
  box-shadow: 0 0 7px #00000099;
  background-color: #1F1F1F;
  cursor: default;
`
const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-bottom: 10px;
`
const CloseButton = styled.div`
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
`
const Address = styled.div`
  font-size: 14px;
  color: #4D97FC;
  flex: 1;
  flex-shrink: 1;
`
const Time = styled.div`
  font-size: 14px;
  color: #4D97FC;
`
const Position = styled.span`
  font-weight: bold;
  margin-right: 5px;
`

const Description = styled.div`
  padding: 10px 10px 0px 10px;
  font-size: 14px;
  border-top: solid 1px var(--text-4);
`
const Contact = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: var(--text-3);
  padding-bottom: 10px;
`
const Status = styled.div`
  font-size: 13px;
  text-align: end;
  padding: 0 10px 10px;
  color: ${props => props.color};
`
