import { message, Progress, Upload } from "antd"
import { observer } from "mobx-react-lite"
import { useMemo, useState } from "react"
import styled from "styled-components"
import {
	InboxOutlined,
	CloudUploadOutlined,
	LoadingOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons"
import { Routing } from "../../../../network"

const accept = [".xls", ".xlsx", ".csv"]

const StepUpload = ({ next }) => {

	const [uploadStatus, setUploadStatus] = useState({ status: "idle" })

	const uploadImage = async options => {
		const { onSuccess, onError, file, onProgress } = options

		setUploadStatus({ status: "uploading", percent: 0 })

		const result = await Routing.uploadPoints(file, {
			headers: { "content-type": "multipart/form-data" },
			onUploadProgress: event => {
				const percent = Math.floor((event.loaded / event.total) * 100)
				setUploadStatus({ status: "uploading", percent })
				onProgress({ percent })
			},
		})

		if (!result.success) {
			setUploadStatus({ status: "error", error: result.error })
			return
		}
		setUploadStatus({ status: "done" })
		setTimeout(() => {
			next(result.data)
		}, 1000)
	}

	const textTitle = useMemo(() => {
		switch (uploadStatus.status) {
			case "idle":
				return "Кликните для загрузки файла или перетащите его в эту область"
			case "uploading":
				return uploadStatus.percent === 100 ? "Обработка файла" : "Загрузка файла"
			case "done":
				return "Файл успешно загружен"
			case "error":
				return "Ошибка загрузки файла"
			default:
				return "Неизвестная ошибка"
		}
	}, [uploadStatus])

	const textDescription = useMemo(() => {
		switch (uploadStatus.status) {
			case "idle":
				return "Поддерживаемые форматы: " + accept.join(", ")
			case "uploading":
				return uploadStatus.percent === 100 ? "Ещё немного..." :
					<Progress style={{ width: "50%" }} percent={uploadStatus.percent} status="active"/>
			case "done":
				return "Самое время проверить ваши данные"
			case "error":
				return uploadStatus.error?.message ?? "Неизвестная ошибка"
			default:
				return "Неизвестная ошибка"
		}
	}, [uploadStatus])

	const icon = useMemo(() => {
		switch (uploadStatus.status) {
			case "idle":
				return <InboxOutlined/>
			case "uploading":
				return uploadStatus.percent === 100 ? <LoadingOutlined/> : <CloudUploadOutlined/>
			case "done":
				return <CheckCircleOutlined/>
			case "error":
				return <CloseCircleOutlined/>
			default:
				return <InboxOutlined/>
		}
	}, [uploadStatus])

	return (
		<Uploader
			accept={accept.join(",")}
			customRequest={uploadImage}
			showUploadList={false}
		>
			<Icon status={uploadStatus.status}>{icon}</Icon>
			<Text>
				<Title>{textTitle}</Title>
				<Description>{textDescription}</Description>
			</Text>
		</Uploader>
	)
}

export default observer(StepUpload)

const Uploader = styled(Upload.Dragger)`
  flex: 1;
  display: flex;
`
const Icon = styled.div`
  font-size: 10em;
  color: ${
          props => props.status === "idle" ? "#1890ff" :
                  props.status === "uploading" ? "#ccc" :
                          props.status === "done" ? "#7cb305" :
                                  props.status === "error" ? "#f5222d" : "#ccc"
  };
`
const Text = styled.div`
  width: 100%;
`
const Title = styled.div`
  font-size: 1.5em;
`
const Description = styled.div``