import {observer} from "mobx-react-lite"
import React from "react"
import {useNavigate, useParams} from "react-router-dom"
import styled from "styled-components"
import {useStore} from "../../../store/Context"
import StatusLabel from "../../../component/StatusLabel"
import dayjs from "dayjs"

const ActualListItem = ({item}) => {

	const store = useStore()
	const navigate = useNavigate()
	let {filter = null} = useParams()

	return (
		<ListItem onClick={() => navigate("/route/" + filter + "/" + item.id)}
				  className={item.id === store.route.actual.active?.id && "active"}>
			<RouteInfo>
				<DriverName>
					{item.driver?.stripName}
				</DriverName>
				<RouteName>{item.name}</RouteName>
				<StatusWrapper>
					{dayjs(item.date).format('DD.MM.YYYY')}
				</StatusWrapper>
			</RouteInfo>
			<PointInfo>
				{/*{
					(item.status === "performed" || item.status === "done") && (
						<span style={{fontSize: 13, opacity: .7}}>{item.points.arrived}</span>
					)
				}*/}
				<StatusLabel status={item.status} options={false} opacity={.8}/>
				<DoneWrapper>{item.countReadyPoints.ready} из {item.countReadyPoints.all}</DoneWrapper>
			</PointInfo>
		</ListItem>
	)
}

export default observer(ActualListItem)

const ListItem = styled.span`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-bottom: 1px solid #303030;
  padding: 15px;
  min-height: 101px;
  cursor: pointer;

  &:hover {
    background: #212121;
  }

  &.active {
    background: #113545;
    border-left: solid 3px #165D9D;
  }
`
const StatusWrapper = styled.div`
  margin-top: 10px;
  flex: 1;
`
const DoneWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

const RouteInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const RouteName = styled.div`
  line-height: 15px;
  margin-bottom: 7px;
  opacity: .7;
  font-size: 14px;
`
const DriverName = styled.div`
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 5px;
  max-width: 150px;
`
const PointInfo = styled.div`
  font-size: 15px;
  line-height: 15px;
  position: relative;
`
