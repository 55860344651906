import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
	Card,
	PageHeader,
} from "antd"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import TTTable from "../../component/TTTable"
import { useStore } from "../../store/Context"
import dayjs from "dayjs"

const columns = [
	{
		title: "Название",
		dataIndex: "name",
	},
	{
		title: "Адрес",
		dataIndex: "address",
	},
	{
		title: "Создан",
		dataIndex: "created_at",
		render: (text, data) => dayjs(data.created_at).format("D MMMM YYYY"),
	},
]

const OfficeScreen = () => {

	const store = useStore()
	const navigate = useNavigate()

	return (
		<Wrapper>
			<PageHeader
				title="Офисы"
			/>
			<ContentWrapper>
				<TTTable
					data={store.office}
					columns={columns}
					addItem={{ title: "Добавить офис", link: "driver/add" }}
					/*onclick={(item) => navigate("/office/" + item.id)}*/
				/>
			</ContentWrapper>
		</Wrapper>
	)
}

export default observer(OfficeScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
