import {observer} from "mobx-react-lite"
import {Outlet} from "react-router-dom"
import styled from "styled-components"
import LeftMenu from "./LeftMenu"
import {Layout} from "antd"
import React from "react"
import TopMenu from "./TopMenu"
import GlobalSocketUpdate from "../../component/GlobalSocketUpdate"
import CheckConnection from "../../component/CheckConnection"

const Inside = () => {

	return (
		<Wrapper>
			<CheckConnection/>
			<GlobalSocketUpdate/>
			{
				process.env.REACT_APP_DEBUG === "true" && (<Debug version={process.env.REACT_APP_ENV}/>)
			}
			<TopMenu/>
			<ContentWrapper>
				<LeftMenu/>
				<Outlet/>
			</ContentWrapper>
		</Wrapper>
	)
}

export default observer(Inside)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const Debug = styled.div`
  display: flex;
  height: 30px;
  background-color: #bb4545;
  color: #fff;
  align-items: center;
  padding: 15px;

  &:after {
    content: '${props => props.version} версия';
  }
`
const ContentWrapper = styled(Layout.Content)`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
`
