import { Checkbox } from "antd"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useStore } from "../../../store/Context"
import { FormItem, ModalForm } from "../../ModalForm"
import axios from "../../../inizialize/Axios"

const ModalExportForm = ({ data, update = false, ...props }) => {

	const [possibleEmails, setPossileEmails] = useState([])
	const store = useStore()

	useEffect(() => {
		loadPossibleEmails()
	}, [])

	const loadPossibleEmails = async () => {
		const result = await axios.get("/v1/office/" + store.user.office.id + "/email")
		if (result.success) {
			setPossileEmails(result.data)
		}
	}

	return (
		<ModalForm
			{...props}
		>
			<FormItem name={"subject"} title={"Тема письма"}/>
			<FormItem name={"emails"} title={"Почтовые ящики"}>
				<Checkbox.Group options={possibleEmails}/>
			</FormItem>
		</ModalForm>
	)
}

export default observer(ModalExportForm)

const Wrapper = styled.div``