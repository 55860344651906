import {observer} from "mobx-react-lite"
import React, {useEffect, useMemo, useState} from "react"
import {useStore} from "../../../../store/Context"
import {CloseCircleFilled, SaveOutlined, TagFilled, ExclamationCircleOutlined} from "@ant-design/icons"
import {Dropdown, InputNumber, Menu, Popover, Slider, Switch} from "antd"
import styled from "styled-components"
import dayjs from "dayjs"
import Calendar from 'react-calendar'
import '../../../../css/calendar.css'
import {faClock} from "@fortawesome/pro-light-svg-icons"
import Icon from "../../../../component/Icon"
import {faTrash} from "@fortawesome/free-solid-svg-icons"
import Modal from "antd/es/modal/Modal"

const Toolbox = ({filters}) => {

	const store = useStore()

	const [filterEditing, setFilterEditing] = useState(null)
	const [hoverFilterIcon, setHoverFilterIcon] = useState(null)

	const marks = {
		0: {style: {fontSize: 9}, label: <span style={{paddingLeft: 10}}>00:00</span>},
		144: {style: {fontSize: 9}, label: <span>12:00</span>},
		287: {style: {fontSize: 9}, label: <span style={{paddingRight: 10}}>23:59</span>}
	}

	const charsFilter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k']

	const timeParser = (val) => {
		if (val === 288) return '23:59'
		return dayjs('2000-01-01').add(val * 5, 'minutes').format('HH:mm')
	}

	const fromToLetter = (type, from, to, blank = '') => {
		switch (type) {
			case 'time' : {
				if (from && to !== 288) {
					blank = timeParser(from) + ' — ' + timeParser(to)
				} else if (from) {
					blank = 'от ' + timeParser(from)
				} else if (to !== 288) {
					blank = 'до ' + timeParser(to)
				}
				break
			}
			default: {
				if (from && to) {
					blank = from + ' — ' + to
				} else if (from) {
					blank = 'от ' + from
				} else if (to) {
					blank = 'до ' + to
				}
				break
			}
		}


		return blank
	}

	const deleteHotKey = (type, from, to) => {
		Modal.confirm({
			title: 'Удалить фильтр?',
			icon: <ExclamationCircleOutlined/>,
			content: 'После удаления его невозможно будет восстановить.',
			okText: 'Удалить',
			cancelText: 'Отмена',
			onOk: () => store.user.deleteHotKey(type, from, to)
		})
	}

	const HotKeys = ({data, filterId}) => {
		if (!data.length) return null
		return (
			<Menu
				style={{left: -5, minWidth: 155}} items={data.map((filterButton, index) => {
				return {
					key: index,
					style: {paddingLeft: 5},
					label:
						<HotKeyMenu
							onClick={() => {
								store.user.setRoutingPointFilter(filterId, {
									from: filterButton.from ?? 0,
									to: filterButton.to ?? 0
								})
							}}
						>
							<HotKeyTag
								onClick={() => deleteHotKey(filterId, filterButton.from, filterButton.to)}>
								<span className={'hotkey-char'}>{charsFilter[index].toUpperCase()}</span>
								<span className={'hotkey-delete'}>
									<Icon name={faTrash} size={10} color={'#e89a3c'}/>
								</span>
							</HotKeyTag>
							{fromToLetter(
								filterId,
								filterButton.from,
								filterButton.to
							)}
						</HotKeyMenu>
				}
			})}/>
		)
	}

	return (
		<Wrapper>
			<ToolItem>
				<Popover placement={"bottom"} color={"#1F1F1F"}
						 overlayInnerStyle={{backgroundColor: 'red'}}
						 content={<Calendar
							 showNeighboringMonth={false}
							 minDate={dayjs().toDate()}
							 maxDate={dayjs().add(1, 'month').toDate()}
							 fullscreen={false}
							 prev2Label={null}
							 next2Label={null}
							 minDetail="month"
							 value={dayjs(store.routing.activeDate).toDate()}
							 onChange={(e) => {
								 store.routing.setActiveDate(dayjs(e).format('YYYY-MM-DD'))
							 }}
						 />}>
					{dayjs(store.routing.activeDate).format('D MMMM, dd')}
				</Popover>
			</ToolItem>
			<ToolItem
				className={store.user.settings.routing.pointFilter.hideComplete ? 'active-toolbox' : null}>
				Скрыть готовые
				<Switch
					onChange={(value) => store.user.setRoutingPointFilter('hideComplete', value)}
					checked={store.user.settings.routing.pointFilter.hideComplete}
					size={'small'} style={{marginLeft: 6, transform: 'scale(.8)'}}/>
			</ToolItem>


			<ToolItem>
				<TagFilled height={4} width={4} fill={"#C9C5BE"}
						   style={{opacity: .5, marginRight: 3}}/> Метки
			</ToolItem>


			{
				store.user.settings.routing.showFilter.time && (
					<ToolItem
						className={(store.user.settings.routing.pointFilter.time.from || store.user.settings.routing.pointFilter.time.to !== 288) ? 'active-toolbox' : null}
					>
						<NumberFilter>
							<Popover
								mouseEnterDelay={.3}
								mouseLeaveDelay={.2}
								placement={"bottom"}
								overlayStyle={{width: 380}}
								color={"#1F1F1F"}
								content={
									<div style={{width: 380, padding: 10}}>
										<h4>Время</h4>
										<Slider
											min={0}
											max={288}
											marks={marks}
											onChange={(val) => {
												store.user.setRoutingPointFromTo('time', 'from', val[0])
												store.user.setRoutingPointFromTo('time', 'to', val[1])
											}}
											value={[store.user.settings.routing.pointFilter.time.from, store.user.settings.routing.pointFilter.time.to]}
											style={{width: 350}}
											range
											tipFormatter={(val) => timeParser(val)}
										/>
										<div style={{
											width: '100%',
											display: 'flex',
											flex: 1,
											flexWrap: 'wrap'
										}}>
											{
												store.user.settings.routing.savedFilters.filter(_item => _item.type === 'time').map((timeFilter, index) => (
													<TimeHotkeyMenuItem
														key={'tf' + index}
														onClick={() => {
															store.user.setRoutingPointFilter('time', {
																from: timeFilter.from,
																to: timeFilter.to
															})
														}}
													>
														<HotKeyTag
															onClick={() => deleteHotKey('time', timeFilter.from, timeFilter.to)}
														>
													<span
														className={'hotkey-char'}>{charsFilter[index].toUpperCase()}</span>
															<span className={'hotkey-delete'}>
														<Icon name={faTrash} size={10} color={'#e89a3c'}/>
													<	/span>
														</HotKeyTag>
														{fromToLetter(
															'time',
															timeFilter.from,
															timeFilter.to,
														)}
													</TimeHotkeyMenuItem>
												))
											}
										</div>
									</div>
								}
							>
								<FromTo>
									<FilterInfo>
										{fromToLetter(
											'time',
											store.user.settings.routing.pointFilter.time.from,
											store.user.settings.routing.pointFilter.time.to,
											'Любое время'
										)}
									</FilterInfo>
								</FromTo>
							</Popover>
							<FilterIcon
								onMouseEnter={() => setHoverFilterIcon((store.user.settings.routing.pointFilter.time.from || store.user.settings.routing.pointFilter.time.to !== 288) ? 'time' : null)}
								onMouseLeave={() => setHoverFilterIcon(null)}
							>
								{
									hoverFilterIcon === 'time' ? (
										<>
											<SaveOutlined
												className={'save-icon'}
												onClick={() => {
													store.user.setRoutingHotkey('time', store.user.settings.routing.pointFilter.time.from, store.user.settings.routing.pointFilter.time.to)
												}}
												style={{marginRight: 5}}
											/>

											<CloseCircleFilled
												onClick={() => {
													store.user.setRoutingPointFilter('time', {
														from: 0,
														to: 288
													})
													setHoverFilterIcon(null)
												}}
											/>
										</>
									) : (
										<Icon name={faClock}/>
									)
								}
							</FilterIcon>
						</NumberFilter>
					</ToolItem>
				)
			}


			{
				filters.slice(1).filter(_item => store.user.settings.routing.showFilter[_item.id]).map(_filter => {
					let filterData = {
						hotKeys: store.user.settings.routing.savedFilters.filter(_item => _item.type === _filter.id),
						from: store.user.settings.routing.pointFilter[_filter.id].from,
						to: store.user.settings.routing.pointFilter[_filter.id].to,
						editedAny: store.user.settings.routing.pointFilter[_filter.id].from || store.user.settings.routing.pointFilter[_filter.id].to,
						editedFrom: store.user.settings.routing.pointFilter[_filter.id].from,
						editedTo: store.user.settings.routing.pointFilter[_filter.id].to && store.user.settings.routing.pointFilter[_filter.id].to,
						canSave: true
					}

					return (
						<ToolItem
							key={'f' + _filter.id}
							className={(filterData.editedAny) ? 'active-toolbox' : null}
						>
							<NumberFilter>
								<Dropdown
									mouseEnterDelay={.3}
									disabled={!filterData.hotKeys.length}
									overlay={<HotKeys data={filterData.hotKeys} filterId={_filter.id}/>}
								>
									<FromTo>
										<InputNumber
											id={'no-padding-input'} style={{width: 47}} bordered={0}
											maxLength={6}
											onFocus={() => setFilterEditing(_filter.id)}
											onBlur={e => {
												store.user.setRoutingPointFromTo(_filter.id, 'from', e.target.value)
												setFilterEditing(false)
											}}
											value={filterData.from ? filterData.from : ''}
											controls={false} size={'small'}
										/> —
										<InputNumber
											id={'no-padding-input'} style={{width: 47}} bordered={0}
											maxLength={6}
											onFocus={() => setFilterEditing(_filter.id)}
											onBlur={e => {
												store.user.setRoutingPointFromTo(_filter.id, 'to', e.target.value)
												setFilterEditing(false)
											}}
											value={filterData.to ? filterData.to : ''}
											controls={false} size={'small'}
										/>
										{(!filterEditing || (filterEditing && filterEditing !== _filter.id)) && (
											<FilterInfo
												className={'filter-info'}
												style={{
													textAlign: (!filterData.editedAny) ? 'left' : 'center'
												}}
											>
												{fromToLetter(_filter.id, filterData.from, filterData.to, _filter.mapTitle)}
											</FilterInfo>)}

									</FromTo>
								</Dropdown>
								<FilterIcon
									onMouseEnter={() => setHoverFilterIcon(filterData.editedAny ? _filter.id : null)}
									onMouseLeave={() => setHoverFilterIcon(null)}
								>
									{
										hoverFilterIcon === _filter.id ? (
											<>
												<SaveOutlined
													className={'save-icon'}
													onClick={() => {
														store.user.setRoutingHotkey(_filter.id, filterData.from, filterData.to)
													}}
													style={{marginRight: 5}}
												/>

												<CloseCircleFilled
													onClick={() => {
														store.user.setRoutingPointFilter(_filter.id, {
															from: 0,
															to: 0
														})
														setHoverFilterIcon(null)
													}}
												/>
											</>
										) : (
											_filter.icon
										)
									}
								</FilterIcon>
							</NumberFilter>
						</ToolItem>
					)
				})
			}
		</Wrapper>
	)
}

export default observer(Toolbox)

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;

  .ant-popover-inner, .ant-popover-inner-content {
    padding: 0 0 0 0 !important;
  }

  .active-toolbox {
    border: solid 1px #FF9500;
    box-shadow: #FF9500 0px 0px 3px 1px;
  }

  #no-padding-input {
    padding: 0;
    margin: 0;
    text-align: center;
    width: 47px;
    border-bottom: solid 1px #464543;
    border-radius: 0;
    font-size: 13px;
    color: #C8C4BD;
    height: auto;
    line-height: normal;
  }
`
const ToolItem = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 10px;
  background-color: #1F1F1F;
  border-radius: 50px;
  margin-top: 10px;
  margin-left: 5px;
  border: solid 1px transparent;
  box-shadow: rgb(13 13 13 / 65%) 0px 0px 3px 1px;
  font-size: 13px;
  height: 28px;
  overflow: hidden;
`
const NumberFilter = styled.div`
  display: flex;
  flex: 1;
  min-width: 125px;
  align-items: center;

  :hover {
    .filter-info {
      visibility: hidden;
      opacity: 0;
      transition: visibility .15s, opacity .15s linear;
    }
  }
`
const FromTo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
`
const FilterInfo = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  background-color: #1F1F1F;
  inset: 0;
  justify-content: center;
  align-items: center;
`
const SaveIcon = styled.div`
  cursor: pointer;
  width: 26px;
  margin-left: 5px;
  padding-right: 8px;
`
const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  width: 33px;
  height: 14px;

  .save-icon:hover {
    color: #88db57;
  }
`
const HotKeyMenu = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #C8C4BD;
`
const HotKeyTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9d134;
  background: #1f2611;
  border: solid 1px #3e4f13;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  .hotkey-delete {
    display: none;
  }

  &:hover {
    background: #2b1d11;
    border-color: #593815;

    .hotkey-delete {
      display: block;
    }

    .hotkey-char {
      display: none;
    }
  }
`
const TimeHotkeyMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  width: 175px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 12px;
  color: #C8C4BD;

  &:hover {
    background-color: #313131;
    transition: .3s;
  }
`
