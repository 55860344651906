import {observer} from "mobx-react-lite"
import React, {useMemo} from "react"
import styled from "styled-components"
import {useStore} from "../../../../store/Context"
import Icon from "../../../../component/Icon";
import {faReply} from "@fortawesome/free-solid-svg-icons";
import Click from "../../../../assets/svg/click.svg";
import {faFileSpreadsheet} from "@fortawesome/pro-solid-svg-icons/faFileSpreadsheet";


const EmptyData = ({type, visible, dataLength}) => {

	const store = useStore()

	if (!visible) return null

	switch (type) {

		case 'points': {
			return (
				<Wrapper>
					<Icon name={faFileSpreadsheet} size={100}
						  color={"#3B3D3D"}/>
					<p style={{width: "40%", marginTop: 20}}>
						За выбранную дату точек не нашлось. Выберите другую дату.
					</p>
				</Wrapper>
			)

			break
		}
		case 'routes': {
			return (
				<Wrapper>
					<Icon name={faReply} size={100} style={{transform: 'scaleY(-1) rotate(-92deg)'}}
						  color={"#3B3D3D"}/>
					<p style={{width: '40%', marginTop: 20}}>
						Нажмите CTRL + Enter, что бы создать новый маршрутный лист.
					</p>
				</Wrapper>
			)
			break
		}
		case 'routePoints': {
			return (
				<Wrapper>
					{
						!store.routing.activeRoute ? (
							<>
								<Icon name={faReply} size={100} color={"#3B3D3D"}/>
								<p style={{width: '40%', marginTop: 20}}>
									Здесь отображаются точки из выбранного слева маршрутного листа.
								</p>
							</>
						) : !dataLength && (
							<>
								<Click width={100} height={100}/>
								<p style={{width: '40%', marginTop: 20}}>
									Удерживая клавишу CTRL кликните по точке, что бы добавить её в
									маршрутный лист или удалить из него.
								</p>
							</>
						)
					}
				</Wrapper>
			)
			break
		}
	}
}

export default observer(EmptyData)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  left: 0;
  right: 0;
  bottom: 40px;
  top: 0;
  position: absolute;
  flex-direction: column;
  border: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
`
