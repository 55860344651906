import {applySnapshot, types} from "mobx-state-tree"
import {InvoiceItem} from "./InvoiceItem"

const Address = types.model({
	lng: types.number,
	lat: types.number,
})

export const PointItem = types.model({
	id: types.identifierNumber,
	external_id: types.maybeNull(types.string),
	type: types.maybeNull(types.enumeration(["point", "warehouse"])),
	name: types.maybeNull(types.string),
	address: types.maybeNull(types.string),
	address_location: types.maybeNull(Address),
	status: types.enumeration(["created", "on_way", "arrived", "done", "canceled:driver", "canceled:manager", "canceled:api"]),
	reason: types.maybeNull(types.string),
	client_name: types.maybeNull(types.string),
	client_contact: types.maybeNull(types.string),
	client_data: types.maybeNull(types.string),
	position: types.maybeNull(types.integer),
	from_at: types.maybeNull(types.string),
	to_at: types.maybeNull(types.string),
	updated_at: types.maybeNull(types.string),
	media: types.maybeNull(types.array(types.string)),
	invoices: types.optional(types.array(InvoiceItem), []),
}).actions(self => {
	return {
		updateMedia (media) {
			self.media.replace(media)
		},
		update (data) {
			applySnapshot(self, data)
		},
		updateInvoices (data) {
			self.invoices = data
		},
		updateLocation (data) {
			self.address_location = data
		}
	}
}).views(self => ({
	get countCargo () {
		let result = {
			total: 0,
			count: 0,
			countFact: 0,
			price: 0,
			priceFact: 0,
			changed: false
		}

		self.invoices.forEach(invoice => {
			result.total += invoice.cargos.length
			invoice.cargos.forEach(cargo => {
				if (cargo.count !== cargo.count_fact) result.changed = true
				result.price += cargo.price
				result.priceFact += cargo.price_fact
				result.count += cargo.count
				result.countFact += cargo.count_fact
			})
		})

		return result
	}
}))

