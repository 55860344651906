import { observer } from "mobx-react-lite"
import React from "react"
import Highlighter from "react-highlight-words"

const HighLight = ({ text, highlight }) => {

	if (!text) return null
	if (!highlight) return text
	if (text) return text

	return (
		<Highlighter
			searchWords={highlight}
			autoEscape={true}
			highlightStyle={{
				backgroundColor: "rgb(255, 192, 105)",
				padding: 0,
			}}
			textToHighlight={text + ""}
		/>
	)
}

export default observer(HighLight)