import {observer} from "mobx-react-lite"
import React, {useEffect, useMemo, useState} from "react"
import mapboxgl from "mapbox-gl"
import {useStore} from "../../../../store/Context"
import {Mapbox} from "../../../../component/Mapbox"
import RoutingToolbox from "./Toolbox"
import CircleLayer from "../../../../component/Mapbox/Components/CircleLayer"
import {prettyDigit, prettyTimePeriod} from "../../../../store/helper/StringMethod"
import {Popup} from "react-map-gl"
import styled from "styled-components"
import PolylineRouting from "../../../../component/Mapbox/Components/PolylineRouting"
import Icon from "../../../../component/Icon"
import {faBorderStyleAlt} from "@fortawesome/pro-light-svg-icons/faBorderStyleAlt"
import {Divider} from "antd"
import Settings from "./Settings"
import {faCog} from "@fortawesome/pro-solid-svg-icons/faCog"
import {
	faClock,
	faCube,
	faDrawSquare,
	faLayerGroup,
	faRubleSign,
	faWeightHanging
} from "@fortawesome/pro-light-svg-icons"

const Map = ({selectPoint, mapRef}) => {

	const store = useStore()

	const filters = [
		{id: 'time', title: 'Время', mapTitle: 'Любое время', icon: <Icon name={faClock}/>},
		{
			id: 'count',
			title: 'Кол-во',
			ending: 'количества',
			mapTitle: 'Любое кол-во',
			icon: <Icon name={faLayerGroup}/>
		},
		{
			id: 'weight',
			title: 'Вес',
			ending: 'веса, кг',
			mapTitle: 'Любой вес',
			icon: <Icon name={faWeightHanging}/>
		},
		{
			id: 'cubic',
			title: 'Объем',
			ending: 'объема, м³',
			mapTitle: 'Любой объем',
			icon: <Icon name={faCube}/>
		},
		{
			id: 'square',
			title: 'Площадь',
			ending: 'площади, м²',
			mapTitle: 'Любая площ...',
			icon: <Icon name={faDrawSquare}/>
		},
		{
			id: 'price',
			title: 'Сумма',
			ending: 'суммы, ₽',
			mapTitle: 'Любая сумма',
			icon: <Icon name={faRubleSign}/>
		}
	]

	const [pointHover, setPointHover] = useState(null)
	const [showPopUp, setShowPopUp] = useState(0)
	const [mapBounds, setMapBounds] = useState(null)

	const [startSelect, setStartSelect] = useState(null)
	const [selectedArea, setSelectedArea] = useState(null)

	const [settingVisible, setSettingVisible] = useState(false)

	const [excludeTooltipPoints, setExcludeTooltipPoints] = useState([])

	const data = useMemo(() => {
		return [...store.routing.points].filter(_point => _point.address_location?.lat)
	}, [store.routing.refreshedAt])

	const outOfMap = useMemo(() => {
		const total = store.routing.points.length
		const inMap = store.routing.points.filter(_point =>
			_point.address_location?.lat &&
			_point.address_location?.lat >= mapBounds._sw.lat &&
			_point.address_location?.lat <= mapBounds._ne.lat &&
			_point.address_location?.lng >= mapBounds._sw.lng &&
			_point.address_location?.lng <= mapBounds._ne.lng).length
		return total - inMap
	}, [mapBounds])

	useEffect(() => {
		if (!store.routing.activeRoute) return
		let bounds = []
		store.routing.getRoutePoints(store.routing.activeRoute).map(data => {
			if (data.address_location?.lat && data.address_location?.lng) {
				bounds.push([data.address_location.lng, data.address_location.lat])
			}
		})
		if (bounds.length) {
			const box = new mapboxgl.LngLatBounds(bounds[0], bounds[0])
			bounds.map(bound => {
				box.extend(bound)
			})

			setTimeout(() => {
				flyToArea(box)
			}, 200)
		}
	}, [store.routing.activeRoute])

	const flyToArea = box => {
		if (!box || !mapRef.current) return
		mapRef.current.fitBounds(box, {
			padding: {top: 100, bottom: 100, left: 100, right: 100},
			maxZoom: 11.5,
			speed: 2,
		})
	}

	const onHover = event => {

		const {features, type} = event
		if (type === 'mouseleave') {
			setPointHover(null)
			//store.routing.setRouteHover(null)
			return
		}
		const pointData = features && features[0]
		if (!excludeTooltipPoints.includes(pointData.properties.pointId)) {
			setPointHover(pointData && pointData.properties)
		}
		/*if (pointData.properties.routeId) {
			store.routing.setRouteHover(parseInt(pointData.properties.routeId))
		}*/
	}

	const onClick = event => {
		const {features} = event
		const pointData = features && features[0]
		if (pointData.properties.pointId && !excludeTooltipPoints.includes(pointData.properties.pointId)) {
			const pointItem = store.routing.points.find(_point => _point.id === parseInt(pointData.properties.pointId))
			if (pointData) selectPoint(event.originalEvent, pointItem)
		}
	}

	const mapSelect = (e, mapEventData) => {
		setSelectedArea({
			start: {x: startSelect.x, y: startSelect.y},
			x: e.clientX - startSelect.x,
			y: e.clientY - startSelect.y
		})
	}

	const hidePointsTooltip = (e) => {
		setTimeout(() => {
			setExcludeTooltipPoints(e)
		}, 100)
	}

	return (
		<div
			onContextMenu={(e) => {
				e.preventDefault()
			}}
			style={{width: "100%", height: "100%"}}
			onMouseDownCapture={(e) => {
				if (e.button === 2) {
					setStartSelect({x: e.clientX, y: e.clientY})
				}
			}}
			onMouseUpCapture={() => {
				setStartSelect(null)
				setSelectedArea(null)
			}}
			onMouseLeave={() => {
				setStartSelect(null)
				setSelectedArea(null)
			}}
		>
			<Mapbox
				ref={mapRef}
				initialViewState={{
					longitude: store.user.office.region_map_settings?.lng ?? 37.5877594,
					latitude: store.user.office.region_map_settings?.lat ?? 55.7421473,
					zoom: store.user.office.region_map_settings?.zoom ?? 9.2
				}}
				maxZoom={18}
				onZoom={(e) => {
					if (e.viewState.zoom >= store.user.settings.routing.pointInfoScale) {
						if (!showPopUp) setShowPopUp(true)
					} else {
						if (showPopUp) setShowPopUp(false)
					}
				}}
				dragRotate={false}
				/*onMouseDown={(e) => {
					console.log('starPolygonSearch')
				}}*/
				style={{width: "100%", height: "100%"}}
				onLoad={() => mapRef.current.resize()}
				interactiveLayerIds={(data.length && !startSelect) ? ['point'] : null}
				onMouseEnter={onHover}
				onMouseLeave={onHover}
				onMouseMove={(e) => startSelect && mapSelect(e.originalEvent, e)}
				onClick={(data.length && !startSelect) ? onClick : null}
				cursor={pointHover ? 'pointer' : null}
				onMoveEnd={() => setMapBounds(mapRef.current.getMap().getBounds())}
			>
				<RoutingToolbox filters={filters} setSettingVisible={setSettingVisible}
								mapRef={mapRef}/>
				{
					(store.routing.activePolyline) && (
						<PolylineRouting
							color={"rgba(255,210,96, .75)"}
							width={5}
							opacity={1}
							path={store.routing.activePolyline}
						/>
					)
				}
				{
					data.length && (
						<CircleLayer onFiltered={(e) => hidePointsTooltip(e)} points={data.map(_point => {
							return {
								id: _point.id,
								route_id: _point.route_id,
								latitude: _point.address_location.lat,
								longitude: _point.address_location.lng,
								position: _point.position,
								time: prettyTimePeriod(_point.from_at, _point.to_at),
								to_at: _point.to_at,
								lat: _point.address_location.lat,
								lng: _point.address_location.lng,
								name: _point.name,
								address: _point.address,
								statistic: _point.statistic,
							}
						})}/>
					)
				}

				{
					(pointHover && showPopUp) && (
						<Popup
							longitude={pointHover.lng}
							latitude={pointHover.lat}
							offset={[0, -10]}
							closeButton={false}
							maxWidth={200}
							className="point-info"
						>
							<PopHeader>
								<PointName>
									{pointHover.name}
								</PointName>
								<PointTime>
									{pointHover.time}
								</PointTime>
							</PopHeader>
							<PopAddress>
								{pointHover.address}
							</PopAddress>
							<Divider style={{marginBlock: 5}}/>
							<Statistic>
								<div>
									Кол-во<br/>{prettyDigit(pointHover.count)}
								</div>
								<div>
									Вес<br/>{prettyDigit(pointHover.weight, 'weight')}
								</div>
								<div>
									Объем<br/>{prettyDigit(pointHover.cubic, 'cubic')}
								</div>
								<div>
									Площ.<br/>{prettyDigit(pointHover.square, 'square')}
								</div>
								<div>
									Сумма<br/>{prettyDigit(pointHover.price, 'price')}
								</div>
							</Statistic>
						</Popup>
					)
				}
				<OutOfMap>
					<Icon name={faBorderStyleAlt} color={"#FF9500"} size={40}/>
					<div>{outOfMap}</div>
				</OutOfMap>
				<SettingsIcon onClick={() => setSettingVisible(true)}>
					<Icon name={faCog} color={"#C8C4BD"} size={22}/>
				</SettingsIcon>
			</Mapbox>
			{selectedArea && (
				<SelectedArea
					onMouseMove={(e) => mapSelect(e)}
					style={{
						left: selectedArea.start.x,
						top: selectedArea.start.y,
						width: selectedArea.x,
						height: selectedArea.y,
					}}/>)}
			{!selectedArea && (
				<Settings mapRef={mapRef} filters={filters} settingVisible={settingVisible}
						  setSettingVisible={setSettingVisible}/>
			)}
		</div>
	)
}

export default observer(Map)

const PopHeader = styled.div`
  display: flex;
  flex: 1;
  min-width: 270px;
  font-size: 13px;
`
const PointName = styled.div`
  flex: 1;
  font-size: 14px;
  padding-right: 20px;
  font-weight: 500;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
`
const PointTime = styled.div`
  color: #FF8C00;
`
const PopAddress = styled.div`
  max-width: 250px;
`
const Statistic = styled.div`
  display: flex;
  flex: 1;

  div {
    flex: .2;
    font-size: 10px;
  }
`
const OutOfMap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #FF9500;
  font-weight: 500;

  div {
    position: absolute;
    text-shadow: 0px 0px 2px #000000;
  }
`
const SettingsIcon = styled.div`
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: #1F1F1F;
  border-radius: 50px;
  border: solid 1px transparent;
  box-shadow: rgb(13 13 13 / 65%) 0px 0px 3px 1px;
`
const SelectedArea = styled.div`
  position: absolute;
  background-color: rgba(24, 144, 255, .3);
  border: solid 1px #1890FF;
`
