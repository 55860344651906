import { __extends } from "tslib";
import Path from './path';
/** 引入 Path 对应的 ShapeFactory */
import './shape/line';
/**
 * Line 几何标记。
 * 常用于折线图的绘制。
 */
var Line = /** @class */ (function (_super) {
    __extends(Line, _super);
    function Line(cfg) {
        var _this = _super.call(this, cfg) || this;
        _this.type = 'line';
        var _a = cfg.sortable, sortable = _a === void 0 ? false : _a; // 关闭默认的 X 轴数据排序
        _this.sortable = sortable;
        return _this;
    }
    return Line;
}(Path));
export default Line;
