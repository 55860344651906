export var SHAPE_TO_TAGS = {
    rect: 'path',
    circle: 'circle',
    line: 'line',
    path: 'path',
    marker: 'path',
    text: 'text',
    polyline: 'polyline',
    polygon: 'polygon',
    image: 'image',
    ellipse: 'ellipse',
    dom: 'foreignObject',
};
export var SVG_ATTR_MAP = {
    opacity: 'opacity',
    fillStyle: 'fill',
    fill: 'fill',
    fillOpacity: 'fill-opacity',
    strokeStyle: 'stroke',
    strokeOpacity: 'stroke-opacity',
    stroke: 'stroke',
    x: 'x',
    y: 'y',
    r: 'r',
    rx: 'rx',
    ry: 'ry',
    width: 'width',
    height: 'height',
    x1: 'x1',
    x2: 'x2',
    y1: 'y1',
    y2: 'y2',
    lineCap: 'stroke-linecap',
    lineJoin: 'stroke-linejoin',
    lineWidth: 'stroke-width',
    lineDash: 'stroke-dasharray',
    lineDashOffset: 'stroke-dashoffset',
    miterLimit: 'stroke-miterlimit',
    font: 'font',
    fontSize: 'font-size',
    fontStyle: 'font-style',
    fontVariant: 'font-variant',
    fontWeight: 'font-weight',
    fontFamily: 'font-family',
    startArrow: 'marker-start',
    endArrow: 'marker-end',
    path: 'd',
    class: 'class',
    id: 'id',
    style: 'style',
    preserveAspectRatio: 'preserveAspectRatio',
};
export var EVENTS = [
    'click',
    'mousedown',
    'mouseup',
    'dblclick',
    'contextmenu',
    'mouseenter',
    'mouseleave',
    'mouseover',
    'mouseout',
    'mousemove',
    'wheel',
];
