import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
	Card,
	Tabs,
} from "antd"
import styled from "styled-components"
import PageHeader from "../../component/PageHeader"
import SettingMainTab from "./SettingMainTab"
import SettingManagerTab from "./SettingManagerTab"
import { useStore } from "../../store/Context"

const SettingScreen = () => {

	const store = useStore()


	const [tab, setTab] = useState("tab-main")


	if (store.user.office.level !== "director") return null

	return (
		<Wrapper>
			<PageHeader
				title="Управление"
			/>
			<Card bordered={false} style={{ padding: 0, paddingLeft: 25 }}>
				<Tabs defaultActiveKey="1" onChange={(index) => setTab(index)}>
					<Tabs.TabPane tab="Общие настройки" key="tab-main"/>
					<Tabs.TabPane tab="Логисты" key="tab-manager" disabled/>
					<Tabs.TabPane tab="Система мотивации" key="3" disabled/>
					<Tabs.TabPane tab="Поощрения и штрафы" key="4" disabled/>
					<Tabs.TabPane tab="Мобильное приложение" key="5" disabled/>
				</Tabs>
			</Card>
			<ContentWrapper>
				<Card bordered={false} style={{ padding: 25 }}>
					{
						tab === "tab-main" ? (
							<SettingMainTab/>
						) : tab === "tab-manager" && (
							<SettingManagerTab/>
						)
					}
				</Card>
			</ContentWrapper>
		</Wrapper>
	)
}

export default observer(SettingScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
