import { __extends } from "tslib";
import Geometry from './base';
import './shape/edge';
/**
 * Edge 几何标记，用于绘制关系图中的**边**图形，如：
 * 1. 流程图
 * 2. 树
 * 3. 弧长连接图
 * 4. 和弦图
 * 5. 桑基图
 */
var Edge = /** @class */ (function (_super) {
    __extends(Edge, _super);
    function Edge() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = 'edge';
        _this.shapeType = 'edge';
        _this.generatePoints = true;
        return _this;
    }
    return Edge;
}(Geometry));
export default Edge;
