import {observer} from 'mobx-react-lite'
import styled from "styled-components"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {Driver, Route} from "../../../network"
import MiniLoader from "../../../component/MiniLoader"
import LogList from "./LogList"
import {Mapbox} from "../../../component/Mapbox"
import {useStore} from "../../../store/Context"
import polyline from "@mapbox/polyline"
import MapTrackPoint from "./MapTrackPoint"
import {Popup} from "react-map-gl"
import {Card, Col, Row, Slider} from "antd"
import dayjs from "dayjs"
import {getCenterOfBounds, getPathLength} from "geolib"
import duration from "dayjs/plugin/duration"
import BoxMarker from "../../../component/Mapbox/Components/BoxMarker"
import {parseLog} from "../../../store/helper/LogInfo"
import PolylineDashboard from "../../../component/Mapbox/Components/PolylineDashboard"

dayjs.extend(duration)

const RouteLogsTab = ({route}) => {
	const store = useStore()
	const mapRef = useRef()

	const [logs, setLogs] = useState([])
	const [loading, setLoading] = useState(false)
	const [loadingTrack, setLoadingTrack] = useState(false)
	const [track, setTrack] = useState([])
	const [trackLine, setTrackLine] = useState([])
	const [pointHover, setPointHover] = useState(null)
	const [duration, setDuration] = useState(null)
	const [activePoint, setActivePoint] = useState(null)

	const statData = useMemo(() => {
		let data = {
			distance: 0,
			duration: 0,
			slider: {
				marks: [],
				disabled: true,
				max: 100
			}
		}
		if (track.length) {
			data.distance = getPathLength(track)

			data.slider.disabled = false
			data.slider.max = track.length - 1
			data.slider.marks = {
				0: dayjs(track[0].coord_at).format('HH:mm'),
				[track.length - 1]: dayjs(track[track.length - 1].coord_at).format('HH:mm')
			}
		}

		return data
	}, [track])

	const initMapArea = useMemo(() => {
		if (route.points.length) {
			let coords = route.points.map(data => {
				if (data.address_location?.lat && data.address_location?.lng) {
					return {latitude: data.address_location.lat, longitude: data.address_location.lng}
				}
			})
			if (coords?.length) {
				return getCenterOfBounds(coords)
			}
		}

		return {
			longitude: store.user.office.region_map_settings?.lng ?? 37.5877594,
			latitude: store.user.office.region_map_settings?.lat ?? 55.7421473
		}
	}, [])

	useEffect(() => {
		getLogs()
		getTrack()
	}, [])

	const getLogs = async () => {
		setLoading(true)
		let result = await Route.getLogs({routeId: route.id})
		if (result.code === 200 && result.data.length) {
			const sortedLogs = [...result.data.sort((a, b) => {
				return new Date(a.date) - new Date(b.date)
			})]
			let logsArr = []
			let currDate = null
			let duration = {
				start: null,
				finish: null
			}

			sortedLogs.map(_log => {
				if (_log.date && dayjs(_log.date).format('YYYYMMDD') !== currDate) {
					currDate = dayjs(_log.date).format('YYYYMMDD')
					logsArr.push({
						date: dayjs(_log.date).format('YYYY-MM-DD'),
						items: []
					})
				}
			})

			sortedLogs.map(_data => {
				if (_data.date && _data.item_type && _data.item_id && _data.item_name) {
					let rootIndex = logsArr.findIndex(_log => _log.date === dayjs(_data.date).format('YYYY-MM-DD'))
					if (rootIndex !== -1) {
						let parentIndex = logsArr[rootIndex].items.findIndex(_parent => (_parent.item_type === _data.item_type && _parent.item_id === _data.item_id))
						if (parentIndex === -1) {
							logsArr[rootIndex].items.push({
								item_type: _data.item_type,
								item_id: _data.item_id,
								item_name: _data.item_name,
								logs: []
							})
						}
					}
				}
			})

			sortedLogs.map(_data => {
				if (_data.date && _data.item_type && _data.item_id && _data.item_name) {
					const root = logsArr.find(_log => _log.date === dayjs(_data.date).format('YYYY-MM-DD'))
					if (root) {
						const item = root.items.find(_item => _item.item_type === _data.item_type && _item.item_id === _data.item_id)
						if (item) {
							item.logs.push(parseLog(_data))
						}
					}
				}
				if (!duration.start && _data.item_type === 'point' && _data.method === 'status' && _data.data.status === 'on_way') {
					duration.start = _data.date
				}
				if (duration.start && _data.item_type === 'route' && _data.method === 'status' && _data.data.status === 'done') {
					setDuration(dayjs(_data.date).diff(duration.start, "minutes"))
				}
			})

			setLogs(logsArr)
		}
		setLoading(false)
	}

	const getTrack = async () => {
		setLoadingTrack(true)
		let result = await Driver.geometryRoute({driverId: route.driver.id, routeId: route.id})
		if (result.code === 200 && result?.data?.length) {
			result.data = result.data.filter((value, index, self) =>
					index === self.findIndex((item) => (
						item.latitude === value.latitude && item.longitude === value.longitude
					))
			)

			setTrack(result.data)
			/*simplify()*/

			setTrackLine(polyline.encode(result.data.map(data => {
				return [data.latitude, data.longitude]
			})))
		}

		setLoadingTrack(false)
	}

	const onHover = useCallback(event => {
		const {features, point: {x, y}, type} = event
		if (type === 'mouseleave') {
			setPointHover(null)
			return
		}
		const pointData = features && features[0];
		setPointHover(pointData && pointData.properties)
	}, [])

	const formatter = (value) => {
		if (track[value]?.coord_at) return dayjs(track[value].coord_at).format('HH:mm:ss')
	}

	const searchCoord = (data) => {
		const time = dayjs(data).unix()
		const closest = track.reduce((a, b) => {
			return (Math.abs(dayjs(a.coord_at).unix() - time) < Math.abs(dayjs(b.coord_at).unix() - time)) ? a : b
		})

		if (closest) {
			flyToPoint(closest)
			setPointHover(closest)
		}
	}

	const flyToPoint = point => {
		if (point?.latitude && point?.longitude) {
			mapRef.current.flyTo({
				center: [
					point.longitude,
					point.latitude,
				],
				speed: 2,
				zoom: 12
			})
		}
	}


	return (
		<Wrapper>
			<Mapbox
				ref={mapRef}
				initialViewState={{
					longitude: initMapArea.longitude,
					latitude: initMapArea.latitude,
					zoom: store.user.office.region_map_settings?.zoom ?? 9.2
				}}
				style={{width: 'calc(100% - 350px)', height: 'calc(100% - 110px)'}}
				className="log-map"
				interactiveLayerIds={track.length ? ['point'] : []}
				onMouseEnter={onHover}
				onMouseLeave={onHover}
			>
				{
					trackLine && (
						<PolylineDashboard
							color={"rgba(136,219,87, .75)"}
							width={5}
							path={trackLine}
							opacity={1}
						/>
					)
				}

				{
					route?.points.map(item => (
						<BoxMarker
							key={"point-" + item.id}
							item={item}
							active={activePoint?.id}
							onClick={() => {
								setActivePoint(activePoint => (!activePoint || (activePoint.id !== item.id)) ? item : null)
							}}
						/>
					))
				}

				{track?.length ? (<MapTrackPoint points={track}/>) : null}
				{
					pointHover?.longitude && (
						<Popup
							longitude={pointHover.longitude}
							latitude={pointHover.latitude}
							offset={[0, -10]}
							closeButton={false}
							closeOnClick={false}
							className="trackPointInfo"
						>
							<b>{dayjs(pointHover.coord_at).format('HH:mm')}</b>
							<div>{pointHover.speed > 0 ? Math.floor(Math.round(pointHover.speed) * 3.6) : 0} км/ч</div>
						</Popup>
					)
				}
			</Mapbox>
			<LogStat>
				<Row gutter={24}>
					<Col>
						<StatCard bordered={false}>
							<StatTitle>
								Дистанция, км
							</StatTitle>
							<StatValue>
								{statData.distance ? (statData.distance / 1000).toFixed(1) : '00.0'}
							</StatValue>
						</StatCard>
					</Col>
					<Col>
						<StatCard bordered={false}>
							<StatTitle>
								Время
							</StatTitle>
							<StatValue>
								{duration ? dayjs.duration(duration, 'minutes').format('HH:mm') : '00:00'}
							</StatValue>
						</StatCard>
					</Col>
					<Col>
						<StatCard bordered={false}>
							<StatTitle>
								Трек
							</StatTitle>
							<Slider marks={statData.slider.marks} included={false}
									tipFormatter={formatter}
									disabled={statData.slider.disabled}
									tooltipVisible={statData.slider.disabled ? false : undefined}
									max={statData.slider.max}
									onChange={(e) => setPointHover(track[e])}
									style={{width: 230}}
							/>
						</StatCard>
					</Col>
				</Row>
			</LogStat>
			<Logs>
				{loading ? (
					<MiniLoader style={{marginTop: '20%'}}/>
				) : (
					<LogList logs={logs} onClick={searchCoord}/>
				)}
			</Logs>
		</Wrapper>
	)
}

export default observer(RouteLogsTab)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  .trackPointInfo {
    color: #000000;
    z-index: 10;
  }

  .log-map {
    width: 0 !important;
  }
`
const Logs = styled.div`
  box-shadow: rgba(13, 13, 13, 0.65) 0px 2px 8px 0px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 350px;
  background-color: rgba(31, 31, 31, 100%);
  padding: 15px;
  overflow: auto;
`
const LogStat = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding-right: 300px;
  height: 110px;
  background-color: rgb(31, 31, 31, 100%);
  color: rgba(229, 224, 216, 0.45);
`

const StatCard = styled(Card)`
  padding: 20px;
  color: rgba(229, 224, 216, 0.85);
  background-color: transparent;

  .ant-slider-mark-text {
    font-size: 12px;
    color: rgba(229, 224, 216, 0.80);
  }
`
const StatTitle = styled.div`
  color: rgba(229, 224, 216, 0.45);
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
`
const StatValue = styled.div`
  font-size: 30px;
  font-weight: 300;
`
