import {types} from "mobx-state-tree"

const Type = types.model({
	title: types.maybeNull(types.string),
	small: types.maybeNull(types.string),
	step: types.maybeNull(types.number),
	format: types.model({
		decimal: types.maybeNull(types.integer),
		decimal_separator: types.maybeNull(types.string),
		thousands_separator: types.maybeNull(types.string),
	}),
})

export const CargoItem = types.model({
	id: types.identifierNumber,
	name: types.maybeNull(types.string),
	type: types.maybeNull(Type),
	count: types.maybeNull(types.number),
	price: types.maybeNull(types.number),
	count_fact: types.maybeNull(types.number),
	price_fact: types.maybeNull(types.number),
	updated_at: types.maybeNull(types.string),
}).actions(self => ({})).views(self => ({}))
