import {observer} from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import {Result, Button} from 'antd';
import {useNavigate} from "react-router-dom";


const ErrorScreen = ({errorType = '404'}) => {

	const navigate = useNavigate()

	switch (errorType) {
		case '404':
			return (
				<Wrapper>
					<Result
						status="404"
						title="Error 404"
						subTitle="Сожалеем, но данная страница не найдена."
						extra={<Button onClick={() => navigate('/')} type="primary">Вернуться на главную</Button>}
					/>
				</Wrapper>
			)
			break
	}
}

export default observer(ErrorScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
