import AsyncStorage from "@react-native-async-storage/async-storage"
import { makeAutoObservable, runInAction } from "mobx"
import { User } from "../network"
import _, { debounce } from "lodash"
import { mergeDeep } from "./helper/StringMethod"
import { message } from "antd"

class UserStore {

	isAuth = false
	login = null
	level = null
	name = null
	middlename = null
	surname = null
	sex = null
	birthday_at = null
	company = null
	office = null
	offices = null
	avatar = null
	settings = {
		terminal: {
			layout: {
				topHeight: 0,
				bottomHeight: 0,
				bottomLeftWidth: 0,
				bottomRightWidth: 0,
			},
		},
		routing: {
			layout: {
				topHeight: 0,
				bottomHeight: 0,
				topLeftWidth: 0,
				topRightWidth: 0,
				bottomLeftWidth: 0,
				bottomRightWidth: 0,
			},
			showFilter: {
				time: true,
				count: true,
				weight: true,
				cubic: true,
				square: true,
				price: true,
			},
			showTimeRadius: 5,
			pointInfoScale: 10,
			pointFilter: {
				hideComplete: false,
				time: { from: 0, to: 288 },
				count: { from: 0, to: 0 },
				weight: { from: 0, to: 0 },
				cubic: { from: 0, to: 0 },
				square: { from: 0, to: 0 },
				price: { from: 0, to: 0 },
			},
			savedFilters: [],
		},
	}

	updateSettingsEvent = false

	channels = []

	dashboardSetting = {
		layout: {
			topHeight: 0,
			bottomHeight: 0,
			bottomLeftWidth: 0,
			bottomRightWidth: 0,
		},
	}

	constructor (store) {
		makeAutoObservable(this, { store: false, channels: false })
		this.store = store
	}

	actionLogin = async (data = null) => {
		this.store.app.setShowLoading(true)
		let auth
		if (data === null) {
			auth = await User.info()
		} else {
			auth = await User.login(data)
		}
		if (auth.success) {
			let active_office = parseInt(sessionStorage.getItem("office"))
			if (!active_office) {
				active_office = parseInt(await AsyncStorage.getItem("office"))
			}
			let office = false

			if (active_office) {
				office = auth.data.offices.find(item => active_office === item.id)
			}

			if (!office) {
				office = auth.data.offices.find(item => item.default)
			}

			await AsyncStorage.setItem("office", office.id)
			sessionStorage.setItem("office", office.id)

			runInAction(async () => {
				this.fill(auth.data)
				this.office = { ...office }
				let token = auth.data.token
				if (!token) {
					token = await AsyncStorage.getItem("token")
				} else {
					await AsyncStorage.setItem("token", token)
				}
				if (auth.data.channels) {
					this.channels = auth.data.channels
					const office_filter = new RegExp("office\.([^\.]+\.)*" + this.office.id)
					this.store.socket.connect(auth.data.channels.filter(item => item.indexOf("office.") === -1 || office_filter.test(item)), token)
				}
				await this.store.route.actual.fetch()
				if (this.level === "director") {
					await this.store.office.fetch()
				}

				this.store.app.setShowLoading(false)
			})
		} else {
			this.store.app.setShowLoading(false)
		}
		return auth
	}

	changeOffice = async office => {
		const newOffice = this.offices.find(item => item.id === office)
		if (newOffice) {
			await AsyncStorage.setItem("office", newOffice.id)
			sessionStorage.setItem("office", newOffice.id)
			const token = await AsyncStorage.getItem("token")
			runInAction(async () => {
				this.office = { ...newOffice }
				this.store.app.setShowLoading(true)
				await this.store.route.actual.clean()
				await this.store.route.archive.clean()
				await this.store.socket.disconnect()
				if (this.channels) {
					const office_filter = new RegExp("office\.([^\.]+\.)*" + this.office.id)
					this.store.socket.connect(this.channels.filter(item => item.indexOf("office.") === -1 || office_filter.test(item)), token)
				}
				await this.store.route.actual.fetch()
				if (this.level === "director") {
					await this.store.office.fetch()
				}
				this.store.app.setShowLoading(false)
			})
		}
	}

	actionLogout = async () => {
		this.store.app.setShowLoading(true)
		await this.store.route.actual.clean()
		await this.store.route.archive.clean()
		await this.store.socket.disconnect()
		await this.fill(null)
		await AsyncStorage.removeItem("token")
		this.store.app.setShowLoading(false)
	}

	fill = data => {
		["login", "level", "name", "middlename", "surname", "sex", "birthday_at", "company", "offices", "avatar"].map(key => {
			this[key] = data === null ? null : data[key]
		})

		if (data) {
			this.settings = mergeDeep({ ...this.settings }, data.settings)
		}

		this.isAuth = data !== null
	}

	saveSettings = debounce(async () => {
		await User.updateSettings({ settings: this.settings })
	}, 2500)

	setSettings (key, value) {
		this.settings[key] = value
		this.saveSettings()
	}

	changeRoutingUpdateFlag () {
		this.updateSettingsEvent = !this.updateSettingsEvent
	}

	changeRoutingUpdateEvent = debounce(async () => {
		this.changeRoutingUpdateFlag()
	}, 1)

	updateOfficeInfo = data => {
		this.office = data
	}

	setDashboardLayoutSettings = (type, data) => {
		this.dashboardSetting.layout[type] = data
	}

	/*changeRoutingUpdateEvent = () => {
		this.updateSettingsEvent = !this.updateSettingsEvent
	}*/

	setLayoutSettings = (section, type, data) => {
		this.settings[section].layout[type] = data
		this.saveSettings()
	}

	setRoutingSettings = (type, data) => {
		this.settings.routing[type] = data
		this.saveSettings()
		this.changeRoutingUpdateEvent()
	}

	setRoutingShowFilter = (type, data) => {
		this.settings.routing.showFilter[type] = data
		if (!data) this.settings.routing.pointFilter[type] = { from: 0, to: type === "time" ? 288 : 0 }
		this.saveSettings()
		this.changeRoutingUpdateEvent()
	}
	setRoutingPointFilter = (type, data) => {
		this.settings.routing.pointFilter[type] = data
		this.saveSettings()
		this.changeRoutingUpdateEvent()
	}
	setRoutingPointFromTo = (type, fromTo, data) => {
		data = parseFloat(data)
		if (data > 0) {
			this.settings.routing.pointFilter[type][fromTo] = data
		} else {
			this.settings.routing.pointFilter[type][fromTo] = 0
		}
		this.saveSettings()
		this.changeRoutingUpdateEvent()
	}
	setRoutingHotkey = (type, from, to) => {
		const check = this.settings.routing.savedFilters.findIndex(_item => _item.type === type && _item.from === from && _item.to === to)
		if (check === -1) {
			const checkSum = this.settings.routing.savedFilters.filter((_item, index) => _item.type === type)
			if (checkSum.length === 10) {
				message.warning("Удалите любой фильтр, чтобы добавить новый", 2)
				return
			}
			this.settings.routing.savedFilters.push({
				type, from, to,
			})

			message.success("Фильтр успешно сохранен", 2)
			this.saveSettings()
			this.changeRoutingUpdateEvent()
		} else {
			message.warning("Такой фильтр уже существует", 2)
		}
	}

	deleteHotKey = (type, from, to) => {
		const checkIndex = this.settings.routing.savedFilters.findIndex(_item => _item.type === type && _item.from === from && _item.to === to)
		if (checkIndex !== -1) {
			this.settings.routing.savedFilters.splice(checkIndex, 1)
		}
	}

}

export default UserStore
