import {observer} from "mobx-react-lite"
import React from "react"
import {useStore} from "../../../../store/Context"
import '../../../../css/calendar.css'
import {Drawer, Form, Checkbox, Col, Row, Select} from "antd"

const Settings = ({filters, mapRef, settingVisible, setSettingVisible}) => {

	const store = useStore()

	const TimeRadiusMenu = () => {
		const _menu = [.1, .5, 1, 2, 3, 5, 8, 10, 12, 15]
		return (<Select
			defaultValue={store.user.settings.routing.showTimeRadius}
			onChange={(e) => {
				store.user.setRoutingSettings('showTimeRadius', e)
			}}
		>
			{
				_menu.map((_item, index) => (
					<Select.Option key={'tr-' + index} value={_item}>{_item} км</Select.Option>
				))
			}
		</Select>)
	}

	const PointInfoScale = () => {
		const _menu = Array.from({length: (12 - 7) / .2 + 1}, (_, i) => parseFloat((i * .2 + 7).toFixed(1)))
		return (<Select
			defaultValue={store.user.settings.routing.pointInfoScale}
			onChange={(e) => {
				store.user.setRoutingSettings('pointInfoScale', e)
				mapRef.current.setZoom(e)
			}}
		>
			{
				_menu.map((_item, index) => (
					<Select.Option key={'ps-' + index} value={_item}>{_item}</Select.Option>
				))
			}
		</Select>)

	}


	return (
		<Drawer
			closable={true}
			title="Настройки карты"
			placement="right"
			width={350}
			onClose={() => setSettingVisible(false)}
			visible={settingVisible}
		>
			<Form layout="vertical">
				<Form.Item label="Показ. время соседних точек в радиусе"
						   tooltip="Укажите радиус отображения времени доставки соседних точек. Радиус считается от последней точки в МЛ.">
					<TimeRadiusMenu/>
				</Form.Item>
				<Form.Item label="Инфо о точках при масштабе от"
						   tooltip="Укажите минимальный масштаб карты, при котором при наведении на точку будет всплывать инфорамация о ней.">
					<PointInfoScale/>
				</Form.Item>
				<Form.Item label="Фильтры на карте">
					<Row>
						{
							filters.map((item, index) => (
								<Col key={'fl' + index} span={8}>
									<Checkbox checked={store.user.settings.routing.showFilter[item.id]}
											  onChange={(e) => store.user.setRoutingShowFilter(item.id, e.target.checked)}
											  style={{lineHeight: '32px'}}>
										{item.title}
									</Checkbox>
								</Col>
							))
						}
					</Row>

				</Form.Item>
			</Form>
		</Drawer>
	)
}

export default observer(Settings)
