import {CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined} from "@ant-design/icons"
import {observer} from "mobx-react-lite"
import {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import {Routing} from "../../../../network"
import {useBroadcast} from "../../../../store/SocketStore"

const StepPublish = ({points, closeModal}) => {

	const [data, setData] = useState(["Отправка данных..."])
	const [status, setStatus] = useState("loading")
	const codeRef = useRef()

	useEffect(() => {
		(async () => {
			const result = await Routing.pointsFromExcel(points)
			if (result.success) {
				setData(data => [...data, "Сервер принял данные..."])
			} else {
				setStatus("error")
				setData(data => [...data, "Ошибка загрузки данных."])
			}
		})()
	}, [])

	useEffect(() => {
		if (codeRef.current) {
			codeRef.current.scrollTop = codeRef.current.scrollHeight
		}
		if (points.length <= data.length - 1) {
			setStatus("done")
			setTimeout(() => {
				closeModal()
			}, 1000)
		}
	}, [data])

	useBroadcast(item => {
		if (item.from_excel) {
			setData(data => [...data, "Загружена точка " + item.name + " (" + item.address + ") [" + (data.length - 1) + "/" + points.length + "]"])
		}
	}, ["point.add"])

	return (
		<Wrapper>
			<Icon>
				{status === "loading" && <LoadingOutlined/>}
				{status === "error" && <CloseCircleOutlined style={{color: "#f5222d"}}/>}
				{status === "done" && <CheckCircleOutlined style={{color: "#7cb305"}}/>}
			</Icon>
			<Code ref={codeRef}>{data.map((item, index) => <div key={'d' + index}>{item}</div>)}</Code>
		</Wrapper>
	)
}

export default observer(StepPublish)

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
`
const Icon = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6em;
`
const Code = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #333;
  padding: 5px;
  overflow: auto;
  font-family: "Inconsolata", "Fira Mono", "Source Code Pro", Monaco, Consolas, "Lucida Console", monospace
`
