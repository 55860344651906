import {observer} from "mobx-react-lite"
import React from "react"
import {Modal, Button} from "antd"
import styled from "styled-components"
import RouteView from "./component/RouteView"

const ModalRouteView = ({
							route = null, onCancel = () => {
	}
						}) => {

	if (!route) return null

	return (
		<ModalItem
			visible={true}
			width={950}
			bodyStyle={{display: 'flex', flex: 1, flexDirection: 'column', height: 800, padding: 0}}
			maskClosable={true}
			onCancel={() => {
				onCancel()
			}}
			footer={[
				<Button
					onClick={() => {
						onCancel()
					}}
					key={'cancel-btn'}
					type="primary"
				>
					Закрыть
				</Button>,
			]}
		>
			<RouteView route={route} loadPoints/>
		</ModalItem>
	)
}

export default observer(ModalRouteView)

const ModalItem = styled(Modal)`
  .ant-modal-close {
    top: -40px;
    right: -40px;
    color: #FFFFFF;
  }
`
