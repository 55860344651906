const RouteFilter = {
	all: {
		name: "Все МЛ"
	},
	performed: {
		name: "В работе"
	},
	created: {
		name: "Ожидают"
	},
	done: {
		name: "Выполненные"
	},
	canceled: {
		name: "Отмененные"
	}
}

export default RouteFilter
