import {observer} from "mobx-react-lite"
import React, {useMemo} from "react"
import styled from "styled-components"
import {useStore} from "../../../../store/Context"
import {Dropdown, Modal, Table, Tooltip} from "antd"
import Flag from "../../../../assets/svg/flag.svg"
import {getPrettyPhone, prettyDigit, prettyTimePeriod} from "../../../../store/helper/StringMethod"
import PointStatus from "../../../../store/helper/PointStatus"
//import {pointContextMenu} from "./pointContextMenu"
import {VList} from "virtuallist-antd"
import {Point} from "../../../../network"
import useForceUpdate from "../../../../component/useForceUpdate"
import {useBroadcast} from "../../../../store/SocketStore"
import Icon from "../../../../component/Icon"
import {faLock} from "@fortawesome/pro-regular-svg-icons/faLock"
//import EmptyData from "./EmptyData";

const TablePoints = ({
						 selectPoint,
						 setEditPointItem,
						 setShowMovePointModal,
						 getPolyline,
						 syncRoute
					 }) => {

	const store = useStore()

	const data = useMemo(() => {
		if (!store.route.actual.activeRoute) return []
		return [...store.route.actual.getRouteById(store.route.actual.activeRoute).points].sort((a, b) => {
			return a.position - b.position
		})
	}, [store.route.actual.refreshedAt, store.route.actual.activeRoute])

	const toolBarData = useMemo(() => {
		return {
			total: data.length
		}
	}, [data])


	const vComponents = useMemo(() => {
		return VList({
			height: store.user.settings.routing.layout.bottomHeight,
			resetTopWhenDataChange: false,
			vid: 'TerminalTableRoutePoints'
		})
	}, [store.user.settings.routing.layout.bottomHeight])

	const forceUpdate = useForceUpdate()

	useBroadcast(() => {
		forceUpdate()
	}, ["point.status"])

	const DropMenu = ({point, children}) => {
		let route = point.route_id ? store.routing.getRouteById(point.route_id) : null
		return (
			<Dropdown
				//overlay={pointContextMenu(point, route, selectPoint, store, setEditPointItem, setShowMovePointModal, changeStatus)}
				trigger={["contextMenu"]}
				onVisibleChange={(visible) => {
					if (visible) {
						store.routing.setActivePoint(point.id)
					}
				}}
			>
				<div className={'droppable'}>
					{children}
				</div>
			</Dropdown>
		)
	}


	const columns = [
		{
			title: '',
			dataIndex: 'position',
			fixed: 'left',
			width: 30,
			render: (text, record) => <DropMenu point={record}>
				<PointPosition>{text}</PointPosition>
			</DropMenu>
		},
		{
			title: 'Название',
			dataIndex: 'name',
			fixed: 'left',
			width: 200,
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				return (
					<DropMenu point={record}>
						<Ellipsis width={200}>
							<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
								{text}
							</Tooltip>
						</Ellipsis>
					</DropMenu>
				)
			},
		},
		{
			title: 'Адрес',
			dataIndex: 'address',
			width: 300,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>
		},
		{
			title: 'Время',
			dataIndex: 'time',
			width: 150,
			render: (text, record) => <DropMenu
				point={record}>{prettyTimePeriod(record.from_at, record.to_at)}</DropMenu>,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			width: 120,
			render: (text, record) => {
				let route = record.route_id ? store.routing.getRouteById(record.route_id) : null
				return (
					<DropMenu point={record}>
						{text.isCanceled ? (
							<span style={{color: PointStatus[text].color}}>
								{PointStatus[text].name}
							</span>
						) : (!route || route?.draft) ? (
							"Черновик"
						) : (
							<span style={{color: PointStatus[text].color}}>
								{PointStatus[text].name}
							</span>
						)}
					</DropMenu>
				)
			},
		},
		{
			title: 'Кол-во',
			dataIndex: 'count',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.count)}</DropMenu>
		},
		{
			title: 'Вес',
			dataIndex: 'weight',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.weight, 'weight')}</DropMenu>
		},
		{
			title: 'Объем',
			dataIndex: 'cubic',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.cubic, 'cubic')}</DropMenu>
		},
		{
			title: 'Площадь',
			dataIndex: 'square',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.square, 'square')}</DropMenu>
		},
		{
			title: 'Сумма',
			dataIndex: 'price',
			width: 100,
			render: (text, record) => <DropMenu
				point={record}>{prettyDigit(record.statistic?.price, 'price')}
			</DropMenu>
		},
		{
			title: 'Получатель',
			dataIndex: 'client_name',
			width: 250,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={250}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text?.length ? text : '—'}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
		},
		{
			title: 'Телефон',
			dataIndex: 'client_contact',
			width: 150,
			render: (text, record) => <DropMenu point={record}>{getPrettyPhone(text)}</DropMenu>
		},
		{
			title: 'Коммент',
			dataIndex: 'comment',
			width: 300,
			render: (text, record) => <DropMenu point={record}>
				<Ellipsis width={300}>
					<Tooltip placement="topLeft" title={text} mouseEnterDelay={.4}>
						{text}
					</Tooltip>
				</Ellipsis>
			</DropMenu>,
		},
	]

	return (
		<Wrapper>
			<TableWrapper tableHeight={store.user.settings.terminal.layout.bottomHeight}>
				<TableWrapper tableHeight={store.user.settings.terminal.layout.bottomHeight}>
					<Table
						dataSource={data}
						columns={columns}
						pagination={false}
						size="small"
						scroll={{
							y: store.user.settings.terminal.layout.bottomHeight - 80,
							x: 800
						}}
						rowKey='id'
						showSorterTooltip={false}
						components={vComponents}
						onRow={(record) => {
							return {
								onClick: () => {
									//store.routing.setActiveRoute(record.id)
								},
							}
						}}
						//rowSelection={[]}
						/*rowClassName={(record => (
							store.routing.activeRoute === record.id && 'tr-active-route'
						))}*/
					/>
				</TableWrapper>
			</TableWrapper>

			{/*<EmptyData visible={(store.routing.activeRoute && !data.length) || !data.length} dataLength={data.length}
					   type={'routePoints'}/>*/}

			<ToolBox>
				<div style={{flex: 1}}>
					Всего: {toolBarData.total}
				</div>
			</ToolBox>
		</Wrapper>
	)
}

export default observer(TablePoints)

const Wrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 10px;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;


  .row-dragging {
    border: 10px solid #ccc;
  }

  .row-dragging td {
    margin-right: 200px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }

  .drag-handle {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    min-width: 30px;
    padding: 5px;
    cursor: grab;
  }

  .ant-table-body {
    min-height: ${props => props.tableHeight - 80}px;
  }
`

const Ellipsis = styled.span`
  width: ${props => props.width}px;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ToolBox = styled.div`
  display: flex;
  min-height: 30px;
  padding: 5px 10px;
  background-color: #1F1F1F;
  color: #7B7975;
  font-size: 13px;
  align-items: center;
`
const PointPosition = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-weight: 600;
`
