import { types } from "mobx-state-tree"

const OfficeLocation = types.model({
	lng: types.number,
	lat: types.number,
})

export const OfficeItem = types.model({
	id: types.identifierNumber,
	name: types.maybeNull(types.string),
	address: types.maybeNull(types.string),
	address_location: types.maybeNull(OfficeLocation),
	position: types.integer,
	blocked: types.boolean,
	created_at: types.maybeNull(types.string),
}).views(self => {
	return {}
})
