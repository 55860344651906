import {observer} from "mobx-react-lite"
import React, {useState} from "react"
import {Modal, Button, Tabs} from "antd"
import styled from "styled-components"
import dayjs from "dayjs"
import PointStatus from "../../store/helper/PointStatus"
import PointInvoicesTab from "./component/PointInvoicesTab"
/*import PointLogsTab from "./component/PointLogsTab"*/
import PointPhotosTab from "./component/PointPhotosTab"


const ModalPointView = ({
							point, onCancel = () => {
	}
						}) => {

	const [tab, setTab] = useState('tab-invoices')

	if (!point) return null

	return (

		<ModalItem
			visible={true}
			width={950}
			maskClosable={true}
			onCancel={(e) => {
				onCancel()
				e.stopPropagation()
			}}
			bodyStyle={{display: 'flex', flex: 1, flexDirection: 'column', height: 700, padding: 0}}
			footer={[
				<Button
					onClick={(e) => {
						onCancel()
						e.stopPropagation()
					}}
					key={'cancel-btn'}
					type="primary"
					style={{zIndex: 1}}
				>
					Закрыть
				</Button>,
			]}
		>
			<HeaderWrapper>
				<TitleWrapper>
					<Title>
						{point.position + '. ' + point.name}
						{
							point.external_id && <ExternalId>{point.external_id}</ExternalId>
						}
					</Title>
					<PointCurrentStatus
						color={PointStatus[point.status].color}>
						{PointStatus[point.status].name}
						{
							point.status !== 'created' && (
								dayjs(point.updated_at).format('[ в ]HH:mm')
							)
						}
					</PointCurrentStatus>
				</TitleWrapper>
				<TabsWrapper
					onChange={(index) => setTab(index)}
					activeKey={tab}
				>
					<Tabs.TabPane tab="Список накладных" key={'tab-invoices'}/>
					<Tabs.TabPane tab="Фотографии" key={'tab-photos'} disabled={!point.media?.length}/>
					{/*<Tabs.TabPane tab="Журнал изменений" key={'tab-logs'}/>*/}
				</TabsWrapper>
			</HeaderWrapper>
			<ContentWrapper>
				{
					tab === 'tab-invoices' ? (
						<PointInvoicesTab point={point}/>
					) : tab === 'tab-photos' && (
						<PointPhotosTab point={point}/>
					)
				}
			</ContentWrapper>
		</ModalItem>
	)
}

export default observer(ModalPointView)

const ModalItem = styled(Modal)`
  .ant-modal-close {
    top: -40px;
    right: -40px;
    color: #FFFFFF;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #1F1F1F;
  position: relative;
`
const TitleWrapper = styled.div`
  position: relative;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
const Title = styled.div`
  flex: 1;
  font-size: 22px;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 25px;
`

const PointCurrentStatus = styled.div`
  text-align: end;
  justify-content: flex-end;
  color: ${props => props.color};
  opacity: .8;
`
const TabsWrapper = styled(Tabs)`
  position: relative;
  display: flex;
  flex: 1;

  .ant-tabs-nav {
    margin: 0;
    padding-left: 25px;
  }
`
const ExternalId = styled.div`
  font-size: 12px;
  opacity: .8;
`
