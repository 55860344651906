import { __extends } from "tslib";
import StateAction from './state';
/**
 * @ignore
 * 允许多选的 Action
 * @class
 */
var ElementMultipleSelected = /** @class */ (function (_super) {
    __extends(ElementMultipleSelected, _super);
    function ElementMultipleSelected() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'selected';
        return _this;
    }
    /**
     * 选中节点，允许多选
     */
    ElementMultipleSelected.prototype.selected = function () {
        this.setState();
    };
    return ElementMultipleSelected;
}(StateAction));
export default ElementMultipleSelected;
