import AsyncStorage from "@react-native-async-storage/async-storage"
import axios from "axios"
import qs from "qs"
import config from "../config"
import store from "../store"

const Axios = axios.create({})

Axios.interceptors.request.use(async function (options) {

	if (options.external) return options

	const prefix = "user"
	const token = await AsyncStorage.getItem("token")

	let office = sessionStorage.getItem("office")
	if (!office) {
		office = await AsyncStorage.getItem("office")
	}

	// ставим активный офис
	options.headers["X-Office"] = office

	// ставим сокет
	if (store.socket.id) {
		options.headers["X-Socket-ID"] = store.socket.id
	}

	// ставим токен, только если нет опции noAuth
	if (token && !options.noAuth && !options.external) {
		options.headers["Authorization"] = "Bearer " + token
	}

	// если ссылка не внешняя, настраиваем запрос !!!! выше прервал, можно удалять наверное
	if (!options.external) {
		options.url = config.axios.baseURL + "/" + prefix + options.url

		// устанавливаем хидеры дефолтные
		options.headers = {...options.headers, ...config.axios.headers}

		// get параметры делаем верно
		if (options.method === "get" && options.params) {
			options.url = options.url + "?" + qs.stringify(options.params, {encodeValuesOnly: true, skipNulls: true})
			options.params = []
		}
	}

	return options
}, function (error) {
	//console.log("Request error: ", error)
})

Axios.interceptors.response.use(response => {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return {code: response.status, ...response.data}
}, error => {
	const json = error.toJSON()

	if (error.response?.data) {
		return {
			code: json.status,
			...error.response.data,
		}
	}

	return {
		success: false,
		code: json.status,
		error: json.message,
	}
})

export default Axios
