import { __extends } from "tslib";
import ElementSingleState from './single-state';
/**
 * @ignore
 * 仅允许单个 Element Active 的 Action
 */
var ElementSingleActive = /** @class */ (function (_super) {
    __extends(ElementSingleActive, _super);
    function ElementSingleActive() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'active';
        return _this;
    }
    /**
     * 当前事件相关的 Element Active
     */
    ElementSingleActive.prototype.active = function () {
        this.setState();
    };
    return ElementSingleActive;
}(ElementSingleState));
export default ElementSingleActive;
