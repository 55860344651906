import React from "react"
import styled from "styled-components"
import {Dropdown, Menu} from "antd"
import {EllipsisOutlined} from "@ant-design/icons"
import RouteStatus from "../store/helper/RouteStatus"
import dayjs from "dayjs"

const StatusLabel = ({status = undefined, time = false, options = true, opacity = 1, setStatus}) => {

	const statusParams = RouteStatus[status]
	const menu = () => {
		return (
			<Menu>
				{
					RouteStatus[status].button.action.map((item, index) => (
						<Menu.Item
							onClick={() => setStatus(item.newStatus)}
							key={index}
							style={{color: item.color}}
						>
							{item.name}
						</Menu.Item>
					))
				}
			</Menu>
		)
	}


	return (
		<LabelBlock
			style={{
				borderColor: statusParams.button.border,
				background: statusParams.button.background,
				color: statusParams.color,
				opacity: opacity
			}}>
			<StatusName>
				{statusParams.name}
				{
					time && (
						' в ' + dayjs(time).format('HH:mm')
					)
				}
			</StatusName>
			{
				(options && RouteStatus[status].button?.action?.length) && (
					<Dropdown overlay={menu()}>
						<ActionIcon style={{borderColor: statusParams.button.border}}>
							<EllipsisOutlined/>
						</ActionIcon>
					</Dropdown>
				)
			}
		</LabelBlock>
	)
}
export default StatusLabel

const LabelBlock = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  border: solid;
  padding: 0px 7px 0px 7px;
  font-size: 12px;
`
const StatusName = styled.div`
  padding: 2px 0px 2px 0px;
`
const ActionIcon = styled.div`
  cursor: pointer;
  padding: 2px 0px 2px 5px;
  margin-left: 7px;
  border-left: solid 1px;
`
