/**
 * 在 Chart 中查找特定 id 的子 View
 * @param chart
 * @param id
 */
export function findViewById(chart, id) {
    return chart.views.find(function (view) { return view.id === id; });
}
/**
 * 获取同 view 同一级的所有 views
 * @param view 当前 view
 * @returns 同一级的 views
 * @ignore
 */
export function getViews(view) {
    var parent = view.parent;
    return parent ? parent.views : [];
}
/**
 * 获取同 view 同一级的 views，不包括自身
 * @param view 当前 view
 * @returns 同一级的 views
 * @ignore
 */
export function getSiblingViews(view) {
    return getViews(view).filter(function (sub) { return sub !== view; });
}
