import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import DriverLine from "../../component/DriverLine"
import PageHeader from "../../component/PageHeader"
import TTTable from "../../component/TTTable"
import { useStore } from "../../store/Context"
import ModalDriverForm from "./Modal/ModalDriverForm"
import { useNavigate } from "react-router-dom"

const DriverScreen = ({}) => {

	const store = useStore()
	const navigate = useNavigate()

	const [showFormModal, setShowFormModal] = useState(false)

	useEffect(() => {
		store.driver.fetch()
	}, [])

	const columns = [
		{
			dataIndex: "name",
			render: (text, record) => <DriverLine
				driver={record}
			/>,
		},
		{
			dataIndex: "active_route",
			render: (text, record) => record.routes?.performed?.name,
		},
	]

	return (
		<Wrapper>
			<PageHeader
				title={["Водители"]}
			/>
			<ContentWrapper>
				<TTTable
					defaultColumns={["name", "login", "phone", "active_route", "created_at"]}
					data={store.driver}
					columns={columns}
					addItem={{ title: "Добавить водителя", link: "driver/add", setShowFormModal }}
					onclick={(item) => navigate("/driver/" + item.id)}
				/>
			</ContentWrapper>
			{
				showFormModal && (
					<ModalDriverForm
						showFormModal={showFormModal}
						setShowFormModal={setShowFormModal}
					/>
				)
			}

			<ModalDriverForm
				visible={showFormModal}
				onCancel={() => {
					setShowFormModal(false)
				}}
				api={"/v1/driver/create"}
				onSuccess={() => {
					setShowFormModal(false)
					store.driver.fetch()
				}}
			/>
		</Wrapper>
	)
}

export default observer(DriverScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
