import React from "react";
import {observer} from "mobx-react-lite"

const Box = ({width = 40, height = 40, color = '#5eac24'}) => {

	return (
		<svg id="Layer_1" height={height} viewBox="0 0 512 512" width={width} xmlns="http://www.w3.org/2000/svg"
			 data-name="Layer 1">
			<g>
				<path
					d="m444.939 372.9-172.939 99.849v-207.513l179.712-103.76v199.7a12.914 12.914 0 0 1 -6.773 11.725zm-377.878 0a12.891 12.891 0 0 1 -6.772-11.714v-199.7l179.711 103.75v207.513zm182.169-338.981-172.94 99.845 179.71 103.753 179.71-103.753-172.94-99.845a12.963 12.963 0 0 0 -13.539 0zm211.711 77.472-182.171-105.183a45.094 45.094 0 0 0 -45.541 0l-182.168 105.183a45.055 45.055 0 0 0 -22.772 39.43v210.356a45.065 45.065 0 0 0 22.768 39.434l182.173 105.178a45.086 45.086 0 0 0 45.539 0l182.172-105.184a45.042 45.042 0 0 0 22.768-39.424v-210.361a45.06 45.06 0 0 0 -22.768-39.429z"
					fill="#282828"/>
				<g fill={color}>
					<path d="m60.29 161.476v199.705a12.891 12.891 0 0 0 6.771 11.719l172.939 99.849v-207.513z"/>
					<path d="m272 265.236v207.513l172.939-99.849v.007a12.914 12.914 0 0 0 6.773-11.725v-199.7z"/>
					<path d="m256 237.517 179.71-103.753-172.941-99.843a12.963 12.963 0 0 0 -13.539 0l-172.94 99.843z"/>
				</g>
			</g>
		</svg>
	)
}

export default observer(Box)
