import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {Form, Input, Button, message} from "antd"
import {ClockCircleOutlined} from '@ant-design/icons'
import styled from "styled-components"
import {useStore} from "../../store/Context"
import {Office} from "../../network"
import {getPrettyPhone} from "../../store/helper/StringMethod"

const SettingMainTab = () => {
	const store = useStore()

	const [name, setName] = useState(store.user.office.name)
	const [subscribeEmail, setSubscribeEmail] = useState('')
	const [reasonRoute, setReasonRoute] = useState('')
	const [reasonPoint, setReasonPoint] = useState('')
	const [reasonInvoice, setReasonInvoice] = useState('')

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setSubscribeEmail(prepareArrayData(store.user.office.subscribe_email))
		setReasonRoute(prepareArrayData(store.user.office.reason_route))
		setReasonPoint(prepareArrayData(store.user.office.reason_point))
		setReasonInvoice(prepareArrayData(store.user.office.reason_invoice))
	}, [])

	const send = async () => {
		setLoading(true)
		await new Promise(resolve => setTimeout(resolve, 500))
		let data = {
			id: store.user.office.id,
			name: name,
			subscribe_email: prepareArrayData(subscribeEmail, true),
			reason_route: prepareArrayData(reasonRoute, true),
			reason_point: prepareArrayData(reasonPoint, true),
			reason_invoice: prepareArrayData(reasonInvoice, true),
		}

		let result = await Office.update(data)
		if (result.code === 200) {
			store.user.updateOfficeInfo(result.data)
			message.success("Данные успешно сохранены")
		} else {
			message.warning("Произошла ошибка")
		}

		setLoading(false)
	}

	const prepareArrayData = (data, revert = false) => {
		switch (revert) {
			case false: {
				let str = ''
				data.map(item => {
					str += item + '\n'
				})
				return str.trim()
			}
			case true: {
				let arr = []
				data.split("\n").map(item => {
					if (item.trim().length) arr.push(item.trim())
				})
				return arr
			}
		}

	}

	return (
		<Wrapper>
			<Form
				labelCol={{span: 8}}
				wrapperCol={{span: 16}}
				onFinish={() => send()}
				initialValues={{
					name: name,
					closeTime: '23:00',
					supportPhone: getPrettyPhone(store.user.office.support_phone),
					subscribeEmail: prepareArrayData(store.user.office.subscribe_email),
					reasonRoute: prepareArrayData(store.user.office.reason_route),
					reasonPoint: prepareArrayData(store.user.office.reason_point),
					reasonInvoice: prepareArrayData(store.user.office.reason_invoice)
				}}
			>
				<Form.Item label="Название филиала" name={'name'}>
					<Input
						onChange={(e) => setName(e.target.value)}
						style={{width: 180}}
					/>
				</Form.Item>
				<Form.Item
					extra="В это всемя все незакрытые МЛ будут закрыты."
					label="Принудительное закрыте МЛ"
					name="closeTime"
				>
					<Input suffix={<ClockCircleOutlined/>} disabled style={{width: 180}}/>
				</Form.Item>
				<Form.Item label="Телефон ответственного" extra="Телефон доступен водителям." name={'supportPhone'}>
					<Input
						disabled
						style={{width: 180}}
					/>
				</Form.Item>
				<Form.Item
					extra="Укажите список email адресов через ↵"
					label="Адреса для отчётов"
					name="subscribeEmail"
				>
					<Input.TextArea
						autoSize={{minRows: 3, maxRows: 25}}
						style={{width: 450}}
						value={prepareArrayData(store.user.office.subscribe_email)}
						onChange={(e) => setSubscribeEmail(e.target.value)}
						spellCheck={false}
					/>
				</Form.Item>
				<Form.Item extra="Укажите причины для МЛ через ↵"
						   label="Причины отказа МЛ"
						   name="reasonRoute">
					<Input.TextArea
						autoSize={{minRows: 3, maxRows: 25}}
						style={{width: 450}}
						value={prepareArrayData(store.user.office.reason_route)}
						onChange={(e) => setReasonRoute(e.target.value)}
						spellCheck={false}
					/>
				</Form.Item>
				<Form.Item extra="Укажите причины для Точек через ↵"
						   label="Причины отказа Точек"
						   name="reasonPoint">
					<Input.TextArea
						autoSize={{minRows: 3, maxRows: 25}}
						style={{width: 450}}
						value={prepareArrayData(store.user.office.reason_point)}
						onChange={(e) => setReasonPoint(e.target.value)}
						spellCheck={false}
					/>
				</Form.Item>
				<Form.Item extra="Укажите причины для Накладных через ↵"
						   label="Причины отказа Грузов"
						   name="reasonInvoice">
					<Input.TextArea
						autoSize={{minRows: 3, maxRows: 25}}
						style={{width: 450}}
						value={prepareArrayData(store.user.office.reason_invoice)}
						onChange={(e) => setReasonInvoice(e.target.value)}
						spellCheck={false}
					/>
				</Form.Item>
				<Form.Item>
					<Button type="primary" loading={loading} htmlType="submit">Сохранить</Button>
				</Form.Item>
			</Form>
		</Wrapper>
	)
}

export default observer(SettingMainTab)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
