import {types} from "mobx-state-tree"
import {Route} from "../../network"
import {DriverItem} from "./DriverItem"
import {PointItem} from "./PointItem"

const Tag = types.model({
	id: types.maybeNull(types.integer),
	name: types.maybeNull(types.string),
})

const Geo = types.model({
	status: types.maybeNull(types.string),
	distance: types.maybeNull(types.integer),
	duration: types.maybeNull(types.integer),
	geometry: types.maybeNull(types.string),
})

const Points = types.model({
	created: types.integer,
	on_way: types.integer,
	arrived: types.integer,
	done: types.integer,
	"canceled:driver": types.integer,
	"canceled:manager": types.integer,
})

export const RouteItem = types.model({
	id: types.identifierNumber,
	external_id: types.maybeNull(types.string),
	name: types.string,
	date: types.string,
	status: types.enumeration(["created", "accepted", "performed", "done", "canceled:driver", "canceled:manager", "canceled:api"]),
	in_work: types.boolean,
	exec_order: types.maybeNull(types.enumeration(["fix", "optional", "fix_first", "fix_last", "fix_ends"])),
	need_background_location: types.boolean,
	updated_at: types.maybeNull(types.string),
	created_at: types.maybeNull(types.string),
	tags: types.maybeNull(types.array(Tag)),
	geo: types.maybeNull(Geo),
	points: types.optional(types.array(PointItem), []),
	points_stat: types.maybeNull(Points),
	driver: types.maybeNull(types.late(() => DriverItem)),
}).actions(self => {
	return {
		setStatus ({status, updated_at, in_work}) {
			if (status) self.status = status
			if (updated_at) self.updated_at = updated_at
			self.in_work = in_work
		},
		async refreshGeometry () {
			let geometry = await Route.routeGeometry({id: self.id})
			if (geometry.status === 200) {
				self.setGeometry(geometry.data.data.geometry)
			}
		},
		setGeometry (geometry) {
			self.geo.geometry = geometry
		},
		setGeo (geo) {
			self.geo = geo
		},
	}
}).views(self => {
	return {
		get key () {
			return self.id
		},
		get countReadyPoints () {
			let ready = self.points.filter(point => ["done", "canceled:driver", "canceled:manager"].includes(point.status))
			return {
				ready: ready.length,
				all: self.points.length,
			}
		},
		get sortedPoints () {
			return [...self.points].sort((a, b) => a.position - b.position)
		},
	}
})
