import {Button, Form, Input} from "antd"
import {observer} from "mobx-react-lite"
import {useNavigate} from "react-router-dom"
import styled from "styled-components"
import TTForm from "../../component/TTForm/TTForm"
import {useStore} from "../../store/Context"

const LoginPage = ({}) => {

	const store = useStore()
	const [from] = Form.useForm()
	const navigate = useNavigate()

	const handlerSuccess = () => {
		navigate("/")
	}

	return (
		<Wrapper>
			<TTForm
				name="basic"
				labelCol={{span: 8}}
				wrapperCol={{span: 16}}
				autoComplete="off"
				from={from}
				api={store.user.actionLogin}
				onApiSuccess={handlerSuccess}
			>
				<TTForm.Item label={"Логин"} name={"login"}>
					<Input/>
				</TTForm.Item>

				<TTForm.Item label={"Пароль"} name={"password"}>
					<Input.Password/>
				</TTForm.Item>

				<TTForm.Item wrapperCol={{offset: 8, span: 16}}>
					<Button type="primary" htmlType="submit">
						Войти
					</Button>
				</TTForm.Item>
			</TTForm>
		</Wrapper>
	)
}

export default observer(LoginPage)

const Wrapper = styled.div`
  padding: 20px;
  //border: 1px solid #666;
  //background: #e6e6e6;
  //border-radius: 10px;
  //box-shadow: 0 0 20px #000000cc;
`
