import {observer} from "mobx-react-lite"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {useStore} from "../../../store/Context"
import {Button, Divider, Form, Input, Modal} from "antd"
import dayjs from "dayjs";
import styled from "styled-components"
import Avatar from "antd/es/avatar/avatar"
import {Routing} from "../../../network"
import {getPrettyPhone} from "../../../store/helper/StringMethod"
import RouteColors from "../../../store/helper/RouteColors"

const ModalRouteForm = ({
							addPointItem,
							setShowRouteFormModal, setAddPointItem,
							editRouteItem, setEditRouteItem
						}) => {

	const store = useStore()
	const formRef = useRef()

	const [routeName, setRouteName] = useState(null)
	const [routeComment, setRouteComment] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [selectedDriver, setSelectedDriver] = useState(null)

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			let data = {
				name: 'МЛ ' + dayjs(store.routing.activeDate + ' ' + dayjs().format('HH:mm:ss')).format('DDMMHHmmss'),
				selectedDriver: null,
				color: RouteColors[Math.floor(Math.random() * ((RouteColors.length - 1) - 0 + 1)) + 0]
			}

			if (editRouteItem) {
				data.name = editRouteItem.name
				data.comment = editRouteItem.comment
				data.color = editRouteItem.color

				if (editRouteItem.driver_id) {
					let _findDriver = store.routing.drivers.find(_driver => _driver.id === editRouteItem.driver_id)
					if (_findDriver) {
						setSelectedDriver({..._findDriver, ...{fullName: _findDriver.surname + ' ' + _findDriver.name}})
					}
				}
			}

			setRouteName(data.name)
			setRouteComment(data.comment)
		}, 100)
	}, [])

	useEffect(() => {
		formRef.current.setFieldsValue({"name": routeName})
	}, [routeName])
	useEffect(() => {
		formRef.current.setFieldsValue({"comment": routeComment})
	}, [routeComment])

	useEffect(() => {
		if (!searchText.trim().length) setSelectedDriver(null)
	}, [searchText])

	useEffect(() => {
		if (selectedDriver?.fullName) {
			setSearchText(selectedDriver.fullName)
			formRef.current.setFieldsValue({"driver": selectedDriver.fullName});
		}
	}, [selectedDriver])

	const driverItems = useMemo(() => {
		if (selectedDriver) return []
		let _data = [...store.routing.drivers].map(_driver => {
			return {..._driver, fullName: _driver.surname + ' ' + _driver.name}
		})

		if (searchText.length) {
			return _data.filter(driver => (driver.surname + ' ' + driver.name).toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
		}

		return _data
	}, [store.routing.drivers, searchText, selectedDriver])

	const sendForm = async () => {
		setLoading(true)
		//if (!(selectedDriver && routeName.trim().length) && addPointItem) return

		let color = RouteColors[Math.floor(Math.random() * ((RouteColors.length - 1) - 0 + 1)) + 0]
		let postData = {date: store.routing.activeDate, color: color, driver_id: null}
		if (routeName?.trim()) postData.name = routeName.trim()
		if (routeComment?.trim()) postData.comment = routeComment.trim()
		if (addPointItem?.id) postData.points = [addPointItem.id]
		if (selectedDriver?.id) postData.driver_id = selectedDriver.id
		if (editRouteItem?.id) postData.id = editRouteItem.id

		if (editRouteItem) {
			let result = await Routing.update(postData)
			if (result.code === 200) {
				store.routing.updateRoute(result.data)
			}
		} else {
			let result = await Routing.create(postData)
			if (result.code === 200) {
				store.routing.addRoute(result.data)

				if (addPointItem?.id) {
					store.routing.setRouteId(addPointItem.id, result.data.id, 1)
				}

				store.routing.setActiveRoute(result.data.id)
			}
		}
		setLoading(false)
		handleCancel()
	}

	const handleCancel = () => {
		setShowRouteFormModal(false)
		setAddPointItem(null)
		setEditRouteItem(null)
	}

	return (
		<Modal
			title={editRouteItem ? 'Радактировать МЛ' : 'Новый МЛ'}
			visible={true}
			width={600}
			maskClosable={false}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Закрыть
				</Button>,
				<Button loading={loading} key="submit" type="primary" onClick={sendForm}
				>
					{editRouteItem ? 'Сохранить' : 'Добавить'}
				</Button>
			]}
		>
			<FormWrapper
				ref={formRef}
				initialValues={{
					name: routeName,
					comment: routeComment,
				}}
				labelCol={{span: 6}}
				wrapperCol={{span: 18}}
				colon={false}
			>
				<Form.Item name={"name"} label="Название">
					<Input
						allowClear
						onChange={(e) => setRouteName(e.target.value)}
					/>
				</Form.Item>
				<Form.Item name={"comment"} label="Комментарий">
					<Input
						allowClear
						onChange={(e) => setRouteComment(e.target.value)}
					/>
				</Form.Item>
				<Form.Item name={"driver"} label="Водитель">
					<Input
						allowClear
						autoFocus
						className={selectedDriver && 'selectedDriver'}
						prefix={selectedDriver && <Avatar
							src={process.env.REACT_APP_API_URL + "/media/driver/" + selectedDriver.login + "/avatar"}
							size={22}/>}
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</Form.Item>
			</FormWrapper>
			<DriverList>
				{
					(driverItems.length) ? driverItems.map((driver, index) => {
						let routeCount = store.routing.routes.filter(_route => (_route.driver_id === driver.id && _route.status.indexOf('canceled') === -1)).length
						return (
							<DriverItem key={'driver-' + index} onClick={() => setSelectedDriver(driver)}>
								<div>
									<Avatar
										src={process.env.REACT_APP_API_URL + "/media/driver/" + driver.login + "/avatar"}
										size={22}/>

								</div>
								<div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 15}}>
									<div style={{color: '#E9E5DE', fontWeight: '500'}}>
										{driver.fullName}
									</div>
									<div style={{opacity: .7, fontWeight: '300', fontSize: 12}}>
										{driver.phone && (getPrettyPhone(driver.phone))}
										{driver.auto_brand && (<><Divider type="vertical"/> {driver.auto_brand}</>)}
										{driver.auto_number && (<><Divider type="vertical"/> {driver.auto_number}</>)}
									</div>
								</div>
								{routeCount ? (
									<RouteCount>
										{routeCount}
									</RouteCount>
								) : null}
							</DriverItem>
						)
					}) : (null)
				}
			</DriverList>
		</Modal>
	)
}

export default observer(ModalRouteForm)

const FormWrapper = styled(Form)`
  .selectedDriver input {
    color: #177DDC;
    font-weight: 600;
  }
`
const DriverList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 300px;
  padding-left: 135px;

  .searchString {
    font-weight: bold;
    color: #177DDC;
  }


`
const DriverItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-block: 10px;
  padding-left: 10px;
  cursor: pointer;

  &:hover {
    background: #212121;
  }
`

const RouteCount = styled.div`
  padding-right: 20px;
  font-size: 20px;
  color: #FF9500;
`
