/* 依赖的模块，在这里统一引入，方便打包优化 */
// G
export { Event, AbstractGroup, AbstractShape, } from '@antv/g-base';
// adjust
export { registerAdjust, getAdjust, Adjust } from '@antv/adjust';
// attr
export { getAttribute, Attribute } from '@antv/attr';
export { Color } from '@antv/attr';
// coordinate
export { getCoordinate, registerCoordinate, Coordinate } from '@antv/coord';
// scale
export { getScale, registerScale, Scale } from '@antv/scale';
// component
import { Annotation, Axis, Component, Crosshair, Grid, GroupComponent, HtmlComponent, Legend, Slider, Tooltip, Scrollbar, } from '@antv/component';
export { HtmlComponent, GroupComponent, Component, Crosshair };
export { Annotation };
// axis
var LineAxis = Axis.Line, CircleAxis = Axis.Circle;
export { LineAxis, CircleAxis };
// grid
var LineGrid = Grid.Line, CircleGrid = Grid.Circle;
export { LineGrid, CircleGrid };
// legend
var CategoryLegend = Legend.Category, ContinuousLegend = Legend.Continuous;
export { CategoryLegend, ContinuousLegend };
// Tooltip
var HtmlTooltip = Tooltip.Html;
export { HtmlTooltip };
// Slider
export { Slider };
// Scrollbar
export { Scrollbar };
