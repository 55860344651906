import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import styled from "styled-components"

const RoadNumber = ({ number, size = "normal" }) => {

	const pretty = useMemo(() => {
		let result = number.replace("а", "a")
			.replaceAll("в", "b")
			.replaceAll("е", "e")
			.replaceAll("к", "k")
			.replaceAll("м", "m")
			.replaceAll("н", "h")
			.replaceAll("о", "o")
			.replaceAll("р", "p")
			.replaceAll("с", "c")
			.replaceAll("т", "t")
			.replaceAll("у", "y")
			.replaceAll("х", "x")
			.toUpperCase()
			.split("")

		return (
			<div>
				{
					result.map((item, index) => parseInt(item) == item ?
						<NumberWrapper size={size} key={"char-" + index}>{item}</NumberWrapper> :
						<TextWrapper size={size} key={"char-" + index}>{item}</TextWrapper>)
				}
			</div>
		)
	}, [number])

	return (
		<Wrapper size={size}>
			{number && pretty}
		</Wrapper>
	)
}

export default observer(RoadNumber)

const Wrapper = styled.div`
  font-family: "RoadNumbers";
  font-size: ${props => props.size === "small" ? 14 : 16}px;
  letter-spacing: ${props => props.size === "small" ? 2 : 3}px;
  display: flex;

  div {
    border: 1px solid #666;
    border-radius: ${props => props.size === "small" ? 4 : 5}px;
    display: flex;
    flex-direction: row;
    padding: ${props => props.size === "small" ? "1px 3px 0" : "2px 4px 0"};
    height: ${props => props.size === "small" ? 20 : 24}px;
    overflow: hidden;
  }
`
const NumberWrapper = styled.span`
  width: ${props => props.size === "small" ? 8 : 10}px;
  height: 28px;
  line-height: 28px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: ${props => props.size === "small" ? -2 : -2}px;
`
const TextWrapper = styled.span`
  color: #777 !important;
  height: 28px;
  font-size: ${props => props.size === "small" ? 16 : 18}px;
  width: ${props => props.size === "small" ? 10 : 11}px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: ${props => props.size === "small" ? -2 : -2}px;
`