import { observer } from "mobx-react-lite"
import styled from "styled-components"

const Outside = ({ children }) => {

	return (
		<Wrapper>
			{children}
		</Wrapper>
	)
}

export default observer(Outside)


const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`