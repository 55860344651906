import {observer} from "mobx-react-lite"
import React, {createRef, useEffect, useRef, useState} from "react"
import styled from "styled-components"
import "mapbox-gl/dist/mapbox-gl.css"
import {useStore} from "../../store/Context"
import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex"
import TerminalMap from "./component/TerminalMap"
import TableRoutes from "./component/table/TableRoutes"
import "../../css/dashboard.css"
import TableRoutePoints from "./component/table/TableRoutePoints"
import {notification} from "antd"
import CatchKeyPress from "../../component/CatchKeyPress"

const TerminalScreen = () => {

	const store = useStore()
	const mapRef = useRef()
	const topAreaRef = createRef()
	const bottomAreaRef = createRef()


	useEffect(() => {
		if (!store.user.settings.terminal.layout.topHeight && topAreaRef?.current?.ref?.current?.clientHeight) {
			store.user.setDashboardLayoutSettings('topHeight', topAreaRef?.current?.ref?.current?.clientHeight)
		}
		if (!store.user.settings.terminal.layout.bottomHeight && bottomAreaRef?.current?.ref?.current?.clientHeight) {
			store.user.setDashboardLayoutSettings('bottomHeight', bottomAreaRef?.current?.ref?.current?.clientHeight)
		}
	}, [topAreaRef, bottomAreaRef])


	return (
		<Wrapper className="routing-wrapper">
			<WrapperRelative>
				<WrapperAbsolute>
					<ReflexContainer orientation="horizontal" ref={topAreaRef}>
						<ReflexElement
							flex={
								store.user.settings.terminal.layout.topHeight ?
									store.user.settings.terminal.layout.topHeight / (store.user.settings.terminal.layout.topHeight + store.user.settings.terminal.layout.bottomHeight)
									: .5
							}
							minSize={100}
							onResize={(e) => {
								mapRef.current.resize()
								store.user.setLayoutSettings('terminal', 'topHeight', e.domElement.offsetHeight)
							}}
						>
							<TerminalMap mapRef={mapRef}/>
						</ReflexElement>

						<ReflexSplitter style={{height: 5}}/>

						<ReflexElement
							minSize={300}
							onResize={(e) => {
								mapRef.current.resize()
								store.user.setLayoutSettings('terminal', 'bottomHeight', e.domElement.offsetHeight)
							}}
						>
							<ReflexContainer orientation="vertical" ref={bottomAreaRef}>
								<ReflexElement
									flex={
										store.user.settings.terminal.layout.bottomLeftWidth ?
											store.user.settings.terminal.layout.bottomLeftWidth / (store.user.settings.terminal.layout.bottomLeftWidth + store.user.settings.terminal.layout.bottomRightWidth)
											: .5
									}
									minSize={300}
									onResize={(e) => {
										mapRef.current.resize()
										store.user.setLayoutSettings('terminal', 'bottomLeftWidth', e.domElement.offsetWidth)
									}}
								>
									<TableRoutes/>
								</ReflexElement>
								<ReflexSplitter style={{width: 5}}/>
								<ReflexElement
									minSize={300}
									onResize={(e) => {
										mapRef.current.resize()
										store.user.setLayoutSettings('terminal', 'bottomRightWidth', e.domElement.offsetWidth)
									}}
								>
									<TableRoutePoints/>
								</ReflexElement>
							</ReflexContainer>
						</ReflexElement>
					</ReflexContainer>
				</WrapperAbsolute>
			</WrapperRelative>
			<CatchKeyPress onEsc={() => store.route.actual.setActiveRoute(0)}/>
		</Wrapper>
	)
}

export default observer(TerminalScreen)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const WrapperRelative = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`
const WrapperAbsolute = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .reflex-splitter {
    background-color: #383838;
    border: 0 !important;
  }

  .ant-table-placeholder {
    display: none !important;
  }
`
