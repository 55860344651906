import { Tooltip } from "antd"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import updateLocale from "dayjs/plugin/updateLocale"
import calendar from "dayjs/plugin/calendar"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(updateLocale)
dayjs.extend(calendar)
dayjs.extend(relativeTime)
dayjs.updateLocale("ru", {
	months: "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(","),
})

const Date = ({ date }) => {

	const prettyDate = useMemo(() => {
		if (!date) return null
		if (dayjs().diff(dayjs(date), "month") > 6) {
			return dayjs(date).format("DD.MM.YYYY HH:mm")
		}
		return dayjs(date).fromNow()
	}, [date])

	return (
		<Wrapper title={date}>
			{prettyDate}
		</Wrapper>
	)
}

export default observer(Date)

const Wrapper = styled(Tooltip)``