import { AutoComplete, Form, Input, Radio, Switch } from "antd"
import MaskedInput from "antd-mask-input"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { useFields } from "./Context"
import { AsyncAutocomplete } from "./Field"

const FormItem = ({
	name,
	children,
	hidden = false,
	listItemRender = null,
	title,
	...props
}) => {

	const field = useFields(name, props)
	const form = Form.useFormInstance()

	const renderInput = () => {
		const props = field.extra?.input ?? {}

		if (field.extra?.list || props.autocomplete) {
			if (field.type === "select") {
				return (
					<Radio.Group
						options={Object.entries(field.extra.list).map(([value, label]) => ({
							label,
							value,
						}))}
						optionType="button"
						buttonStyle="solid"
					/>
				)
			}
			return (
				<AutoComplete options={filterAutocomplete().filter(item => {
					if (!form.getFieldValue(field.name) || item.value.toLowerCase().includes(form.getFieldValue(field.name).toLowerCase())) {
						return true
					}
					return false
				})}>
					<Input.Search {...props}/>
				</AutoComplete>
			)
		}
		if (field.extra?.list_uri) {
			return (
				<AsyncAutocomplete
					field={field}
					listField={field.extra?.list_field ?? "name"}
					inputProps={props}
					renderItem={listItemRender}
				/>
			)
		}
		if (field.extra?.mask) {
			return (
				<MaskedInput maskOptions={{ lazy: true }} mask={field.extra.mask} {...props}/>
			)
		}

		switch (field.type) {
			case "checked": {
				return <Switch {...props}/>
			}
			default: {
				return <Input {...props}/>
			}
		}
	}

	const filterAutocomplete = () => {
		let list = []
		if (!field || !field.extra?.list) return list

		if (field.extra?.list_parent) {
			if (form.getFieldValue(field.extra.list_parent) && field.extra.list[form.getFieldValue(field.extra.list_parent)]) {
				list = [...field.extra.list[form.getFieldValue(field.extra.list_parent)]].map(item => ({ value: item }))
			}
		} else {
			list = [...field.extra.list].map(item => ({ value: item }))
		}
		return list
	}

	const isHidden = () => {
		if (typeof hidden === "function") {
			return hidden(field, form.getFieldsValue())
		}
		return hidden
	}

	if (!field) return null

	return (
		<Form.Item
			noStyle
			shouldUpdate
		>
			{() => (
				<Form.Item
					required={field.required}
					validateStatus={form.getFieldError(field.name).length ? "error" : undefined}
					help={form.getFieldError(field.name).length > 0 && form.getFieldError(field.name).join("\n")}
					name={field.name}
					label={title ?? field.title}
					hidden={isHidden()}
					{...props}
				>
					{
						children ?? renderInput()
					}
				</Form.Item>
			)}
		</Form.Item>

	)
}

export default observer(FormItem)

const Wrapper = styled.div``