import { __extends } from "tslib";
import { registerGeometryLabel, GeometryLabel } from '@antv/g2';
// Step 1
// 自定义 Label 类
// 需要继承 GeometryLabel 基类
var VennLabel = /** @class */ (function (_super) {
    __extends(VennLabel, _super);
    function VennLabel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 获取每个 label 的位置
     * @param labelCfg
     * @param mappingData
     * @param index
     * @returns label point
     */
    VennLabel.prototype.getLabelPoint = function (labelCfg, mappingData, index) {
        var _a = labelCfg.data, x = _a.x, y = _a.y;
        var _b = labelCfg.customLabelInfo, offsetX = _b.offsetX, offsetY = _b.offsetY;
        return {
            content: labelCfg.content[index],
            x: x + offsetX,
            y: y + offsetY,
        };
    };
    return VennLabel;
}(GeometryLabel));
// Step 2: 注册 CustomLabel
registerGeometryLabel('venn', VennLabel);
