import {observer} from "mobx-react-lite"
import styled from "styled-components"
import {MapboxMarker} from "../../../component/Mapbox"
import CarIcon from "../../../store/helper/CarIcon"

const WIDTH = 40
const HEIGHT = 36.6

const CarMarker = ({
					   item,
					   hide = false,
					   width = WIDTH,
					   height = HEIGHT,
					   ...props
				   }) => {

	if (hide) return null
	if (!item?.driver?.coord?.latitude || !item?.driver?.coord?.longitude) return null


	return (
		<MapboxMarker
			latitude={item.driver.coord.latitude}
			longitude={item.driver.coord.longitude}
			anchor={"center"}
		>
			<Wrapper {...props}>
				<DoneInfo>{item.countReadyPoints.ready} из {item.countReadyPoints.all}</DoneInfo>
				<div style={{cursor: "pointer"}}>
					<CarIcon heading={item.driver.coord.heading}/>
				</div>
			</Wrapper>
		</MapboxMarker>
	)
}

export default observer(CarMarker)

const Wrapper = styled.div``
const DoneInfo = styled.div`
  padding: 3px;
  background: #FFD61330;
  color: #FFD613;
`
