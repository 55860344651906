import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import PointStatus from "../../../store/helper/PointStatus"
import dayjs from "dayjs"
import {prettyMoney, prettyTimePeriod} from "../../../store/helper/StringMethod"
import Icon from "../../../component/Icon";
import {faMapMarkerSlash} from "@fortawesome/pro-regular-svg-icons/faMapMarkerSlash"

const PointItem = ({point, setActivePoint}) => {

	return (
		<>
			<PointListItem onClick={() => {
				setActivePoint(point)
			}}>
				<PointInfo>
					<PointName>
						{point.position}. {point.name}
					</PointName>
					<PointAddress color={!point.address_location ? '#FF9500' : null}>
						{
							!point.address_location && <Icon name={faMapMarkerSlash} color={'#FF9500'} size={14}/>
						}
						{point.address}
					</PointAddress>
				</PointInfo>
				<PointPhoto>
					{
						point.media?.length ? (
							<span>{point.media?.length} фото</span>
						) : null
					}
				</PointPhoto>
				<PointTime>
					{prettyTimePeriod(point.from_at, point.to_at)}
				</PointTime>
				{/*<PointMoney>
					<div>{point.countCargo.total} позиций</div>
					<div style={{color: point.countCargo.changed ? '#FF9500' : '#FFFFFF'}}>
						{prettyMoney(point.countCargo.priceFact)}
					</div>
				</PointMoney>*/}
				<PointCurrentStatus color={PointStatus[point.status].color}>
					{PointStatus[point.status].name}
					{
						point.status !== 'created' && (
							dayjs(point.updated_at).format('[ в ]HH:mm')
						)
					}
				</PointCurrentStatus>
			</PointListItem>
		</>
	)
}

export default observer(PointItem)


const PointListItem = styled.div`
  display: flex;
  flex: 1;
  padding: 25px;
  flex-direction: row;
  border-bottom: dashed 1px #303030;
  cursor: pointer;

  :hover {
    background: #ffffff03;
  }
`
const PointInfo = styled.div`
  flex: .8;
`
const PointPhoto = styled.div`
  flex: .1;
  opacity: .7;
`

const PointTime = styled.div`
  flex: .4;
  text-align: center;
  opacity: .7;
`
const PointMoney = styled.div`
  flex: .4;
  text-align: left;
  opacity: .7;
`
const PointCurrentStatus = styled.div`
  width: 140px;
  text-align: end;
  justify-content: flex-end;
  color: ${props => props.color};
  opacity: .8;
`

const PointName = styled.div`
  font-weight: bold;
  opacity: .9;
`
const PointAddress = styled.div`
  opacity: .7;
  font-size: 13px;
  color: ${props => props.color};
`
