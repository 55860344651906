/**
 * @fileoverview 多边形
 * @author dxq613@gmail.com
 */
import { __extends } from "tslib";
import ShapeBase from './base';
import inPolyline from '../util/in-stroke/polyline';
import isInPolygon from '../util/in-path/polygon';
var Polygon = /** @class */ (function (_super) {
    __extends(Polygon, _super);
    function Polygon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Polygon.prototype.isInStrokeOrPath = function (x, y, isStroke, isFill, lineWidth) {
        var points = this.attr().points;
        var isHit = false;
        if (isStroke) {
            isHit = inPolyline(points, lineWidth, x, y, true);
        }
        if (!isHit && isFill) {
            isHit = isInPolygon(points, x, y); // isPointInPath(shape, x, y);
        }
        return isHit;
    };
    Polygon.prototype.createPath = function (context) {
        var attrs = this.attr();
        var points = attrs.points;
        if (points.length < 2) {
            return;
        }
        context.beginPath();
        for (var i = 0; i < points.length; i++) {
            var point = points[i];
            if (i === 0) {
                context.moveTo(point[0], point[1]);
            }
            else {
                context.lineTo(point[0], point[1]);
            }
        }
        context.closePath();
    };
    return Polygon;
}(ShapeBase));
export default Polygon;
