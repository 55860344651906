import { HolderOutlined } from "@ant-design/icons"
import { Checkbox, Col, Drawer, Form, Row, Space, Typography } from "antd"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import styled from "styled-components"

const TableSettings = ({
	setSettingVisible,
	settingVisible,
	table = null,
}) => {

	useEffect(() => {
	}, [table.getColumns])

	const onDragEnd = result => {
		table.setColumns(reorder(toJS(table.getColumns), result.source.index, result.destination.index))
	}

	const reorder = (list, from, to) => {
		const result = Array.from(list)
		const [removed] = result.splice(from, 1)
		result.splice(to, 0, removed)

		return result
	}

	return (
		<Drawer
			closable={true}
			title="Настройки таблицы"
			placement="right"
			width={350}
			onClose={() => setSettingVisible(false)}
			visible={settingVisible}
		>
			<Block>
				<Title>Столбцы:</Title>
				<DragDropContext
					onDragEnd={onDragEnd}
				>
					<Droppable droppableId="droppable">
						{provided => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{
									table.getColumns.map(({ column, title, checked }, index) => {
										return (
											<Draggable key={"column-" + column} draggableId={column} index={index}>
												{provided => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}

													>
														<div
															style={{ lineHeight: 2 }}
														>
															<HolderOutlined
																{...provided.dragHandleProps}
																style={{ cursor: "move" }}
															/>
															<Checkbox
																checked={checked}
																style={{ marginLeft: 5 }}
																onChange={event => table.setColumnChecked(column, event.target.checked)}
															>
																{title}
															</Checkbox>
														</div>
													</div>
												)}
											</Draggable>
										)
									})
								}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Block>
		</Drawer>
	)
}

export default observer(TableSettings)

const Wrapper = styled.div``
const Block = styled.div`
  margin-bottom: 30px;
`
const Title = styled.div`
  margin-bottom: 4px;
  font-size: 16px;
`