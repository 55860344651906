export default {
    fontFamily: "\n  BlinkMacSystemFont, \"Segoe UI\", Roboto,\"Helvetica Neue\",\n  Helvetica, \"PingFang SC\", \"Hiragino Sans GB\", \"Microsoft YaHei\",\n  SimSun, \"sans-serif\"",
    textColor: '#2C3542',
    activeTextColor: '#333333',
    uncheckedColor: '#D8D8D8',
    lineColor: '#416180',
    regionColor: '#CCD7EB',
    verticalAxisRotate: -Math.PI / 4,
    horizontalAxisRotate: Math.PI / 4,
    // descriptionIcon theme
    descriptionIconStroke: '#fff',
    descriptionIconFill: 'rgba(58, 73, 101, .25)',
};
