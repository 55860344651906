import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Date from "../../component/Date"
import DriverLine from "../../component/DriverLine"
import PageHeader from "../../component/PageHeader"
import TTTable from "../../component/TTTable"
import { useStore } from "../../store/Context"
import RouteStatus from "../../store/helper/RouteStatus"
import { useNavigate } from "react-router-dom"
import ModalRouteView from "./ModalRouteView"

const RouteArchiveScreen = ({}) => {

	const store = useStore()
	const navigate = useNavigate()

	const [showInfoModal, setShowInfoModal] = useState(false)
	const [activeRoute, setActiveRoute] = useState(null)

	const columns = [
		{
			dataIndex: "date",
			render: (text, record) => <Date date={record.date}/>,
		},
		{
			dataIndex: "driver",
			render: (text, record) => (
				<DriverLine
					driver={record.driver}
					withLink={true}
				/>
			),
		},
		{
			dataIndex: "status",
			render: (text, record) => (
				<Status background={RouteStatus[record.status].color}>
					<StatusText>{RouteStatus[record.status].name}</StatusText>
				</Status>
			),
			renderFilter: (text, record) => (
				<Status>
					<StatusSquare background={RouteStatus[record.status].color}/>
					<StatusText>{RouteStatus[record.status].name}</StatusText>
				</Status>
			),
		},
	]

	return (
		<Wrapper>
			<PageHeader
				title={<>
					<span>Маршрутные листы</span>
					<span style={{ fontSize: 14, color: "#666", marginLeft: 10 }}>Архив</span>
				</>}
			/>
			<ContentWrapper>
				<TTTable
					defaultColumns={["name", "date", "driver", "tags", "status"]}
					data={store.route.archive}
					columns={columns}
					canExport={true}
					onclick={(item) => {
						setActiveRoute(item)
						setShowInfoModal(true)
					}}
				/>
			</ContentWrapper>
			{
				showInfoModal && (
					<ModalRouteView
						route={activeRoute}
						onCancel={() => {
							setShowInfoModal(false)
							setActiveRoute(null)
						}}
					/>
				)
			}
		</Wrapper>
	)
}

export default observer(RouteArchiveScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const Driver = styled.a`
  color: #6190C6;
`
const Status = styled.span`
  color: ${props => props.background};
`
const StatusText = styled.span``
const StatusSquare = styled.span`
  background: ${props => props.background};
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 3px;
  margin-right: 7px;
  display: inline-block;
  margin-bottom: -1px;
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
