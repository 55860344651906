import {types} from "mobx-state-tree"
import {CargoItem} from "./CargoItem"

export const InvoiceItem = types.model({
	id: types.identifierNumber,
	name: types.maybeNull(types.string),
	reason: types.maybeNull(types.string),
	updated_at: types.maybeNull(types.string),
	barcode_at: types.maybeNull(types.string),
	cargos: types.optional(types.array(CargoItem), []),
}).actions(self => {
	return {}
}).views(self => ({}))
