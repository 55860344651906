import React, {useState} from "react"
import {observer} from "mobx-react-lite"
import {
	Form, Input, Button
} from "antd"
import {
	ClockCircleOutlined
} from '@ant-design/icons'
import styled from "styled-components"

const SettingManagerTab = () => {

	const [emails, setEmails] = useState('')

	return (
		<Wrapper>
			<Form
				labelCol={{span: 8}}
				wrapperCol={{span: 16}}
			>
				<Form.Item extra="В это всемя все незакрытые МЛ будут закрыты."
						   label="Принудительное закрыте МЛ"
						   name="requiredMarkValue">
					<Input
						suffix={
							<ClockCircleOutlined/>
						}
						defaultValue={'23:00'}
						disabled
						style={{width: 180}}
					/>
				</Form.Item>
				<Form.Item label="Телефон ответственного" extra="Телефон доступен водителям.">
					<Input
						defaultValue={'+7 (800) 700-52-73'}
						disabled
						style={{width: 180}}
					/>
					{/*<MaskedInput
						//value={phone}
						//onChange={(e) => setPhone(e.target.value)}
						prefix="+7"
						mask="(111) 111 11 11"
						size="10"
						style={{width: 180}}
					/>*/}
				</Form.Item>
				<Form.Item extra="Укажите список email адресов через ↵"
						   label="Адреса для отчётов"
						   name="zazaza">
					<Input.TextArea
						placeholder="Email адреса"
						autoSize={{minRows: 3, maxRows: 25}}
						style={{width: 280}}
						value={emails}
						onChange={({target: {value}}) => {
							setEmails({value})
						}}
					/>
				</Form.Item>
				<Form.Item>
					<Button type="primary" loading={false}>Сохранить</Button>
				</Form.Item>
			</Form>
		</Wrapper>
	)
}

export default observer(SettingManagerTab)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
