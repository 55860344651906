import {observer} from "mobx-react-lite"
import {applySnapshot} from "mobx-state-tree"
import {useStore} from "../store/Context"
import {useBroadcast} from "../store/SocketStore"
import {useEffect, useState} from "react"
import dayjs from "dayjs"
import {gpsCalculateHeading} from "../store/helper/GpsMath"

const GlobalSocketUpdate = ({}) => {

	const store = useStore()
	const [date] = useState(dayjs().format("DD"))
	useEffect(() => {
		countDown()
	}, [])

	const countDown = () => {
		if (dayjs().format("DD") !== date) {
			window.location.reload(false)
		}
		setTimeout(() => {
			countDown()
		}, 1000)
	}

	const syncRoute = (data) => {
		if (data.date === store.routing.activeDate) {

			let routeData = {...data}
			routeData.driver_id = data.driver?.id
			delete routeData.driver
			delete routeData.points

			let _routeIndex = store.routing.routes.findIndex(_route => _route.id === data.id)
			if (_routeIndex === -1) {
				store.routing.addRoute(routeData)
			} else {
				store.routing.updateRoute(routeData)
			}

			data.points.map((pointData) => {
				let point = {...pointData}
				store.routing.points.filter(_point => _point.route_id === data.id).map(point => {
					if (data.points.findIndex(_pointItem => _pointItem.id === point.id) === -1) {
						store.routing.setRouteId(point.id, 0)
					}
				})

				let _pointIndex = store.routing.points.findIndex(_point => _point.id === point.id)
				if (_pointIndex === -1) {
					store.routing.addPoint(point)
				} else {
					store.routing.updatePoint(point)
				}
			})
		}
	}

	useBroadcast(async (data, event, channel) => {
			console.log(event, data, channel)
			if (!data) return

			switch (event) {
				case "route.add":
				case "route.publish": {
					if (!data.draft && data.date === dayjs().format("YYYY-MM-DD")) {
						let _routeIndex = store.route.actual.items.findIndex(_route => _route.id === data.id)
						if (_routeIndex === -1) {
							store.route.actual.setItem(data)
						}
					}

					syncRoute(data)
					break
				}
				case "route.update": {
					if (!data.draft) {
						let _routeIndex = store.route.actual.items.findIndex(_route => _route.id === data.id)
						if (_routeIndex !== -1) {
							store.route.actual.updateRoute(data)
						}
					}

					syncRoute(data)
					break
				}

				case "route.remove": {
					store.routing.removeRoute(data.id)
					break
				}

				case "route.status": {
					store.route.actual.updateRouteStatus(data.id, data.status, data.updated_at)
					store.routing.updateRouteStatus(data.id, data.status, data.updated_at)
					break
				}

				case "route.distance": {
					if (!data.draft) {
						let _route = store.route.actual.items.find(_route => _route.id === data.id)
						if (_route) {
							_route.setGeo(data.geo)
						}
					}

					let _route = store.routing.routes.find(_item => _item.id === data.id)
					if (_route) store.routing.setRouteGeo(data.id, data.geo)

					break
				}


				////Точки

				case "point.add": {
					if (data.route_id) {
						let _routeIndex = store.route.actual.items.findIndex(_route => _route.id === data.id)
						if (_routeIndex !== -1) store.route.actual.setItem(data)
					}

					if (dayjs(data.from_at).format("YYYY-MM-DD") === store.routing.activeDate) {
						let _pointIndex = store.routing.points.findIndex(_point => _point.id === data.id)
						if (_pointIndex === -1) {
							store.routing.addPoint(data)
						} else {
							store.routing.updatePoint(data)
						}
					}
					break
				}

				case "point.update": {
					if (data.route_id) {
						const route = store.route.actual.items.find(item => item.id === data.route_id)
						if (route) {
							const point = route.points?.find(item => item.id === data.id)
							if (point) {
								applySnapshot(point, {...point, ...data})
							}
						}
					}

					if (dayjs(data.from_at).format("YYYY-MM-DD") === store.routing.activeDate) {
						let _pointIndex = store.routing.points.findIndex(_point => _point.id === data.id)
						if (_pointIndex !== -1) {
							store.routing.updatePoint(data)
						} else {
							store.routing.addPoint(data)
						}
					}

					break
				}

				case "point.status": {
					store.route.actual.updatePointStatus(data.id, data.status, data.updated_at)
					store.routing.updatePointStatus(data.id, data.status, data.updated_at)
					break
				}

				case "point.location": {
					if (data.route_id) {
						let _route = store.route.actual.items.find(_item => _item.id === data.route_id)
						if (_route) {
							let _point = _route.points.find(_point => _point.id === data.id)
							if (_point) _point.updateLocation(data.address_location)
						}
					}

					if (dayjs(data.date).format("YYYY-MM-DD") === store.routing.activeDate) {
						store.routing.updatePointLocation(data.id, data.address_location)
					}

					break
				}

				/*case "point.update.cargo": {
					const route = store.route.actual.items.find(item => item.id === data.route_id)
					if (route) {
						const point = route.points?.find(item => item.id === data?.point_id)
						if (point) {
							const invoice = point.invoices?.find(item => item.id === data?.invoice_id)
							if (invoice) {
								const cargo = invoice.cargos?.find(item => item.id === data?.id)
								if (cargo) {
									const upData = {
										count_fact: data.count_fact,
										price_fact: data.price_fact,
									}
									applySnapshot(cargo, {...cargo, ...upData})
								}
							}
						}
					}
					break
				}*/


				case "driver.location": {
					const route = store.route.actual.items.filter(item => item.driver?.id === data.driver_id)
					route.map(item => {
						if (item.driver?.coord?.latitude && item.driver?.coord?.longitude) {
							data.location.heading = gpsCalculateHeading({
								a: item.driver.coord,
								b: data.location,
							})
						}
						applySnapshot(item.driver.coord, data.location)
					})
					break
				}

				case "transport.update": {
					if (data.id) {
						const transport = store.transport.items.find(item => item.id === data.id)
						if (transport) {
							applySnapshot(transport, {...transport, ...data})
						}
					}
					break
				}



				////Марштутизация
				/*case "routing.add":
				case "routing.point.add": {
					if (data && dayjs(data.from_at).format("YYYY-MM-DD") === store.routing.activeDate) {
						let _pointIndex = store.routing.points.findIndex(_point => _point.id === data.id)
						if (_pointIndex === -1) {
							store.routing.addPoint(data)
						}
					}
					break
				}
				case "routing.route.add": {
					if (data && dayjs(data.date).format("YYYY-MM-DD") === store.routing.activeDate) {
						let _routeIndex = store.routing.routes.findIndex(_route => _route.id === data.id)
						if (_routeIndex === -1) {
							store.routing.addRoute(data)

							data.points.map((pointId, index) => {
								store.routing.setRouteId(pointId, data.id, index + 1)
							})
						}
					}
					break
				}
				case "routing.route.update": {
					if (data && dayjs(data.date).format("YYYY-MM-DD") === store.routing.activeDate) {
						store.routing.updateRoute(data)

						store.routing.points.filter(_point => _point.route_id === data.id).map(point => {
							if (data.points.indexOf(point.id) === -1) {
								store.routing.setRouteId(point.id, 0)
							}
						})

						data.points.map((pointId, index) => {
							store.routing.setRouteId(pointId, data.id, index + 1)
						})
					}
					break
				}
				case "routing.route.remove": {
					if (data) {
						store.routing.removeRoute(data.id)
					}
					break
				}
				case "routing.point.remove": {
					if (data) {
						store.routing.removePoint(data.id)
					}
					break
				}

				case "routing.point.status": {
					store.routing.updatePointStatus(data.id, data.status, data.updated_at)
					break
				}*/

				/*case "driver.add": {
					if (data) {
						let _routeIndex = store.route.actual.items.findIndex(_route => _route.id === data.id)
						if (_routeIndex === -1) {
							store.route.actual.setItem(data)
						}

						syncRoute(data)
					}
					break
				}*/

				/*case "routing.point.add": {
					if (data) {
						let _pointIndex = store.routing.points.findIndex(_point => _point.id === data.id)
						if (_pointIndex === -1) {
							store.routing.addPoint(data)
						}
					}
					break
				}*/
			}
		}
		,
		"all",
	)

	return null
}

export default observer(GlobalSocketUpdate)
