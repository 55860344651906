/**
 * 是否真实的是数字
 * @param v
 */
export function isRealNumber(v) {
    return typeof v === 'number' && !isNaN(v);
}
/**
 * @ignore
 * Determines whether between is
 * @param value
 * @param start
 * @param end
 * @returns true if between
 */
export function isBetween(value, start, end) {
    var min = Math.min(start, end);
    var max = Math.max(start, end);
    return value >= min && value <= max;
}
