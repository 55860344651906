import React from "react"
import { StyleSheet } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Platform } from "react-native-web"

const Icon = ({ size, name, color, style, spin = false }) => {

	const webStyles = StyleSheet.flatten([style, Platform.OS === "web" && { width: size, height: size }])

	return (
		<FontAwesomeIcon
			icon={name}
			color={color ? color : "#C8C4BD"}
			style={webStyles}
			spin={spin}
		/>
	)
}
export default Icon
