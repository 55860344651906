import {observer} from "mobx-react-lite"
import React, {useEffect, useMemo, useState} from "react"
import {useStore} from "../../../store/Context"
import {Mapbox, MapboxMarker} from "../../../component/Mapbox"
import styled from "styled-components"

const TerminalMap = ({mapRef}) => {

	const store = useStore()

	const [activeRoute, setActiveRoute] = useState(null)

	return (
		<Mapbox
			ref={mapRef}
			initialViewState={{
				longitude: store.user.office.region_map_settings?.lng ?? 37.5877594,
				latitude: store.user.office.region_map_settings?.lat ?? 55.7421473,
				zoom: store.user.office.region_map_settings?.zoom ?? 9.2,
			}}
			maxZoom={18}
			style={{width: "100%", height: "100%"}}
			onLoad={() => mapRef.current.resize()}
		>
			{/*{
				routes.map((_route, _index) => {
					let color = RouteColors[Math.floor(Math.random() * ((RouteColors.length - 1) - 0 + 1)) + 0]
					return (
						<>
							{
								_route.stops.map(_point => (
									<MapboxMarker
										latitude={_point.location_metadata.supplied_coordinate[0]}
										longitude={_point.location_metadata.supplied_coordinate[1]}
										anchor={"center"}
									>
										<Box width={15} height={15} color={color}/>
									</MapboxMarker>
								))
							}
						</>
					)
				})
			}*/}
		</Mapbox>
	)
}

export default observer(TerminalMap)

const PopHeader = styled.div`
  display: flex;
  flex: 1;
  min-width: 200px;
  font-size: 13px;
`
const PointName = styled.div`
  flex: 1;
  font-size: 14px;
  padding-right: 20px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
`
const PointTime = styled.div`
  color: #FF8C00;
`
const PopAddress = styled.div`

`
const OutOfMap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #FF9500;
  font-weight: 500;

  div {
    position: absolute;
    text-shadow: 0px 0px 2px #000000;
  }
`

