import {applySnapshot, types} from "mobx-state-tree"
import {InvoiceItem} from "./item"
import {Driver, Routing} from "../network"
import dayjs from "dayjs"
import RouteColors from "./helper/RouteColors";

const DriverItem = types.model({
	id: types.identifierNumber,
	login: types.maybeNull(types.string),
	name: types.maybeNull(types.string),
	middlename: types.maybeNull(types.string),
	surname: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	auto_brand: types.maybeNull(types.string),
	auto_number: types.maybeNull(types.string),
	count: types.number
})

const Address = types.model({
	lng: types.number,
	lat: types.number
})

const Geo = types.model({
	distance: types.maybeNull(types.number),
	duration: types.maybeNull(types.number),
	geometry: types.maybeNull(types.string),
	status: types.maybeNull(types.string)
})

const PointStatistic = types.model({
	count: types.maybeNull(types.number),
	cubic: types.maybeNull(types.number),
	price: types.maybeNull(types.number),
	square: types.maybeNull(types.number),
	weight: types.maybeNull(types.number)
})

export const RouteItem = types.model({
	id: types.number,
	driver_id: types.maybeNull(types.number),
	name: types.maybeNull(types.string),
	date: types.maybeNull(types.string),
	status: types.enumeration(["created", "accepted", "performed", "done", "canceled:driver", "canceled:manager", "canceled:api"]),
	color: types.maybeNull(types.string),
	in_work: types.maybeNull(types.boolean),
	draft: types.maybeNull(types.boolean),
	locked: types.maybeNull(types.boolean),
	updated_at: types.maybeNull(types.string),
	need_background_location: types.maybeNull(types.boolean),
	geo: types.maybeNull(Geo),
	comment: types.maybeNull(types.string)
}).views(self => ({
	get isCanceled () {
		return self.status.indexOf("canceled") !== -1
	},
}))

export const PointItem = types.model({
	id: types.identifierNumber,
	external_id: types.maybeNull(types.string),
	route_id: types.maybeNull(types.number),
	status: types.enumeration(["created", "on_way", "arrived", "done", "canceled:driver", "canceled:manager", "canceled:api"]),
	type: types.maybeNull(types.enumeration(["point", "warehouse"])),
	name: types.maybeNull(types.string),
	address: types.maybeNull(types.string),
	address_location: types.maybeNull(Address),
	space: types.maybeNull(types.number),
	weight: types.maybeNull(types.number),
	client_name: types.maybeNull(types.string),
	client_contact: types.maybeNull(types.string),
	client_data: types.maybeNull(types.string),
	position: types.maybeNull(types.integer),
	from_at: types.maybeNull(types.string),
	to_at: types.maybeNull(types.string),
	updated_at: types.maybeNull(types.string),
	media: types.maybeNull(types.array(types.string)),
	statistic: types.maybeNull(PointStatistic),
	invoices: types.optional(types.array(InvoiceItem), []),
	comment: types.maybeNull(types.string)
}).views(self => ({
	get isCanceled () {
		return self.status.indexOf("canceled") !== -1
	},
}))

export const RoutingStore = types.model({
	routes: types.optional(types.array(RouteItem), []),
	points: types.optional(types.array(PointItem), []),
	drivers: types.optional(types.array(DriverItem), []),

	activeDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
	//activeDate: '2022-07-13',
	refreshedAt: dayjs().valueOf(),
	activeRoute: types.maybeNull(types.number),
	activePoint: 0,
	loading: false,
	syncLoading: false,
	activePolyline: types.maybeNull(types.string),
	pointHover: types.maybeNull(types.number),
	routeHover: types.maybeNull(types.number),
	/*	statMaxCount: 0,
		statMaxWeight: 0,
		statMaxCubic: 0,
		statMaxSquare: 0,
		statMaxPrice: 0,*/
	selectedPoints: types.optional(types.array(types.model({
		id: types.identifierNumber
	})), [])
}).actions(self => ({
	async fetch (data) {
		self.setLoading(true)
		self.routes = []
		self.points = []

		let _drivers = await Driver.routesDate(data)
		if (_drivers.code === 200) {
			self.setDrivers(_drivers.data.sort((a, b) => a.surname?.localeCompare(b.surname)))

			let result = await Routing.date(data)
			if (result.code === 200) {

				result.data.points.map(item => {
					if (item.address_location && item.address_location?.lat && item.address_location?.lng) {
						let double = result.data.points.findIndex(
							el => el.address_location?.lat === item.address_location.lat &&
								el.address_location?.lng === item.address_location.lng &&
								el.id !== item.id,
						)
						if (double !== -1) {
							item.address_location.lat += (Math.random() * (10 - 4.5) / 10000)
							item.address_location.lng += (Math.random() * (10 - 4.5) / 10000)
						}
					}

					/*item.statistic.count = Math.floor(Math.random() * 55)
					item.statistic.weight = Math.floor(Math.random() * 2500)
					item.statistic.cubic = Math.floor(Math.random() * 10)
					item.statistic.square = Math.floor(Math.random() * 400)
					item.statistic.price = Math.floor(Math.random() * 100000)*/

					/*self.setStatData('statMaxCount', item.statistic.count)
					self.setStatData('statMaxWeight', item.statistic.weight)
					self.setStatData('statMaxCubic', item.statistic.cubic)
					self.setStatData('statMaxSquare', item.statistic.square)
					self.setStatData('statMaxPrice', item.statistic.price)*/
				})

				self.setPoints(result.data.points.sort((a, b) => (b.address_location?.lat - a.address_location?.lat)))

				result.data.routes = result.data.routes.sort((a, b) => {
					return a.position - b.position
				}).map(route => {
					if (!route.color) route.color = RouteColors[Math.floor(Math.random() * ((RouteColors.length - 1) - 0 + 1)) + 0]
					return route
				})

				self.setRoutes(result.data.routes)
			}
		}

		self.setLoading(false)
	},
	setSelectedPoints (data) {
		self.selectedPoints = data
	},
	/*setStatData (item, data) {
		if (data > self[item]) self[item] = data
	},*/
	refreshList () {
		self.refreshedAt = dayjs().valueOf()
	},
	setActiveDate (date) {
		self.activeDate = date
	},
	setActiveRoute (routeId) {
		self.activeRoute = routeId
	},
	setActivePoint (pointId) {
		self.activePoint = pointId
	},
	setPointHover (data) {
		self.pointHover = data
	},
	setRouteHover (data) {
		self.routeHover = data
	},
	setLoading (loading) {
		self.loading = loading
	},
	setSyncLoading (loading) {
		self.syncLoading = loading
	},
	setPoints (items) {
		self.points = items
		self.refreshList()
	},
	addPoint (point) {
		if (point) {
			self.points.push(point)
			self.refreshList()
		}
	},
	setRouteId (pointId, routeId, position = 0) {
		const _point = self.points.find(_item => _item.id === pointId)
		if (_point) {
			_point.route_id = routeId
			_point.position = position
			self.refreshList()
		}
	},
	setActivePolyline (data) {
		self.activePolyline = data
	},
	setRoutes (items) {
		self.routes = items
		self.refreshList()
	},
	setDrivers (drivers) {
		self.drivers = drivers
	},
	addRoute (data) {
		self.routes.push(data)
		self.refreshList()
	},
	updateRoute (data) {
		let _route = self.routes.find(_item => _item.id === data.id)
		if (_route) {
			applySnapshot(_route, data)
			self.refreshList()
		}
	},
	updateRouteStatus (routeId, status, time) {
		let _route = self.routes.find(_item => _item.id === routeId)
		if (_route) {
			_route.status = status
			_route.updated_at = time
			self.refreshList()
		}
	},
	setRouteDraft (routeId, draft) {
		let _route = self.routes.find(_item => _item.id === routeId)
		if (_route) {
			_route.draft = draft
			self.refreshList()
		}
	},
	setRouteLocked (routeId, locked) {
		let _route = self.routes.find(_item => _item.id === routeId)
		if (_route !== -1) {
			_route.locked = locked
			self.refreshList()
		}
	},
	setRouteStatus (routeId, status) {
		let _route = self.routes.find(_item => _item.id === routeId)
		if (_route) {
			_route.status = status
			self.refreshList()
		}
	},
	setRouteGeo (routeId, geo) {
		let _route = self.routes.find(_route => _route.id === routeId)
		if (_route) {
			_route.geo = geo
			if (self.activeRouteId === _route.id && geo) {
				self.activePolyline = geo.geometry
				self.refreshList()
			}
		}
	},
	updatePointStatus (pointId, status, time = dayjs().format("YYYY-MM-DD HH:mm:ss")) {
		let _point = self.points.find(_item => _item.id === pointId)
		if (_point) {
			_point.status = status
			_point.updated_at = time
			self.refreshList()
		}
	},
	updatePointLocation (pointId, location) {
		let _point = self.points.find(_item => _item.id === pointId)
		if (_point) {
			_point.address_location = location
			self.refreshList()
		}
	},
	removeRoute (routeId, deleteInnerPoints = false) {
		if (self.activeRouteId === routeId) {
			self.activeRouteId = 0
		}
		self.points.filter(_point => _point.route_id === routeId).map(point => {
			if (deleteInnerPoints) {
				self.removePoint(point.id)
			} else {
				self.setRouteId(point.id, null)
			}
		})

		self.routes.splice(self.routes.findIndex(_item => _item.id === routeId), 1)
		self.refreshList()
	},

	removePoint (pointId) {
		self.points.splice(self.points.findIndex(item => item.id === pointId), 1)
		self.refreshList()
	},
	recountPositions (routeId) {
		let _data = self.points.filter(_item => _item.route_id === routeId)
		if (_data && _data.length) {
			_data.sort((a, b) => {
				return a.position - b.position
			}).map((_point, _index) => {
				let _pointIndex = self.points.findIndex(_sPoint => _sPoint.id === _point.id)
				if (_pointIndex !== -1) self.points[_pointIndex].position = _index + 1
			})
			self.refreshList()
		}
	},
	updatePointPosition (pointId, position) {
		let _point = self.points.find(_item => _item.id === pointId)
		if (_point) {
			_point.position = position
			self.refreshList()
		}
	},
	updatePoint (data) {
		let _point = self.points.find(_item => _item.id === data.id)
		if (_point) {
			applySnapshot(_point, data)
			self.refreshList()
		}
	},
	routeStatistic (routeId) {
		let result = {duration: 0, distance: 0, points: 0, count: 0, cubic: 0, price: 0, square: 0, weight: 0}
		self.points.filter(_point => _point.route_id && _point.route_id === routeId).map(_point => {
			result.points++
			result.count += _point.statistic.count
			result.cubic += _point.statistic.cubic
			result.price += _point.statistic.price
			result.square += _point.statistic.square
			result.weight += _point.statistic.weight
		})

		let routeItem = self.routes.find(_route => _route.id === routeId)
		if (routeItem?.geo?.duration) result.duration = routeItem?.geo?.duration
		if (routeItem?.geo?.distance) result.distance = routeItem?.geo?.distance

		return result
	},
})).views(self => ({
	/*get activePoint () {
		if (self.activePointId) {
			let point = self.points.find(_point => _point.id === self.activePointId)
			if (point) return point
		}

		return null
	},*/
	get sortedItems () {
		return self.points.slice().sort((a, b) => {
			return b.address_location?.lat - a.address_location?.lat
		})
	},
	get donePoints () {
		let count = 0
		self.points.forEach(_item => {
			if (_item.route_id) count++
		})

		return count
	},
	getRouteById (routeId) {
		let _data = self.routes.find(_item => _item.id === routeId)
		return _data ? _data : null
	},
	getRoutePoints (routeId) {
		if (!routeId) return []
		let _data = self.points.filter(_item => _item.route_id === routeId).sort((a, b) => {
			return a.position - b.position
		})
		return _data ? _data : []
	},
	getRoutePointsIndexes (routeId) {
		let data = []

		self.getRoutePoints(routeId)?.sort((a, b) => {
			return a.position - b.position
		})?.map(_point => {
			data.push(_point.id)
		})

		return data

	},
	getDriver (driverId) {
		if (!driverId) return null
		let _data = self.drivers.find(_driver => _driver.id === driverId)
		return _data ? _data.surname + " " + _data.name : null
	},
	lastPointGps (routeId) {
		let _data = self.getRoutePoints(routeId)
		if (_data.length) {
			return _data.sort((a, b) => {
				return b.position - a.position
			})[0].address_location
		}
		return {lat: 0, lng: 0}
	},
	/*lastPointGps (routeId) {
		let _points = self.points.filter(_point => _point.route_id === routeId)
		if (_points.length) {
			console.log({..._points[_points.length - 1].address_location})
			return _points[_points.length - 1].address_location
		}
		return {lat: 0, lng: 0}
	},*/

}))

export default RoutingStore
