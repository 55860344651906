import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const PageHeader = ({ height = "auto", title, children }) => {

	const navigate = useNavigate()

	const titleContent = useMemo(() => {
		if (Array.isArray(title)) {
			const result = []

			title.forEach((item, index) => {
				result.push(<div key={"item-" + index}>{item}</div>)
				if (title[index + 1]) {
					result.push(<Slash key={"splash-" + index}>/</Slash>)
				}
			})

			return result
		}
		return title
	}, [title])

	return (
		<Wrapper height={height}>
			<TitleWrapper>
				{titleContent}
			</TitleWrapper>
			{children}
		</Wrapper>
	)
}

export default observer(PageHeader)

const Wrapper = styled.div`
  padding: 0 24px;
  font-size: 20px;
  background-color: #242525;
  position: relative;
  height: ${props => typeof props.height === "number" ? props.height + "px" : props.height};

  a {
    color: #999;
    position: relative;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    text-decoration-color: #666;

    &:hover {
      color: #e2e2e2;
      text-decoration: none;
    }
  }
`
const TitleWrapper = styled.div`
  height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Slash = styled.div`
  color: #444;
  margin: 0 10px 0 12px;
  font-size: 30px;
`