import {observer} from "mobx-react-lite"
import React, {useEffect, useMemo, useState} from "react"
import {useStore} from "../../../store/Context"
import {Button, Form, Input, message, Modal, Switch, TimePicker} from "antd"
import styled from "styled-components"
import DatePicker from "../../../component/antd/DatePicker"
import {Point, Routing} from "../../../network"
import dayjs from "dayjs";

const ModalMovePoint = ({
							setShowMovePointModal
						}) => {

	const store = useStore()

	const activePoint = useMemo(() => {
		return store.routing.points.find(_point => _point.id === store.routing.activePoint)
	}, [store.routing.activePoint])

	const [move, setMove] = useState(false)
	const [date, setDate] = useState(null)
	const [time, setTime] = useState([dayjs(activePoint.from_at), dayjs(activePoint.to_at)])
	const [comment, setComment] = useState('')
	const [loading, setLoading] = useState(false)

	const sendForm = async () => {
		setLoading(true)
		setTimeout(async () => {
			if (move) {
				if (date && dayjs(dayjs().subtract(1, 'day')).isBefore(date, 'day')) {
					let data = {
						id: activePoint.id,
						eject: true,
						date: dayjs(date).format('YYYY-MM-DD'),
						from_at: time?.length ? dayjs(time[0]).format('HH:mm') : '00:00',
						to_at: time?.length ? dayjs(time[1]).format('HH:mm') : '00:00',
						comment: comment
					}

					let result = await Routing.pointMove(data)
					if (result.code === 200) {
						store.routing.updatePointStatus(activePoint.id, 'canceled:manager')
						////Временное решение пока нет сокетов
						if (store.routing.activeDate === dayjs(result.data.from_at).format('YYYY-MM-DD')) {
							store.routing.addPoint(result.data)
						}
					}
				} else {
					message.warning('Новая дата должна быть на сегодня или позже', 2)
					setLoading(false)
					return
				}
			} else {
				let result = await Point.editStatus({
					id: activePoint.id,
					status: 'canceled:manager'
				})
				if (result.code === 200) {
					store.routing.updatePointStatus(activePoint.id, result.data.status)
				}
			}
			setLoading(false)
			handleCancel()
		}, 500)
	}

	const handleCancel = () => {
		setShowMovePointModal(false)
	}

	if (!activePoint) return null


	return (
		<Modal
			title={'Отмена и перенос точки'}
			visible={true}
			width={500}
			maskClosable={false}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Закрыть
				</Button>,
				<Button danger loading={loading} key="submit" type="primary" onClick={sendForm}>
					{
						move ? (
							'Отменить и перенести'
						) : (
							'Отменить точку'
						)
					}
				</Button>
			]}
		>
			<FormWrapper
				labelCol={{span: 6}}
				wrapperCol={{span: 18}}
				colon={false}
			>
				<Form.Item name={"move"} label="Перенести">
					<Switch checked={move} onChange={setMove}/>
				</Form.Item>
				<div style={{height: 180}}>
					{
						move ? (
							<>
								<Form.Item name={"date"} label="Дата">
									<DatePicker onChange={setDate}
												format={"DD.MM.YYYY"}
												allowClear
												placeholder='Выбрать дату'/>
								</Form.Item>
								<Form.Item name={"time"} label="Время">
									<TimePicker.RangePicker onChange={setTime}
															defaultValue={[dayjs(activePoint.from_at), dayjs(activePoint.to_at)]}
															format={'HH:mm'}
															minuteStep={5}/>
								</Form.Item>
								<Form.Item name={"comment"} label="Комментарий">
									<Input
										allowClear
										onChange={(e) => setComment(e.target.value)}
									/>
								</Form.Item>
							</>
						) : (
							<>
								<Form.Item name={""} label=" ">
									<span>При отмене точки у вас есть возможность склонировать её на другой день. Для это вам необходимо выбрать дату и по желанию скорректировать время окна.</span>
								</Form.Item>
							</>
						)
					}
				</div>
			</FormWrapper>
		</Modal>
	)
}

export default observer(ModalMovePoint)

const FormWrapper = styled(Form)`

`
