class config {
	axios = {
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			"X-App": "web",
			"X-Locale": "ru-RU",
		},
		timeout: 5000,
	}
}

export default new config()