import {
	ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined,
	ExclamationCircleOutlined, LoadingOutlined,
	MinusOutlined,
	PlusOutlined, RotateLeftOutlined, RotateRightOutlined,
	VerticalAlignMiddleOutlined,
} from "@ant-design/icons"
import { Button, message, Modal } from "antd"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import Viewer from "react-viewer"
import axios from "../../../inizialize/Axios"

const TransportDocumentMedia = ({ transport, document }) => {

	const [files, setFiles] = useState([])
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState(0)
	const [loading, setLoading] = useState(0)
	const uploadRef = useRef(null)

	useEffect(() => {
		setFiles(document.getMedia)
	}, [document.getMedia])

	const fileList = useMemo(() => {
		return files.map(file => ({
			uid: file,
			status: "done",
			alt: "",
			url: process.env.REACT_APP_API_URL + "/media/uuid/" + file + "/thumb",
			src: process.env.REACT_APP_API_URL + "/media/uuid/" + file,
		}))
	}, [files])

	const onRemove = file => {
		Modal.confirm({
			title: "Уверены?",
			icon: <ExclamationCircleOutlined/>,
			content: "Уверены, что хотите удалить этот файл?",
			okText: "Да",
			cancelText: "Нет",
			onOk: async () => {
				const result = await axios.post("/v1/transport/" + transport.id + "/document/" + document.id + "/media/" + file.uid + "/remove")
				if (result.success) {
					setPreviewImage(0)
					setPreviewVisible(false)
					document.updateMedia(result.data)
				}
			},
		})
	}

	const beforeUpload = async event => {
		const formData = new FormData()
		for (let i = 0; i < event.target.files.length; i++) {
			formData.append("file[]", event.target.files[i])
		}
		setLoading(true)
		const result = await axios.post("/v1/transport/" + transport.id + "/document/" + document.id + "/media/upload", formData)
		setLoading(false)
		if (result.success) {
			document.updateMedia(result.data)
		} else {
			message.error("Не удалось загрузить файл")
		}
		return false
	}

	return (
		<Wrapper>
			<PreviewWrapper>
				<UploadWrapper onClick={() => !loading && uploadRef.current.click()}>
					{
						loading ? <LoadingOutlined/> : (
							<span>
								Добавить изображение
							</span>
						)
					}
				</UploadWrapper>
				{
					fileList.map((item, index) => {
						if (fileList.length > 2 && index === 1) {
							return <More
								key={"more"}
								onClick={() => {
									setPreviewImage(1)
									setPreviewVisible(true)
								}}
								count={fileList.length - 1}
								background={item.url}
							/>
						}
						if (index > 1) {
							return
						}
						return (
							<img
								onClick={() => {
									setPreviewImage(index)
									setPreviewVisible(true)
								}}
								key={"image-" + item.uid}
								width={100}
								src={item.url}
								style={{
									border: "1px solid #444",
									borderRadius: 5,
									marginRight: 7,
								}}
							/>
						)
					})
				}
			</PreviewWrapper>
			<Viewer
				visible={previewVisible}
				onClose={() => { setPreviewVisible(false) }}
				activeIndex={previewImage}
				images={fileList}
				totalName={"из"}
				customToolbar={(defaultConfig) => {
					return [
						{
							key: "plus",
							actionType: 1,
							render: <Button type={"primary"}><PlusOutlined/></Button>,
						},
						{
							key: "minus",
							actionType: 2,
							render: <Button type={"primary"}><MinusOutlined/></Button>,
						},
						{
							key: "reset",
							actionType: 7,
							render: <Button type={"primary"}>100%</Button>,
						},
						{
							key: "prev",
							onClick: () => setPreviewImage(previewImage => previewImage > 0 ? previewImage - 1 : fileList.length - 1),
							render: <Button type={"primary"}><ArrowLeftOutlined/></Button>,
						},
						{
							key: "next",
							onClick: () => setPreviewImage(previewImage => previewImage < fileList.length - 1 ? previewImage + 1 : 0),
							render: <Button type={"primary"}><ArrowRightOutlined/></Button>,
						},
						{
							key: "rotateLeft",
							actionType: 5,
							render: <Button type={"primary"}><RotateLeftOutlined/></Button>,
						},
						{
							key: "rotateRight",
							actionType: 6,
							render: <Button type={"primary"}><RotateRightOutlined/></Button>,
						},
						{
							key: "scaleX",
							actionType: 9,
							render: <Button type={"primary"}><VerticalAlignMiddleOutlined
								rotate={90}/></Button>,
						},
						{
							key: "scaleY",
							actionType: 10,
							render: <Button type={"primary"}><VerticalAlignMiddleOutlined/></Button>,
						},
						{
							key: "delete",
							render: <Button type={"primary"} danger><DeleteOutlined/></Button>,
							onClick: () => onRemove(fileList[previewImage]),
						},
					]
				}}
			/>
			<FileUpload
				multiple accept={"image/*"}
				onChange={beforeUpload}
				type={"file"}
				name={"file"}
				ref={uploadRef}
			/>
		</Wrapper>
	)
}

export default observer(TransportDocumentMedia)

const Wrapper = styled.div`
`

const FileUpload = styled.input`
  display: none;
  position: absolute;
`
const PreviewWrapper = styled.div`
  margin: 10px 0 6px;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
  }
`
const UploadWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  margin-right: 7px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  cursor: pointer;
  background: #292929;
  color: #888;
  text-align: center;
  transition: all ease-out .2s;

  &:hover {
    background-color: #2c2c2c;
    border-color: #2c2c2c;
    color: #e2e2e2;
  }

  .anticon {
    font-size: 18px;
    margin-bottom: 5px;
  }
`
const More = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 5px;
  position: relative;
  vertical-align: middle;

  &:before {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    content: "";
    background: url(${props => props.background}) no-repeat 50% 50%;
    background-size: cover;
    opacity: .4;
  }

  &:after {
    content: "+${props => props.count}";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }
`