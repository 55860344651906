import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PageHeader from "../../component/PageHeader"
import RoadNumber from "../../component/RoadNumber"
import TransportLine from "../../component/TransportLine"
import { useStore } from "../../store/Context"
import { useNavigate } from "react-router-dom"
import TTTable from "../../component/TTTable"
import ModalTransportForm from "./Modal/ModalTransportForm"

const TransportScreen = ({}) => {

	const store = useStore()
	const navigate = useNavigate()

	const [showFormModal, setShowFormModal] = useState(false)

	useEffect(() => {
		store.transport.fetch()
	}, [])

	const columns = [
		{
			title: "Модель",
			dataIndex: "model",
			render: (text, record) => <TransportLine transport={record}/>,
		},
		{
			title: "ГосНомер",
			dataIndex: "gosnumber",
			render: (text, record) => (<RoadNumber number={record.gosnumber}/>),
		},
	]

	return (
		<Wrapper>
			<PageHeader
				title={"Транспорт"}
			/>
			<ContentWrapper>
				<TTTable
					defaultColumns={["model", "name", "gosnumber", "tags", "created_at"]}
					data={store.transport}
					columns={columns}
					addItem={{ title: "Добавить транспорт", setShowFormModal }}
					onclick={(item) => navigate("/transport/" + item.id)}
				/>
			</ContentWrapper>
			<ModalTransportForm
				visible={showFormModal}
				onCancel={() => {
					setShowFormModal(false)
				}}
				api={"/v1/transport/create"}
				onSuccess={() => {
					setShowFormModal(false)
					store.transport.fetch()
				}}
				data={{
					owner_type: "App\\Models\\Office",
				}}
			/>
		</Wrapper>
	)
}

export default observer(TransportScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding: 20px 20px 20px 20px;
`
