import { __spreadArrays } from "tslib";
import { Util } from '@antv/g2';
var ORIGIN_MATRIX = [1, 0, 0, 0, 1, 0, 0, 0, 1];
/**
 * 矩阵变换
 * @param actions
 * @param matrix
 */
export function transform(actions, matrix) {
    var ulMatrix = matrix ? __spreadArrays(matrix) : __spreadArrays(ORIGIN_MATRIX);
    return Util.transform(ulMatrix, actions);
}
