import config from "../config"
import axios from "../inizialize/Axios"

export const Reference = {
	async minimum () {
		return axios.get("/v1/reference/minimum", {noAuth: true})
	},
	minimumUrl () {
		return config.axios.baseURL + "/user/v1/reference/minimum"
	},
}

export const User = {
	async info () {
		return axios.get("/v1/profile/info")
	},
	async login (data) {
		return axios.post("/v1/profile/login", data)
	},
	async updateSettings (data) {
		return axios.post("/v1/profile/settings", data)
	}
}
export const Route = {
	async list (data) {
		return axios.get("/v1/route/list", {params: data})
	},
	async actual (data) {
		return axios.get("/v1/route/actual", {params: data})
	},
	async getRoute (data) {
		return axios.get("/v1/route/refresh/" + data.id)
	},
	async getPoints (data) {
		return axios.get("/v1/route/refresh/" + data.id)
	},
	async editStatus (data) {
		return axios.post("/v1/route/status/" + data.id, data)
	},
	async routeGpsHistory (data) {
		return axios.get("/v1/route/geometry_driver/" + data.routeId)
	},
	async routeGeometry (data) {
		return axios.get("/v1/route/geometry/" + data.routeId)
	},
	async getLogs (data) {
		return axios.get("/v1/route/logs/" + data.routeId)
	},
}
export const Point = {
	async getPoint (data) {
		return axios.get("/v1/point/refresh/" + data.id)
	},
	async update (data) {
		return axios.post("/v1/point/update/" + data.id, data)
	},
	async getLogs (data) {
		return axios.get("/v1/point/logs/" + data.pointId)
	},
	async editStatus (data) {
		return axios.post("/v1/point/status/" + data.id, data)
	},
}
export const Driver = {
	async list (data) {
		return axios.get("/v1/driver/list", {params: data})
	},
	async info (data) {
		return axios.get("/v1/driver/info/" + data.id)
	},
	async add (data) {
		return axios.post("/v1/driver/add", data)
	},
	async edit (data) {
		return axios.post("/v1/driver/edit/" + data.id, data)
	},
	async remove (data) {
		return axios.delete("/v1/driver/remove/" + data.id)
	},
	async routesDate (data) {
		return axios.get("/v1/driver/routes_date", {params: data})
	},
	async geometryRoute (data) {
		return axios.post("/v1/driver/geometry/" + data.driverId + "/route/" + data.routeId, {raw: true})
	},

}

export const Office = {
	async list (data) {
		return axios.get("/v1/office/list", {params: data})
	},
	async update (data) {
		return axios.post("/v1/office/update/" + data.id, data)
	},
}

export const Routing = {
	async date (data) {
		return axios.get("/v1/routing/" + data.date)
	},
	async create (data) {
		return axios.post("/v1/routing/route/create", data)
	},
	async update (data) {
		return axios.post("/v1/routing/route/update/" + data.id, data)
	},
	async remove (data) {
		return axios.post("/v1/routing/route/remove/" + data.id)
	},
	async pointMove (data) {
		return axios.post("/v1/routing/point/move/" + data.id, data)
	},
	async publish (data) {
		return axios.post("/v1/routing/route/publish/" + data.id)
	},
	async pointsFromExcel (data) {
		return axios.post("/v1/routing/point/excel/", {data})
	},
	async uploadPoints (file, config) {
		const formData = new FormData()
		formData.append("file", file)
		formData.append("type", "routing")

		return axios.post("/v1/routing/upload", formData, config)
	},
}

export const Transport = {
	async list (data) {
		return axios.get("/v1/transport/list", {params: data})
	},
	async info (data) {
		return axios.get("/v1/transport/info/" + data.id)
	},
	async remove (data) {
		return axios.post("/v1/transport/remove/" + data.id)
	},
}


export const External = {
	async weather () {
		return axios.get("https://api.openweathermap.org/data/2.5/onecall?lat=55.74690208076966&lon=37.622790913728096&appid=e6ce70c02b7f0a5675e905cef22d2609&exclude=current,minutely,hourly,alerts&units=metric&lang=ru", {external: true})
	},

	async getRouteDirection (data) {
		return axios.post("https://api.openrouteservice.org/v2/directions/driving-car",
			{geometry_simplify: true, instructions: false, geometry: true, ...data},
			{
				headers: {
					"Accept": "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8",
					"Content-Type": "application/json",
					"Authorization": "5b3ce3597851110001cf624801ec655a37794478bb0af155955b9669",
				},
				external: true,
				noAuth: true,
			},
		)
	},

	async addressSuggest (data) {
		return axios.post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
			{count: 10, ...data},
			{
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": "Token c78cc23dd291dca0ac4ff86a9b2ff43580234486",
				},
				external: true,
				noAuth: true,
			},
		)
	},


}
