import { __extends } from "tslib";
import ListState from './list-state';
/**
 * 图例项和坐标轴文本选中的 Action
 * @ignore
 */
var ListSelected = /** @class */ (function (_super) {
    __extends(ListSelected, _super);
    function ListSelected() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'selected';
        return _this;
    }
    ListSelected.prototype.selected = function () {
        this.setState();
    };
    return ListSelected;
}(ListState));
export default ListSelected;
