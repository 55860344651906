import React, {useEffect, useRef, useState} from "react"
import Icon from "../../component/Icon"
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown"
import {useNavigate} from "react-router-dom"
import {useStore} from "../../store/Context"
import styled from "styled-components"

const MenuItem = ({item, imSub = false, parentKey = null, activeMenu, mini = false}) => {
	const navigate = useNavigate()
	const store = useStore()
	const subMenuRef = useRef(null)

	const [subMenuHeight, setSubMenuHeight] = useState('auto')

	useEffect(() => {
		if (subMenuRef.current && subMenuRef.current.clientHeight) {
			setSubMenuHeight(subMenuRef.current.clientHeight)
		}
	}, [subMenuRef.current])

	if (item.key === "setting" && store.user.office.level !== "director") return null

	return (
		<>
			<MenuItemBlock
				onClick={() => {
					imSub ? navigate(parentKey + "/" + item.key + "/") :
						//!item.subMenu?.length ? navigate(item.key) : alert(123)
						!item.subMenu?.length ? navigate(item.key) : console.log("collapse menu")
				}}
				style={{
					paddingLeft: imSub ? 45 : 20,
					backgroundColor: activeMenu === item.key ? "#177DDC" : "transparent",
					color: activeMenu === item.key ? "#FFFFFF" : item.color && item.color,
				}}>
				{
					item.icon && (
						<MenuIcon>
							{item.icon}
						</MenuIcon>
					)
				}
				<MenuName mini={mini}>
					{item.name}
				</MenuName>

				{
					item.subMenu && (
						<SubMenuArrow>
							<Icon name={faChevronDown} size={10} color="#C8C4BD"/>
						</SubMenuArrow>
					)
				}
				{
					item.counter && (
						<SubMenuArrow>
							{item.counter}
						</SubMenuArrow>
					)
				}
			</MenuItemBlock>
			<SubMenu ref={subMenuRef} mini={mini} height={subMenuHeight}>
				{
					item.subMenu && (
						<>
							{
								item.subMenu.map(submenuItem => (
									<MenuItem
										key={submenuItem.key}
										parentKey={item.key}
										imSub={true}
										item={submenuItem}
										activeMenu={activeMenu}
									/>
								))
							}
						</>
					)
				}
			</SubMenu>
		</>
	)
}

export default MenuItem


const MenuItemBlock = styled.div`
  display: flex;
  flex-direction: row;
  padding: 9px 12.5px 9px 25px;
  margin: 3.5px 0 3.5px 0;
  cursor: pointer;
  width: 230px;

  :hover {
    color: #FFFFFF;
  }
`
const MenuIcon = styled.div`
  padding-right: 10px;
`
const MenuName = styled.div`
  flex: 1;
  transition: .2s linear all;
  opacity: ${props => props.mini ? 0 : 1};
`
const SubMenu = styled.div`
  max-height: ${props => props.mini ? '0px' : (props.height !== 'auto' ? props.height + 'px' : 'auto')};
  overflow: hidden;
  transition: .2s linear all;
`
const SubMenuArrow = styled.div`
  transition: .2s linear all;
  opacity: 1;
`
