import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Button, Card, Col, Drawer, Row, Spin, Table, Progress} from "antd";
import {CaretUpOutlined, InfoCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {Pie, TinyArea} from '@ant-design/plots';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";

const AnalyticsScreen = ({}) => {

	const data = [
		147, 155, 163, 123, 122, 154, 150, 125, 132, 138, 147, 155, 163, 123, 122, 154, 150, 125, 132, 138, 132, 138, 147, 155, 163, 123, 122, 154, 150, 125, 132, 138
	];
	const config = {
		autoFit: true,
		data,
		smooth: true,
	};

	const dataAppVersion = [
		{
			type: '1.0.0',
			value: 27,
		},
		{
			type: '1.0.1',
			value: 25,
		},
		{
			type: '1.0.2',
			value: 18,
		},
		{
			type: '1.1.7',
			value: 15,
		},
		{
			type: '1.2.0',
			value: 10,
		},
		{
			type: '1.2.2',
			value: 5,
		},
	];
	const configAppVersion = {
		data: dataAppVersion,
		angleField: 'value',
		colorField: 'type',
		radius: 0.9,
		label: {
			type: 'inner',
			offset: '-30%',
			content: ({type}) => `${type}`,
			style: {
				fontSize: 11,
				textAlign: 'center',
			},
		},
		interactions: [
			{
				type: 'element-active',
			},
		],
		pieStyle: {
			strokeOpacity: .7,
			stroke: '#7B7975'
		},
		theme: {
			colors10: [
				'#FF6B3B',
				'#626681',
				'#FFC100',
				'#9FB40F',
				'#76523B',
				'#DAD5B5',
				'#0E8E89',
				'#E19348',
				'#F383A2',
				'#247FEA',
			]
		},
		legend: false
	};

	/*const dataTaxes = [
		{
			type: '> 20км/ч',
			value: 27,
		},
		{
			type: '> 40км/ч',
			value: 25,
		},
		{
			type: '> 60км/ч',
			value: 18,
		},
		{
			type: 'Парковка',
			value: 15,
		},
		{
			type: 'ЦКАД',
			value: 10,
		},
		{
			type: 'Другое',
			value: 5,
		},
	];
	const configTaxes = {
		appendPadding: 10,
		data: dataTaxes,
		angleField: 'value',
		colorField: 'type',
		legend: false,
		radius: 1,
		innerRadius: 0.3,
		label: {
			type: 'spider',
			labelHeight: 28,
			content: '{name} ',
			style: {
				fill: '#FFFFFF90'
			}
		},
		tooltip: {
			content: '{name} {percentage}%',
		},
		interactions: [
			{
				type: 'element-selected',
			},
			{
				type: 'element-active',
			},
		],
		pieStyle: {
			strokeOpacity: 0,
			fillOpacity: .8
		},
		statistic: {
			title: false,
			content: {
				content: '',
			},
		},
		theme: {
			colors10: [
				'#FF6B3B',
				'#626681',
				'#FFC100',
				'#9FB40F',
				'#76523B',
				'#DAD5B5',
				'#0E8E89',
				'#E19348',
				'#F383A2',
				'#247FEA',
			],
			styleSheet: {
				borderColor: 'red'
			}
		}
	};*/

	const dataSource = [
		{
			key: '1',
			name: 'Михеев Сергей',
			age: '+7 (926) 958-42-37',
			address: '₽ 14,276',
		},
		{
			key: '2',
			name: 'Фатехов Геннадий',
			age: '+7 (929) 344-22-32',
			address: '₽ 14,276',
		},
		{
			key: '2',
			name: 'Сминов Михаил',
			age: '+7 (916) 611-07-12',
			address: '₽ 14,276',
		},
		{
			key: '2',
			name: 'Жирков Алексей',
			age: '+7 (903) 995-11-07',
			address: '₽ 14,276',
		},
		{
			key: '2',
			name: 'Измайлов Роман',
			age: '+7 (909) 568-41-39',
			address: '₽ 14,276',
		},
		{
			key: '2',
			name: 'Гурцев Иван',
			age: '+7 (910) 332-17-18',
			address: '₽ 14,276',
		},
	];

	const columns = [
		{
			title: 'Имя',
			dataIndex: 'name',
			key: 'name',
			render: text => <a>{text}</a>
		},
		{
			title: 'Телефон',
			dataIndex: 'age',
			key: 'age',
		},
		{
			title: 'Штраф',
			dataIndex: 'address',
			key: 'address',
		},
	];

	const [visible, setVisible] = useState(false)
	const [result, setResult] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showResult, setShowResult] = useState(false)

	useEffect(() => {
		if (result) {
			setLoading(true)
			setTimeout(() => {
				setLoading(false)
				setShowResult(true)
			}, 1500)
		}
	}, [result])


	return (
		<Wrapper>
			<ChartsWrapper>
				<Row gutter={16}>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Количество МЛ</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								146
							</ChartValue>
							<ChartGraphic>
								<TinyArea
									line={{color: '#975FE4'}}
									areaStyle={{fill: 'l(270) 1:#593F7C 0:#242424'}}
									{...config}
								/>
								{/*<div style={{backgroundColor: '#DEE3E9', height: 10}}>
									<div style={{height: '100%', width: '1%', background: '#14BABA'}}/>
								</div>*/}
							</ChartGraphic>
							<ChartDesc>
								ПВ: 94% &nbsp;&nbsp;ЧВ: 6% &nbsp;&nbsp;НВ: 0.1%
							</ChartDesc>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Количество точек</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								1,752
							</ChartValue>
							<ChartGraphic>
								<TinyArea
									line={{color: '#ECB617'}}
									areaStyle={{fill: 'l(270) 1:#ECB617 0:#242424'}}
									{...config}
								/>
								{/*<div style={{backgroundColor: '#DEE3E9', height: 10}}>
									<div style={{height: '100%', width: '1%', background: '#588AEE'}}/>
								</div>*/}
							</ChartGraphic>
							<ChartDesc>
								ПВ: 94% &nbsp;&nbsp;ЧВ: 6% &nbsp;&nbsp;НВ: 0.1%
							</ChartDesc>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Время на точке</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								00:12″
							</ChartValue>
							<ChartGraphic>
								<div style={{position: 'relative', backgroundColor: '#DEE3E9', height: 10}}>
									<div style={{
										position: 'absolute',
										top: -5,
										left: '71%',
										width: 1.5,
										height: 20,
										backgroundColor: '#78a1f2'
									}}/>
									<div style={{height: '100%', width: '74%', background: '#588AEF'}}/>
								</div>
							</ChartGraphic>
							<ChartDesc>
								Среднее за год 00:16″
							</ChartDesc>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false}>
							<ChartTitle>
								<ChartName>Точек на километр</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								0,078
							</ChartValue>
							<ChartGraphic>
								<TinyArea
									line={{color: '#6B5BEF'}}
									areaStyle={{fill: 'transparent'}}
									{...config}
								/>
							</ChartGraphic>
							<ChartDesc>
								Среднее в компании 0%
							</ChartDesc>
						</ChartCard>
					</Col>
				</Row>


				<Row style={{marginTop: 16, height: 580}} gutter={16}>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false} style={{width: '100%'}}>
							<ChartTitle>
								<ChartName>Своевременность</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
							<ChartValue>
								89.2% <CaretUpOutlined style={{color: '#88db57'}}/>
							</ChartValue>
							<ChartGraphic>
								<TinyArea
									line={{color: '#88db57'}}
									areaStyle={{fill: 'l(270) 1:#88db57 0:#242424'}}
									{...config}
								/>
							</ChartGraphic>
							<ChartDesc>
								ПВ: 94% &nbsp;&nbsp;ЧВ: 6% &nbsp;&nbsp;НВ: 0.1%
							</ChartDesc>
						</ChartCard>

						<ChartCard bordered={false} style={{marginTop: 16, width: '100%', height: 364}}>
							<ChartTitle>
								<ChartName>Карта опозданий</ChartName>
								<InfoCircleOutlined/>
							</ChartTitle>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={12}>
						<ChartCard bordered={false} style={{width: '100%', height: '100%'}}>
							<ChartTitle>
								<ChartName>Штрафы ГИБДД</ChartName>
								{/*<ChartBlockComment>Прошлая неделя</ChartBlockComment>*/}
							</ChartTitle>
							<ChartValue>
								₽ 14,276
							</ChartValue>
							<ChartGraphic>
								<TinyArea
									line={{color: '#E24348'}}
									areaStyle={{fill: 'l(270) 1:#E24348 0:#242424'}}
									{...config}
								/>
							</ChartGraphic>

							<Table size={'small'} style={{marginTop: 25}} dataSource={dataSource} columns={columns}/>
						</ChartCard>
					</Col>
					<Col className="gutter-row" span={6}>
						<ChartCard bordered={false} style={{width: '100%', height: '100%'}}>
							<ChartTitle>
								<ChartName>Мобильное приложение</ChartName>
							</ChartTitle>
							<Pie style={{height: 250}} {...configAppVersion} />

							<div>
								Xiaomi
								<Progress style={{top: -10}} percent={44} size="small"/>
							</div>
							<div style={{marginTop: -8}}>
								Samsung
								<Progress style={{top: -10}} percent={23} size="small"/>
							</div>
							<div style={{marginTop: -8}}>
								Apple
								<Progress style={{top: -10}} percent={17} size="small"/>
							</div>
							<div style={{marginTop: -8}}>
								Другие
								<Progress style={{top: -10}} percent={16} size="small"/>
							</div>

							<div style={{marginTop: 30}}>
								Фоновая геолокация
								<Progress
									strokeColor={{
										'0%': '#E75957',
										'100%': '#87d068',
									}}
									size={"small"}
									percent={45}
								/>
							</div>

						</ChartCard>
					</Col>
				</Row>
			</ChartsWrapper>
			{/*{
				visible && (
					<Drawer title="Поиск штрафа" placement="right" visible={visible} onClose={() => {
						setVisible(false);
						setResult(null)
						setShowResult(false)
					}}>
						<BarcodeScannerComponent
							width={'100%'}
							height={'50%'}
							onUpdate={(err, code) => {
								if (code && code !== result) {
									setResult(code.text)
								}
							}}
						/>
						{
							loading && (
								<div style={{width: '100%', textAlign: 'center', alignItems: 'center'}}>
									<Spin size={'large'}/>
								</div>
							)
						}
						{
							showResult && (
								<>
									Номер постановления
									<div>
										<span style={{fontSize: 30}}>{result}</span>
									</div>
									<div style={{marginTop: 25, color: '#88db57', fontSize: 30}}>
										Оплачен
									</div>
								</>
							)
						}
					</Drawer>
				)
			}*/}

		</Wrapper>
	)
}

export default observer(AnalyticsScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding: 20px;
`
const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ChartCard = styled(Card)`
  padding: 20px;
  color: rgba(229, 224, 216, 0.85);
`
const ChartTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  color: rgba(229, 224, 216, 0.45);
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
`
const ChartName = styled.div`
  display: flex;
  flex: 1;
`
const ChartValue = styled.div`
  font-size: 30px;
  font-weight: 300;
`
const ChartGraphic = styled.div`
  margin-top: 15px;
  height: 45px;
  border-bottom: solid 1px #3E4141;
`
const ChartDesc = styled.div`
  margin-top: 10px;
  font-weight: 300;
`

const ChartBlockName = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-transform: uppercase;
`
const ChartBlockComment = styled.div`
  font-size: 12px;
`
