import {observer} from "mobx-react-lite"
import React, {useMemo, useState} from "react"
import {NavLink} from "react-router-dom"
import styled from "styled-components"
import {useStore} from "../../store/Context"
import Profile from "./Profile"
import {Dropdown, Menu} from "antd"
import {CaretDownOutlined} from "@ant-design/icons"

const LeftMenu = () => {
	const store = useStore()

	const [showOffice, setShowOffice] = useState(false)

	const menu = (
		<Menu onMouseLeave={() => setShowOffice(false)}>
			{
				store.user.offices.map(office => (
					<Menu.Item key={office.id} onClick={() => {
						setShowOffice(false)
						store.user.changeOffice(office.id)
					}}>
						{office.name}
					</Menu.Item>
				))
			}
		</Menu>
	)

	return (
		<Wrapper>
			<Company>
				<NavLink to={"/"} style={{display: 'flex', flexDirection: 'row'}}>
					<img style={{maxHeight: 30}}
						 src={process.env.REACT_APP_API_URL + "/media/company/" + store.user.company.slug + "/logo?r=" + Math.random()}/>
					<CompanyName>{store.user.company.name}</CompanyName>
				</NavLink>
				{
					store.user.offices.length && (
						<DropdownWrapper single={store.user.offices.length === 1}>
							<Dropdown
								overlay={menu}
								placement="bottomLeft"
								arrow
								visible={store.user.offices.length > 1 && showOffice}
								onMouseEnter={() => setShowOffice(!showOffice)}
							>
								<OfficeName>
									{store.user.office.name}
									{store.user.offices.length !== 1 && (<>&nbsp;<CaretDownOutlined/></>)}
								</OfficeName>
							</Dropdown>
						</DropdownWrapper>
					)
				}
			</Company>
			<UserWrapper>
				<Profile/>
			</UserWrapper>
		</Wrapper>
	)
}

export default observer(LeftMenu)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  max-height: 48px;
  background-color: #0F1C29;
  flex: 1;
`
const Company = styled.div`
  display: flex;
  flex: 1;
  color: #FFFFFF95;
  align-items: center;

  :hover {
    color: #FFFFFF95;
  }
`
const OfficeName = styled.div`
  font-size: 14px;
`
const CompanyName = styled.div`
  font-size: 19px;
  font-weight: 400;
  margin-left: 10px;
  color: rgb(229, 224, 216);
`
const UserWrapper = styled.div`
  display: flex;
  padding-right: 15px;
  flex-direction: row;
`
const DropdownWrapper = styled.div`
  margin-left: 10px;
  border: 1px solid ${props => props.single ? "transparent" : "#ffffff22"};
  border-radius: 2px;
  padding: 1px 4px 1px 8px;
`
/*const Divider = styled.div`
height: 1px;
max-height: 1px;
background-color: var(--border-1);
margin: 10px 0;
`
const MenuIcon = styled.div`
width: 20px;
height: 20px;
`
const MenuName = styled.div`
flex: 1;
font-size: 19px;
font-weight: 300;
margin-left: 10px;
color: var(--text-3);
`
const MenuItem = styled(NavLink)`
display: flex;
flex-direction: row;
align-items: center;
padding: 5px 20px 6px;
margin: 2px 0;

&:hover {
background: var(--background-main-2);

${MenuName} {
color: var(--text-2);
}
}

&.active {
background: var(--background-active-1);

${MenuName} {
color: var(--text-active-2)
}
}
`*/
