const RouteStatus = {
	/*	undefined: {
			name: "Без статуса",
			color: "#FF9500",
			button: {
				background: '#472a00',
				border: '#9e5c00',
			},
			striped: false,
		},*/
	created: {
		name: "Отправлен",
		color: "#FF9500",
		button: {
			background: '#472a00',
			border: '#9e5c00',
			action: [{name: 'Завершить', newStatus: 'done', color: '#88db57'}, {
				name: 'Отменить',
				newStatus: 'canceled:manager',
				color: '#E75957'
			}]
		},
	},
	accepted: {
		name: "Подтвержден",
		color: "#C9C5BE",
		button: {
			background: '#272827',
			border: '#454847',
			action: [{name: 'Завершить', newStatus: 'done', color: '#88db57'}, {
				name: 'Отменить',
				newStatus: 'canceled:manager',
				color: '#E75957'
			}]
		},
	},
	performed: {
		name: "Исполняется",
		color: "#409FE7",
		button: {
			background: '#113545',
			border: '#155780',
			action: [{name: 'Завершить', newStatus: 'done', color: '#88db57'}, {
				name: 'Отменить',
				newStatus: 'canceled:manager',
				color: '#E75957'
			}]
		},
	},
	done: {
		name: "Завершен",
		color: "#88db57",
		button: {
			background: '#272F18',
			border: '#4C7825'
		},
		striped: true,
	},
	"canceled:manager": {
		name: "Отменили",
		color: "#E75957",
		button: {
			background: '#40160F',
			border: '#731A10',
			action: [{name: 'Отправить снова', newStatus: 'created', color: '#FF9500'}]
		},
		striped: true,
	},
	"canceled:api": {
		name: "Отменили",
		color: "#E75957",
		button: {
			background: '#40160F',
			border: '#731A10',
			action: [{name: 'Отправить снова', newStatus: 'created', color: '#FF9500'}]
		},
		striped: true,
	},
	"canceled:driver": {
		name: "Отклонён",
		color: "#E75957",
		button: {
			background: '#40160F',
			border: '#731A10',
			action: [{name: 'Отправить снова', newStatus: 'created', color: '#FF9500'}]
		},
		striped: true,
	},
}
export default RouteStatus
