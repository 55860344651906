const RouteColors = [
	'#B22222',
	'#88DB57',
	'#DC143C',
	'#FF6347',
	'#FF7F50',
	'#CD5C5C',
	'#FF4500',
	'#FF8C00',
	'#FFA500',
	'#FFD700',
	'#BDB76B',
	'#808000',
	'#FFFF00',
	'#9ACD32',
	'#7CFC00',
	'#ADFF2F',
	'#228B22',
	'#00FF00',
	'#90EE90',
	'#00FA9A',
	'#00FF7F',
	'#20B2AA',
	'#00FFFF',
	'#6495ED',
	'#00BFFF',
	'#1E90FF',
	'#4169E1',
	'#8A2BE2',
	'#6A5ACD',
	'#7B68EE',
	'#9370DB',
	'#9932CC',
	'#BA55D3',
	'#EE82EE',
	'#D2691E',
	'#CD853F',
	'#F4A460',
	'#FFE4B5'
]
export default RouteColors
