import { __extends } from "tslib";
import { getSpline } from '../util';
import PathMask from './path';
/**
 * Smooth path mask
 * @ignore
 */
var SmoothPathMask = /** @class */ (function (_super) {
    __extends(SmoothPathMask, _super);
    function SmoothPathMask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // 生成 mask 的路径
    SmoothPathMask.prototype.getMaskPath = function () {
        var points = this.points;
        return getSpline(points, true);
    };
    return SmoothPathMask;
}(PathMask));
export default SmoothPathMask;
