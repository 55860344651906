import {observer} from "mobx-react-lite"
import {Layer, Source} from "react-map-gl"
import styled from "styled-components"
import polyline from "@mapbox/polyline"

const PolylineRouting = ({
							 color,
							 width,
							 opacity,
							 path,
						 }) => {

	const data = {
		'type': 'FeatureCollection',
		'features': [
			{
				'type': 'Feature',
				'properties': {},
				'geometry': {
					'coordinates': polyline.toGeoJSON(path).coordinates,
					'type': 'LineString'
				}
			}
		]
	}
	return (
		<Source type="geojson" data={data} lineMetrics={true}>
			<Layer
				beforeId={"point"}
				type="line"
				source="my-data"
				paint={{
					"line-color": color,
					"line-width": width,
					"line-opacity": opacity,
					'line-gradient': [
						'interpolate',
						['linear'],
						['line-progress'],
						0,
						'#C9C5BE',
						1,
						'lime'
					]
				}}
				layout={{
					'line-cap': 'round',
					'line-join': 'round'
				}}
			/>
		</Source>
	)
}

export default observer(PolylineRouting)

const Wrapper = styled.div``
