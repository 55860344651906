import { AutoComplete, Avatar, Input, Radio, Select } from "antd"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { FormItem, ModalForm } from "../../../component/ModalForm"
import DriverListItem from "./component/DriverListItem"
import axios from "../../../inizialize/Axios"

const ModalTransportForm = ({
	type = "create",
	data = {},
	...props
}) => {

	const [transport, setTransport] = useState([])
	const [brand, setBrand] = useState("")
	const [transportLoading, setTransportLoading] = useState(false)
	const [transportTags, setTransportTags] = useState([])

	useEffect(() => {
		loadTransport()
		loadTransportTags()
	}, [])

	const loadTransport = async () => {
		setTransportLoading(true)
		const result = await axios.get("/v1/reference/autos")
		setTransportLoading(false)
		if (result.success) {
			setTransport(result.data)
		}
	}

	const loadTransportTags = async () => {
		const result = await axios.get("/v1/tags/transport")
		if (result.success) {
			setTransportTags(result.data)
		}
	}

	const renderAutoType = () => (
		<Radio.Group
			options={[
				{ label: "Легковые", value: "cars" },
				{ label: "Коммерческие", value: "commercial" },
			]}
			buttonStyle="solid"
			optionType="button"
		/>
	)

	const renderBrandName = item => {
		let type = "Легковые"
		switch (item.type) {
			case "lcv": {
				type = "Лёгкие коммерческие"
				break
			}
			case "truck": {
				type = "Коммерческие"
				break
			}
			case "artic": {
				type = "Тягачи"
				break
			}
		}
		return (
			<BrandWrapper>
				<Avatar src={process.env.REACT_APP_API_URL + "/media/auto_logos/" + item.name}/>
				<BrandName>{item.name}</BrandName>
				<BrandType>{type}</BrandType>
			</BrandWrapper>
		)
	}

	const transportBrand = useMemo(() => {
		return transport.map(item => ({
			item,
			key: "brand-" + item.id,
			value: item.id.toString(),
			label: renderBrandName(item),
		}))
	}, [transport])

	const transportModel = useMemo(() => {
		if (brand) {
			const find = transport.find(item => item.id === brand)
			if (find) {
				return [...find.models.map(item => ({
					item,
					key: "model-" + item.id,
					value: item.name,
					label: item.name,
				}))]
			}
		}
		return []
	}, [brand])

	return (
		<ModalForm
			defaultData={data}
			modificator={data => {
				if (data.tags) {
					data.tags = data.tags.map(item => item.name)
				}
				return data
			}}
			{...props}
		>
			{({ form }) => (
				<>
					<FormItem name={"external_id"}/>
					<FormItem name={"name"}/>
					<FormItem name={"brand"}>
						<AutoComplete
							options={transportBrand}
							filterOption={(inputValue, option) =>
								option.item.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
								option.item.name_ru.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
								option.item.name_en.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
							}
							onSelect={(value, option) => {
								form.setFieldValue("brand", option.item.name)
								setBrand(option.item.id)
							}}
						>
							<Input.Search/>
						</AutoComplete>
					</FormItem>
					<FormItem name={"model"}>
						<AutoComplete
							options={transportModel}
							filterOption={(inputValue, option) =>
								option.item.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
								option.item.name_ru.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
								option.item.name_en.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
							}
						>
							<Input.Search/>
						</AutoComplete>
					</FormItem>
					<FormItem name={"owner_type"}>
						<Radio.Group
							options={[
								{ label: "Компания", value: "App\\Models\\Office" },
								{ label: "Водитель", value: "App\\Models\\Driver" },
							]}
							optionType="button"
							buttonStyle="solid"
							onChange={event => {
								if (event.target.value === "App\\Models\\Driver") {
									form.setFieldsValue({
										owner_id: null,
									})
								}
							}}
						/>
					</FormItem>
					<FormItem
						name={"owner_id"}
						hidden={(field, form) => form.owner_type !== "App\\Models\\Driver"}
						listItemRender={driver => <DriverListItem driver={driver}/>}
					/>
					<FormItem name={"sts"}/>
					<FormItem name={"pts"}/>
					<FormItem name={"insure"}/>
					<FormItem name={"gosnumber"}/>
					<FormItem name={"vin"}/>
					<FormItem name={"tags"}>
						<Select
							mode={"tags"}
							options={transportTags.map(item => ({ value: item.name, label: item.name }))}
						/>
					</FormItem>
				</>
			)}
		</ModalForm>
	)
}

export default observer(ModalTransportForm)

const Wrapper = styled.div``
const BrandWrapper = styled.div``
const BrandName = styled.span`
  margin-left: 10px;
`
const BrandType = styled.span`
  margin-left: 10px;
  color: #999;
`