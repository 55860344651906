import {Table} from "antd"
import dayjs from "dayjs"
import {observer} from "mobx-react-lite"
import {useEffect, useMemo, useRef, useState} from "react"
import styled from "styled-components"
import {useWindowHeight} from "@react-hook/window-size"

const StepData = ({data, next}) => {

	const windowHeight = useWindowHeight()
	const wrapperRef = useRef()
	const [pageSize, setPageSize] = useState(10)
	const [tableWidth, setTableWidth] = useState("auto")

	useEffect(() => {
		if (wrapperRef.current) {
			const height = wrapperRef.current?.clientHeight
			setTableWidth(wrapperRef.current?.clientWidth)
			setPageSize(Math.floor(height / 47) - 2)
		}
	}, [data, windowHeight, wrapperRef])

	const cargos = useMemo(() => {
		let count = 0

		data.map(point => count = count + point.invoices[0].cargos.length)

		return count
	}, [data])

	return (
		<Wrapper>
			<InfoWrapper>
				<Info>
					<Small>Дата</Small>
					<Big color={"orange"} size={3}>{
						data[0] ?
							dayjs(data[0].from_at).format("DD.MM.YYYY")
							: '-------'
					}</Big>
				</Info>
				<Info>
					<Small>Всего грузов</Small>
					<Big color={"orange"}>{cargos}</Big>
				</Info>
				<Info>
					<Small>Построено точек</Small>
					<Big>{data.length}</Big>
				</Info>
			</InfoWrapper>
			<TableWrapper ref={wrapperRef}>
				<Table
					columns={[
						{
							title: "Точка",
							key: "name",
							dataIndex: "name",
							ellipsis: true,
						},
						{
							title: "Адрес",
							key: "address",
							dataIndex: "address",
							ellipsis: true,
						},
						{
							title: "Грузов",
							key: "count",
							width: 70,
							align: "right",
							render: (text, record) => record.invoices[0].cargos.length,
						},
					]}
					rowKey='key'
					dataSource={[...data].map((item, index) => ({...item, key: index}))}
					size={"small"}
					pagination={{
						pageSize,
						size: "small",
						showSizeChanger: false,
					}}
				/>
			</TableWrapper>
		</Wrapper>
	)
}

export default observer(StepData)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
`
const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
const Info = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  flex-direction: column;
`
const TableWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex: 3;
`
const Small = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.5em;
`
const Big = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  font-size: ${props => props.size ?? 5}em;
  color: ${props => props.color ?? "#1f9d55"};
`
