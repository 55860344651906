import {types} from "mobx-state-tree"
import {Route} from "../../network"
import {RouteItem} from "../item"
import dayjs from "dayjs"

const RouteActual = types.model({
	loading: false,
	items: types.optional(types.array(RouteItem), []),
	_active: 0,
	activeRoute: 0,
	refreshedAt: dayjs().valueOf()
}).actions(self => ({
	async fetch () {
		self.setLoading(true)
		const list = await Route.actual()
		if (list.success) {
			self.setItems(list.data)
			/*if (list.data.length && !self.active) {
				console.log(getRoot(self))
				self.setActive(list.data[0].id)
			}*/
		}
		self.setLoading(false)
	},
	async clean () {
		self.items = []
	},
	setActive (id) {
		self._active = id
	},
	setActiveRoute (id) {
		self.activeRoute = id
	},
	setLoading (loading) {
		self.loading = loading
	},
	setItem (item) {
		self.items.push(item)
	},
	setItems (items) {
		self.items = items
	},
	getRouteById (id) {
		return self.items.find(item => item.id === id)
	},
	updateRouteStatus (routeId, status, time) {
		let _routeIndex = self.items.findIndex(_route => _route.id === routeId)
		if (_routeIndex !== -1) {
			self.items[_routeIndex].status = status
			self.items[_routeIndex].updated_at = time
		}
	},
	updatePointStatus (pointId, status, time) {
		self.items.map(route => {
			let point = route.points.find(_point => _point.id === pointId)
			if (point) {
				point.status = status
				point.updated_at = time
				return
			}
		})
	}
})).views(self => ({
	routeFilter (filter, withActive = false) {
		let result = []
		switch (filter) {
			case "performed":
			case "created":
			case "done": {
				result = self.current.filter(item => item.status === filter)
				break
			}
			case "canceled": {
				result = self.current.filter(item => item.status === "canceled:driver" || item.status === "canceled:manager")
				break
			}
			default: {
				result = self.current
				break
			}
		}

		if (withActive) {
			if (self.active && result.findIndex(item => item.id === self.active.id) === -1) {
				result.push(self.active)
			}
		}

		return result
	},
	routeFastFilter (data, filters, searchText) {
		let result = data
		let afterFilters = {
			fromStatus: [],
			fromTags: []
		}

		if (filters.length) {
			let diffArray = []

			filters.map(item => {
				if (item.type === 'status') {
					diffArray.push(item.name)
				}
			})

			afterFilters.fromStatus = result.filter(route => diffArray.includes(route.status))

			diffArray = []
			filters.map(item => {
				if (item.type === 'tag') {
					diffArray.push(item.name)
				}
			})

			afterFilters.fromTags = result.filter(route => {
				let checkTag = route.tags?.findIndex(tag => {
					return diffArray.includes(tag.id)
				})
				return checkTag !== -1
			})

			result = afterFilters.fromStatus.concat(afterFilters.fromTags)
		} else {
			if (searchText.length) {
				result = result.filter(route =>
					route.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
					route.driver?.stripName?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
			}
		}

		return result
	},
	get current () {
		return self.items.filter(item => dayjs(item.date).isBefore(dayjs())).sort((a, b) => a.driver?.surname?.localeCompare(b.driver?.surname))
	},
	get future () {
		return self.items.filter(item => item.date === dayjs().add(1, "day").format("YYYY-MM-DD")).sort((a, b) => a.driver?.surname?.localeCompare(b.driver?.surname))
	},
	get futureStat () {
		let result = {
			total: self.future.length,
			canceled: self.future.filter(item => ["canceled:driver", "canceled:manager"].includes(item.status)).length,
			created: self.future.filter(item => item.status === "created").length,
		}
		return result
	},
	get active () {
		return self.items.find(item => item.id === self._active)
	},
	get tagList () {
		const result = []
		self.items.map(item => {
			if (item.tags?.length) {
				item.tags.map(tag => {
					let check = result.findIndex(ritem => ritem.id === tag.id)
					if (check === -1) {
						result.push(tag)
					}
				})
			}
		})

		return result
	}
}))

export default RouteActual
