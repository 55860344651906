var ANIMATIONS_MAP = {};
/**
 * 根据名称获取对应的动画执行函数
 * @param type 动画函数名称
 */
export function getAnimation(type) {
    return ANIMATIONS_MAP[type.toLowerCase()];
}
/**
 * 注册动画执行函数
 * @param type 动画执行函数名称
 * @param animation 动画执行函数
 */
export function registerAnimation(type, animation) {
    ANIMATIONS_MAP[type.toLowerCase()] = animation;
}
