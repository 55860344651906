import { createContext, useContext } from "react"

const FieldContext = createContext(null)

export const FieldProvider = ({ value, children }) => (
	<FieldContext.Provider value={value}>{children}</FieldContext.Provider>
)

export const useFields = (field, props) => {
	const data = useContext(FieldContext)
	if (!data) throw new Error("useFields must be used within a FieldProvider")
	if (field) {
		return data.fields?.find(item => item.name === field) ?? { ...props, name: field }
	}
	return data
}
