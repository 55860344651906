import { Avatar } from "antd"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const DriverLine = ({
	driver,
	avatar = true,
	avatarSize = 24,
	textSize = 15,
	withLink = false,
}) => {

	const navigate = useNavigate()

	const name = useMemo(() => {
		const value = [];
		["surname", "name", "middlename"].map(item => driver[item] && value.push(driver[item]))
		return value.join(" ")
	}, [driver.surname, driver.name, driver.middlename])

	const onClick = event => {
		navigate("/driver/" + driver.id)
		event.stopPropagation()
	}

	const LinkWrapper = ({ children }) => {
		if (withLink) {
			return <Link onClick={onClick}>{children}</Link>
		}
		return children
	}

	return (
		<Wrapper>
			<LinkWrapper>
				{
					avatar && (<AvatarWrapper
						size={avatarSize}
						src={process.env.REACT_APP_STATIC_URL + "/media/driver/" + driver.login + "/avatar"}
					/>)
				}
				<Name size={textSize}>{name}</Name>
			</LinkWrapper>
		</Wrapper>
	)
}

export default observer(DriverLine)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    color: #ccc;
    text-decoration: underline;
    text-decoration-color: #999;
    transition: all .2s linear;
    text-decoration-thickness: auto;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
`
const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const AvatarWrapper = styled(Avatar)`
  margin-right: 6px;
`
const Name = styled.div`
  font-size: ${props => props.size}px;
`