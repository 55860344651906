import { __extends } from "tslib";
import StateAction from './state';
/**
 * 元素 active 的 Action，允许多个元素同时 active
 * @class
 * @ignore
 */
var ElementActive = /** @class */ (function (_super) {
    __extends(ElementActive, _super);
    function ElementActive() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'active';
        return _this;
    }
    /**
     * Active Element
     */
    ElementActive.prototype.active = function () {
        this.setState();
    };
    return ElementActive;
}(StateAction));
export default ElementActive;
