import { __extends } from "tslib";
import ElementSingleState from './single-state';
/**
 * @ignore
 * 单选的 Action
 */
var ElementSingleSelected = /** @class */ (function (_super) {
    __extends(ElementSingleSelected, _super);
    function ElementSingleSelected() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'selected';
        return _this;
    }
    /**
     * 选中
     */
    ElementSingleSelected.prototype.selected = function () {
        this.setState();
    };
    return ElementSingleSelected;
}(ElementSingleState));
export default ElementSingleSelected;
