import {Modal, Tabs} from "antd"
import {useNavigate} from "react-router-dom"
import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import StatusLabel from "../../../component/StatusLabel"
import {Route} from "../../../network"
import {ExclamationCircleOutlined, TagFilled} from "@ant-design/icons"
import RoutePointsTab from "./RoutePointsTab"
import RouteLogsTab from "./RouteLogsTab"
import {applySnapshot} from "mobx-state-tree"
import MiniLoader from "../../../component/MiniLoader"


const RouteView = ({route, loadPoints = false}) => {

	const navigate = useNavigate()

	const [tab, setTab] = useState('tab-points')
	const [ready, setReady] = useState(false)

	useEffect(() => {
		if (loadPoints) {
			fetchPoints()
		} else {
			setReady(true)
		}
	}, [])

	useEffect(() => {
		setTab('tab-points')
	}, [route])

	const fetchPoints = async () => {
		let result = await Route.getRoute({id: route.id})
		if (result.code === 200) {
			applySnapshot(route, {...route, ...result.data})
			setReady(true)
		}
	}

	const setStatus = async (status) => {
		Modal.confirm({
			title: 'Подтвердите смену статуса',
			icon: <ExclamationCircleOutlined/>,
			content: 'В некоторых случаях возврат к другим статусам будет невозможным.',
			okText: 'Сменить',
			cancelText: 'Отмена',
			okButtonProps: {
				danger: true
			},
			onOk: async () => {
				const result = await Route.editStatus({id: route.id, status: status})
				if (result.success) {
					route.setStatus(result.data)
				}
			}
		})
	}

	return (
		<Wrapper>
			<HeaderWrapper>
				<TitleWrapper>
					<Title>
						{route.name}
						{
							(route.tags?.length) ? route.tags.map((tag, index) => (
								<Tag key={'tag-' + index}>
									<TagFilled height={4} width={4} fill={"#C9C5BE"}
											   style={{opacity: .5, marginRight: 2}}/>
									{tag.name}
								</Tag>
							)) : null
						}
						{
							route.external_id && <ExternalId>{route.external_id}</ExternalId>
						}
					</Title>
					<DriverInfo>
						<DriverName onClick={() => navigate('/driver/' + route.driver.id)}>
							{route.driver?.stripName}
							<sup style={{left: 5, color: '#88db57'}}>0.0</sup>
							<DriverPhone>
								{route.driver?.prettyPhone}
							</DriverPhone>
						</DriverName>
					</DriverInfo>
				</TitleWrapper>
				<TabsWrapper
					onChange={(index) => setTab(index)}
					activeKey={tab}
				>
					<Tabs.TabPane tab="Список точек" key={'tab-points'}/>
					<Tabs.TabPane tab="Журнал изменений" key={'tab-logs'}/>
					<Tabs.TabPane tab="Чек-лист" key={'tab-todo'}/>
				</TabsWrapper>
				<StatusLabelContainer>
					<StatusLabel status={route.status} setStatus={setStatus} time={route.updated_at}/>
				</StatusLabelContainer>
			</HeaderWrapper>
			<ContentWrapper>
				{
					!ready ? (
						<MiniLoader/>
					) : tab === 'tab-points' ? (
						<RoutePointsTab route={route}/>
					) : tab === 'tab-logs' && (
						<RouteLogsTab route={route}/>
					)
				}
			</ContentWrapper>
		</Wrapper>
	)
}

export default observer(RouteView)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #1F1F1F;
  position: relative;
`
const TitleWrapper = styled.div`
  position: relative;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
`
const DriverInfo = styled.div`
  align-items: center;
  cursor: pointer;
`
const DriverName = styled.div`
  margin-left: 5px;
`
const DriverPhone = styled.div`
  font-size: 11px;
  opacity: .7;
  margin-bottom: 10px;
`
const Title = styled.div`
  flex: 1;
  font-size: 22px;
`
const Tag = styled.sup`
  opacity: .7;
  top: -15px;
  font-size: 11px;
  margin-left: 5px;
`
const TabsWrapper = styled(Tabs)`
  position: relative;
  display: flex;
  flex: 1;

  .ant-tabs-nav {
    margin: 0;
    padding: 0 25px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  background-color: #242424;
`
const StatusLabelContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 15px;
`
const ExternalId = styled.div`
  font-size: 12px;
  opacity: .8;
`
