/**
 * 获取当前事件相关的图表元素
 * @param context 交互的上下文
 * @ignore
 */
export function getCurrentElement(context) {
    var event = context.event;
    var element;
    var target = event.target;
    if (target) {
        element = target.get('element');
    }
    return element;
}
