import {faSignOut} from "@fortawesome/pro-regular-svg-icons/faSignOut"
import {faUser} from "@fortawesome/pro-light-svg-icons/faUser"
import {Avatar, Dropdown, Menu, Popconfirm} from "antd"
import {observer} from "mobx-react-lite"
import {useNavigate, NavLink} from "react-router-dom"
import styled from "styled-components"
import Icon from "../../component/Icon"
import {useStore} from "../../store/Context"
import {CaretDownOutlined} from "@ant-design/icons";
import React from "react";

const Profile = ({}) => {

	const store = useStore()
	const navigate = useNavigate()

	const handlerLogout = async e => {
		//await store.user.actionLogout()
		navigate("/", {state: {logout: true}})
	}

	const menu = (
		<Menu>
			<Menu.Item key={"user-menu"}>
				<a onClick={() => handlerLogout()}>
					Выход
				</a>
			</Menu.Item>
		</Menu>
	)

	return (
		<Wrapper>
			<AvatarWrapper>
				{
					store.user.avatar
						? (<Avatar size={24} src={"/media/manager/" + store.user.id + "/avatar"}/>)
						: (<Avatar size={24} icon={<Icon name={faUser}/>}/>)
				}
			</AvatarWrapper>
			<Dropdown overlay={menu}>
				<ProfileName>{store.user.name + ' ' + store.user.surname} <CaretDownOutlined/></ProfileName>
			</Dropdown>
			{/*<ActionWrapper>
				<Popconfirm
					onCancel={stopPropogation}
					onConfirm={handlerLogout}
					title="Уверены？"
					okText="Да"
					cancelText="Нет"
				>
					<ActionExit>
						<Icon name={faSignOut} size={20} color={"#b71e32"}/>
					</ActionExit>
				</Popconfirm>
			</ActionWrapper>*/}
		</Wrapper>
	)
}

export default observer(Profile)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
const AvatarWrapper = styled.div`
  margin-right: 10px;
`
const ProfileName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(229, 224, 216, 0.85);
`
