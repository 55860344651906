import {observer} from "mobx-react-lite"
import CarNorth from "../../assets/svg/van/carNorth.svg"
import CarNorthEast from "../../assets/svg/van/carNorthEast.svg"
import CarEast from "../../assets/svg/van/carEast.svg"
import CarSouthEast from "../../assets/svg/van/carSouthEast.svg"
import CarSouth from "../../assets/svg/van/carSouth.svg"
import CarSouthWest from "../../assets/svg/van/carSouthWest.svg"
import CarWest from "../../assets/svg/van/carWest.svg"
import CarNorthWest from "../../assets/svg/van/carNorthWest.svg"


const CarIcon = ({heading = 0}) => {

	let car = {
		src: CarNorth,
		xy: 35
	}

	if (heading <= 112.5 && heading <= 22.5) {
		car.src = CarNorth
	} else if (heading > 22.5 && heading <= 67.5) {
		car.src = CarNorthEast
	} else if (heading > 67.5 && heading <= 112.5) {
		car.src = CarEast
		car.xy = 40
	} else if (heading > 112.5 && heading <= 157.5) {
		car.src = CarSouthEast
	} else if (heading > 157.5 && heading <= 202.5) {
		car.src = CarSouth
	} else if (heading > 202.5 && heading <= 247.5) {
		car.src = CarSouthWest
	} else if (heading > 247.5 && heading <= 292.5) {
		car.src = CarWest
		car.xy = 40
	} else if (heading > 292.5 && heading <= 337.5) {
		car.src = CarNorthWest
	}

	return (
		<img src={car.src} width={car.xy} height={car.xy}/>
	)
}

export default observer(CarIcon)
