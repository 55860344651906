/**
 * 交互的基类。
 */
var Interaction = /** @class */ (function () {
    function Interaction(view, cfg) {
        this.view = view;
        this.cfg = cfg;
    }
    /**
     * 初始化。
     */
    Interaction.prototype.init = function () {
        this.initEvents();
    };
    /**
     * 绑定事件
     */
    Interaction.prototype.initEvents = function () { };
    /**
     * 销毁事件
     */
    Interaction.prototype.clearEvents = function () { };
    /**
     * 销毁。
     */
    Interaction.prototype.destroy = function () {
        this.clearEvents();
    };
    return Interaction;
}());
export default Interaction;
