import { Plot } from '../../core/plot';
import { deepAssign } from '../../utils';
export var X_FIELD = 'x';
export var VIOLIN_Y_FIELD = 'violinY';
export var VIOLIN_SIZE_FIELD = 'violinSize';
export var MIN_MAX_FIELD = 'minMax';
export var QUANTILE_FIELD = 'quantile';
export var MEDIAN_FIELD = 'median';
export var VIOLIN_VIEW_ID = 'violin_view';
export var MIN_MAX_VIEW_ID = 'min_max_view';
export var QUANTILE_VIEW_ID = 'quantile_view';
export var MEDIAN_VIEW_ID = 'median_view';
export var DEFAULT_OPTIONS = deepAssign({}, Plot.getDefaultOptions(), {
    // 多 view 组成，一定要设置 view padding 同步
    syncViewPadding: true,
    // 默认核函数
    kde: {
        type: 'triangular',
        sampleSize: 32,
        width: 3,
    },
    // 默认小提琴轮廓样式
    violinStyle: {
        lineWidth: 1,
        fillOpacity: 0.3,
        strokeOpacity: 0.75,
    },
    // 坐标轴
    xAxis: {
        grid: {
            line: null,
        },
        tickLine: {
            alignTick: false,
        },
    },
    yAxis: {
        grid: {
            line: {
                style: {
                    lineWidth: 0.5,
                    lineDash: [4, 4],
                },
            },
        },
    },
    // 图例
    legend: {
        position: 'top-left',
    },
    // Tooltip
    tooltip: {
        showMarkers: false,
    },
});
