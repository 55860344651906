import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Card, Collapse, Col, Row, Popconfirm, Button, Form, Radio, Switch, Space } from "antd"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import CopyString from "../../../component/CopyString"
import MiniLoader from "../../../component/MiniLoader"
import { FormItem, ModalForm } from "../../../component/ModalForm"
import axios from "../../../inizialize/Axios"
import MediaUpload from "../Modal/component/MediaUpload"
import ModalDocumentForm from "../Modal/ModalDocumentForm"
import Document from "./Document"
import TransportDocumentMedia from "./TransportDocumentMedia"

const TransportTabDocument = ({ transport }) => {

	const { add_document = false } = useParams()
	const [showFormModal, setShowFormModal] = useState(false)
	const [showUpdateModal, setShowUpdateModal] = useState(false)
	const [modalData, setModalData] = useState({})

	const addDocument = event => {
		event.stopPropagation()
		setShowFormModal(true)
	}

	useEffect(() => {
		if (add_document) {
			setShowFormModal(true)
		}
	}, [add_document])

	return (
		<Wrapper>
			<Space size={16} wrap align="start">
				{
					[...transport.documents].filter(item => item.active).map(document => (
						<Document
							key={"document-" + document.id}
							transport={transport}
							document={document}
							setModalData={setModalData}
							setShowUpdateModal={setShowUpdateModal}
						/>
					))
				}
				<AddWrapper
					onClick={() => {
						setShowFormModal(true)
					}}
				>
					<PlusOutlined/>
					Добавить документ
				</AddWrapper>
			</Space>
			<CollapseWrapper>
				<CollapsePanel
					header={"Старые (" + [...transport.documents].filter(item => !item.active).length + ")"}
					key="all"
				>
					<Space size={16} wrap>
						{
							[...transport.documents].filter(item => !item.active).map(document => (
								<Document
									key={"document-" + document.id}
									transport={transport}
									document={document}
									setModalData={setModalData}
									setShowUpdateModal={setShowUpdateModal}
								/>
							))
						}
					</Space>
				</CollapsePanel>
			</CollapseWrapper>
			<ModalDocumentForm
				api={"/v1/transport/" + transport.id + "/document/create"}
				visible={showFormModal}
				type={"create"}
				onCancel={() => {
					setShowFormModal(false)
				}}
				onSuccess={(data) => {
					setShowFormModal(false)
					transport.setDocuments(data)
				}}
				data={{
					active: true,
				}}
			/>

			{
				modalData && (
					<ModalDocumentForm
						api={"/v1/transport/" + transport.id + "/document/" + modalData.id + "/update"}
						visible={showUpdateModal}
						type={"update"}
						onCancel={() => {
							setShowUpdateModal(false)
							setModalData({})
						}}
						onSuccess={data => {
							setShowUpdateModal(false)
							transport.setDocuments(data)
						}}
						defaultData={modalData}
					/>
				)
			}
		</Wrapper>
	)
}

export default observer(TransportTabDocument)

const AddWrapper = styled.div`
  width: 202px;
  height: 202px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  border: 2px dotted #666;
  color: #999;
  transition: all .2s linear;
  cursor: pointer;

  &:hover {
    color: #e2e2e2;
    border-color: #999;
    box-shadow: 0px 2px 20px #00000066;
  }

  .anticon {
    font-size: 40px;
    margin-bottom: 10px;
  }
`

const Wrapper = styled.div`
  margin: 20px;

  .ant-card-head {
    padding: 0 4px 0 14px;
    height: 35px;
    min-height: 35px;

    .ant-card-head-title {
      padding: 0 0 5px;
    }
  }

  .ant-card {
    pointer-events: initial;

    .ant-card-extra {
      opacity: 0;
      padding: 0;
      transition: opacity 0.2s ease;
    }

    &:hover {
      .ant-card-extra {
        opacity: 1;
      }
    }
  }

  .ant-card-body {
    padding: 14px 14px 4px !important;
  }
`
const CollapseWrapper = styled(Collapse)`
  margin-top: 20px;
`
const CollapsePanel = styled(Collapse.Panel)``
