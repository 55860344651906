import { observer } from "mobx-react-lite"
import styled, { ThemeProvider } from "styled-components"

const Styled = ({ children }) => {

	const theme = {}

	return (
		<ThemeProvider theme={theme}>
			<Wrapper className={"dark-theme"}>
				{children}
			</Wrapper>
		</ThemeProvider>
	)
}

export default observer(Styled)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`