import { Avatar, Typography } from "antd"
import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import RoadNumber from "./RoadNumber"

const TransportLine = ({
	transport,
	withName = false,
	withLink = false,
	avatar = true,
	avatarSize = 24,
	textSize = 15,
	withNumber = false,
}) => {

	const navigate = useNavigate()

	const name = useMemo(() => {
		const value = [];
		["brand", "model"].map(item => transport[item] && value.push(transport[item]))
		if (withName && transport["name"]) {
			value.push("(" + transport["name"] + ")")
		}
		return value.join(" ")
	}, [transport])

	const onClick = event => {
		navigate("/transport/" + transport.id)
		event.stopPropagation()
	}

	const LinkWrapper = ({ children }) => {
		if (withLink) {
			return <Link onClick={onClick}>{children}</Link>
		}
		return children
	}

	return (
		<Wrapper>
			<LinkWrapper>
				<NameLine>
					{
						avatar && (
							<AvatarWrapper
								size={avatarSize}
								src={process.env.REACT_APP_STATIC_URL + "/media/auto_logos/" + transport.brand}
							/>
						)
					}
					<Name size={textSize}>{name}</Name>
					{
						withNumber && (
							<Number style={withNumber?.style ?? {}}>
								<RoadNumber size={"small"} number={transport.gosnumber}/>
							</Number>
						)
					}
				</NameLine>
			</LinkWrapper>
		</Wrapper>
	)
}

export default observer(TransportLine)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: #ccc;
    text-decoration: underline;
    text-decoration-color: #999;
    transition: all .2s linear;
    text-decoration-thickness: auto;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
`
const Link = styled.a`
  display: inline-block;
  flex: 0;
  flex-direction: column;
`
const AvatarWrapper = styled(Avatar)`
  margin-right: 6px;
`
const NameLine = styled.span`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const Name = styled.div`
  font-size: ${props => props.size}px;
`
const Number = styled.div`
  margin-left: auto;
`