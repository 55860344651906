import { Tag } from "antd"
import { observer } from "mobx-react-lite"
import styled from "styled-components"

const TableTags = ({ tags }) => {

	return (
		<Wrapper>
			{
				tags.map(item => (
					<Tag key={"tag-" + item.id} closable={false}>{item.name}</Tag>
				))
			}
		</Wrapper>
	)
}

export default observer(TableTags)

const Wrapper = styled.div``