import {Table} from "antd"
import {observer} from 'mobx-react-lite'
import React, {useEffect, useMemo, useState} from "react"
import styled from "styled-components"
import {Point} from "../../../network"
import MiniLoader from "../../../component/MiniLoader"
import dayjs from "dayjs"
import Icon from "../../../component/Icon"
import {faBarcodeRead} from "@fortawesome/pro-light-svg-icons/faBarcodeRead"

const PointInvoicesTab = ({point}) => {

	const [ready, setReady] = useState(false)
	const [invoices, setInvoices] = useState([])

	useEffect(() => {
		if (!point) {
			setInvoices([])
			return
		}
		loadCargo()
	}, [])

	const tableData = useMemo(() => {
		if (!invoices.length) return []

		let tmp_array = []

		invoices.map(invoice => {
			let invoiceData = {
				key: 'invoice-' + invoice.id,
				name: invoice.name,
				count: '',
				count_fact: '',
				price: '',
				price_fact: '',
				barcode_at: invoice.barcode_at,
				children: []
			}

			invoice.cargos.map(cargo => {
				invoiceData.children.push({
					key: 'cargo-' + cargo.id,
					name: cargo.name.length > 35 ? cargo.name.substr(0, 35) + '...' : cargo.name,
					count: cargo.count,
					count_fact: cargo.count_fact,
					price: cargo.price,
					price_fact: cargo.price_fact
				})
			})
			tmp_array.push(invoiceData)
		})

		return tmp_array

	}, [invoices])

	const loadCargo = async () => {
		let result = await Point.getPoint({id: point.id})
		if (result.code === 200) {
			setInvoices(result.data.invoices)
		}

		setReady(true)
	}

	const columns = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
			width: 380,
			render: (text, row) => {
				let color = '#F9F9F9'
				if (row?.children?.length) {
					if (row?.children.findIndex(c => c.count !== c.count_fact) !== -1) {
						color = '#FF9500'
					}
				}
				return (
					<span style={{color: color}}>
							{text}
						{
							row.barcode_at && (
								<sup style={{fontSize: 11, marginLeft: 5, top: -10, color: '#88db57'}}>
									<Icon name={faBarcodeRead}
										  style={{color: '#88db57'}}/> {dayjs(row.barcode_at).format('HH:mm')}
								</sup>
							)
						}
					</span>
				)
			},
		},
		{
			title: 'Кол-во',
			dataIndex: 'count',
			key: 'count'
		},
		{
			title: 'Факт',
			dataIndex: 'count_fact',
			key: 'count_fact',
			align: 'center',
			render: (text, row, index) => {
				if (row.count !== row.count_fact) {
					return <span style={{color: '#FF9500'}}>{text}</span>
				}
				return <span>{text}</span>
			},
		},
		{
			title: 'Стоимость',
			dataIndex: 'price',
			key: 'price',
			align: 'center',
		},
		{
			title: 'Сумма',
			dataIndex: 'price_fact',
			key: 'price_fact',
			align: 'center',
		},
	]

	return (
		<>
			{
				ready ? (
					<TableComponent
						pagination={false}
						columns={columns}
						dataSource={tableData}
						scroll={{y: 500, x: 500}}
					/>
				) : (
					<MiniLoader/>
				)
			}
		</>
	)
}

export default observer(PointInvoicesTab)

const TableComponent = styled(Table)`
  .ant-table-cell-with-append, .ant-table-cell {
    opacity: .8;
    font-weight: normal;
  }
`

