import { __extends } from "tslib";
import ElementRangeState from './range-state';
/**
 * @ignore
 * 图表元素区域 Active 的 Action
 */
var ElementRangeActive = /** @class */ (function (_super) {
    __extends(ElementRangeActive, _super);
    function ElementRangeActive() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'active';
        return _this;
    }
    /**
     * 图表元素 Active
     */
    ElementRangeActive.prototype.active = function () {
        this.setState();
    };
    return ElementRangeActive;
}(ElementRangeState));
export default ElementRangeActive;
