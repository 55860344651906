import {faLink} from "@fortawesome/pro-regular-svg-icons/faLink"
import {faUnlink} from "@fortawesome/pro-regular-svg-icons/faUnlink"
import {faPen} from "@fortawesome/pro-regular-svg-icons/faPen"
import {faBan} from "@fortawesome/pro-regular-svg-icons/faBan"
import {Menu, Modal} from "antd"
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync"
import Icon from "../../../../component/Icon"
import React from "react"

export const pointContextMenu = (point, route, selectPoint, store, setEditPointItem, setShowMovePointModal, changeStatus) => {

	let menuOptions = [
		{
			title: 'Привязать к МЛ',
			onClick: () => {
				selectPoint(null, point);
				return true
			},
			color: '#88db57',
			icon: faLink,
			disabled: !store.routing.activeRoute || route?.isCanceled || point.isCanceled,
			show: !point.route_id
		},
		{
			title: 'Отвязать от МЛ',
			onClick: () => {
				selectPoint(null, point);
				return true
			},
			color: '#FF9500',
			icon: faUnlink,
			disabled: !point.route_id || !route?.draft || route?.locked,
			show: point.route_id
		},
		{
			title: 'Редактировать данные',
			onClick: () => setEditPointItem(point),
			color: '#C9C6BF',
			icon: faPen,
			disabled: (route && !route.draft && point.status !== 'created') || (route && route.locked),
			show: true
		},
		{
			title: 'Отменить точку',
			onClick: () => setShowMovePointModal(true),
			color: '#E75957',
			icon: faBan,
			//disabled: (route && route.isCanceled) || point.status === 'done' || (route && route.locked),
			disabled: (route && route.isCanceled) || point.status === 'done' || (route && route.locked),
			show: !point.isCanceled
		},
		{
			title: 'Восстановить точку',
			onClick: () => {
				Modal.confirm({
					title: 'Внимание!',
					content: 'Подтвердите восстановление точки.',
					visible: true,
					cancelText: 'Закрыть',
					okText: 'Восстановить',
					onOk: () => changeStatus(point, 'created')
				})
			},
			color: '#88db57',
			icon: faSync,
			disabled: route?.isCanceled || route?.status === 'done',
			show: point.isCanceled
		}
	]

	return (
		<Menu
			items={
				menuOptions.filter(_menu => _menu.show).map((menu, index) => {
					return {
						key: '' + index,
						label: menu.title,
						onClick: menu.onClick,
						disabled: menu.disabled,
						icon: <Icon
							name={menu.icon}
							color={(menu.color && !menu.disabled) ? menu.color : menu.disabled ? '#636363' : null}
							size={14}
							style={{marginRight: 5}}
						/>,
						style: {color: (menu.color && !menu.disabled) ? menu.color : menu.disabled ? '#636363' : null}
					}
				})
			}/>
	)
}
