import {types} from "mobx-state-tree"
import RouteActual from "./collection/RouteActual"
import RouteArchive from "./collection/RouteArchive"

const RouteStore = types.model({
	actual: types.optional(RouteActual, {}),
	archive: types.optional(RouteArchive, {}),
}).actions(self => ({})).views(self => ({}))

export default RouteStore
