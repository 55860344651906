import {observer} from "mobx-react-lite"
import {Route, Routes} from "react-router-dom"
import DashboardScreen from "./Dashboard/DashboardScreen"
import DriverScreen from "./Driver/DriverScreen"
import DriverViewScreen from "./Driver/DriverViewScreen"
import Inside from "./Layout/Inside"
import OfficeScreen from "./Office/OfficeScreen"
import RouteLogsView from "./Route/component/RouteLogsTab"
import RoutePointsView from "./Route/component/RoutePointsTab"
import RouteActualScreen from "./Route/RouteActualScreen"
import RouteArchiveScreen from "./Route/RouteArchiveScreen"
import RouteScreen from "./Route/RouteScreen"
import ErrorScreen from "./Error/ErrorScreen"
import SettingScreen from "./Setting/SettingScreen"
import {useStore} from "../store/Context"
import AnalyticsScreen from "./Analytics/AnalyticsScreen"
import RoutingScreen from "./Routing/RoutingScreen"
import TerminalScreen from "./Terminal/TerminalScreen"
import TransportScreen from "./Transport/TransportScreen"
import TransportViewScreen from "./Transport/TransportViewScreen"

const InsideNavigation = () => {

	const store = useStore()

	return (
		<Routes>
			<Route path="/" element={<Inside/>}>
				<Route path="" element={<DashboardScreen/>}/>
				<Route path="terminal" element={<TerminalScreen/>}/>
				<Route path="driver" element={<DriverScreen/>}/>
				<Route path="analytics" element={<AnalyticsScreen/>}/>
				<Route path="routing" element={<RoutingScreen/>}/>
				<Route path="transport" element={<TransportScreen/>}/>
				<Route path="404" element={<ErrorScreen/>} type={"404"}/>
				<Route path="driver/:id" element={<DriverViewScreen/>}>
					<Route path=":tab" element={<DriverViewScreen/>}/>
				</Route>
				<Route path="transport/:id" element={<TransportViewScreen/>}>
					<Route path=":tab" element={<TransportViewScreen/>}/>
				</Route>
				<Route path="route" element={<RouteScreen/>}>
					<Route index element={<RouteActualScreen/>}/>
					<Route path={":filter"} element={<RouteActualScreen/>}>
						<Route path={":id"}>
							<Route index element={<RoutePointsView/>}/>
							<Route path={"log"} element={<RouteLogsView/>}/>
						</Route>
					</Route>
					<Route path={"archive"} element={<RouteArchiveScreen/>}/>
				</Route>
				<Route path="setting" element={<SettingScreen/>}>
					<Route index element={<SettingScreen/>}/>
				</Route>
				{
					store.user.level === "director" && (
						<>
							<Route path="office" element={<OfficeScreen/>}>
								<Route index element={<OfficeScreen/>}/>
							</Route>
						</>
					)
				}
			</Route>
		</Routes>
	)
}

export default observer(InsideNavigation)
