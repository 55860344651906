import { Form, message } from "antd"
import { observer } from "mobx-react-lite"
import { createContext, useState } from "react"
import styled from "styled-components"
import TTItem from "./TTItem"

const TTForm = ({
	children,
	api = false,
	onFinish = () => {},
	onApiSuccess = () => {},
	onApiError = () => {},
	...props
}) => {

	const [error, setError] = useState({})

	const onHandlerFinish = async values => {
		if (api && typeof api === "function") {
			setError({})
			const form = await api(values)
			if (form.success) {
				if (onApiSuccess && typeof onApiSuccess === "function") {
					onApiSuccess(form)
				}
			} else {
				if (onApiError && typeof onApiError === "function") {
					onApiError(form)
				}
				if (form.error.type === "validation") {
					setError(form.error.data)
				} else {
					setError({ "*": form.error.message })
					message.error({ content: form.error.message, key: "error", duration: 5 })
				}
			}
		}
		if (onFinish && typeof onFinish === "function") {
			onFinish(values)
		}
	}

	return (
		<FormWrapper
			onFinish={onHandlerFinish}
			{...props}
		>
			<FormContext.Provider value={error}>
				{children}
			</FormContext.Provider>
		</FormWrapper>
	)
}

TTForm.Item = TTItem
export default observer(TTForm)
export const FormContext = createContext({})

const FormWrapper = styled(Form)`
  min-width: 300px;
`