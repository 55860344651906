import { Divider, Input, Select, Switch } from "antd"
import MaskedInput from "antd-mask-input"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FormItem, ModalForm } from "../../../component/ModalForm"
import axios from "../../../inizialize/Axios"

const ModalDriverForm = ({ data, update = false, ...props }) => {

	const [driverTags, setDriverTags] = useState([])

	useEffect(() => {
		loadDriverTags()
	}, [])

	const loadDriverTags = async () => {
		const result = await axios.get("/v1/tags/driver")
		if (result.success) {
			setDriverTags(result.data)
		}
	}

	return (
		<ModalForm
			defaultData={data}
			modificator={data => {
				if (update) {
					data.phone = data.phone.substring(2)
				}
				return data
			}}
			modificatorOnSend={data => {
				if (data.phone) {
					data.phone = "+7" + data.phone.replace(/[^0-9]/g, "")
				}
				if (!update && data.phone) {
					data.password = data.phone.substring(8)
				}
				return data
			}}
			{...props}
		>
			<FormItem name={"external_id"}/>
			<FormItem name={"surname"} required/>
			<FormItem name={"name"} required/>
			<FormItem name={"middlename"}/>
			<FormItem name={"phone"} required>
				<MaskedInput
					prefix="+7"
					mask="(000) 000 00 00"
					size="10"
				/>
			</FormItem>
			<FormItem name={"password"} title={update ? "Новый PIN код" : "PIN код"}>
				<Input
					disabled={!update}
					placeholder={!update ? "Автоматически. Последние 4 символа номера телефона." : ""}
				/>
			</FormItem>
			<FormItem name={"tags"}>
				<Select
					mode={"tags"}
					options={driverTags.map(item => ({ value: item.name, label: item.name }))}
				/>
			</FormItem>
			<Divider/>
			<FormItem name={"sms"} title={"Отправить СМС"}>
				<Switch checked={false} disabled/>
			</FormItem>
		</ModalForm>
	)
}

export default observer(ModalDriverForm)

const Wrapper = styled.div``