import React from "react"
import {useCallback, useEffect} from "react"


const CatchKeyPress = ({
						   onEsc = () => {
						   },
						   onCtrlEnter = () => {
						   }
					   }) => {


	useEffect(() => {
		document.addEventListener("keydown", keyDown, false)
		return () => {
			document.removeEventListener("keydown", keyDown, false)
		}
	}, [])

	const keyDown = useCallback((e) => {
		if (e.key === "Escape") onEsc()
		if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) onCtrlEnter()
	}, [])

	return null
}
export default CatchKeyPress
