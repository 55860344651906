import { __extends } from "tslib";
import ListState from './list-state';
/**
 * 列表项（图例项、坐标轴文本）激活的 Action
 * @class
 * @ignore
 */
var ListActive = /** @class */ (function (_super) {
    __extends(ListActive, _super);
    function ListActive() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateName = 'active';
        return _this;
    }
    /**
     * 激活选项
     */
    ListActive.prototype.active = function () {
        this.setState();
    };
    return ListActive;
}(ListState));
export default ListActive;
