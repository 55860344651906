import { toJS } from "mobx"
import { types } from "mobx-state-tree"

export const DocumentItem = types.model({
	id: types.identifierNumber,
	type: types.maybeNull(types.string),
	name: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	number: types.maybeNull(types.string),
	active: types.maybeNull(types.boolean),
	media: types.maybeNull(types.array(types.string)),
}).views(self => ({
	get getMedia () {
		return toJS(self.media)
	},
})).actions(self => ({
	updateMedia (media) {
		self.media = media
	},
}))
