import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons"
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons"
import { Button, Card, Dropdown, Menu, Modal, Popconfirm, Upload } from "antd"
import { observer } from "mobx-react-lite"
import React, { useMemo, useRef } from "react"
import styled from "styled-components"
import CopyString from "../../../component/CopyString"
import Icon from "../../../component/Icon"
import axios from "../../../inizialize/Axios"
import TransportDocumentMedia from "./TransportDocumentMedia"

const Document = ({ document, transport, setModalData, setShowUpdateModal }) => {

	const removeDocument = document => {
		Modal.confirm({
			title: "Уверены?",
			icon: <ExclamationCircleOutlined/>,
			content: "Уверены, что хотите удалить этот документ?",
			okText: "Да",
			cancelText: "Нет",
			onOk: async () => {
				const result = await axios.post("/v1/transport/" + transport.id + "/document/" + document.id + "/remove")
				if (result.success) {
					transport.setDocuments(result.data)
				}
			},
		})
	}

	const documentTitle = useMemo(() => {
		switch (document.type) {
			case "sts":
				return "СТС"
			case "pts":
				return "ПТС"
			case "insure":
				return "ОСАГО"
			default:
				return "Документ"
		}
	}, [document])

	const menu = useMemo(() => (
		<Menu
			items={[
				{
					key: "update",
					label: "Редактировать",
				},
				{
					key: "remove",
					label: "Удалить",
					danger: true,
				},
			]}
			onClick={({ item, key }) => {
				switch (key) {
					case "update": {
						setModalData(document)
						setShowUpdateModal(true)
						break
					}
					case "remove": {
						removeDocument(document)
						break
					}
				}
			}}
		/>
	), [])

	return (
		<DocumentWrapper>
			<TitleWrapper>
				<Title>{documentTitle}</Title>
				<Dropdown overlay={menu} trigger={["click"]}>
					<Actions>
						<Icon name={faEllipsisV}/>
					</Actions>
				</Dropdown>
			</TitleWrapper>
			<ContentWrapper>
				<Number>
					<span>Номер:</span>
					{
						document.number ? (<CopyString value={document.number}/>) : (
							<Null>Нет номера</Null>
						)
					}
				</Number>
				<TransportDocumentMedia transport={transport} document={document}/>
			</ContentWrapper>

		</DocumentWrapper>
	)
}

export default observer(Document)

const DocumentWrapper = styled.div`
  background: #222;
  border-radius: 10px;
  transition: all ease .2s;
  width: 344px;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 1px 5px #00000099;
  }
`
const TitleWrapper = styled.div`
  background: #191919;
  padding: 10px 10px 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Title = styled.div`
  font-size: 18px;
`
const Actions = styled.div`
  font-size: 18px;
  border: 1px solid #444;
  color: #555;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #222;
  }
`
const ContentWrapper = styled.div`
  padding: 10px 0px 10px 16px;
`
const Number = styled.div`
  display: flex;
  flex-direction: row;

  span {
    color: #888;
    margin-right: 10px;
  }
`
const Null = styled.div`
  color: #999;
`