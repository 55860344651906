import mapboxgl from "mapbox-gl"
import {observer} from "mobx-react-lite"
import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import "mapbox-gl/dist/mapbox-gl.css"
import {Mapbox} from "../../component/Mapbox"
import {Route} from "../../network"
import {useStore} from "../../store/Context"
import FastSearch from "../Route/component/FastSearch"
import DashboardRouteList from "./component/DashboardRouteList"
import CatchKeyPress from "../../component/CatchKeyPress"
import BoxMarker from "../../component/Mapbox/Components/BoxMarker"
import CarMarker from "../../component/Mapbox/Components/CarMarker"
import PolylineDashboard from "../../component/Mapbox/Components/PolylineDashboard"

const DashboardScreen = () => {

	const store = useStore()

	const [activeRoute, setActiveRoute] = useState(null)
	const [activePoint, setActivePoint] = useState(null)
	const [searchText, setSearchText] = useState("")
	const [filters, setFilters] = useState([])
	const [routePolyline, setRoutePolyline] = useState(null)
	const [driverGps, setDriverGps] = useState(null)
	const [mapLastPosition, setMapLastPosition] = useState(null)

	const mapRef = useRef(null)

	useEffect(() => {
		setActiveRoute(null)
	}, [searchText, filters])

	useEffect(() => {
		setRoutePolyline(null)
		setDriverGps(null)
		if (activeRoute) {
			if (mapRef.current) {
				const center = mapRef.current.getCenter()
				setMapLastPosition({
					latitude: center.lat,
					longitude: center.lng,
					zoom: mapRef.current.getZoom(),
					bearing: mapRef.current.getBearing(),
					padding: mapRef.current.getPadding(),
					pitch: mapRef.current.getPitch(),
				})
			}
			getRoutePolyline(activeRoute.id)
			getDriverGps(activeRoute.id)
			if (activeRoute.points) {
				const bounds = []

				// добавляем все точки в bounds, на выходе интерфейс выберет 2 крайние. левый низ и правый верх

				activeRoute.points.map(item => {
					if (item.address_location?.lng && item.address_location?.lat) {
						bounds.push([item.address_location.lng, item.address_location.lat])
					}
				})

				// добавляем тачку в bounds, чтобы относительно неё тоже рамки строились

				if (activeRoute.status !== "created" && activeRoute.driver?.coord?.latitude && activeRoute.driver?.coord?.longitude) {
					bounds.push([activeRoute.driver.coord.longitude, activeRoute.driver.coord.latitude])
				}

				// вычисляем квадрат
				const box = new mapboxgl.LngLatBounds(
					bounds[0],
					bounds[0],
				)
				bounds.map(bound => {
					box.extend(bound)
				})

				// фитим карту до bounds
				mapRef.current.fitBounds(box, {
					padding: {top: 50, bottom: 100, left: 50, right: 100},
					maxZoom: 13,
				})
			}
		} else {
			if (mapLastPosition) {
				mapRef.current.flyTo({
					center: [
						mapLastPosition.longitude,
						mapLastPosition.latitude,
					],
					zoom: mapLastPosition.zoom,
					bearing: mapLastPosition.bearing,
					padding: mapLastPosition.padding,
					pitch: mapLastPosition.pitch,
				})
			}
		}
	}, [activeRoute])

	const getRoutePolyline = async routeId => {
		let result = await Route.routeGeometry({routeId})
		if (result.code === 200) {
			setRoutePolyline(result.data.geometry)
		}
	}

	const getDriverGps = async routeId => {
		let result = await Route.routeGpsHistory({routeId})
		if (result.code === 200) {
			setDriverGps(result.data)
		}
	}


	return (
		<Wrapper>
			<Mapbox
				initialViewState={{
					longitude: store.user.office.region_map_settings?.lng ?? 37.5877594,
					latitude: store.user.office.region_map_settings?.lat ?? 55.7421473,
					zoom: store.user.office.region_map_settings?.zoom ?? 9.2,
					padding: {right: 300},
				}}
				ref={mapRef}
			>
				{
					store.route.actual.routeFastFilter([...store.route.actual.current], filters, searchText).filter(item => item.driver && item.driver?.coord && item.status !== "created").map(item => item.driver && (
						<CarMarker
							key={"car-" + item.id + Math.random() * (1000000 - 40000) + 40000}
							hide={activeRoute && activeRoute.id !== item.id ? true : false}
							item={item}
							onClick={() => {
								setActiveRoute(activeRoute => (!activeRoute || (activeRoute.id !== item.id)) ? item : null)
							}}
						/>
					))
				}
				{
					activeRoute?.points.map(item => (
						<BoxMarker
							key={"point-" + item.id}
							item={item}
							active={activePoint?.id}
							onClick={() => {
								setActivePoint(activePoint => (!activePoint || (activePoint.id !== item.id)) ? item : null)
							}}
						/>
					))
				}
				{
					(activeRoute && routePolyline) && (
						<PolylineDashboard
							color={"rgba(96, 174, 255, .6)"}
							width={5}
							opacity={1}
							path={routePolyline}
						/>
					)
				}
				{
					driverGps && (
						<PolylineDashboard
							color={"rgba(255,210,96, .75)"}
							width={5}
							opacity={1}
							path={driverGps}
						/>
					)
				}

				<RightPanel background={activeRoute ? "transparent" : "#2A2C2C"}>
					<FastSearch filters={filters} setFilters={setFilters} searchText={searchText}
								setSearchText={setSearchText}/>
					<DashboardRouteList
						data={store.route.actual.routeFastFilter([...store.route.actual.current], filters, searchText)}
						activeRoute={activeRoute}
						setActiveRoute={setActiveRoute}
						mapRef={mapRef}
					/>
				</RightPanel>
				{/*<DashboardPlan/>*/}
			</Mapbox>


			{/*<Modal
				visible={true}
				width={450}
				closable={false}
				footer={null}
				bodyStyle={{padding: 0}}
			>
				<div style={{display: 'flex', padding: 25, backgroundColor: '#202020', flexDirection: 'row'}}>
					<div style={{display: 'flex', flex: .3, paddingTop: 25}}>
						<Icon name={faPhoneAlt} color={'#88db57'} size={30}/>
					</div>


					<div>
						<div style={{fontSize: 23}}>
							Ильвиров Руслан
							<sup style={{marginLeft: 10, color: '#88db57'}}>9.7</sup>
						</div>
						<div style={{opacity: .8}}>+7 (926) 887-33-11</div>
						<div style={{opacity: .8}}>Входящий звонок...</div>
						<div style={{flexDirection: 'row', marginTop: 25}}>
							<Button size={'large'} style={{backgroundColor: '#00BE7B', border: 0}}
									type="primary">Ответить</Button>

							<Button style={{marginLeft: 15}}
									size={'large'}
									type={"primary"} danger>Сбросить</Button>
						</div>
					</div>
				</div>
				<div style={{padding: 25, backgroundColor: '#2A2C2C'}}>
					<div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
						<div style={{flex: 1}}>
							<a>МЛ 2022-29-98</a>
						</div>
						<div style={{textAlign: 'end'}}>
							9 и 10
						</div>
					</div>
					<Image style={{marginTop: 20}} width={'100%'}
						   src={miniMap} alt={'Привет'}/>
				</div>
			</Modal>*/}
			<CatchKeyPress onEsc={() => setActiveRoute(null)}/>
		</Wrapper>
	)
}

export default observer(DashboardScreen)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const RightPanel = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: ${props => props.background};
`
