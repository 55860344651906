import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Alert } from "antd"
import { Reference } from "../network"
import { Detector } from "react-detect-offline"
import { observer } from "mobx-react-lite"

const CheckConnection = () => {

	const [connection, setConnection] = useState(true)

	return (
		<>
			<Detector
				polling={{
					enabled: true,
					url: Reference.minimumUrl(),
					interval: 60000,
					timeout: 5000,
				}}
				onChange={s => {
					setConnection(s)
				}}
				render={() => null}
			/>
			{
				!connection ? (
					<Layout>
						<Alert
							message="Соединение прервано"
							showIcon
							closable
							description={
								<div>
									Внимание, соединение с сервером было прервано.
									Необходимо <span
									onClick={() => window.location.reload(false)}
									style={{
										fontWeight: "bold",
										textDecoration: "underline",
										cursor: "pointer",
									}}>перезагрузить</span> страницу.
								</div>
							}
							type="warning"
							/*action={
								<Button size="small" danger>
									Detail
								</Button>
							}*/
						/>
					</Layout>
				) : null
			}
		</>
	)
}
export default observer(CheckConnection)

const Layout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 15;
`
