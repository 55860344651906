import React from "react";
import {observer} from "mobx-react-lite"

const Flag = ({width = 40, height = 40, color = '#5eac24', lineColor = '#000000'}) => {

	return (
		<svg
			height={height}
			viewBox="0 0 32 32"
			width={width}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m27.4 4.97-3.48 6.08a.77.77 0 0 0 0 .75l3.48 6.08H7.44V4.97z"
				fill={color}
			/>
			<path
				d="m27.4 4.97-3.48 6.08zM23.92 11.05l3.48-6.08H7.44v12.91H27.4l-3.48-6.08a.77.77 0 0 1 0-.75zm5.43 7.21c.06.11.09.24.09.37 0 .41-.33.75-.75.75H7.44v10.85h2.64a.749.749 0 1 1 0 1.5H3.31a.749.749 0 1 1 0-1.5h2.63V1.02a.749.749 0 1 1 1.5 0v2.45h21.25a.75.75 0 0 1 .66 1.12l-3.92 6.83z"
				fill={lineColor}
			/>
		</svg>
	)
}

export default observer(Flag)
