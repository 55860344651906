import { __assign } from "tslib";
import { isFunction, map, isNumber, maxBy, get } from '@antv/util';
import { FUNNEL_PERCENT, FUNNEL_CONVERSATION, FUNNEL_MAPPING_VALUE } from '../constant';
/**
 * 漏斗图 transform
 * @param geometry
 */
export function transformData(data, originData, options) {
    var formatData = [];
    var yField = options.yField, maxSize = options.maxSize, minSize = options.minSize;
    var maxYFieldValue = get(maxBy(originData, yField), [yField]);
    var max = isNumber(maxSize) ? maxSize : 1;
    var min = isNumber(minSize) ? minSize : 0;
    // format 数据
    formatData = map(data, function (row, index) {
        var percent = (row[yField] || 0) / maxYFieldValue;
        row[FUNNEL_PERCENT] = percent;
        row[FUNNEL_MAPPING_VALUE] = (max - min) * percent + min;
        // 转化率数据存储前后数据
        row[FUNNEL_CONVERSATION] = [get(data, [index - 1, yField]), row[yField]];
        return row;
    });
    return formatData;
}
/**
 * 漏斗图通用转化率组件
 * @param getLineCoordinate 用于获取特定的 line 的位置及配置
 */
export function conversionTagComponent(getLineCoordinate) {
    return function (params) {
        var chart = params.chart, options = params.options;
        var conversionTag = options.conversionTag;
        var data = chart.getOptions().data;
        if (conversionTag) {
            var formatter_1 = conversionTag.formatter;
            data.forEach(function (obj, index) {
                if (index <= 0 || Number.isNaN(obj[FUNNEL_MAPPING_VALUE]))
                    return;
                var lineOption = getLineCoordinate(obj, index, data, {
                    top: true,
                    text: {
                        content: isFunction(formatter_1) ? formatter_1(obj, data) : formatter_1,
                        offsetX: conversionTag.offsetX,
                        offsetY: conversionTag.offsetY,
                        position: 'end',
                        autoRotate: false,
                        style: __assign({ textAlign: 'start', textBaseline: 'middle' }, conversionTag.style),
                    },
                });
                chart.annotation().line(lineOption);
            });
        }
        return params;
    };
}
