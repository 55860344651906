import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"
import Outside from "./Layout/Outside"
import LoginPage from "./Outside/LoginPage"

const OutsideNavigation = ({}) => {

	return (
		<Routes>
			<Route path="/" element={<Navigate to={"/login"} replace/>}/>
			<Route path="/login" element={<Outside><LoginPage/></Outside>}/>
		</Routes>
	)
}

export default observer(OutsideNavigation)
