import DriverStore from "./DriverStore"
import OfficeStore from "./OfficeStore"
import RouteStore from "./RouteStore"
import TransportStore from "./TransportStore"
import UserStore from "./UserStore"
import AppStore from "./AppStore"
import SocketStore from "./SocketStore"
import RoutingStore from "./RoutingStore"

class Store {
	constructor () {
		this.app = new AppStore(this)
		this.user = new UserStore(this)
		this.driver = DriverStore.create()
		this.transport = TransportStore.create()
		this.office = OfficeStore.create()
		this.route = RouteStore.create()
		this.routing = RoutingStore.create()
		this.socket = new SocketStore(this)
	}
}

export default new Store()
