import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { useStore } from "../store/Context"
import InsideNavigation from "./InsideNavigation"
import OutsideNavigation from "./OutsideNavigation"

import AppLoader from "../component/AppLoader"


const AppNavigation = ({}) => {
	const store = useStore()
	const [ready, setReady] = useState(false)
	const { state } = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		(async () => {
			await store.user.actionLogin()
			setReady(true)
		})()
	}, [])

	useEffect(() => {
		if (state?.logout) {
			(async () => {
				await store.user.actionLogout()
				navigate("/")
			})()
		}
	}, [state])

	if (!ready || store.app.showLoading) return (
		<AppLoader/>
	)

	if (store.user.isAuth) {
		return <InsideNavigation/>
	} else {
		return <OutsideNavigation/>
	}
}

export default observer(AppNavigation)
