/**
 * 分面图 默认配置项
 */
export var DEFAULT_OPTIONS = {
    title: {
        style: {
            fontSize: 12,
            fill: 'rgba(0,0,0,0.65)',
        },
    },
    rowTitle: {
        style: {
            fontSize: 12,
            fill: 'rgba(0,0,0,0.65)',
        },
    },
    columnTitle: {
        style: {
            fontSize: 12,
            fill: 'rgba(0,0,0,0.65)',
        },
    },
};
