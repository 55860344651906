import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useRef, createRef, useState, useLayoutEffect} from "react"
import styled from "styled-components"
import {useStore} from "../../store/Context"
import CatchKeyPress from "../../component/CatchKeyPress"
import {message, Spin} from "antd"
import {LoadingOutlined} from "@ant-design/icons"
import ModalRouteForm from "./Modal/ModalRouteForm"
import {debounce} from "lodash"
import {External, Routing, User} from "../../network"
import Map from "./component/Map/Map"
import ModalPointForm from "./Modal/ModalPointForm"
import ModalMovePoint from "./Modal/ModalMovePoint"
import ModalUploadExcel from "./Modal/ModalUploadExcel"
import TablePoints from "./component/Table/TablePoints"
import TableRoutePoints from "./component/Table/TableRoutePoints"

import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex"
import 'react-reflex/styles.css'
import TableRoutes from "./component/Table/TableRoutes"
import "../../css/routing.css"

const RoutingScreen = () => {

	const store = useStore()
	const mapRef = useRef()
	const topAreaRef = createRef()
	const bottomAreaRef = createRef()

	const [addPointItem, setAddPointItem] = useState(null)
	const [editRouteItem, setEditRouteItem] = useState(null)
	const [showRouteFormModal, setShowRouteFormModal] = useState(false)
	const [clickPoint, setClickPoint] = useState(false)

	const [editPointItem, setEditPointItem] = useState(false)
	const [showMovePointModal, setShowMovePointModal] = useState(false)
	const [showModalUploadExcel, setShowModalUploadExcel] = useState(false)

	const [size, setSize] = useState([0, 0])

	useEffect(() => {
		store.routing.setRoutes([])
		store.routing.setPoints([])
		store.routing.fetch({date: store.routing.activeDate})
		store.routing.setActiveRoute(null)
		store.routing.setActivePolyline(null)
	}, [store.routing.activeDate])

	useLayoutEffect(() => {
		const updateSize = () => setSize([window.innerWidth, window.innerHeight])
		window.addEventListener('resize', updateSize)
		return () => window.removeEventListener('resize', updateSize)
	}, [])

	useEffect(() => {
		if (!store.user.settings.routing.layout.topHeight && topAreaRef?.current?.ref?.current?.clientHeight) {
			store.user.setLayoutSettings('routing', 'topHeight', topAreaRef?.current?.ref?.current?.clientHeight)
		}
		if (!store.user.settings.routing.layout.bottomHeight && bottomAreaRef?.current?.ref?.current?.clientHeight) {
			store.user.setLayoutSettings('routing', 'bottomHeight', bottomAreaRef?.current?.ref?.current?.clientHeight)
		}
	}, [topAreaRef, bottomAreaRef])

	useEffect(() => {
		if (store.routing.activeRoute) {
			const route = store.routing.routes.find(_route => _route.id === store.routing.activeRoute)
			if (route?.geo?.geometry) {
				store.routing.setActivePolyline(route.geo.geometry)
				return
			}
		}
		store.routing.setActivePolyline(null)
		store.routing.setRouteHover(null)
	}, [store.routing.activeRoute])

	/*useEffect(() => {
		saveSettings()
	}, [store.user.effectSettings])*/

	const selectPoint = async (e, point, scrollList = false) => {
		if (e && !e.ctrlKey && !e.metaKey) {
			if (point.route_id) {
				store.routing.setActiveRoute(point.route_id)
			}
			if (point?.address_location?.lat && point?.address_location?.lng) {
				mapRef.current.flyTo({
					center: [
						point.address_location?.lng,
						point.address_location?.lat,
					],
					zoom: 12,
					speed: 2,
				})
			}
			return
		}

		if (store.routing.activeRoute) {
			const activeRoute = store.routing.routes.find(_route => _route.id === store.routing.activeRoute)
			if (activeRoute?.locked) {
				message.warning("Маршрутный лист заблокирован", 2)
				return
			}
			if (activeRoute?.id && !activeRoute?.draft) {
				message.error("Маршрутный лист уже в работе.", 2)
				return
			}
		}

		if (!point.route_id) {
			if (!store.routing.activeRoute) {
				setAddPointItem(point)
				setShowRouteFormModal(true)
				return
			} else {
				store.routing.setRouteId(point.id, store.routing.activeRoute, store.routing.getRoutePoints(store.routing.activeRoute).length + 1)
			}
		} else if (store.routing.activeRoute && store.routing.activeRoute === point.route_id) {
			store.routing.setRouteId(point.id, 0)
			store.routing.recountPositions(store.routing.activeRoute)
		} else {
			message.warning("Эта точка уже находится в другом маршрутном листе", 2)
			return
		}

		if (store.routing.activeRoute) {
			await getPolyline(store.routing.activeRoute)
			syncRoute(store.routing.activeRoute)
		}
		setClickPoint(!clickPoint)
	}

	const getPolyline = useCallback(debounce(async (routeId) => {
		let _select = store.routing.getRoutePoints(routeId)
		if (_select.length > 1) {
			let _coords = []
			_select.map(point =>
				_coords.push([point.address_location.lng, point.address_location.lat]),
			)

			let r = await External.getRouteDirection({coordinates: _coords})
			if (r.code === 200) {
				if (r.routes[0]?.geometry) {
					let _data = {
						geometry: r.routes[0]?.geometry,
						distance: r.routes[0]?.summary.distance,
						duration: r.routes[0]?.summary.duration,
						status: "done",
					}

					store.routing.setRouteGeo(routeId, _data)
					store.routing.setActivePolyline(_data.geometry)
				}
			} else {
				store.routing.setRouteGeo(routeId, null)
				store.routing.setActivePolyline(null)
				message.warning("Не удалось построить маршрут", 2)
			}
		} else {
			store.routing.setRouteGeo(store.routing.activeRoute, null)
			store.routing.setActivePolyline(null)
		}
	}, 750), [])


	const syncRoute = useCallback(debounce(async (routeId) => {
		if (!routeId) return
		store.routing.setSyncLoading(true)

		let _route = store.routing.getRouteById(routeId)
		if (_route) {
			_route = {..._route}
			_route.points = store.routing.getRoutePointsIndexes(routeId)
			let result = await Routing.update(_route)

			if (result.code === 200) {
				store.routing.updateRoute(result.data)
			}
		}

		setTimeout(() => {
			store.routing.setSyncLoading(false)
		}, 500)
	}, 1000), [])

	return (
		<Wrapper className="routing-wrapper">
			<ReflexContainer orientation="horizontal">
				<ReflexElement
					minSize={250}
					flex={
						store.user.settings.routing.layout.topHeight ?
							store.user.settings.routing.layout.topHeight / (store.user.settings.routing.layout.topHeight + store.user.settings.routing.layout.bottomHeight)
							: .5
					}
					onResize={(e) => {
						mapRef.current.resize()
						store.user.setLayoutSettings('routing', 'topHeight', e.domElement.offsetHeight)
					}}
				>
					<ReflexContainer orientation="vertical" ref={topAreaRef}>
						<ReflexElement
							style={{position: 'relative'}}
							minSize={250}
							flex={
								store.user.settings.routing.layout.topLeftWidth ?
									store.user.settings.routing.layout.topLeftWidth / (store.user.settings.routing.layout.topLeftWidth + store.user.settings.routing.layout.topRightWidth)
									: .5
							}
							onResize={(e) => {
								mapRef.current.resize()
								store.user.setLayoutSettings('routing', 'topLeftWidth', e.domElement.offsetWidth)
							}}
						>
							<WrapperAbsolute>
								<TablePoints
									selectPoint={selectPoint}
									setShowMovePointModal={setShowMovePointModal}
									setEditPointItem={setEditPointItem}
									openUploadExcelModal={setShowModalUploadExcel}
								/>
							</WrapperAbsolute>
						</ReflexElement>

						<ReflexSplitter style={{width: 5}}/>

						<ReflexElement
							minSize={250}
							onResize={(e) => {
								store.user.setLayoutSettings('routing', 'topRightWidth', e.domElement.offsetWidth)
							}}
						>
							<Map mapRef={mapRef} selectPoint={selectPoint}/>
						</ReflexElement>
					</ReflexContainer>
				</ReflexElement>

				<ReflexSplitter style={{height: 5}}/>

				<ReflexElement minSize={250}
							   onResize={(e) => {
								   mapRef.current.resize()
								   store.user.setLayoutSettings('routing', 'bottomHeight', e.domElement.offsetHeight)
							   }}
				>
					<ReflexContainer orientation="vertical" ref={bottomAreaRef}>
						<ReflexElement
							style={{position: 'relative'}}
							minSize={250}
							flex={
								store.user.settings.routing.layout.bottomLeftWidth ?
									store.user.settings.routing.layout.bottomLeftWidth / (store.user.settings.routing.layout.bottomLeftWidth + store.user.settings.routing.layout.bottomRightWidth)
									: .5
							}
							onResize={(e) => {
								store.user.setLayoutSettings('routing', 'bottomLeftWidth', e.domElement.offsetWidth)
							}}
						>
							<WrapperAbsolute>
								<TableRoutes
									syncRoute={syncRoute}
									setEditRouteItem={setEditRouteItem}
									setShowRouteFormModal={setShowRouteFormModal}
								/>
							</WrapperAbsolute>
						</ReflexElement>

						<ReflexSplitter style={{width: 5}}/>

						<ReflexElement
							style={{position: 'relative'}}
							minSize={250}
							onResize={(e) => {
								store.user.setLayoutSettings('routing', 'bottomRightWidth', e.domElement.offsetWidth)
							}}
						>
							<WrapperAbsolute>
								<TableRoutePoints
									syncRoute={syncRoute} selectPoint={selectPoint}
									setShowMovePointModal={setShowMovePointModal}
									setEditPointItem={setEditPointItem}
									getPolyline={getPolyline}
								/>
							</WrapperAbsolute>
						</ReflexElement>
					</ReflexContainer>
				</ReflexElement>
			</ReflexContainer>

			<CatchKeyPress
				onEsc={() => {
					store.routing.setActiveRoute(null)
					mapRef.current.flyTo({
						center: [
							store.user.office.region_map_settings?.lng ?? 37.5877594,
							store.user.office.region_map_settings?.lat ?? 55.7421473,
						],
						zoom: store.user.office.region_map_settings?.zoom ?? 8.5,
						speed: 2,
					})
				}}
				onCtrlEnter={() => setShowRouteFormModal(true)}
			/>
			{showRouteFormModal && (
				<ModalRouteForm addPointItem={addPointItem}
								editRouteItem={editRouteItem}
								setEditRouteItem={setEditRouteItem}
								setShowRouteFormModal={setShowRouteFormModal} setAddPointItem={setAddPointItem}
				/>
			)}
			{store.routing.loading && (
				<Loading>
					<Spin indicator={<LoadingOutlined style={{fontSize: 50, color: "#FFFFFF"}} spin/>}/>
				</Loading>
			)}
			{editPointItem &&
			<ModalPointForm editPointItem={editPointItem} setEditPointItem={setEditPointItem}/>}
			{showMovePointModal && <ModalMovePoint setShowMovePointModal={setShowMovePointModal}/>}
			<ModalUploadExcel visible={showModalUploadExcel} onClose={() => setShowModalUploadExcel(false)}/>
		</Wrapper>
	)
}

export default observer(RoutingScreen)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  .reflex-splitter {
    background-color: #383838;
    border: 0 !important;
  }

  .ant-table-placeholder {
    display: none !important;
  }

  .reflex-container {
    position: static;
  }

  .reflex-container > .reflex-element {
    position: static;
  }

  /*#cursor {
    border-radius: 0 !important;
    border: 0 !important;
    margin-left: 35px;
    margin-top: 35px;
    font-size: 12px;
    color: #FF9500;
    text-shadow: 0px 0px 20px #000000;

    div {
      display: inline-block;
      white-space: nowrap;
      padding: 0px;
      box-shadow: 0px 0px 5px 5px rgba(42, 44, 44, 0.5);
      background-color: rgba(42, 44, 44, 0.7);
    }
  }*/
`
const WrapperAbsolute = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const Loading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: .6;
  z-index: 25;
`

