import { useEffect, useState } from "react"
import { AutoComplete, Form, Input, Select } from "antd"
import debounce from "lodash/debounce"
import { observer } from "mobx-react-lite"
import axios from "../../../inizialize/Axios"

const AsyncAutocomplete = ({
	field,
	renderItem = null,
	listField = "name",
}) => {
	const form = Form.useFormInstance()
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (form.getFieldValue(field.name)) {
			handlerSearchId(form.getFieldValue(field.name))
		}
	}, [form.getFieldValue(field.name)])

	const handlerSearch = async name => {
		if (!name) {
			return setOptions([])
		}
		setLoading(true)
		const result = await axios.get(field.extra.list_uri, { params: { _filters: { [listField]: name } } })
		setLoading(false)
		if (!result.success) {
			return setOptions([])
		}
		setOptions(result.data.list)

	}
	const handlerSearchId = async id => {
		if (!id) {
			return setOptions([])
		}
		setLoading(true)
		const result = await axios.get(field.extra.info_uri + "/" + id)
		setLoading(false)
		if (!result.success) {
			return setOptions([])
		}
		setOptions([result.data])

	}

	return (
		<Select
			showSearch={true}
			labelInValue={true}
			allowClear={true}
			filterOption={false}
			optionLabelProp={"children"}
			onSearch={debounce(handlerSearch, 200)}
			onChange={value => form.setFieldsValue({ [field.name]: value ? value.value : null })}
			value={{ value: form.getFieldValue(field.name) }}
		>
			{
				options.map(item => (
					<Select.Option key={"driver-" + item.id} value={item.id}>
						{renderItem ? renderItem(item) : item.name}
					</Select.Option>
				))
			}
		</Select>
	)
}

export default observer(AsyncAutocomplete)