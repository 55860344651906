import { __assign } from "tslib";
export var LEFT_AXES_VIEW = 'left-axes-view';
export var RIGHT_AXES_VIEW = 'right-axes-view';
export var DEFAULT_YAXIS_CONFIG = {
    nice: true,
    label: {
        autoHide: true,
        autoRotate: false,
    },
};
export var DEFAULT_LEFT_YAXIS_CONFIG = __assign(__assign({}, DEFAULT_YAXIS_CONFIG), { position: 'left' });
export var DEFAULT_RIGHT_YAXIS_CONFIG = __assign(__assign({}, DEFAULT_YAXIS_CONFIG), { position: 'right', grid: null });
