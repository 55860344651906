import { Alert } from "antd"
import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import { FormItem, ModalForm } from "../../../component/ModalForm"
import TransportLine from "../../../component/TransportLine"

const ModalSetTransportForm = ({ data, ...props }) => {

	return (
		<ModalForm
			defaultData={data}
			{...props}
		>
			{
				data && (
					<Alert
						message="Внимание"
						description="При привязке нового ТС, старое будет отвязано от водителя"
						type="warning"
						showIcon
						style={{ marginBottom: 30 }}
					/>
				)
			}
			<FormItem
				name={"transport_id"}
				listItemRender={transport => <TransportLine transport={transport} withNumber/>}
			/>
		</ModalForm>
	)
}

export default observer(ModalSetTransportForm)

const Wrapper = styled.div``